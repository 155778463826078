import { PayloadAction } from '@reduxjs/toolkit'
import { get } from 'lodash'

import { errorPayloadPath } from '#constants/common'
import intl from '#intl'
import { TListKeys } from '#reducers/commonTypes'

export const errorPath = 'error.message'

export function getErrorMessage<T>(action: PayloadAction<T>): string {
  return (get(action, errorPayloadPath) || get(action, errorPath) || intl.serverError) as string
}

interface IPrefixHelper {
  create(methodName: string): string
}
export class CreatePrefix implements IPrefixHelper {
  private readonly prefix: string
  public constructor(prefix: string) {
    this.prefix = prefix
  }
  public create(methodName: string): string {
    return `${this.prefix}/${methodName}`
  }
}

function getStructure<T extends { id: number }>(list: T[]): TListKeys<T> {
  return list.reduce(
    (acc: TListKeys<T>, cur) => ({
      allIds: [...acc.allIds, cur.id],
      byId: {
        ...acc.byId,
        [cur.id]: cur
      }
    }),
    { allIds: [], byId: {} }
  )
}

export function createPageListStructure<T extends { id: number }>(list: T[]): TListKeys<T> {
  const { allIds, byId } = getStructure(list)
  return {
    allIds,
    byId
  }
}

export function createPageListStructureDeep<T extends { id: number; children: T[] }>(
  list: T[]
): TListKeys<T> {
  const result: T[] = []

  function getAllElements(list: T[]): void {
    list.forEach((item) => {
      if (item.children && item.children.length > 0) {
        result.push(item)
        return getAllElements(item.children)
      }
      result.push(item)
    })
  }

  getAllElements(list)
  return getStructure(result)
}
