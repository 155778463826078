import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getServerFiles } from '#reducers/adminPage/serverFiles/effects'
import { getErrorMessage } from '#reducers/helper'
import { TMainDataStateType } from '#src/reducers'

import { TTreeNode } from '../../../../server/helpers/TreeNode'

export type TFetchServerFilesSucceed = {
  treeStructure: TTreeNode
}
export type TSetCurrentFolder = {
  folder: string
}
export type TSetCurrentFile = {
  file: TNullable<TTreeNode>
}

export type TState = {
  isLoading: boolean
  treeStructure: TNullable<TTreeNode>
  message: string
  currentFolder: string
  currentFile: TNullable<TTreeNode>
}
const initialState: TState = {
  isLoading: false,
  treeStructure: null,
  message: '',
  currentFolder: '',
  currentFile: null
}

const serverFilesSlice = createSlice({
  name: 'serverFiles',
  initialState,
  reducers: {
    setCurrentFolder: (state, { payload }: PayloadAction<TSetCurrentFolder>) => {
      state.currentFolder = payload.folder
    },
    setCurrentFile: (state, { payload }: PayloadAction<TSetCurrentFile>) => {
      state.currentFile = payload.file
    },
    purgeState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServerFiles.pending, (state) => {
        state.isLoading = true
        state.message = ''
      })
      .addCase(getServerFiles.fulfilled, (state, { payload }) => {
        if (payload) {
          state.isLoading = false
          state.treeStructure = payload.treeStructure
        }
      })
      .addCase(getServerFiles.rejected, (state, action) => {
        const message = getErrorMessage(action)
        state.isLoading = false
        state.message = message
      })
  }
})
export default serverFilesSlice.reducer

export const { setCurrentFolder, setCurrentFile, purgeState } = serverFilesSlice.actions

const serverFiles = (state: TMainDataStateType): TState => state.adminPage.serverFiles
export const isLoadingSelector = createSelector(serverFiles, (state) => state.isLoading)
export const treeStructureSelector = createSelector(serverFiles, (state) => state.treeStructure)
export const errorMessageSelector = createSelector(serverFiles, (state) => state.message)
export const currentFolderSelector = createSelector(serverFiles, (state) => state.currentFolder)
export const currentFileSelector = createSelector(serverFiles, (state) => state.currentFile)
