import * as AdminApiTypes from '#modules/api/admin/types'
import { adminApi } from '#src/modules/api'
import { CacheResponse } from '#src/modules/CacheResponse'

import { BASE_CONFIG as baseConfig } from '../constants/common'

const CACHE_PATH = '[fetchBackgroundImage][getLandingConfig]'

export type TProduct = 'all' | 'installment'

export type TProductConfig = {
  [key in string]: AdminApiTypes.IConfig
}

export class BannerWithTextConfig {
  private static instance: BannerWithTextConfig
  private _config: TNullable<TProductConfig> = null
  private product: TProduct = 'all'
  private readonly cache = CacheResponse.getInstance()

  private constructor() {}

  public static getInstance(): BannerWithTextConfig {
    if (!BannerWithTextConfig.instance) BannerWithTextConfig.instance = new BannerWithTextConfig()
    return BannerWithTextConfig.instance
  }

  get landingConfig(): TNullable<TProductConfig> {
    return this._config
  }

  set landingConfig(opt: TNullable<TProductConfig>) {
    this._config = opt
  }

  public async initLandingConfig(): Promise<TProductConfig> {
    if (!this.product) throw new Error('Не указан тип продукта')
    if (!['all', 'installment'].includes(this.product))
      throw new Error('Указан некорректный тип продукта')

    const cacheData = this.cache.getData<AdminApiTypes.IConfig>(`${CACHE_PATH}[${this.product}]`)
    if (cacheData) {
      return {
        [this.product]: { ...cacheData }
      }
    }
    try {
      const response = await adminApi.getLandingConfig(this.product)
      if (response.code === AdminApiTypes.ResponseCode.success) {
        const { data } = response
        this.cache.setData<AdminApiTypes.IConfig>(`${CACHE_PATH}[${this.product}]`, { ...data })
        return {
          [this.product]: { ...data }
        }
      }
      return {
        [this.getProduct()]: { ...baseConfig }
      }
    } catch (_err) {
      return {
        [this.getProduct()]: { ...baseConfig }
      }
    }
  }

  public setProduct(product: TProduct): BannerWithTextConfig {
    this.product = product
    return this
  }

  public getProduct(): TProduct {
    return this.product
  }
}
