import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { HttpClient } from '#src/modules/api/httpClient'

class FileTransferApi extends HttpClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this._initializeRequestInterceptor()
    this.initializeResponseInterceptor()
  }

  private readonly initializeResponseInterceptor = (): void => {
    this.instance.interceptors.response.use(this.fileTransferResponseSuccess, this.onResponseError)
  }

  private readonly fileTransferResponseSuccess = (response: AxiosResponse): AxiosResponse =>
    response
}

const fileTransfer = new FileTransferApi({})

export default fileTransfer.instance
