import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { getClientData } from '#reducers/clientData/selectors'
import { CreatePrefix } from '#reducers/helper'
import { personalApi } from '#src/modules/api'
import { IFreezeStructure } from '#src/modules/api/personal/types'

type TActionClientFreezeStateSuccess = {
  data: TNullable<IFreezeStructure>
}

interface IFreezeInitialState {
  loading: boolean
  data: TNullable<IFreezeStructure>
}

const initialState: IFreezeInitialState = {
  loading: false,
  data: null
}

const prefixHelper = new CreatePrefix('creditHistory')

export const fetchClientFreezeStateRequest = createAction(
  prefixHelper.create('fetchClientFreezeStateRequest')
)

export const fetchClientFreezeStateSuccess = createAction<TActionClientFreezeStateSuccess>(
  prefixHelper.create('fetchClientFreezeStateSuccess')
)

export const fetchClientFreezeStateFailure = createAction(
  prefixHelper.create('fetchClientFreezeStateFailure')
)

export const fetchClientFreezeState = createAsyncThunk(
  prefixHelper.create('freeze/getFreeze'),
  async ({ token, creditId }: { token: string; creditId: number }, thunkAPI) => {
    thunkAPI.dispatch(fetchClientFreezeStateRequest())
    try {
      const response = await personalApi.getFreezeState({ token, creditId })
      if (response.code !== 0) throw response
      thunkAPI.dispatch(fetchClientFreezeStateSuccess(response))
    } catch (err) {
      thunkAPI.dispatch(fetchClientFreezeStateFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const freezeSlice = createSlice({
  name: 'freezeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchClientFreezeStateRequest, (state) => {
        state.loading = true
      })
      .addCase(fetchClientFreezeStateSuccess, (state, action) => {
        state.data = action.payload.data
        state.loading = false
      })
      .addCase(fetchClientFreezeStateFailure, (state) => {
        state.loading = false
      })
})

export const freezeStructureSelector = createSelector(
  getClientData,
  (clientData) => clientData.freeze
)

export const freezeDataSelector = createSelector(freezeStructureSelector, (freeze) => freeze.data)
export const freezeLoadingSelector = createSelector(
  freezeStructureSelector,
  (freeze) => freeze.loading
)
export const freezeIdSelector = createSelector(freezeDataSelector, (data) => data?.params?.freezeId)

export default freezeSlice.reducer
