import querystring, { ParsedQuery } from 'query-string'
import { Cookies } from 'react-cookie'

import { logError } from '#hook/useErrorLogger'
import { mainSiteApi } from '#modules/api'

const cookies = new Cookies()

type TVisitParameters = {
  parameters: ParsedQuery
  partnerParameters: string
  referer: string
}
export class UserStatistics {
  public async sendIncomingStat(): Promise<void> {
    try {
      const formData = this.preparePartnerFormData()
      if (formData.has('c4s')) {
        const extraData = this.getNavigatorExtraData()
        formData.append('extraData', JSON.stringify(extraData))
        return await mainSiteApi.saveIncomingStat(formData)
      }
    } catch (error) {
      void logError(error)
    }
  }

  public getVisitParameters(): TVisitParameters {
    const referer = document.referrer
    let parametersString = this.getSavedParametersString()
    let parameters = this.getSavedParameters()
    if (this.hasLocationParameters()) {
      const newParams = this.getParsedParameters()
      parameters = { ...parameters, ...newParams }
      parametersString = querystring.stringify(parameters)
    }
    return {
      parameters,
      partnerParameters: parametersString,
      referer
    }
  }

  public supportsLocalStorage(): boolean {
    try {
      return 'localStorage' in window && window['localStorage'] !== null
    } catch (e) {
      return false
    }
  }

  private preparePartnerFormData(): FormData {
    const { partnerParameters, referer } = this.getVisitParameters()
    const fd = new FormData()
    const deviceId = this.getDeviceIdFromStorage()
    fd.append('partnerParameters', partnerParameters)
    fd.append('referer', referer || '')
    deviceId && fd.append('c4s', deviceId)
    return fd
  }

  private readonly getNavigatorExtraData = (): {
    userAgent: string
    cookieEnabled: boolean
  } => {
    const {
      navigator: { userAgent, cookieEnabled = false }
    } = window
    return {
      userAgent,
      cookieEnabled
    }
  }

  private getSavedParametersString(): string {
    return (cookies.get('partnerParameters') as string | undefined) || ''
  }

  private readonly getDeviceIdFromStorage = (): TNullable<string> => {
    try {
      let deviceId = (cookies.get('c4s') as string) || null
      if (!deviceId && this.supportsLocalStorage()) deviceId = window.localStorage.getItem('c4s')
      if (!deviceId && window && window.sessionStorage)
        deviceId = window.sessionStorage.getItem('c4s')
      return deviceId
    } catch (_err) {
      return null
    }
  }

  private getSavedParameters(): ParsedQuery {
    return querystring.parse(this.getSavedParametersString())
  }

  private hasLocationParameters(): boolean {
    return window.location.search.length > 0
  }

  private getParsedParameters(): ParsedQuery {
    const { query } = querystring.parseUrl(window.location.href)
    return query
  }
}
