import { createSelector } from '@reduxjs/toolkit'

import { TClientDataStateType } from '#reducers/clientData/index'
import { IPersonGroupState } from '#reducers/clientData/personGroups/types'
import { TPersonalRootState } from '#reducers/entries/personal'

export const getClientData = (state: TPersonalRootState): TClientDataStateType => state.clientData
export const getPersonGroups = (state: TPersonalRootState): IPersonGroupState =>
  state.clientData.personGroups
export const personGroupsSelector = createSelector(
  getPersonGroups,
  (personGroups) => personGroups.data
)
export const personGroupsFetchedSelector = createSelector(
  getPersonGroups,
  (personGroups) => personGroups.isFetched
)
