import { AxiosError } from 'axios'
import { get, has } from 'lodash'

import { AJAX_CONFIG_FIELD_NAME, RESPONSE_CODE_FIELD_NAME } from '#constants/common'
import reportError, { GUID_HEADER_NAME } from '#services/reportError'

const CODES_TO_REPORT = [
  1,
  'ERR_FR_TOO_MANY_REDIRECTS',
  'ERR_BAD_OPTION_VALUE',
  'ERR_BAD_OPTION',
  'ERR_NETWORK',
  'ERR_DEPRECATED',
  'ERR_BAD_RESPONSE',
  'ERR_BAD_REQUEST',
  'ERR_NOT_SUPPORT',
  'ERR_INVALID_URL',
  'ERR_CANCELED',
  'ECONNABORTED',
  'ETIMEDOUT'
]

export type TLogError = (e: any, callPoint?: TNullable<string>) => void

export const logError = (
  errObj: unknown,
  callPoint: TNullable<string> = null
): void | Promise<void> => {
  try {
    const hasCustomConfig = typeof errObj === 'object' && has(errObj, AJAX_CONFIG_FIELD_NAME)
    if (!hasCustomConfig) return reportError(errObj, {}, logError)

    const errorData = errObj as AxiosError & { [AJAX_CONFIG_FIELD_NAME]?: unknown }

    const shouldReportThisAjaxError =
      errorData &&
      errorData[RESPONSE_CODE_FIELD_NAME] &&
      CODES_TO_REPORT.includes(errorData[RESPONSE_CODE_FIELD_NAME])
    if (shouldReportThisAjaxError) {
      const { [AJAX_CONFIG_FIELD_NAME]: config = {}, ...rest } = errorData
      const guid = get(config, ['headers', GUID_HEADER_NAME], '') as string
      void reportError(
        rest,
        { guid, append: { ajaxConfig: JSON.stringify(config, null, 2), callPoint } },
        logError
      )
      return
    }

    console.error(errorData)
  } catch (e) {
    console.error(e)
  }
}
const useErrorLogger = (): [TLogError] => [logError]

export default useErrorLogger
