import { createAsyncThunk } from '@reduxjs/toolkit'
import { isEmpty, last, pickBy } from 'lodash'

import intl from '#intl'
import { baseApi, mainSiteApi } from '#modules/api'
import { TArticle } from '#modules/api/admin/types'
import { IGetPageSuccess } from '#modules/api/base'
import {
  rejectedFecthArticle,
  setArticleData,
  TArticleInitialState
} from '#reducers/article/articleSlice'
import { CreatePrefix } from '#reducers/helper'

const prefixHelper = new CreatePrefix('article')

const SEP = '/'

export enum PageTypeEnum {
  LIST = 'articlesList',
  CONTENT = 'articleContent'
}

export enum RubricPathEnum {
  PERSONAL_FINANCE = 'personalfinance',
  LOANS = 'loans',
  FINANCIAL_LITERACY = 'financialliteracy',
  FAMILY = 'family'
}

export enum SectionNameEnum {
  NEWS = 'news',
  PRESS = 'press',
  ACTIONS = 'actions',
  BLOG = 'blog',
  AFTER_SUBSCRIBE = 'after_subscribe'
}

const SECTION_NAMES = Object.values(SectionNameEnum)
export const RUBRIC_NAMES = Object.values(RubricPathEnum)
export const PREFIX = 'page'
const AFTER_SUBSCRIBE = 'after_subscribe'

const RECOMMENDED_TYPES = {
  news: 0,
  actions: 3,
  blog: 3,
  press: 0,
  [AFTER_SUBSCRIBE]: 3
}

interface ISeoInfo {
  title: string
  seoTitle: TNullable<string>
  seoDescription: TNullable<string>
}

enum LoadedDataTypeEnum {
  UNKNOWN_TYPE = 'unknownType'
}

const buildNormalizedPathsForBlog = (
  pathArr: string[]
): (string | number | (string | number)[])[] => {
  const recommendedType = recommendedAnnounceType(SectionNameEnum.BLOG)
  const [sectionName, rubric, prefixOrArticleName, pageNumber] = pathArr as [
    SectionNameEnum,
    RubricPathEnum,
    string,
    number
  ]
  if (!RUBRIC_NAMES.includes(rubric) && !isEmpty(rubric)) return [[...pathArr], recommendedType]
  if (!rubric) return [[sectionName, PREFIX, 1], recommendedType]
  if (!prefixOrArticleName && !pageNumber)
    return [[sectionName, rubric, PREFIX, 1], recommendedType]
  if (prefixOrArticleName === PREFIX)
    return [[sectionName, rubric, PREFIX, pageNumber || 1], recommendedType]
  return [pathArr, recommendedType]
}

const loadRecommendedList = async (
  id: number,
  type = 0,
  rubric: number
): Promise<null | TArticle[]> => {
  if (!id) return null
  const response = await mainSiteApi.getAnnounces(['', 'recommended', id].join(SEP), {
    type,
    rubric
  })
  if (response.code || isEmpty(response.data)) return null
  return response.data
}

export const getContentByPathExtraContent = async (
  pathExtraContent: TNullable<string[]>
): Promise<TNullable<IGetPageSuccess>> => {
  try {
    if (pathExtraContent) return await loadContent(pathExtraContent)
    return null
  } catch (err) {
    return null
  }
}

export const buildNormalizedPaths = (
  pathname: string
): (string | number | (string | number)[])[] => {
  const pathArr = pathname.split(SEP).filter(Boolean)
  const [sectionName, prefixOrArticleName, pageNumber = null] = pathArr as [
    SectionNameEnum,
    string,
    TNullable<number>
  ]
  if (sectionName === 'blog') return buildNormalizedPathsForBlog(pathArr)
  const recommendedType = recommendedAnnounceType(sectionName)
  if (!SECTION_NAMES.includes(sectionName)) return [[PREFIX, ...pathArr], recommendedType]
  if (!prefixOrArticleName && !pageNumber)
    return [[sectionName, PREFIX, 1], recommendedType, [PREFIX, sectionName]]
  if (prefixOrArticleName === PREFIX)
    return [[sectionName, PREFIX, pageNumber || 1], recommendedType, [PREFIX, sectionName]]
  return [pathArr, recommendedType]
}

function recommendedAnnounceType(sectionName: SectionNameEnum): number {
  if (SECTION_NAMES.includes(sectionName) || AFTER_SUBSCRIBE === sectionName)
    return RECOMMENDED_TYPES[sectionName as keyof typeof RECOMMENDED_TYPES]
  return 0
}

export const loadContent = async (pathsArray: string[]): Promise<IGetPageSuccess> => {
  const response = await baseApi.getPage(pathsArray)
  if (response.code !== 0 || isEmpty(response.data)) throw response
  return response
}

type TProcessLoadedContentReturn = string | number | boolean
const processLoadedContent = (
  content: IGetPageSuccess
): { [key in keyof TArticleInitialState]: TArticleInitialState[key] } | TEmptyObject => {
  if (isEmpty(content)) return {}
  const { data } = content
  if (isEmpty(data)) return {}

  // items и totalItems появляются только для списков с пагинацией, таблица news
  const { items, totalItems = 0 } = data

  const {
    id,
    title,
    seoTitle,
    text,
    seoKeywords,
    seoDescription,
    description,
    keywords,
    type,
    headline = null,
    publishDate,
    imageSource,
    modifyDate,
    isShowUpdateDate,
    isShowDate,
    rubric = null,
    rubricText = null,
    canonicalLink = null,
    isShowRecommendedList,
    authorData,
    timeToRead
  } = data

  const unfilteredData = {
    articleId: id,
    title,
    text,
    headline: headline || title,
    contentList: totalItems && Array.isArray(items) ? items : null,
    lastPage: Math.floor(totalItems / 10),
    totalRows: Number(totalItems),
    seoTitle,
    seoKeywords: seoKeywords || keywords,
    seoDescription: seoDescription || description,
    type: Number(type),
    publishDate,
    imageSource,
    modifyDate,
    isShowUpdateDate: Boolean(isShowUpdateDate),
    isShowDate: Boolean(isShowDate),
    rubric,
    rubricText,
    canonicalLink,
    isShowRecommendedList: Boolean(isShowRecommendedList),
    authorData,
    timeToRead
  }

  // @ts-ignore
  return pickBy<TProcessLoadedContentReturn>(unfilteredData)
}

export const defineLoadedDataType = (
  data: TArticleInitialState | TEmptyObject = {}
): LoadedDataTypeEnum | PageTypeEnum => {
  if (data.text && !data.contentList) return PageTypeEnum.CONTENT
  if (data.contentList && !data.text) return PageTypeEnum.LIST
  return LoadedDataTypeEnum.UNKNOWN_TYPE
}

export const getCurrentPage = (pathsArray: string[]): TNullable<number> =>
  Number(last(pathsArray)) || null

export const buildPageTitle = ([sectionPath]: [
  Exclude<SectionNameEnum, SectionNameEnum.AFTER_SUBSCRIBE>
]): ISeoInfo => {
  const sectionTitle = {
    news: { title: intl.newsTitle, seoTitle: intl.newsHeadTitle, seoDescription: null },
    press: { title: intl.smiAboutUs, seoTitle: intl.smiAboutUsHeadTitle, seoDescription: null },
    actions: { title: intl.actions, seoTitle: intl.actionsHeadTitle, seoDescription: null },
    blog: {
      title: intl.blogTitle,
      seoTitle: intl.blogHeadTitle,
      seoDescription: intl.blogDescription
    }
  }[sectionPath]
  const defaultTitle = {
    title: intl.pageDocumentDefaultTitle,
    seoTitle: null,
    seoDescription: null
  }
  return sectionTitle || defaultTitle
}

export const buildBlogPageInfo = (sectionPath: string): ISeoInfo =>
  ({
    personalfinance: {
      title: intl.personalFinance,
      seoTitle: intl.personalFinanceHeadTitle,
      seoDescription: intl.personalFinanceDescription
    },
    loans: {
      title: intl.aboutLoansSimply,
      seoTitle: intl.aboutLoansHeadTitle,
      seoDescription: intl.aboutLoansDescription
    },
    financialliteracy: {
      title: intl.financialLiteracy,
      seoTitle: intl.financialLiteracyHeadTitle,
      seoDescription: intl.financialLiteracyDescription
    },
    family: {
      title: intl.familyAndChildren,
      seoTitle: intl.familyHeadTitle,
      seoDescription: intl.familyDescription
    }
  }[sectionPath] as ISeoInfo)

export const fetchArticle = createAsyncThunk(
  prefixHelper.create('fetchArticle'),
  async (pathname: string, thunkAPI) => {
    const [pathsArray, recommendedType, pathExtraContent = null] = buildNormalizedPaths(pathname)

    try {
      const response = await loadContent(pathsArray as string[])

      const extraData = await getContentByPathExtraContent(pathExtraContent as string[])
      const processedData = processLoadedContent(response)
      const processedExtraData = processLoadedContent(extraData as IGetPageSuccess)

      const type = defineLoadedDataType(processedData)
      if (type === LoadedDataTypeEnum.UNKNOWN_TYPE) return thunkAPI.rejectWithValue({})

      const recommendedList =
        type === PageTypeEnum.CONTENT && processedData.isShowRecommendedList
          ? await loadRecommendedList(
              processedData.articleId as number,
              recommendedType as number,
              processedData.rubric as number
            )
          : null

      const [sectionName, rubric, prefix] = pathsArray as [
        Exclude<SectionNameEnum, SectionNameEnum.AFTER_SUBSCRIBE>,
        RubricPathEnum,
        string
      ]
      const pageInfo = RUBRIC_NAMES.includes(rubric)
        ? buildBlogPageInfo(rubric)
        : buildPageTitle(pathsArray as [Exclude<SectionNameEnum, SectionNameEnum.AFTER_SUBSCRIBE>])
      const pageTitle = processedData.title || pageInfo.title
      const payload = {
        ...processedData,
        pageType: type,
        title: pageTitle,
        seoTitle: processedData.seoTitle || pageInfo?.seoTitle || pageTitle,
        seoDescription: processedData.seoDescription || pageInfo?.seoDescription,
        currentPage: getCurrentPage(pathsArray as string[]),
        recommendedList,
        hasExtraContent: Boolean(extraData),
        pathname,
        authorData: processedData?.authorData,
        timeToRead: processedData?.timeToRead,
        rubricPath: sectionName === 'blog' && prefix === PREFIX ? rubric : null,
        ...processedExtraData
      }
      // @ts-ignore
      thunkAPI.dispatch(setArticleData(payload))
    } catch (e) {
      thunkAPI.dispatch(rejectedFecthArticle())
      return thunkAPI.rejectWithValue({})
    }
  }
)
