import './overlay.scss'

import { ClickAwayListener } from '@material-ui/core'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import classnames from 'classnames'
import { isEmpty, last, noop, uniqueId } from 'lodash'
import { CSSProperties, FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { Portal } from 'react-portal'

import { KEY_CODES } from '#constants/common'

const DEFAULT_OPACITY = 0.75
const overlayStack: string[] = []

interface IOverlayProps {
  className?: string
  opacity?: number
  isOpen: boolean
  allowClickAway?: boolean
  onClose?: () => void
  style?: CSSProperties
  isCamera: boolean
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const Overlay: FC<PropsWithChildren<IOverlayProps>> = (props) => {
  const {
    className,
    opacity = DEFAULT_OPACITY,
    children,
    onClose = noop,
    isOpen,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    allowClickAway,
    style,
    isCamera
  } = props

  const targetRef = useRef<HTMLDivElement>(null)
  const id = useRef(uniqueId())

  const handleEsc = useCallback(
    (modalId: string) => (evt: { keyCode: number }): void => {
      if (modalId !== last(overlayStack)) return
      evt.keyCode === KEY_CODES.esc && onClose()
    },
    [onClose]
  )

  const attachEventListeners = useCallback((): void => {
    if (isEmpty(overlayStack)) {
      document.getElementById('app')?.classList.add('main-blurred', 'no-scroll')
      targetRef.current && disableBodyScroll(targetRef.current)
    }
    document.addEventListener('keydown', handleEsc(id.current))
  }, [handleEsc])

  const detachEventListeners = useCallback((): void => {
    if (isEmpty(overlayStack)) {
      document.getElementById('app')?.classList.remove('main-blurred', 'no-scroll')
      clearAllBodyScrollLocks()
    }
    document.removeEventListener('keydown', handleEsc(id.current))
  }, [handleEsc])

  const isLastFromStack = (): boolean => last(overlayStack) === id.current

  useEffect(() => {
    if (isOpen) {
      attachEventListeners()
      overlayStack.push(id.current)
    }
    if (!isOpen && isLastFromStack()) {
      overlayStack.pop()
      detachEventListeners()
    }
  }, [attachEventListeners, detachEventListeners, isOpen])

  useEffect(
    () => () => {
      if (isOpen && isLastFromStack()) {
        overlayStack.pop()
        detachEventListeners()
      }
      if (isCamera) {
        overlayStack.splice(0, overlayStack.length)
        detachEventListeners()
      }
    },
    [detachEventListeners, isOpen]
  )

  const handleClickAway = useCallback((): void => {
    if (allowClickAway) onClose()
  }, [allowClickAway, onClose])

  if (!isOpen) return null

  return (
    <Portal>
      <div className='overlay' ref={targetRef} style={{ backgroundColor: `rgb(0,0,0,${opacity})` }}>
        <div
          data-qa='overlayBody'
          className={classnames({ 'overlay-body': true }, className)}
          style={style}
        >
          {allowClickAway ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>{children}</div>
            </ClickAwayListener>
          ) : (
            children
          )}
        </div>
      </div>
    </Portal>
  )
}

export default Overlay
