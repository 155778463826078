import './modal.scss'

import { Portal } from '@material-ui/core'
import classnames from 'classnames'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'

import intl from '#intl'
import { buildUrl, preventWindowScroll } from '#services/helper'
import notFoundImage from '#src/assets/img/404.png'
import { baseApi, mainSiteApi } from '#src/modules/api'

import { Icon } from '../Icon/Icon'
import { Spinner } from '../Spinner/Spinner'

export class Modal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.title
    }
    this.modalBodyRef = createRef()
    this.modalWrapperRef = createRef()

    this.isOutsideEvent = this.isOutsideEvent.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleBodyScroll = this.handleBodyScroll.bind(this)
  }

  componentDidMount() {
    const { url } = this.props

    if (typeof url !== 'undefined') this.loadContent(url)

    preventWindowScroll(true)
    document.addEventListener('mousedown', this.handleOutsideClick)
    document.addEventListener('mouseup', this.handleOutsideClick)
    document.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    preventWindowScroll(false)
    document.removeEventListener('mousedown', this.handleOutsideClick)
    document.removeEventListener('mouseup', this.handleOutsideClick)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  isOutsideEvent(event, target) {
    return !(event.target === target || event.path.filter((item) => item === target)[0])
  }

  loadContent() {
    const { url, data, onUploadContentSuccess, onUploadContentFailure } = this.props
    let request
    this.setState({ loading: true })

    if (url) {
      let _url = null
      if (url.includes('http')) _url = new URL(url)
      else _url = { pathname: url }
      _url = buildUrl('page' + _url.pathname)
      request = baseApi.getPage(
        _url
          .toLowerCase()
          .split('/')
          .filter((v) => v)
      )
    } else {
      request = mainSiteApi.getContent(data)
    }
    request
      .then((response) => {
        const state = { loading: false }
        // eslint-disable-next-line promise/always-return
        if (response.data && response.data.text) {
          state.title = response.data.title
          state.content = response.data.text
        } else {
          state.children = <div>{response.message}</div>
        }

        this.setState(state)
        onUploadContentSuccess(state)
      })
      .catch((err) => {
        const _503 = (
          <div className='modal__404'>
            <div className='text-secondary fw-bolder'>{intl.netWorkErr}</div>
          </div>
        )
        const _404 = (
          <div className='modal__404'>
            <img src={notFoundImage} />
            <div>{intl.pageNotFound}</div>
          </div>
        )
        this.setState({
          loading: false,
          children: err?.code === 503 ? _503 : _404
        })
        onUploadContentFailure(err)
      })
  }

  handleBodyScroll() {
    if (this.modalBodyRef.current.scrollTop > 0) this.setState({ headerShadow: true })
    else this.setState({ headerShadow: false })
  }

  handleOutsideClick(event) {
    const { onClose } = this.props
    if (this.isOutsideEvent(event, this.modalWrapperRef.current) && event.type === 'mousedown')
      onClose()
  }

  handleKeyUp(event) {
    const { onClose } = this.props

    if (event.keyCode === 27) onClose()
  }

  render() {
    const { onClose, forceAppendChildrenToBody } = this.props
    const { content, headerShadow } = this.state
    const children = this.state.children || this.props.children
    const loading = this.state.loading || this.props.loading

    const className = classnames(
      {
        modal: true
      },
      this.props.className
    )
    const contentStyle = this.props.contentStyle || {}
    const bodyStyle = this.props.bodyStyle || {}
    return (
      <Portal>
        <div className={className}>
          {!loading ? (
            <div className='modal__window' ref={this.modalWrapperRef}>
              <div
                className={classnames({ modal__header: true, modal__header_shadow: headerShadow })}
              >
                <span className='modal__close' onClick={onClose}>
                  <Icon name='close' />
                </span>
              </div>
              <div
                className='modal__body'
                style={bodyStyle}
                ref={this.modalBodyRef}
                onScroll={this.handleBodyScroll}
              >
                {content && (
                  <div
                    className='modal__content'
                    style={contentStyle}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}
                {(!content || forceAppendChildrenToBody) && children}
              </div>
            </div>
          ) : (
            <Spinner className='modal__spinner' />
          )}
        </div>
      </Portal>
    )
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.any,
  url: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.any,
  onClose: PropTypes.func,
  forceAppendChildrenToBody: PropTypes.bool,
  onUploadContentSuccess: PropTypes.func,
  onUploadContentFailure: PropTypes.func,
  contentStyle: PropTypes.object,
  bodyStyle: PropTypes.object
}

Modal.defaultProps = {
  onUploadContentSuccess: noop,
  onUploadContentFailure: noop
}
