import axios from 'axios'

import appConfig from '#config'

type TServerTimeResponse = {
  data: {
    timestamp: number
  }
}

export const serverTime = (): Promise<TServerTimeResponse> =>
  axios.get(new URL('/time_service/timestamp', appConfig.apiHost).href)

export default serverTime
