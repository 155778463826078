import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { mainSiteApi } from '#modules/api'
import { ResponseCode } from '#modules/api/types/common'
import { initAbSettings } from '#reducers/abTest/abTestSlice'
import { TPersonalRootState } from '#reducers/entries/personal'
import CacheResponse from '#src/modules/CacheResponse'
import { TRawSettings } from '#src/services/helper'

type TSettings<T> = {
  [key: string]: T
}

export type TSettingsParsed = TSettings<TSettingParsedValue>
type TSettingParsedValue = Record<string, any> | Array<any> | number | string | null

export type TFetchAppSettingsSuccess = {
  payload: {
    data: TSettingsParsed
  }
}
const fetchAppSettingsKey = 'settingsActions/fetchAppSettings'

export const fetchAppSettings = createAsyncThunk(
  'settings/fetchAppSettings',
  async (_, thunkAPI) => {
    let data: TSettingsParsed
    if (CacheResponse.hasData(fetchAppSettingsKey)) {
      data = CacheResponse.getData(fetchAppSettingsKey) as TSettingsParsed
    } else {
      const response = await mainSiteApi.getSettings()
      if (response.code === ResponseCode.success) {
        const { data: responseData } = response
        data = responseData
        CacheResponse.setData(fetchAppSettingsKey, responseData)
      } else {
        data = {}
      }
    }
    if (data.abTest) thunkAPI.dispatch(initAbSettings(data.abTest as TRawSettings))

    return data
  }
)

export interface ISettingsInitialState {
  fetching: boolean
  data: TSettingsParsed
}

export const initialState: ISettingsInitialState = {
  fetching: false,
  data: {}
}

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppSettings.pending, (state) => {
        state.fetching = true
        state.data = {}
      })
      .addCase(fetchAppSettings.rejected, (state) => {
        state.fetching = false
        state.data = {}
      })
      .addCase(fetchAppSettings.fulfilled, (state, action) => {
        state.fetching = false
        state.data = { ...state.data, ...action.payload }
      })
  }
})

export default settingsSlice.reducer

const getSettings = (state: TPersonalRootState): TSettingsParsed => state.settings.data
export const userInterfaceSettingsSelector = createSelector(
  getSettings,
  (settings) => (settings.user_interface || {}) as Record<string, string | number>
)
export const legalCheck = createSelector(getSettings, (settings) => settings.legalCheck)
