import { CSSProperties, FC, useEffect, useMemo, useRef } from 'react'

import { FormItem } from '#components/Form'
import { SmartControlCheckbox } from '#components/SmartControl/SmartControlCheckbox'

type TPrivateAgreement = {
  error: string
  name: string
  id?: string
  type: string
  placeholder: string
  required: boolean
  disabled: boolean
  style?: CSSProperties
  value: string
  handleModalLinkClick: (event: MouseEvent) => void
}

export const PrivateAgreementLinks: FC<TPrivateAgreement> = (props) => {
  const { name, value, error, handleModalLinkClick, ...item } = props
  const id = props?.id || name
  const checkboxRef = useRef<HTMLDivElement>(null)

  const handler = useMemo(
    () =>
      function (event: MouseEvent) {
        return handleModalLinkClick(event)
      },
    [handleModalLinkClick]
  )

  useEffect(() => {
    const links = checkboxRef.current?.querySelectorAll?.('[data-document]') ?? []
    // @ts-ignore
    Array.from(links).forEach((link: HTMLElement) => link.addEventListener('click', handler))
    return () => {
      // @ts-ignore
      Array.from(links).forEach((link: HTMLElement) => link.removeEventListener('click', handler))
    }
  }, [handler])

  return (
    <FormItem ref={checkboxRef} name={name} {...item} error={error}>
      <SmartControlCheckbox {...item} id={id} name={name} value={value} valid={!error} />
    </FormItem>
  )
}
