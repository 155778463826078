import { CancelToken } from 'axios'

import { TButtonType } from '#components/AdminPage/NotificationSettings/ButtonCard/ButtonForm/types'
import { FormActions } from '#components/AdminPage/PersonalAreaPromoSettings/PromoBannerForm/model'
import { LoanProductTypes } from '#modules/api/personal/enums'
import {
  IArticleCodeError,
  ICodeError,
  ICodeSuccess,
  ICodeWithMessage,
  IContentCodeError
} from '#src/modules/api/types/common'

interface IEditLandingConfigSuccess extends ICodeWithMessage {
  id: string
}

interface ILandingConfigSuccess extends ICodeWithMessage {
  data: IConfig
}

type TTextData = {
  title: string
  description: string
  link: string
  periodStart: TNullable<string>
  periodFinish: TNullable<string>
}

interface ILandingTextConfigSuccess extends ICodeWithMessage {
  data: Record<TLandingConfigDirections, TTextData>
}

type TLandingConfigDirections = 'main' | 'promo'

type TLandingViewTypes = Exclude<TViewTypes, 'mobile'>

type TViewBanner = Record<TViewTypes, TBanner[]>
type TPersonViewBanner = Record<TPersonGroups, TViewBanner>

interface IPersonalBannersSuccess extends ICodeWithMessage {
  data: TPersonViewBanner
}

interface IRawPersonalBannersSuccess extends ICodeWithMessage {
  data: TBanner[]
  isRaw: boolean
}

interface IPersonalBannerSuccess extends ICodeWithMessage {
  data: TBanner
  isRaw: boolean
}

interface IPersonalBannerWithStatus extends ICodeWithMessage {
  data: TViewBanner
}

export type TStatusInfo = {
  id: number
  name: string
  color: TNullable<string>
  progress: TNullable<number>
  text: string
  personGroupId: TNullable<number>
}

export type TPersonStatus = Record<TPersonGroups, TStatusInfo>

interface IPersonGroupInfoSuccess extends ICodeWithMessage {
  data: TPersonStatus
}

type TDeviceType = Record<TViewTypes, TDevice>

interface IDeviceTypesSuccess extends ICodeWithMessage {
  data: TDeviceType
}

export type TProductDescription = {
  id: number
  groupId: number
  creditType: LoanProductTypes
  status: string
  content: string
  termMin: number
  termMax: number
  isActive: number
}

interface IPersonProductDescriptionWithStatusSuccess extends ICodeWithMessage {
  data: TProductDescription[]
}

export interface IPersonProductDescriptionSuccess extends ICodeWithMessage {
  data: TProductDescription[]
}

export type TProductDescriptionByIdData = {
  content: string
  productId: number
  persongroupId: number
  termMin: number
  termMax: number
  isActive: number
}

export interface IPersonProductDescriptionByIdSuccess extends ICodeWithMessage {
  data: TProductDescriptionByIdData
}

interface IPersonalBanner {
  id: number
  promoHeader: string
  promoDescription: string
  promoLinkUrl: string
  promoLinkText: string
  file: string
  periodStart: TNullable<string>
  periodFinish: TNullable<string>
}

type TSelectedRows = Pick<IPersonalBanner, 'id'> & Partial<TBannerDescription>

type TLandingBanner = {
  id: number
  backgroundImage: string
  periodStart: TNullable<string>
  periodFinish: TNullable<string>
  type: TLandingViewTypes
}

type TLandingBannerViews = Record<TLandingViewTypes, TLandingBanner>

interface ILandingBannerConfigSuccess extends ICodeWithMessage {
  banners: TLandingBanners
}

interface IAddNotificationButtonSuccess extends ICodeWithMessage {
  data: TButtonListItem
}

interface INotificationButtonListSuccess extends ICodeWithMessage {
  data: TButtonListItem[]
}

type TPersonGroup = {
  id: number
  text: string
  orderId: number
}

interface INotificationPersonGroupsSuccess extends ICodeWithMessage {
  data: TPersonGroup[]
}

type TButton = {
  id: number
  url: TNullable<string>
}

interface IAddNotificationSuccess extends ICodeWithMessage {
  data: TNotificationListItem
}

interface INotificationListSuccess extends ICodeWithMessage {
  data: TNotificationListItem[]
}

interface IArticlesListResponseSuccess extends ICodeWithMessage {
  data: {
    itemsPerPage: number
    totalItems: number
    currentPage: number
    items: TArticle[]
  }
}

interface ICreateArticleSuccess extends ICodeWithMessage {
  data: {
    articleId: number
  }
}

interface IGetFullArticleDataSuccess extends ICodeWithMessage {
  data: {
    article: TArticle
  }
}

type TRoles = string[]

interface IGetProfileSuccess extends ICodeWithMessage {
  data: TRoles
}

interface IGetAuthors extends ICodeWithMessage {
  data: IAuthor[]
}

interface IGetAuthor extends ICodeWithMessage {
  data: IAuthor
}

interface IRemoveAuthorSuccess extends ICodeWithMessage {
  id: number
}

interface ICancelToken {
  cancelToken?: CancelToken
}

interface IGetPageListSuccess extends ICodeWithMessage {
  data: TPageListItemWithPaginationInfo
}

interface IGetPageItemHistorySuccess extends ICodeWithMessage {
  data: TPageListItem[]
}

interface IChangePageItemVisibilitySuccess extends ICodeWithMessage {
  data: TPageListItem
}

interface IGetPageItemContentSuccess extends ICodeWithMessage {
  data: TPageItemContent
}

type TLitePageGroup = Omit<TPageGroup, 'isUse'>

interface IGetSitemapSuccess extends ICodeWithMessage {
  data: TSitemapItem[]
}

interface IBasePageGroupFields {
  id: number
  name: string
  isUse: number
  order: number
}

interface IGetPageGroupListSuccess extends ICodeWithMessage {
  data: TPageGroup[]
}

interface IPageGroupSuccess extends ICodeWithMessage {
  data: TPageGroup
}

interface IGetPagesWithPageGroupSuccess extends ICodeWithMessage {
  data: {
    grouped: IPageGroupWithPages[]
    float: TSitemapItem[]
  }
}

export interface IAuthor {
  id: number
  name: string
  position: string
  education: string
  uniqPosition: string
  fileName: string
}

export type TSitemapXmlItem = {
  url: string
  lastmod: string
  changefreq: string
}

interface IGetFullArticleListSuccess extends ICodeWithMessage {
  data: TSitemapXmlItem[]
}

export enum ResponseCode {
  success = 0,
  custom = 3,
  internal = 5
}

export interface IConfig {
  banner: {
    desktop: {
      backgroundImage: string
    }
    tablet: {
      backgroundImage: string
    }
  }
  text: {
    title: string
    description: string
    link: string
  }
}

export interface ISelectedPersonalBanner extends TBannerDescription {
  selectedRows: TSelectedRows[]
  token: string
}

export interface IDeletePersonalBanner {
  id: number
  token: string
}

export interface IGetArticlesRequest {
  token: string
  dataSet?: 'short' | 'full'
  pageNumber?: number
  currentPage?: number
  itemsPerPage?: number
  filterByType: string
  filterByActive: string
  filterByTitle: string
  filterByUrl: string
}

export interface IRemoveArticlesRequest {
  token: string
  articleId: number
}

export interface ICreateArticlesRequest extends Omit<TArticle, 'id'> {
  token: string
}

export interface IUpdateArticlesRequest extends ICreateArticlesRequest {
  articleId: number
}

export type TFetchFullArticlesRequest = IRemoveArticlesRequest

export interface IGetPageListParams extends ICancelToken {
  token: string
  currentPage: number
  itemsPerPage: number
  filterByActive: string
  filterByName: string
  filterByUrl: string
}

export interface IGetPageItemHistory extends ICancelToken {
  token: string
  url: string
}

export interface ICreatePageItemContentRequest extends Omit<TPageItemContent, 'id'> {
  token: string
}

export interface IUpdatePageItemContentRequest extends TPageItemContent {
  token: string
  contentId: string
}

export interface IPageGroupWithPages extends IBasePageGroupFields {
  pages: TSitemapItem[]
}

export interface IAddPersonalBanner extends TBannerDescription {
  statuses: TPersonGroups[]
  types: TViewTypes[]
  personGroups: TPersonStatus
  deviceTypes: TDeviceType
  token: string
  actionType: FormActions.create
}

export interface ICreateAuthorRequest {
  name: string
  position: string
  education: string
  uniqPosition: string
  fileName: string
  token: string
}

export type TActiveLoanConditionsWithActivePersonGroup = {
  id: number
  persongroupId: number
  persongroupName: string
  financeModelId: number
  productId: number
  productName: string
  termMin: number
  termMax: number
  termStep: number
  dimension: TDimension
  amountMin: number
  amountMax: number
}

export interface IActiveLoanConditionsWithActivePersonGroup extends ICodeWithMessage {
  data: TActiveLoanConditionsWithActivePersonGroup[]
}

export type TEditAuthorRequest = ICreateAuthorRequest & { id: number }

export type TRequestParams = {
  token: string
}
export type TBanner = {
  id: number
  deviceType: string
  groupType: string
  promoHeader: string
  promoDescription: string
  promoLinkUrl: string
  promoLinkText: string
  file: string
  periodStart: string
  periodFinish: string
}
export type TViewTypes = 'desktop' | 'tablet' | 'mobile'
export type TPersonGroups =
  | 'new'
  | 'firstPaid'
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'platinum'
  | 'platinumXx'
export type TDevice = {
  id: number
  text: string
  name: string
  label: string
}
export type TBannerDescription = Omit<IPersonalBanner, 'id'>
export type TUpdatePersonalBannerData = IPersonalBanner & { token: string }
export type TLandingBanners = Record<TLandingConfigDirections, TLandingBannerViews>
export type TButtonListItem = {
  id: number
  text: string
  type: TButtonType
  colorButton: string
  colorText: string
}
export type TNotificationType = 'approve' | 'info'
export type TNotificationListItem = {
  active: boolean
  buttons: TButton[]
  dateFrom: string
  dateTill: string
  desktopImage: TNullable<string>
  frequency: string
  groupIds: number[]
  id: number
  mobileImage: TNullable<string>
  personIds: number[]
  rating: string
  tabletImage: TNullable<string>
  text: string
  type: TNotificationType
}
export type TArticle = {
  id: number
  title: TNullable<string>
  publishDate: string
  text: TNullable<string>
  imageSource: TNullable<string>
  url: string
  description: TNullable<string>
  seoKeywords: TNullable<string>
  seoTitle: TNullable<string>
  seoDescription: TNullable<string>
  isIncludedToSiteMap: TNullable<string>
  type: TNullable<number>
  rubric: TNullable<string>
  typeText: string
  rubricText: TNullable<string>
  isPublished: boolean
  modifyDate: TNullable<string>
  pubDate: string
  isShowRecommendedList: TNullable<boolean>
  isShowUpdateDate: TNullable<boolean>
  isShowDate: TNullable<boolean>
  author: number
  authorData: IAuthor
  timeToRead: TNullable<number>
  keywords?: string
  headline?: string
  canonicalLink?: string
  items?: TNullable<[]>
  totalItems?: number
}

export type THideAllPages = Omit<IGetPageItemHistory, 'cancelToken'>
export type TChangePageItemVisibility = {
  token: string
  id: number
  isPublished: number
}
export type TPageListItem = {
  id: number
  url: string
  date: string
  name: string
  published: number
  publishedText: string
  activeVersion: number
}
export type TPageListItemWithPaginationInfo = {
  totalItems: number
  itemsPerPage: number
  currentPage: number
  items: TPageListItem[]
}
export type TPageItemContent = TPageListItem & {
  module: string
  title: string
  description: string
  keywords: string
  info: string
  label: string
}
export type TUpdateRelationshipRequest = {
  token: string
  parent?: number
  children?: number
  after?: number
}
export type TSitemapItem = {
  children: TSitemapItem[]
  date: string
  full_url: string
  id: number
  level: number
  name: string
  order_number: number
  url: string
  parent: number
  label: TNullable<string>
  pageGroup: TNullable<TLitePageGroup>
}
export type TPageGroup = IBasePageGroupFields
export type TModifyGroupInPageParams = {
  pageId: number
  groupId: number
  token: string
}

export type TGetProductDescription = (
  status?: TPersonGroups
) => Promise<TProductDescriptions | TProductDescriptionWithStatus>

export type TGetProductDescriptionByIdParams = {
  id: string
  token: string
  cancelToken: CancelToken
}

export type TCustomResponse = ICodeWithMessage | ICodeError
export type TCheckTokenResponse = ICodeSuccess | ICodeError
export type TEditLandingConfig = IEditLandingConfigSuccess | ICodeError
export type TLandingConfig = ILandingConfigSuccess | ICodeError
export type TLandingTextConfig = ILandingTextConfigSuccess | ICodeError
export type TPersonalBannerWithStatus = IPersonalBannerWithStatus | ICodeError
export type TPersonalBanners = IPersonalBannersSuccess | IRawPersonalBannersSuccess | ICodeError
export type TPersonalBanner = IPersonalBannerSuccess | ICodeError
export type TPersonGroupInfo = IPersonGroupInfoSuccess | ICodeError
export type TDeviceTypes = IDeviceTypesSuccess | ICodeError
export type TProductDescriptions = IPersonProductDescriptionSuccess | ICodeError
export type TProductDescriptionWithStatus = IPersonProductDescriptionWithStatusSuccess | ICodeError
export type TProductDescriptionById = IPersonProductDescriptionByIdSuccess | ICodeError
export type TLandingBannerConfig = ILandingBannerConfigSuccess | ICodeError
export type TUpdatePaPromoDataResult = Promise<TEditLandingConfig>
export type TDeletePaPromoDataResult = Promise<TCustomResponse>
export type TAddNotificationButton = IAddNotificationButtonSuccess | ICodeError
export type TNotificationButtonList = INotificationButtonListSuccess | ICodeError
export type TNotificationPersonGroups = INotificationPersonGroupsSuccess | ICodeError
export type TAddNotification = IAddNotificationSuccess | ICodeError
export type TNotificationInfo = IAddNotificationSuccess | ICodeError
export type TUpdateNotification = IAddNotificationSuccess | ICodeError
export type TNotificationList = INotificationListSuccess | ICodeError
export type TNotificationButton = IAddNotificationButtonSuccess | ICodeError
export type TUpdateNotificationButton = IAddNotificationButtonSuccess | ICodeError
export type TArticlesListResponse = IArticlesListResponseSuccess | IArticleCodeError
export type TCreateArticleResponse = ICreateArticleSuccess | ICodeError
export type TGetFullArticleDataResponse = IGetFullArticleDataSuccess | IArticleCodeError
export type TArticlesPathTestResponse = ICodeWithMessage | IArticleCodeError
export type TGetProfileResponse = IGetProfileSuccess | ICodeError
export type TGetAuthorsResponse = IGetAuthors | ICodeError
export type TGetAuthorResponse = IGetAuthor | ICodeError
export type TRemoveAuthorResponse = IRemoveAuthorSuccess | ICodeError
export type TGetPageList = IGetPageListSuccess | IArticleCodeError
export type TGetPageItemHistory = IGetPageItemHistorySuccess | IArticleCodeError

export type THideAllPAges = ICodeWithMessage | IArticleCodeError
export type TPageItemVisibility = IChangePageItemVisibilitySuccess | IArticleCodeError

export type TPageItemSitemap = IChangePageItemVisibilitySuccess | IArticleCodeError

export type TGetPageItemContent = IGetPageItemContentSuccess | IArticleCodeError

export type TCreatePageItemContent = IGetPageItemContentSuccess | IContentCodeError
export type TGetSitemapList = IGetSitemapSuccess | ICodeError
export type TGetPageGroupList = IGetPageGroupListSuccess | ICodeError
export type TPageGroupResponse = IPageGroupSuccess | ICodeError
export type TGetPagesWithPageGroup = IGetPagesWithPageGroupSuccess | ICodeError
export type TGetFullArticleList = IGetFullArticleListSuccess | ICodeError
export type TGetActiveLoanConditionsWithActivePersonGroup =
  | IActiveLoanConditionsWithActivePersonGroup
  | ICodeError
