import FormData from 'form-data'
import PropTypes from 'prop-types'
import { Cookies } from 'react-cookie'

import BaseLayout from '#components/BaseLayout/Base'
import notify from '#services/notify'
import { brokerApi, personalApi } from '#src/modules/api'

const { brokerLogout } = brokerApi
const { logout: clientLogout } = personalApi
const api = { brokerLogout, clientLogout }
const cookies = new Cookies()

class BaseClientLayout extends BaseLayout {
  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
  }

  logout(key, action, params = {}) {
    const fd = new FormData()

    fd.append(key, this.loggedInToken(key))
    return api[action](fd)
      .then(() => {
        cookies.remove(key, params)
        window.location.reload()
        return null
      })
      .catch((err) => {
        const { message } = err
        notify.push({ message, type: 'danger' })
      })
  }
}

BaseClientLayout.propTypes = {
  dispatch: PropTypes.func
}

BaseClientLayout.childContextTypes = {
  logout: PropTypes.func
}

export default BaseClientLayout
