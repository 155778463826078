import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import * as AdminApiTypes from '#modules/api/admin/types'

type TInitialState = {
  isLoading: boolean
  items: AdminApiTypes.TArticle[]
}

const initialState: TInitialState = {
  isLoading: false,
  items: []
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setNews: (state, { payload }: PayloadAction<AdminApiTypes.TArticle[]>) => {
      state.items = payload
      state.isLoading = false
    }
  }
})

export const { setLoading, setNews } = newsSlice.actions
export default newsSlice.reducer
