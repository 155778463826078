import { TFaqItem } from '#components/FAQ/types'

export const items: TFaqItem[] = [
  {
    title: 'Сколько ждать решения по заявке?',
    text: 'Обработка идёт от 5 минут до 24 часов.'
  },
  {
    title: 'Какой у вас режим работы?',
    text:
      'Оформить и получить заём можно круглосуточно, без праздников и выходных.<br /> <a href="https://4slovo.ru/contact" target="_blank">Служба поддержки</a> доступна с 10:00 до 22:00 по Москве.'
  },
  {
    title: 'Как я узнаю результат?',
    text:
      'Статус заявки можно отследить в личном кабинете на вкладке «Взять заём». <br />Мы также отправим СМС на указанный номер, поэтому убедитесь, что оставили верные контакты в разделе «Мои данные» в личном кабинете.'
  },
  {
    title: 'Как быстро придут деньги?',
    text:
      'Перевод <a href="https://4slovo.ru/microzaym-na-kartu/" target="_blank">на карту</a> будет зачислен в среднем за 60 минут.<br /><br />Перевод на счёт займёт 1–3 дня — это зависит от вашего банка. Мы переводим деньги немедленно после одобрения заявки. <br /><br /> Если деньги не пришли, уточните у банка причину задержки. Проверить статус отправки также можно по <a href="https://pay.tkbbank.ru/new/guest" target="_blank">ссылке</a>.'
  },
  {
    title: 'Почему мне отказали в займе?',
    text:
      'Решение принимает система после проверки по многим факторам. Это данные заявки, кредитная история, размер дохода, вид займа и другие.'
  },
  {
    title: 'Есть смысл обращаться к вам после отказа?',
    text:
      'Конечно, есть, ведь ваша кредитная история могла улучшиться. К тому же система оценки постоянно меняется. Вы можете подать новую заявку через несколько дней. Укажите в ней как можно больше информации и убедитесь, что все данные верны, — это увеличит шансы на одобрение.'
  }
]
