import { configureStore as ReduxConfigureStore } from '@reduxjs/toolkit'

import { mainReducer } from '#reducers/entries/main'
import { partnerReducer } from '#reducers/entries/partner'
import { personalReducer } from '#reducers/entries/personal'
import { systemReducer } from '#reducers/entries/system'
import { throwErrorMiddleware } from '#src/store/throwErrorMiddleware'

import root from '../reducers'

const reducersFileName = {
  mainReducer,
  personalReducer,
  systemReducer,
  index: root,
  partnerReducer
}
const reducerFileName = process.env.__REDUCERS_FILE_NAME__ || 'index'
const rootReducer = reducersFileName[reducerFileName]

export default function configureStore(preloadedState = {}) {
  const store = ReduxConfigureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(throwErrorMiddleware)
  })

  if (process.env.NODE_ENV !== 'production' && module.hot)
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))

  return store
}
