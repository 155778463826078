import './assets/spinner.scss'

import cn from 'classnames'
import { CSSProperties, FC } from 'react'

export const Spinner: FC<{ className?: string; style?: CSSProperties }> = ({
  className,
  style
}) => (
  <span className={cn({ spinner: true }, className)} style={style}>
    <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
      <circle fill='none' strokeWidth='2' strokeLinecap='round' cx='15' cy='15' r='13' />
    </svg>
  </span>
)

export default Spinner
