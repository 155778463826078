export enum RefinanceErrors {
  'CHECK_ENABLED_REFINANCE_CODE' = 1,
  'CHECK_ENABLED_PRODUCT_BY_CREDIT_CODE' = 2,
  'CHECK_COUNT_SUCCESSFUL_ITERATIONS_CODE' = 3,
  'CHECK_ACCESS_PERSON_GROUPS_CODE' = 4,
  'CHECK_CURRENT_PERIOD_FROM_CODE' = 5,
  'CHECK_CURRENT_PERIOD_TO_CODE' = 6,
  'CHECK_COUNT_SUCCESS_PAYMENTS_CODE' = 7,
  'CHECK_AVAILABLE_DAYS_CODE' = 8,
  'CHECK_SUCCESSFUL_PASSING_SCORING_CODE' = 9,
  'CHECK_ISSUING_MONEY_TO_CARD_CODE' = 10,
  'CHECK_MEETS_LOAN_CONDITIONS_CRITERIA_CODE' = 11,
  'CHECK_PAID_PREVIOUS_PERIOD_CODE' = 12
}

export enum UniqueCodesConditionName {
  BUTTON_TEXT = 'submitButtonText',
  BUTTON_TEXT_COLOR = 'submitButtonTextColor',
  BUTTON_COLOR = 'promoTextColor',
  MIN_POSSIBLE_PERIOD = 'minPossiblePeriod',
  MAX_POSSIBLE_PERIOD = 'maxPossiblePeriod'
}

/**
 * @enum
 * @description Результат проверки паспорта на действительность
 */
export enum CheckingResults {
  valid = 0,
  expired = 1,
  notChecked = -1
}

export enum ClientAccountType {
  card = 1,
  account = 0
}

export enum ClientAccountStatus {
  CREATED = 'CREATED',
  BIND_CARD_REQUEST = 'BIND_CARD_REQUEST',
  BIND_CARD_SUCCESS = 'BIND_CARD_SUCCESS',
  BIND_CARD_ERROR = 'BIND_CARD_ERROR',
  BIND_CARD_MISMATCH = 'BIND_CARD_MISMATCH',
  BIND_CARD_EXPIRED = 'BIND_CARD_EXPIRED'
}

export enum NotificationType {
  call = 'call',
  sms = 'sms',
  email = 'email'
}

export enum LoanProductTypes {
  MICRO_CREDIT = 'MicroCredit',
  CONSUMER_CREDIT = 'ConsumerCredit',
  CONSUMER_CREDIT_MONTH = 'ConsumerCreditMonth',
  CONSUMER_CREDIT_MONTH_SHORT = 'ConsumerCreditMonthShort'
}

export enum LoanWay {
  BANK_ACCOUNT = 'bankAccount',
  BANK_CARD = 'bankCard'
}

export enum PersonalAccountState {
  SHOW_PRODUCT_INFO = 'showProductInfo',
  CLIENT_BLOCKED = 'clientBlocked',
  PRODUCT_CONFIRM = 'productConfirm',
  PRODUCT_IN_PROGRESS = 'productInProgress',
  PRODUCT_DENIED = 'productDenied',
  PRODUCT_ORDER = 'productOrder',
  PRODUCT_SOLD = 'productSold',
  PRODUCT_BANKRUPTCY = 'productBankruptcy',
  PRODUCT_TKB_STUB = 'productTkbStub',
  INVALID_PASSPORT = 'invalidPassport'
}

export enum LoginType {
  EMAIL = 'email',
  PHONE = 'mobile'
}

export enum PaymentTypeEnum {
  payment = 'payment',
  prolongation = 'prolongation',
  earlyRepayment = 'earlyRepayment'
}

export enum HowPaymentEnum {
  part = 'part',
  full = 'full'
}
