import { combineReducers } from '@reduxjs/toolkit'

import appSlice from '#reducers/app/appSlice'
import articleSlice from '#reducers/article/articleSlice'
import backgroundWithTextSlice from '#reducers/backgroundWithText/backgroundWithTextSlice'
import blogSlice from '#reducers/blog/blogSlice'
import cameraSlice from '#reducers/cameraSlice'
import notifications from '#reducers/clientData/notificationsSlice'
import feedbackFormSlice from '#reducers/feedbackForm/feedbackFormSlice'
import loanConditionsSlice from '#reducers/loanConditions/loanConditionsSlice'
import loanFormStateSlice from '#reducers/loanFormState/loanFormStateSlice'
import partnerDataSlice from '#reducers/partnerData/partnerDataSlice'

import adminPageReducer from './adminPage'
import brokerInfoReducer from './brokerSlice'
import clientData from './clientData'
import deviceReducer from './deviceTypeSlice'
import newsReducer from './newsSlice'
import seoSlice from './seoSlice'
import settingsSlice from './settingsSlice'
import sitemapReducer from './sitemapSlice'
import statisticsSlice from './statisticsSlice'
import testimonialsSlice from './testimonialsSlice'
import uiReducer from './ui'

const reducers = combineReducers({
  app: appSlice,
  clientData,
  partnerData: partnerDataSlice,
  news: newsReducer,
  seo: seoSlice,
  testimonials: testimonialsSlice,
  blog: blogSlice,
  article: articleSlice,
  sitemap: sitemapReducer,
  device: deviceReducer,
  feedbackForm: feedbackFormSlice,
  brokerInfo: brokerInfoReducer,
  statistics: statisticsSlice,
  loanConditions: loanConditionsSlice,
  loanFormState: loanFormStateSlice,
  ui: uiReducer,
  notifications,
  adminPage: adminPageReducer,
  settings: settingsSlice,
  backgroundWithText: backgroundWithTextSlice,
  camera: cameraSlice
})

export type TMainDataReducerType = typeof reducers
export type TMainDataStateType = ReturnType<TMainDataReducerType>

export default reducers
