import { FC } from 'react'
import { useLocation } from 'react-router'

import { getSchema } from './MicrodataModel'

export const Microdata: FC = () => {
  const { pathname } = useLocation()
  const schema = getSchema(pathname)

  if (schema) {
    return (
      <script
        type='application/ld+json'
        // dangerouslySetInnerHTML неообходим из-за особенностей преобразования html во время рендеринга react
        dangerouslySetInnerHTML={{ __html: schema }}
      />
    )
  }
  return null
}

export default Microdata
