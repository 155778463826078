type TParam = number

type TScreenSize = {
  width: TParam
  height: TParam
}

type TScreenInfo = {
  available: TScreenSize
  absolute: TScreenSize
  devicePixelRation: TParam
}

type TNullObject = Record<string, unknown>

export const getScreenParams = (): TScreenInfo | TNullObject => {
  if (typeof window === 'undefined') return {}
  return {
    available: {
      width: window.screen?.availWidth,
      height: window.screen?.availHeight
    },
    absolute: {
      width: window.screen?.width,
      height: window.screen?.height
    },
    devicePixelRation: window.devicePixelRatio
  }
}

export default getScreenParams
