import { Action, createStore, Store } from 'redux'

type TDictionaryOf<T> = Record<string, T>

type TUnknownStore = Store<TUnknownState, Action<unknown>>
type TUnknownAction = any
type TUnknownDictionary = TDictionaryOf<unknown>
type TUnknownState = TDictionaryOf<TUnknownDictionary>

type TAppSettings = TDictionaryOf<TUnknownDictionary>

type TAccessibleState = {
  settings: { data: TAppSettings }
  adminPage: {
    auth: {
      token: TNullable<string>
    }
  }
}

/**
 * @class
 * @description Класс для доступа к ReduxStore извне React компонентов
 */
export default class StoreAccessor {
  /**
   * @property
   * @description Ссылка на store
   */
  private _store: TUnknownStore

  /**
   * @property
   * @description Установлена ли ссылка на store
   */
  private _isLinked: boolean

  /**
   * @constructor
   */
  constructor() {
    this._store = createStore(() => ({}), {})
    this._isLinked = false
  }

  /**
   * @method
   * @param {TUnknownStore} store
   */
  public link(store: TUnknownStore): void {
    if (!this._isLinked) {
      this._store = store
      this._isLinked = true
      Object.seal(this)
    } else {
      throw new Error(
        'StoreAccessor already linked, create new StoreAccessor if you need access to another store'
      )
    }
  }

  /**
   * @method
   * @param {TUnknownAction} action
   */
  public dispatchAction(action: TUnknownAction): void {
    this._store.dispatch(action)
  }

  /**
   * @property
   * @description Настройки приложения
   */
  public get settings(): TAppSettings {
    return this.state.settings?.data ?? {}
  }

  public getStoreState(): TAccessibleState {
    return this._store.getState() as TAccessibleState
  }

  /**
   * @property
   * @description Состояние
   */
  private get state(): TAccessibleState {
    return this._store.getState() as TAccessibleState
  }
}
