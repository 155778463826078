import { createAsyncThunk } from '@reduxjs/toolkit'

import { BannerWithTextConfig, TProduct } from '#modules/BannerWithTextConfig'
import { CreatePrefix } from '#reducers/helper'
const prefixHelper = new CreatePrefix('backgroundWithText')
export const fetchBackgroundWithText = createAsyncThunk(
  prefixHelper.create('fetchBackgroundWithText'),
  async (type: TProduct, thunkAPI) => {
    const response = await BannerWithTextConfig.getInstance().setProduct(type).initLandingConfig()
    return thunkAPI.fulfillWithValue({ data: response })
  }
)
