type TFileInfo = {
  fileSize: number
  type: string
  width: number
  height: number
}

type TFileInfoError = {
  fileError: string
}

/**
 * Возвращает информацию о файле
 * @param {File} file
 * @returns {Promise<TFileInfo | TFileInfoError>}
 */

export const getFileInfo = (file: File): Promise<TFileInfo | TFileInfoError> => {
  let img: HTMLImageElement
  return new Promise((resolve) => {
    try {
      img = document.createElement('img')
      img.src = window.URL.createObjectURL(file)
      img.onload = () => {
        resolve({
          fileSize: file.size,
          type: file.type,
          width: img.naturalWidth,
          height: img.naturalHeight
        })
        img.remove()
      }
    } catch (error) {
      resolve({ fileError: (<Error>error).message })
      img?.remove?.()
    }
  })
}

export const humanFileSize = (bytes: number, decimals?: number): string => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return String(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + ' ' + sizes[i]
}

export default getFileInfo
