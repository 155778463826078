import { AxiosRequestConfig } from 'axios'
import UniversalFormData from 'form-data'
import { noop, omit } from 'lodash'
export interface ISource {
  [key: string]: any
}

export function safeAssign(source: unknown, path: string, value: unknown): void {
  try {
    Object.defineProperty(source, path, { value, enumerable: true })
  } catch (e) {
    noop(e)
  }
}

export const isFormData = (data?: FormData): boolean => {
  if (!data) return false
  return (
    (typeof FormData !== 'undefined' && data instanceof FormData) ||
    data instanceof UniversalFormData
  )
}

type TConfigData = undefined | any

export const serializeConfigParams = (
  config: AxiosRequestConfig = {}
): Omit<AxiosRequestConfig, 'httpsAgent'> => {
  const partialConfig = omit(config, 'httpsAgent')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const data: TConfigData = partialConfig?.data
  if (isFormData(data)) {
    // Оборачиваем в try/catch, т.к. не все браузеры поддерживают
    // Object.fromEntries и FormData,entries
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      return { ...config, data: Object.fromEntries(data.entries()) }
    } catch (_e) {
      return config
    }
  }
  return config
}
