import { ISubject, Subject } from '#src/modules/api/admin/Subject'

/**
 * Интерфейс Наблюдателя объявляет метод уведомления, который издатели
 * используют для оповещения своих подписчиков.
 */
export interface IObserver {
  // Получить обновление от субъекта.
  update(subject: ISubject): void
}
type TResolve = (value: void | PromiseLike<void>) => void

export class Observer implements IObserver {
  private readonly resolve: TResolve

  /**
   * Инициация инстанса наблюдателя
   * @param resolve функция объекта Promise, а именно Promise.resolve
   */
  public constructor(resolve: TResolve) {
    this.resolve = resolve
  }

  /**
   * Резолвим промис если состояние объекта изменится на ready
   * @param subject - объект в котором происходят изменения состояния
   */
  public update(subject: ISubject): void {
    if (subject instanceof Subject && subject.getState() === 'ready') this.resolve()
  }
}
