import { TFaqItem } from '#components/FAQ/types'

export const items: TFaqItem[] = [
  {
    title: 'Кто может взять деньги?',
    text:
      '<p>Оформить заём может гражданин РФ от 18 до 75 лет.<br />Нужны паспорт, мобильный телефон, банковская карта Visa, Mastercard, «Мир» на ваше имя или реквизиты вашего счёта.</p>'
  },
  {
    title: 'Я не гражданин РФ. Могу ли я взять заём?',
    text: 'К сожалению, нет. Сервис выдаёт деньги в долг только резидентам России.'
  },
  {
    title: 'Что нужно для получения денег?',
    text:
      'Для займов до 15 тыс. руб. включительно — только заявка, мобильный телефон и реквизиты.<br /> Для займов больше 15 тыс. руб. также понадобится приложить два фото: главный разворот паспорта и селфи с паспортом. Как это сделать, можно узнать в <a href="https://www.youtube.com/watch?v=h1ad0YH60mk" target="_blank">видео</a>.'
  },
  {
    title: 'Какую сумму и на какой срок можно взять?',
    text:
      'Вы можете получить от 3 до 70 тыс. руб. на срок от 10 до 364 дней.<br /> Лимит на первый заём — 30 тыс. руб. на 21 день.<br /> До 2024 года в сервисе идёт акция «0% на первый заём»: узнать все условия можно по <a href="https://4slovo.ru/actions/pervyj-zaem-do-10-dnej-besplatno" target="_blank">ссылке</a>.'
  },
  {
    title: 'Как можно получить деньги?',
    text:
      'Есть два варианта: на банковскую карту Visa, Mastercard, «Мир» на ваше имя или по реквизитам вашего счёта.'
  },
  {
    title: 'Обязателен ли мобильный телефон для регистрации?',
    text: 'Да. На указанный номер придёт СМС с кодом подтверждения.'
  },
  {
    title: 'Новые условия выдачи займа с 01.07.23',
    text:
      'Новые условия действуют только для договоров, заключённых с 1 июля 2023 года. Обратите внимание, что для ранее заключённых договоров они не действуют.<br /><br /> Ставка по договорам снижается до 0,8% в день. Предельная величина полной стоимости займа — до 292% годовых. Предельный размер задолженности по договору — до 130% от суммы займа.'
  },
  {
    title: 'Что такое бонусные баллы и как их получить?',
    text:
      'Бонусные баллы клиент получает на виртуальный счёт. Они помогают выгодно вернуть и продлить заём. Обналичить их нельзя.<br /><ol><li>Баллами можно оплатить до 80% всех процентов по займу.</li><li>Продлить можно только займы без просрочки и максимум 14 дней.</li><li>Время действия бонусных баллов индивидуально. Когда баллы сгорят, указано в условиях акций.</li><li>Баллы нельзя использовать для займов: в просрочке; по акции; с аннуитетными платежами (графиком платежей).</li></ol>Чтобы заработать бонусные баллы, можно участвовать в акциях. Узнать о них помогут <a href="https://4slovo.ru/subscribe/" target="_blank">подписка на рассылку</a> и страницы <a href="https://4slovo.ru/actions/" target="_blank">«Акции»</a>, <a href="https://vk.com/4slovo" target="_blank">«ВКонтакте»</a> и <a href="https://ok.ru/mfk4slovo" target="_blank">«Одноклассники»</a>.'
  }
]
