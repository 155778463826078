/*
  eslint-disable @typescript-eslint/no-empty-interface,
  @typescript-eslint/naming-convention,
  @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-return
*/
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
  HttpStatusCode
} from 'axios'
import { isEmpty, isPlainObject } from 'lodash'

import { AJAX_CONFIG_FIELD_NAME, MANDATORY_REPORT_CODE } from '#constants/common'
import { generateGuid, GUID_HEADER_NAME } from '#services/reportError'
import { safeAssign, serializeConfigParams } from '#src/modules/api/interceptorHelper'

import { CancelRequestError, NetworkError } from './exceptions'
declare module 'axios' {
  export interface AxiosResponse {
    __ajax_config: Omit<AxiosRequestConfig, 'httpsAgent'>
  }
}

interface IExtendedAxiosConfig {
  retry: number
  __retryCount: number
  __retryDelay: number
  retryTime: number
  channel?: string
}

export interface IErrorResponse {
  code: number
  message: string
}

export type TAxiosConfig =
  | (AxiosRequestConfig & IExtendedAxiosConfig)
  | Partial<AxiosRequestConfig & IExtendedAxiosConfig>

type TRequest = {
  type: 'request'
  beforeRequest: (config: AxiosRequestConfig) => AxiosRequestConfig
}

export type TCommonAxiosErrorResponse = Promise<IErrorResponse | AxiosResponse | AxiosError>

type TResponse = {
  type: 'response'
  onResponseSuccess: <T>(response: AxiosResponse) => T
  onResponseError: (err: AxiosError) => TCommonAxiosErrorResponse
}

type TExtraDataValue = Omit<AxiosRequestConfig, 'httpsAgent'> | typeof MANDATORY_REPORT_CODE

export type TExtraDataReturn = {
  [key: string]: TExtraDataValue | null
}

export class HttpClient {
  protected readonly _instance: AxiosInstance
  protected _axios: AxiosStatic

  public constructor(axiosConfig: AxiosRequestConfig) {
    this._instance = axios.create({
      ...axiosConfig
    })
    this._axios = axios
  }

  private static defaultConfigValues(): IExtendedAxiosConfig {
    return {
      retry: 0,
      __retryCount: 0,
      __retryDelay: 1,
      retryTime: 1000
    }
  }

  private static defaultErrorValues(): TEmptyObject {
    return {}
  }

  protected _initializeRequestInterceptor = (): void => {
    this._instance.interceptors.request.use(
      (config) => {
        config.headers[GUID_HEADER_NAME] = generateGuid()
        return config
      },
      null,
      { synchronous: true }
    )
  }

  protected _initializeResponseInterceptor = (): void => {
    this._instance.interceptors.response.use(this.onResponseSuccess, this.onResponseError)
  }

  get instance(): AxiosInstance {
    return this._instance
  }

  get axiosStatic(): AxiosStatic {
    return this._axios
  }

  protected transformResponse<T>(response: AxiosResponse): T {
    return response?.data || {}
  }

  protected onResponseSuccess = <T>(
    response: AxiosResponse
  ): T | (T & TExtraDataReturn) | Promise<T> => {
    const config = response?.config || {}
    const extraData = this.createExtraData(AJAX_CONFIG_FIELD_NAME, serializeConfigParams(config))
    const data = this.transformResponse<T>(response)
    if (isPlainObject(data)) return { ...data, ...extraData }
    return data
  }

  protected onResponseError = async (error: AxiosError): TCommonAxiosErrorResponse => {
    const config: TAxiosConfig = error?.config ?? HttpClient.defaultConfigValues()
    const { retry = 0, __retryCount = 0, __retryDelay = 1, retryTime = 1000 } = config
    const response = error?.response ?? HttpClient.defaultErrorValues()

    if (this.axiosStatic.isCancel(error)) {
      const cancelError = new CancelRequestError()
      safeAssign(cancelError, AJAX_CONFIG_FIELD_NAME, serializeConfigParams(config))
      return Promise.resolve(cancelError)
    }

    if (
      __retryCount >= retry ||
      [HttpStatusCode.Unauthorized, HttpStatusCode.NotFound].includes(response.status)
    ) {
      // тут по факту или реально 503, или CORS / CORS Preflight
      if (this.axiosStatic.isAxiosError(error) && isEmpty(response)) {
        const networkError = new NetworkError()
        safeAssign(networkError, AJAX_CONFIG_FIELD_NAME, serializeConfigParams(config))
        return Promise.reject(networkError)
      }
      const errorObj = response
      void safeAssign(errorObj, AJAX_CONFIG_FIELD_NAME, serializeConfigParams(config))

      return Promise.reject(errorObj)
    }

    const backoff = (): Promise<void> =>
      new Promise((resolve) => setTimeout(resolve, __retryDelay * retryTime))

    const updatedConfig = {
      ...config,
      __retryCount: __retryCount + 1,
      __retryDelay: __retryDelay * 2
    }

    await backoff()
    return this.instance(updatedConfig)
  }

  private readonly createExtraData = (path: string, value: TExtraDataValue): TExtraDataReturn => ({
    [path]: value
  })
}
