import { combineReducers } from '@reduxjs/toolkit'

import abTestSlice from '#reducers/abTest/abTestSlice'
import appSlice from '#reducers/app/appSlice'
import notifications from '#reducers/clientData/notificationsSlice'
import loanConditionsSlice from '#reducers/loanConditions/loanConditionsSlice'
import loanFormStateSlice from '#reducers/loanFormState/loanFormStateSlice'
import partnerDataSlice from '#reducers/partnerData/partnerDataSlice'

import brokerInfoReducer from '../../brokerSlice'
import deviceReducer from '../../deviceTypeSlice'
import settingsSlice from '../../settingsSlice'
import statisticsSlice from '../../statisticsSlice'
import uiReducer from '../../ui'

export const partnerReducer = combineReducers({
  app: appSlice,
  partnerData: partnerDataSlice,
  device: deviceReducer,
  brokerInfo: brokerInfoReducer,
  statistics: statisticsSlice,
  loanConditions: loanConditionsSlice,
  loanFormState: loanFormStateSlice,
  ui: uiReducer,
  notifications,
  settings: settingsSlice,
  abTest: abTestSlice
})

export type TPartnerRootState = ReturnType<typeof partnerReducer>
