import { TDataValues, TModelItem } from '#components/Form/Form'
import { TTypes } from '#components/SmartControl/SmartControl'

import intl from '../intl/ru.json'

export type TItemWrapper<T> = TItem & Omit<T, keyof TItem>

type TItem = {
  name?: string
  value?: TDataValues
  emptyMessage?: string
  type?: TTypes
}

export type TValidationResult = {
  isValid: boolean
  validationErrorMessage: string
}

type TValidateItem = (item: TItemWrapper<TModelItem>) => TValidationResult

export const validateItem: TValidateItem = (item) => {
  // start hard code for passportWhoCode
  const { name = null, systemName = null } = item
  const itemName = name || systemName
  let value = null
  if (itemName === 'passportWhoCode') {
    const passportVal = String(item.value).trim()
    if (passportVal.length === 6) value = `${passportVal.substr(0, 3)}-${passportVal.substr(3)}`
    else value = item.value && String(item.value).trim()
  } else {
    value = item.value && String(item.value).trim()
  }
  // end hard code for passportWhoCode

  let isValid = true
  let validationErrorMessage = ''

  if (item.required && !value) {
    isValid = false
    validationErrorMessage = item.emptyMessage || intl.fieldEmpty
  } else if (value && item.validationRule && !new RegExp(item.validationRule).test(value)) {
    isValid = false
    validationErrorMessage = item.validationMessageError || intl.fieldInvalid
  }

  return { isValid, validationErrorMessage }
}
