import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { brokerApi } from '#modules/api'
import { BrokerApiTypes as Types } from '#modules/api/broker/types'
import { ResponseCode } from '#modules/api/types/common'
import { TPartnerRootState } from '#reducers/entries/partner'

export type TInitialState = {
  partnerId: number
  email: string
  name: string
  groupId: number
  contact: string
  param: string
  interestRate: TNullable<Types.TInterestRate>
  interestRateReuse: TNullable<Types.TInterestRate>
  isBroker: 0 | 1
  loading: boolean
  isFetched: boolean
  gidOffline: number
}
const initialState: TInitialState = {
  partnerId: 0,
  email: '',
  name: '',
  groupId: 0,
  contact: '',
  param: '',
  interestRate: {
    periodStart: '',
    summaRegister: 0,
    summaPayment: 0
  },
  interestRateReuse: {
    periodStart: '',
    summaRegister: 0,
    summaPayment: 0
  },
  isBroker: 0,
  loading: false,
  isFetched: false,
  gidOffline: 0
}

function setBrokerData(state: TInitialState, action: PayloadAction<Types.TGetPartnerInfo>): void {
  const {
    isBroker,
    groupId,
    email,
    name,
    interestRateReuse,
    interestRate,
    param,
    contact,
    partnerId,
    gidOffline
  } = action.payload
  state.isBroker = isBroker
  state.groupId = groupId
  state.email = email
  state.name = name
  state.interestRateReuse = interestRateReuse
  state.interestRate = interestRate
  state.param = param
  state.contact = contact
  state.partnerId = partnerId
  state.gidOffline = gidOffline
}

export const fetchBrokerInfo = createAsyncThunk(
  'brokerSlice/fetchBrokerInfo',
  async (params: Types.IGetPartnerInfoRequest, thunkAPI) => {
    const response = await brokerApi.getPartnerInfo(params)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({
        message
      })
    }
    return response.data
  }
)
const brokerSlice = createSlice({
  name: 'brokerSlice',
  initialState,
  reducers: {
    setBrokerInfo: (state, action: PayloadAction<Types.TGetPartnerInfo>) => {
      setBrokerData(state, action)
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchBrokerInfo.pending, (state) => {
        state.loading = true
        state.isFetched = false
      })
      .addCase(fetchBrokerInfo.rejected, (state) => {
        state.loading = false
        state.isFetched = false
      })
      .addCase(fetchBrokerInfo.fulfilled, (state, action) => {
        setBrokerData(state, action)
        state.loading = false
        state.isFetched = true
      })
})

export const { setBrokerInfo } = brokerSlice.actions
export default brokerSlice.reducer

const broker = (state: TPartnerRootState): TInitialState => state.brokerInfo
export const brokerSelector = createSelector(broker, (broker) => broker)
