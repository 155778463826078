import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction
} from '@reduxjs/toolkit'
import { FileWithPreview } from 'react-dropzone'

import { adminApi, nodeApi } from '#modules/api'
import { ResponseCode } from '#modules/api/types/common'
import { TEntries } from '#reducers/adminPage/personNotification/buttonsSlice'
import {
  deleteNotification,
  editNotification
} from '#reducers/adminPage/personNotification/notificationSlice'
import { getErrorMessage } from '#reducers/helper'
import noty from '#services/notify'
import * as AdminApiTypes from '#src/modules/api/admin/types'
import { TMainDataStateType } from '#src/reducers'

export type TNotificationFormKeys = TNotificationsInitialState['form']
export type TNotificationFormPayload =
  | {
      [p in keyof TNotificationFormKeys]: TNotificationFormKeys[p]
    }
  | {
      [key: string]: string | null
    }

type TNotificationsInitialState = {
  isLoading: {
    items: boolean
    item: boolean
  }
  isFetched: boolean
  items: AdminApiTypes.TNotificationListItem[]
  error: {
    items: string
    item: string
  }
  count: number
  pie: TComputedNotificationPie[]
  form: {
    type: TNullable<string>
    buttons: TNullable<string>
    text: string
    frequency: TNullable<string>
    rating: TNullable<string>
    groupOrPerson: TNullable<string>
    file: TNullable<
      [
        {
          preview: string
        }
      ]
    >
    dateFrom: TNullable<string>
    dateTill: TNullable<string>
    active: TNullable<string>
  }
}

export type TComputedNotificationPie = {
  name: AdminApiTypes.TNotificationType
  value: number
}

export type TCountNotificationTypes = {
  [key in AdminApiTypes.TNotificationType]: number | never
}

export function computeNotificationPie(
  notifications: AdminApiTypes.TNotificationListItem[]
): TComputedNotificationPie[] {
  const countNotificationTypes = {} as TCountNotificationTypes
  for (const { type } of notifications) {
    countNotificationTypes[type] = countNotificationTypes[type]
      ? countNotificationTypes[type] + 1
      : 1
  }
  // принудительно говорим, что ключ это тип данных TButtonType а не string
  // @ts-ignore
  const entries: TEntries<TCountNotificationTypes> = Object.entries(countNotificationTypes)
  return entries.map(([key, value]) => ({
    name: key,
    value
  }))
}

const calcPie = (
  items: AdminApiTypes.TNotificationListItem[]
): {
  pie: TComputedNotificationPie[]
  count: number
} => {
  const pie = computeNotificationPie(items)
  const count = items.length
  return {
    pie,
    count
  }
}

const calcPieOptionsAfterAdd = (
  state: TNotificationsInitialState,
  item: AdminApiTypes.TNotificationListItem
): {
  items: AdminApiTypes.TNotificationListItem[]
  pie: TComputedNotificationPie[]
  count: number
} => {
  const items = state.items.concat(item)
  const { pie, count } = calcPie(items)
  return {
    items,
    pie,
    count
  }
}

const calcPieOptionsAfterModify = (
  state: TNotificationsInitialState,
  item: AdminApiTypes.TNotificationListItem
): {
  items: AdminApiTypes.TNotificationListItem[]
  pie: TComputedNotificationPie[]
  count: number
} => {
  const items = state.items.map((notification) =>
    notification.id === item.id ? { ...notification, ...item } : notification
  )
  const { pie, count } = calcPie(items)
  return {
    items,
    pie,
    count
  }
}

export const fetchNotificationList = createAsyncThunk<
  AdminApiTypes.TNotificationListItem[],
  string
>('notifications/fetchList', async (token, { rejectWithValue }) => {
  const response = await adminApi.getNotificationList(token)
  if (response.code !== ResponseCode.success) {
    const { message } = response
    return rejectWithValue({
      message
    })
  }
  return response.data
})

type TCreateNotificationArgs = {
  data: FormData
  token: string
  cb?: () => void
  file?: FileWithPreview | null
}

export const saveBannerWithToken = async (
  token: string,
  file: FileWithPreview
): Promise<string> => {
  const { fileName } = await nodeApi.notificationBanner(file, token)
  return fileName
}

export const addNotification = createAsyncThunk<
  AdminApiTypes.TNotificationListItem,
  TCreateNotificationArgs
>(
  'notifications/createNotification',
  async ({ data, token, cb, file }, { dispatch, rejectWithValue }) => {
    if (file) {
      const fileName = await saveBannerWithToken(token, file)
      data.append('desktopImage', fileName)
      data.append('tabletImage', fileName)
      data.append('mobileImage', fileName)
    }

    const response = await adminApi.addNotification(data)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return rejectWithValue({
        message
      })
    }
    dispatch(flushNotificationForm())
    noty.push({
      message: 'Успешно добавлено',
      type: 'success',
      alarmIcon: false,
      container: 'top-right'
    })
    cb?.()
    return response.data
  }
)

export const updateNotificationFormData = createAction<TNotificationFormPayload>(
  'notifications/setFormData'
)

export const flushNotificationForm = createAction('notifications/flushFormData')

const initialState: TNotificationsInitialState = {
  isLoading: {
    item: false,
    items: false
  },
  isFetched: false,
  items: [],
  error: {
    item: '',
    items: ''
  },
  count: 0,
  pie: [],
  form: {
    type: null,
    buttons: null,
    text: '',
    frequency: null,
    rating: null,
    groupOrPerson: null,
    dateFrom: null,
    dateTill: null,
    active: null,
    file: null
  }
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(flushNotificationForm, (state) => {
        state.form = {
          type: null,
          buttons: null,
          text: '',
          frequency: null,
          rating: null,
          groupOrPerson: null,
          dateFrom: null,
          dateTill: null,
          active: null,
          file: null
        }
      })
      .addCase(
        updateNotificationFormData,
        (state, action: PayloadAction<TNotificationFormPayload>) => {
          state.form = {
            ...state.form,
            ...action.payload
          }
        }
      )
      .addCase(fetchNotificationList.pending, (state) => {
        state.isLoading.items = true
        state.error.items = ''
      })
      .addCase(fetchNotificationList.fulfilled, (state, action) => {
        state.isLoading.items = false
        state.items = action.payload
        state.pie = computeNotificationPie(action.payload)
        state.count = action.payload.length
      })
      .addCase(fetchNotificationList.rejected, (state, action) => {
        state.isLoading.items = false
        state.error.items = getErrorMessage(action)
      })
      .addCase(addNotification.pending, (state) => {
        state.isLoading.item = true
        state.error.item = ''
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.isLoading.item = false
        const { pie, count, items } = calcPieOptionsAfterAdd(state, action.payload)
        state.pie = pie
        state.count = count
        state.items = items
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.isLoading.item = false
        state.error.item = getErrorMessage(action)
      })
      .addMatcher(
        isAnyOf(editNotification.fulfilled, deleteNotification.fulfilled),
        (state, action) => {
          const { pie, count, items } = calcPieOptionsAfterModify(state, action.payload)
          state.pie = pie
          state.count = count
          state.items = items
        }
      )
  }
})

export default notificationsSlice.reducer

export const adminNotifications = (state: TMainDataStateType): TNotificationsInitialState =>
  state.adminPage.notification.notifications
export const adminNotificationsLoadingItem = createSelector(
  adminNotifications,
  (notifications) => notifications.isLoading.item
)
export const adminNotificationErrorItem = createSelector(
  adminNotifications,
  (notifications) => notifications.error.item
)
export const adminNotificationFormData = createSelector(
  adminNotifications,
  (notifications) => notifications.form
)
export const adminNotificationsPie = createSelector(
  adminNotifications,
  (notifications) => notifications.pie
)
export const adminNotificationItems = createSelector(
  adminNotifications,
  (notifications) => notifications.items
)
