import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IAuthor, TArticle } from '#modules/api/admin/types'
import { NotFoundError } from '#modules/api/exceptions'
import { fetchArticle, PageTypeEnum, RubricPathEnum } from '#reducers/article/effects'

export type TArticleInitialState = {
  id: TNullable<number>
  description: TNullable<string>
  keywords: TNullable<string>
  rubricText: TNullable<string>
  loading: boolean
  totalRows: number
  contentList: TNullable<TArticle[]>
  title: TNullable<string>
  seoTitle: TNullable<string>
  text: TNullable<string>
  recommendedList: TNullable<TArticle[]>
  lastPage: TNullable<number>
  seoKeywords: TNullable<string>
  seoDescription: TNullable<string>
  currentPage: TNullable<number>
  pageType: TNullable<PageTypeEnum>
  hasExtraContent: boolean
  pathname: TNullable<string>
  rubric: TNullable<number>
  rubricPath: TNullable<RubricPathEnum>
  articleId: TNullable<number>
  headline: TNullable<string>
  type: TNullable<number>
  publishDate: TNullable<string>
  imageSource: TNullable<string>
  modifyDate: TNullable<string>
  isShowRecommendedList: TNullable<boolean>
  isShowUpdateDate: TNullable<boolean>
  isShowDate: TNullable<boolean>
  isPageContentNotFound: TNullable<boolean>
  canonicalLink: TNullable<string>
  items?: TNullable<[]>
  totalItems?: TNullable<number>
  authorData: TNullable<IAuthor>
  timeToRead: TNullable<number>
}
const initialState: TArticleInitialState = {
  id: null,
  description: null,
  keywords: null,
  rubricText: null,
  loading: false,
  totalRows: 0,
  contentList: null,
  title: null,
  seoTitle: null,
  text: null,
  recommendedList: null,
  lastPage: null,
  seoKeywords: null,
  seoDescription: null,
  currentPage: null,
  pageType: null,
  hasExtraContent: false,
  pathname: null,
  canonicalLink: null,
  rubric: null,
  rubricPath: null,
  articleId: null,
  headline: null,
  type: null,
  publishDate: null,
  imageSource: null,
  modifyDate: null,
  isShowRecommendedList: null,
  isShowUpdateDate: null,
  isShowDate: null,
  isPageContentNotFound: null,
  items: [],
  totalItems: null,
  authorData: null,
  timeToRead: null
}

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    flushArticle: () => initialState,
    setArticleData: (state, { payload }: PayloadAction<TArticleInitialState>) => ({
      ...state,
      ...payload
    }),
    rejectedFecthArticle: (state) => {
      state.loading = false
      state.isPageContentNotFound = true
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchArticle.pending, (state) => {
        state.loading = true
        state.isPageContentNotFound = false
      })
      .addCase(fetchArticle.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(fetchArticle.rejected, () => {
        throw new NotFoundError()
      })
})

export const { flushArticle, setArticleData, rejectedFecthArticle } = articleSlice.actions

export default articleSlice.reducer
