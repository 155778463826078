import { createAsyncThunk } from '@reduxjs/toolkit'
import querystring from 'query-string'

import { brokerApi } from '#modules/api'
import { BrokerApiTypes as Types } from '#modules/api/broker/types'
import { ResponseCode } from '#modules/api/types/common'
import { updatePartnerParameters } from '#reducers/app/appSlice'
import { setBrokerInfo } from '#reducers/brokerSlice'
import { CreatePrefix } from '#reducers/helper'
import {
  fetchPartnerDataNoAccess,
  fetchPartnerErrorFormat,
  fetchPartnerWrongToken
} from '#reducers/partnerData/partnerDataSlice'
import { _brokerApi, TPartnerInfoParams, TRequestRules } from '#reducers/partnerData/types'
import { fetchStatistic } from '#reducers/statisticsSlice'

const prefixHelper = new CreatePrefix('partnerData')

// used redux thunk to apply function to redux action
export const fetchPartnerData = createAsyncThunk<any, any>(
  prefixHelper.create('fetchPartnerData'),
  async (requestRules: TRequestRules, thunkAPI) => {
    const { part } = requestRules
    if (part === 'statistics') return thunkAPI.dispatch(fetchStatistic(requestRules))

    const response = await _brokerApi[part](requestRules)

    if (response.code === ResponseCode.success) {
      if (
        requestRules.part === 'loans' ||
        requestRules.part === 'bids' ||
        requestRules.part === 'accounts'
      )
        return thunkAPI.fulfillWithValue(response.data)
      if (requestRules.part === 'info' && Object.keys(response.data).length > 0) {
        const { param } = response.data as TPartnerInfoParams
        thunkAPI.dispatch(
          updatePartnerParameters({ partnerParameters: querystring.stringify({ aprt159: param }) })
        )
        thunkAPI.dispatch(setBrokerInfo(response.data as Types.TGetPartnerInfo))
      }
    }
  }
)

export const fetchManualPartnerData = createAsyncThunk(
  prefixHelper.create('fetchManualPartnerData'),
  async (requestRules: TRequestRules, thunkAPI) => {
    const response = await brokerApi.getPartnerLoans(requestRules)
    if (response.code === ResponseCode.success) return thunkAPI.fulfillWithValue(response.data)
    if (response.code === ResponseCode.internal)
      thunkAPI.dispatch(fetchPartnerErrorFormat(response.message))
    else if (response.code === ResponseCode.custom) thunkAPI.dispatch(fetchPartnerDataNoAccess())
    else if (response.code === 401) thunkAPI.dispatch(fetchPartnerWrongToken(response))
    else return thunkAPI.rejectWithValue({ response })
  }
)
