import { AxiosResponse } from 'axios'

import { UNAUTHORIZED } from '#constants/common'
import { TSyncActionCreatorType } from '#reducers/adminPage/auth/types'

export enum ResponseCode {
  success = 0,
  custom = 3,
  internal = 5,
  wrongToken = 401
}

export enum ClientLoginCode {
  success = 200,
  isRemoved = 4,
  invalidCredentials = 1
}

export enum RefinanceResponseErrorCode {
  error = 1
}
export enum RefinanceResponseDataExistCode {
  exist = 2
}

export enum ArticleResponseCode {
  notFound = 6
}

export enum OsticketCreateResponseCode {
  notFound = 6
}

export enum TestimonialResponseCode {
  notFound = 6
}

export enum ContentResponseCode {
  existingPage = 1
}

export enum FinanceModelNotFoundCode {
  error = 100
}

export interface ICodeSuccess<T = ResponseCode.success> {
  code: T
}
type TCodeErrors = ResponseCode.custom | ResponseCode.internal | ResponseCode.wrongToken
export interface ICodeError {
  code: TCodeErrors
  message: string
}

export interface IArticleCodeError {
  code: TCodeErrors | ArticleResponseCode.notFound
  message: string
}

export interface ITestimonialCodeError {
  code: TCodeErrors | TestimonialResponseCode.notFound
  message: string
}

export interface ICodeWithMessage extends ICodeSuccess {
  message: string
}

export interface IContentCodeError {
  code: TCodeErrors | ArticleResponseCode.notFound | ContentResponseCode.existingPage
  message: string
}

export interface IRefinanceCodeError {
  code: RefinanceResponseErrorCode.error
  message: string
  data?: {
    exception_name?: string
  }
}

export interface IUnauthorized {
  type: typeof UNAUTHORIZED
  message: string
}

export interface ITransformResponseResponseData {
  message?: string
}

export interface ICodeLoansError {
  code: TCodeErrors | FinanceModelNotFoundCode.error
  message: string
}

export type TTransformResponse<T> = AxiosResponse<IUnauthorized> | AxiosResponse<T>

export type TUnauthorizedErrors = {
  client: (err: IUnauthorized) => void
  admin: () => TSyncActionCreatorType
}

export type TAdminUnauthorizedErrors = {
  admin: () => TSyncActionCreatorType
}

export type TClientUnauthorizedErrors = {
  client: (err: IUnauthorized) => void
}

export type TTUnauthorizedErrorsTypes = keyof TUnauthorizedErrors
export type TAdminUnauthorizedErrorsTypes = keyof TAdminUnauthorizedErrors
export type TClientUnauthorizedErrorsTypes = keyof TClientUnauthorizedErrors
