import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { mainSiteApi } from '#modules/api'
import * as AdminApiTypes from '#modules/api/admin/types'
import { ResponseCode } from '#modules/api/types/common'
import { TListKeys } from '#reducers/commonTypes'
import { createPageListStructure, CreatePrefix } from '#reducers/helper'
import { TMainDataStateType } from '#reducers/index'

export type TInitialState = {
  isLoading: boolean
  activePages: {
    grouped: TListKeys<AdminApiTypes.IPageGroupWithPages>
    float: TListKeys<AdminApiTypes.TSitemapItem>
  }
}
const prefixHelper = new CreatePrefix('sitemap')

export const fetchSitemapCard = createAsyncThunk(
  prefixHelper.create('fetchSitemapCard'),
  async (_, thunkAPI) => {
    const response = await mainSiteApi.getSitemapCard()
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
    return response.data
  }
)

const initialState: TInitialState = {
  isLoading: false,
  activePages: {
    grouped: {
      byId: {},
      allIds: []
    },
    float: {
      byId: {},
      allIds: []
    }
  }
}
const sitemapSlice = createSlice({
  name: 'sitemapSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSitemapCard.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSitemapCard.fulfilled, (state, action) => {
        state.activePages.grouped = createPageListStructure(action.payload.grouped)
        state.activePages.float = createPageListStructure(action.payload.float)
      })
      .addMatcher(isAnyOf(fetchSitemapCard.fulfilled, fetchSitemapCard.rejected), (state) => {
        state.isLoading = false
      })
  }
})

export default sitemapSlice.reducer
export const activePagesSelector = (
  state: TMainDataStateType
): {
  grouped: TListKeys<AdminApiTypes.IPageGroupWithPages>
  float: TListKeys<AdminApiTypes.TSitemapItem>
} => state.sitemap.activePages
