import { TModelItem } from '#components/Form/Form'
import { adminApi } from '#src/modules/api'
import { ResponseCode } from '#src/modules/api/admin/types'

const EMPTY_FIELD_MESSAGE = 'Поле не заполнено'
const maxFieldLength = 65535
export const maxPressLength = 250
export const pressType = 2
const isNotPressType = `type:[^${pressType}]`

const pressAuthorOptions = [
  {
    text: 'Бробанк',
    value: '1'
  },
  {
    text: 'Выберу',
    value: '2'
  },
  {
    text: 'Профиль',
    value: '3'
  },
  {
    text: 'Bankiros',
    value: '4'
  },
  {
    text: 'ВШЭ',
    value: '5'
  },
  {
    text: 'СЕКРЕТ ФИРМЫ',
    value: '6'
  },
  {
    text: 'banki.ru',
    value: '7'
  },
  {
    text: 'ВЗО',
    value: '8'
  },
  {
    text: 'газета.ru',
    value: '9'
  },
  {
    text: 'Займи срочно',
    value: '10'
  },
  {
    text: 'zaim.com',
    value: '11'
  },
  {
    text: 'РИА новости',
    value: '12'
  },
  {
    text: 'Ведомости',
    value: '13'
  },
  {
    text: 'Деловой Петербург',
    value: '14'
  },
  {
    text: 'BankNN.ru',
    value: '15'
  },
  {
    text: 'Финверсия',
    value: '16'
  },
  {
    text: 'ТОП ЗАЙМОВ',
    value: '17'
  },
  {
    text: 'Пробизнес',
    value: '18'
  },
  {
    text: 'atameken',
    value: '19'
  },
  {
    text: 'Кредит отзыв',
    value: '20'
  },
  {
    text: 'perm.ru',
    value: '21'
  },
  {
    text: 'dailymoneyexpert',
    value: '22'
  },
  {
    text: 'shoplog',
    value: '23'
  },
  {
    text: 'Работа.ру',
    value: '24'
  },
  {
    text: 'SibBank.ru',
    value: '25'
  },
  {
    text: 'BusinessClass',
    value: '26'
  },
  {
    text: 'ТААС',
    value: '27'
  },
  {
    text: 'Gaap.ru',
    value: '28'
  },
  {
    text: 'CreditBus',
    value: '29'
  },
  {
    text: 'Контур',
    value: '30'
  },
  {
    text: 'Сейчас.ру',
    value: '31'
  },
  {
    text: 'ГородКиров',
    value: '32'
  },
  {
    text: 'EasyFins',
    value: '33'
  },
  {
    text: 'БО',
    value: '34'
  },
  {
    text: 'CHELLFIN',
    value: '35'
  },
  {
    text: 'Комсомольская правда',
    value: '36'
  },
  {
    text: 'Деловая Газета',
    value: '37'
  },
  {
    text: 'Деловой Квартал',
    value: '38'
  },
  {
    text: 'FUTUREBANKING',
    value: '39'
  },
  {
    text: 'GEEKBRAINS',
    value: '40'
  },
  {
    text: 'HRTV',
    value: '41'
  },
  {
    text: 'Интерфакс',
    value: '42'
  },
  {
    text: 'Известия',
    value: '43'
  },
  {
    text: 'Коммерсант',
    value: '44'
  },
  {
    text: '24 МИР',
    value: '45'
  },
  {
    text: 'МСК-ЗДЕСЬ',
    value: '46'
  },
  {
    text: 'NEW-COMPANION',
    value: '47'
  },
  {
    text: 'ОРТ',
    value: '48'
  },
  {
    text: 'PR explore',
    value: '49'
  },
  {
    text: 'РБК',
    value: '50'
  },
  {
    text: 'РИА Недвижимость',
    value: '51'
  },
  {
    text: 'РусскаяГазета',
    value: '52'
  },
  {
    text: 'Village',
    value: '53'
  },
  {
    text: 'WorldYOU',
    value: '54'
  },
  {
    text: 'Занимай Онлайн',
    value: '55'
  },
  {
    text: 'ЗарплатаРу',
    value: '56'
  },
  {
    text: 'РИАМО',
    value: '57'
  },
  {
    text: 'Bis Journal',
    value: '58'
  },
  {
    text: 'Автор Организация',
    value: '59'
  },
  {
    text: 'InfoPro',
    value: '60'
  },
  {
    text: 'СравниРУ',
    value: '61'
  },
  {
    text: 'ИзвестияРу',
    value: '62'
  },
  {
    text: 'Leads',
    value: '63'
  },
  {
    text: 'ZaimRussia',
    value: '64'
  },
  {
    text: 'Gendir',
    value: '65'
  },
  {
    text: 'NBJ',
    value: '66'
  },
  {
    text: 'ufatimeRu',
    value: '67'
  },
  {
    text: 'prime',
    value: '68'
  },
  {
    text: 'regnumRu',
    value: '69'
  }
].sort((a, b) => {
  if (a.text.toLowerCase() < b.text.toLowerCase()) return -1
  if (a.text.toLowerCase() > b.text.toLowerCase()) return 1
  return 0
})

export const getModel = async (token: string): Promise<TModelItem[]> => {
  const authors = await getAuthors(token)

  return [
    {
      line: 1,
      name: 'title',
      type: 'text',
      label: 'Заголовок',
      placeholder: 'Заголовок статьи',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: '',
      ruleMaxLength: 255
    },
    {
      line: 1,
      name: 'url',
      type: 'text',
      label: 'Путь (URL)',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: '',
      ruleMaxLength: 255,
      depends: isNotPressType
    },
    {
      line: 2,
      name: 'description',
      type: 'textarea',
      label: 'Бриф новости',
      placeholder: 'Бриф новости',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: '',
      ruleMaxLength: maxFieldLength
    },
    {
      line: 3,
      name: 'type',
      type: 'select',
      label: 'Тип статьи',
      placeholder: 'Тип статьи',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      options: [
        {
          text: 'Новость',
          value: '0'
        },
        {
          text: 'Акция',
          value: '1'
        },
        {
          text: 'СМИ',
          value: '2'
        },
        {
          text: 'Блог',
          value: '3'
        }
      ]
    },
    {
      line: 3,
      name: 'rubric',
      type: 'select',
      label: 'Рубрика',
      placeholder: 'Рубрика',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      depends: 'type:3',
      options: [
        {
          text: 'Личные финансы',
          value: '1'
        },
        {
          text: 'О займах просто',
          value: '2'
        },
        {
          text: 'Финансовая грамотность',
          value: '3'
        },
        {
          text: 'Семья и дети',
          value: '4'
        }
      ]
    },
    {
      line: 3,
      name: 'pressAuthor',
      type: 'select',
      label: 'Автор Организация',
      placeholder: 'Организация',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      depends: 'type:2',
      options: pressAuthorOptions
    },
    {
      line: 3,
      name: 'publishDate',
      type: 'dateTimePicker',
      label: 'Дата и время',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: ''
    },
    {
      line: 4,
      name: 'author',
      type: 'select',
      label: 'Автор',
      placeholder: 'Автор',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      options: authors,
      depends: 'type:0'
    },
    {
      line: 4,
      name: 'author',
      type: 'select',
      label: 'Автор',
      placeholder: 'Автор',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      options: authors,
      depends: 'type:1'
    },
    {
      line: 4,
      name: 'author',
      type: 'select',
      label: 'Автор',
      placeholder: 'Автор',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      options: authors,
      depends: 'type:3'
    },
    {
      line: 5,
      name: 'seoTitle',
      type: 'text',
      label: 'SEO TITLE',
      placeholder: 'Seo title',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: '',
      ruleMaxLength: 255,
      depends: isNotPressType
    },
    {
      line: 5,
      name: 'isPublished',
      type: 'checkbox',
      label: 'Опубликовано',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: 0
    },
    {
      line: 5,
      name: 'isIncludedToSiteMap',
      type: 'checkbox',
      label: 'Включить в карту сайта',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: 0
    },
    {
      line: 6,
      name: 'seoDescription',
      type: 'textarea',
      label: 'SEO DESCRIPTION',
      placeholder: 'Seo description',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: '',
      ruleMaxLength: 255,
      depends: isNotPressType
    },
    {
      line: 7,
      name: 'seoKeyWords',
      type: 'text',
      label: 'SEO KEY WORDS',
      placeholder: 'Seo key words',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: '',
      ruleMaxLength: 255,
      depends: isNotPressType
    },
    {
      line: 7,
      name: 'externalLink',
      type: 'text',
      label: 'Ссылка на статью в СМИ',
      placeholder: 'Ссылка',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      default: '',
      ruleMaxLength: 255,
      depends: 'type:2'
    },
    {
      line: 8,
      name: 'urlLink',
      type: 'text',
      label: "Путь (URL) статьи 'Читайте также'",
      required: false,
      depends: 'type:3',
      default: ''
    },
    {
      line: 9,
      name: 'text',
      type: 'richText',
      label: 'Полный текст статьи',
      default: '',
      placeholder: 'Полный текст статьи',
      required: true,
      emptyMessage: EMPTY_FIELD_MESSAGE,
      ruleMaxLength: 65535,
      depends: isNotPressType
    },
    {
      line: 10,
      name: 'timeToRead',
      type: 'number',
      label: 'Время чтения',
      required: true,
      depends: 'type:3',
      default: 0,
      ruleMaxLength: 2,
      emptyMessage: EMPTY_FIELD_MESSAGE
    },
    {
      line: 10,
      name: 'isAppendActions',
      type: 'checkbox',
      label: 'Активировать доп. действия с текстом',
      required: false,
      default: 0,
      depends: isNotPressType
    },
    {
      line: 10,
      name: 'extendedAction',
      type: 'select',
      label: 'Действия с текстом',
      placeholder: 'Действия с текстом',
      emptyMessage: EMPTY_FIELD_MESSAGE,
      required: true,
      depends: 'isAppendActions:1',
      options: [
        {
          text: 'Удалить содержание',
          value: 'removeNavigation'
        },
        {
          text: 'Добавить содержание',
          value: 'addNavigation'
        },
        {
          text: 'Добавить автора',
          value: 'setAuthor'
        },
        {
          text: 'Удалить автора',
          value: 'removeAuthor'
        }
      ]
    },
    {
      line: 12,
      name: 'isShowRecommendedList',
      type: 'checkbox',
      label: 'Отображать блок «Читайте также»',
      required: false,
      default: 1,
      depends: isNotPressType
    },
    {
      line: 13,
      name: 'isShowUpdateDate',
      type: 'checkbox',
      label: 'Отображать дату обновления на странице',
      required: false,
      default: 1
    },
    {
      line: 14,
      name: 'isShowDate',
      type: 'checkbox',
      label: 'Отображать дату  на странице',
      required: false,
      default: 1
    }
  ] as TModelItem[]
}

const getAuthors = async (token: string): Promise<{ text: string; value: string }[]> => {
  const response = await adminApi.getAuthors(token)
  let authors: { text: string; value: string }[] = []

  if (response.code === ResponseCode.success) {
    authors = response.data.map((author: { name: string; id: number }) => ({
      text: author.name,
      value: String(author.id)
    }))
  }

  return authors
}
