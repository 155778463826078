import { format as dateFnsFormat, Locale, parseISO, toDate } from 'date-fns'
import ru from 'date-fns/locale/ru'

import serverTime from '#services/serverTime'
import CacheResponse from '#src/modules/CacheResponse'

const INVALID_DATE = 'Invalid date'

export type TDate = string | number | Date | null

type TGlobalBase = typeof window | typeof global
export type TGlobal = TGlobalBase & {
  __SERVER_TIME__?: number
  __LOCALE_ID__?: string
}

export type TLocale = { [key: string]: Locale }

const locales: TLocale = { ru }

const getGlobalObject = (): TGlobal | null => {
  let globalObj = null
  try {
    globalObj = window
  } catch (e) {
    if (globalObj === null) {
      try {
        globalObj = global
      } catch (e) {
        return null
      }
    }
  }
  return globalObj
}

const getLocale = (): Locale | undefined => {
  const localeId = getGlobalObject()?.__LOCALE_ID__ || null
  if (!localeId) return
  return locales[localeId]
}

const localeTimeKey = 'localeTime/timestamp'
export const localeTime = async (): Promise<number | Date> => {
  try {
    if (CacheResponse.hasData(localeTimeKey)) return CacheResponse.getData(localeTimeKey) as number
    const {
      data: { timestamp }
    } = await serverTime()
    const stamp = timestamp * 1000
    CacheResponse.setData(localeTimeKey, stamp)
    return stamp
  } catch (e) {
    return new Date()
  }
}

const serverTimeKey = 'setServerTime/timestamp'
export const setServerTime = async (): Promise<void> => {
  const globalObj = getGlobalObject()
  if (globalObj === null) return
  try {
    let stamp
    if (CacheResponse.hasData(serverTimeKey)) {
      stamp = CacheResponse.getData(serverTimeKey) as number
    } else {
      const {
        data: { timestamp }
      } = await serverTime()
      stamp = timestamp * 1000
      CacheResponse.setData(serverTimeKey, stamp)
    }
    globalObj.__SERVER_TIME__ = stamp
  } catch (err) {
    globalObj.__SERVER_TIME__ = Date.now()
  }
}

export const now = (): Date => new Date(getGlobalObject()?.__SERVER_TIME__ || Date.now())

export const setLocale = (localeId: string): void => {
  const globalObj = getGlobalObject()
  if (globalObj === null) return
  globalObj.__LOCALE_ID__ = localeId
}

export const parseDate = (value?: TDate): Date => {
  if (typeof value === 'string') return parseISO(value)
  if (typeof value === 'number') return toDate(value)
  if (value instanceof Date) return toDate(value)
  return now()
}

// функция, которая формирует локальную дату и время клиента
// в формате '2009-02-15 15:16:17'
export const getLocalClientTime = (): string => {
  // Получаем текущую дату и время
  const currentDate = new Date()

  // Получаем компоненты даты и времени
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')

  // Формируем строку в нужном формате
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export const formatDate = (value: TDate, format = 'yyyy-MM-dd'): string => {
  const date = parseDate(value)
  if (date === null) return INVALID_DATE
  try {
    return dateFnsFormat(date, format, { locale: getLocale() })
  } catch (error) {
    return INVALID_DATE
  }
}

export const RU_DATE_FORMAT = 'dd.MM.yyyy'
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
