import loadable from '@loadable/component'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'

import Spinner from '#components/Spinner'
import WithDelay from '#hoc/withDelay'
import theme from '#src/assets/scss/Exports.module.scss'

// --- SCREEN SAVERS ---

const StyledLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
    margin: 10
  },
  colorPrimary: {
    backgroundColor: theme.silver
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.primaryColor
  }
})(LinearProgress)

// eslint-disable-next-line react/prop-types
const StyledCircularProgress = ({ style, ...other }) => (
  <Spinner {...other} style={{ color: theme.primaryColor, width: 40, heigh: 40, ...style }} />
)

const WrappedLinearProgress = WithDelay(StyledLinearProgress)
const WrappedCircularProgress = WithDelay(StyledCircularProgress)

const LinearSpinner = <WrappedLinearProgress />
const CircularSpinner = (
  <WrappedCircularProgress
    className='w-100 h-100 d-flex-centered flex-fill'
    style={{ marginTop: '20%' }}
  />
)
// ---

// --- PAGES ---

export const LoginPageClient = loadable(
  () => import(/* webpackChunkName: "login" */ '../LoginPage/LoginPageClient'),
  { fallback: CircularSpinner }
)

export const PersonalAreaPage = loadable(
  () => import(/* webpackChunkName: "personalArea" */ './PersonalAreaPage'),
  { fallback: CircularSpinner }
)

// ---

// --- COMPONENTS ---

export const ActiveLoanForm = loadable(
  () => import(/* webpackChunkName: "activeLoan" */ './components/ActiveLoanForm'),
  { fallback: LinearSpinner }
)

export const ConfirmLoanForm = loadable(
  () => import(/* webpackChunkName: "confirmLoan" */ './components/ConfirmLoanForm'),
  { fallback: LinearSpinner }
)

export const NewLoanForm = loadable(
  () => import(/* webpackChunkName: "newLoan" */ './components/NewLoanForm'),
  { fallback: LinearSpinner }
)

export const RequisitesCard = loadable(
  () => import(/* webpackChunkName: "requisites" */ './components/RequisitesCard'),
  { fallback: LinearSpinner }
)

export const HistoryCard = loadable(
  () => import(/* webpackChunkName: "history" */ './components/HistoryCard'),
  { fallback: LinearSpinner }
)

export const FeedBackCard = loadable(
  () => import(/* webpackChunkName: "feedback" */ './components/FeedBackCard'),
  { fallback: LinearSpinner }
)

export const UserCard = loadable(
  () => import(/* webpackChunkName: "profile" */ './components/UserCard'),
  { fallback: LinearSpinner }
)

export const FriendsBonusCard = loadable(
  () => import(/* webpackChunkName: "takeBonus" */ './components/FriendsBonusCard'),
  { fallback: LinearSpinner }
)

export const Services = loadable(
  () => import(/* webpackChunkName: "profile" */ './components/Services'),
  { fallback: LinearSpinner }
)

// ---

// --- MODAL ---

export const CreditRestructureModal = loadable(
  () => import(/* webpackChunkName: "restructure" */ './components/CreditRestructureModal'),
  { fallback: null }
)

// ---
