import { CSSProperties, FocusEvent, MouseEvent, RefObject } from 'react'

export enum ButtonComponentTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
  LABEL = 'label',
  LINK = 'link',
  ANCHOR = 'anchor'
}
export type TOnClickEvent<T> = T extends ButtonComponentTypes.BUTTON | ButtonComponentTypes.SUBMIT
  ? MouseEvent<HTMLButtonElement>
  : T extends ButtonComponentTypes.LABEL
  ? MouseEvent<HTMLLabelElement>
  : T extends ButtonComponentTypes.LINK | ButtonComponentTypes.ANCHOR
  ? MouseEvent<HTMLAnchorElement>
  : never
interface IButtonBaseProps {
  size: 's' | 'm' | 'l' | 'xl' | 'custom'
  color: 'red' | 'green' | 'blue' | 'gray'
  style: CSSProperties
  className: string
  inverted: boolean
  fluid: boolean
  id: string
  url: string
  focus: boolean
  disabled: boolean
  loading: boolean
  width: 's' | 'm' | 'l' | 'xl'
  'data-qa': string
  onFocus: (event: FocusEvent) => void
  onBlur: (event: FocusEvent) => void
  ariaLabel: string
}
interface ISubmitProps extends Partial<IButtonBaseProps> {
  type: ButtonComponentTypes.SUBMIT | ButtonComponentTypes.BUTTON
  onClick?: (event: TOnClickEvent<ButtonComponentTypes.BUTTON>) => void
}

interface ISubmitProps {
  ariaLabel?: string
}

interface ILabelProps extends Partial<IButtonBaseProps> {
  type: ButtonComponentTypes.LABEL
  onClick?: (event: TOnClickEvent<ButtonComponentTypes.LABEL>) => void
}

interface IAnchorProps extends Partial<IButtonBaseProps> {
  type: ButtonComponentTypes.LINK | ButtonComponentTypes.ANCHOR
  onClick?: (event: TOnClickEvent<ButtonComponentTypes.LINK>) => void
  url: string
}

export type TButtonProps = ISubmitProps | ILabelProps | IAnchorProps

type TBaseAttributes = Pick<TButtonProps, 'id' | 'disabled' | 'data-qa' | 'type'>
type TAttributes<
  T = ButtonComponentTypes,
  U = TBaseAttributes
> = T extends ButtonComponentTypes.LABEL
  ? Omit<U, 'type'> & {
      onFocus: (event: FocusEvent) => void
      onBlur: (event: FocusEvent) => void
      onClick: (event: TOnClickEvent<ButtonComponentTypes.LABEL>) => void
      ref: RefObject<HTMLLabelElement>
    }
  : T extends ButtonComponentTypes.LINK | ButtonComponentTypes.ANCHOR
  ? Omit<U, 'type'> & {
      onFocus: (event: FocusEvent) => void
      onBlur: (event: FocusEvent) => void
      onClick: (event: TOnClickEvent<ButtonComponentTypes.LINK>) => void
      ref: RefObject<HTMLAnchorElement>
    }
  : T extends ButtonComponentTypes.BUTTON | ButtonComponentTypes.SUBMIT
  ? Omit<U, 'type'> & {
      onFocus: (event: FocusEvent) => void
      onBlur: (event: FocusEvent) => void
      onClick: (event: TOnClickEvent<ButtonComponentTypes.BUTTON>) => void
      ref: RefObject<HTMLButtonElement>
      ariaLabel?: string
    }
  : never
export type TLabelProps = TAttributes<ButtonComponentTypes.LABEL>
export type TLinkProps = TAttributes<ButtonComponentTypes.LINK>
export type TSubmitProps = TAttributes<ButtonComponentTypes.BUTTON>
