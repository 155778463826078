import './universal_notify_container.scss'

import { FC } from 'react'

import { Icon } from '#src/components/Icon/Icon'

type TUniversalProps = {
  iconName: string
  title: string
  message: string
  type: 'success' | 'info' | 'danger'
}
export const Universal: FC<TUniversalProps> = ({ iconName, title, message, type }) => {
  let color = '#000000'
  switch (type) {
    case 'success':
      color = '#127875'
      break
    case 'info':
      color = '#C46937'
      break
  }

  return (
    <div className={'universal__noty--container'}>
      <Icon
        style={{
          position: 'absolute',
          top: '16px',
          left: '16px',
          width: '24px',
          color: 'transparent'
        }}
        name={iconName}
      />
      <div
        style={{ color }}
        className={'font-weight-bold'}
        dangerouslySetInnerHTML={{ __html: title }}
      ></div>
      <div className={'mt-2'} dangerouslySetInnerHTML={{ __html: message }}></div>
    </div>
  )
}
