import {
  FETCH_PERSON_GROUPS_FAILURE,
  FETCH_PERSON_GROUPS_REQUEST,
  FETCH_PERSON_GROUPS_SUCCESS,
  IPersonGroupState,
  TPersonGroupsActionTypes
} from './types'

const initialState: IPersonGroupState = {
  data: null,
  isLoading: false,
  isFetched: false
}

export default (state = initialState, action: TPersonGroupsActionTypes): IPersonGroupState => {
  switch (action.type) {
    case FETCH_PERSON_GROUPS_REQUEST:
      return {
        ...state,
        isFetched: false,
        isLoading: true
      }
    case FETCH_PERSON_GROUPS_SUCCESS:
      return {
        ...state,
        isFetched: true,
        isLoading: false,
        data: action.payload
      }
    case FETCH_PERSON_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
