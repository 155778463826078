import { ReactChildren, ReactNode } from 'react'

type TWrapSubscribeFormProps = {
  hasAgreement: boolean
  children?: ReactChildren
}
const WrapSubscribeInputs: (props: TWrapSubscribeFormProps) => ReactNode = (props): ReactNode => {
  const { hasAgreement, children } = props
  if (hasAgreement) return <div className={'form-subscribe_container_form__line'}>{children}</div>
  if (children) return children
  return null
}

export default WrapSubscribeInputs
