/* eslint-disable @typescript-eslint/no-magic-numbers */
export const getRouteValue = (path) => ROUTES[path]
export const ROUTES = {
  main: '/',
  additionalPromoInfo: '/vam-dostupno-10-dnej-zajma-besplatno',
  additionalPromoInfoTemp: '/actions/rozygrysh-polnogo-pogasheniya-zajma-v-mfk-chestnoe-slovo',
  register: '/register',
  login: '/login',
  changePassword: '/change-password',
  complain: '/complain',
  reviews: '/reviews',
  about: '/about',
  aboutNested: '/about/*',
  howTakeLoan: '/kak-poluchit-zaym',
  howReturnLoan: '/kak_vernut_zajm',
  howProlongLoan: '/kak_prodlit_zajm',
  howToUseLoan: '/kak-polzovatsya-zaymom',
  improveCreditHistory: '/uluchshenie_kreditnoj_istorii',
  privileges: '/privileges',
  actions: '/actions',
  news: '/news',
  friends: '/friends',
  faq: '/faq',
  faqNested: '/faq/*',
  documents: '/dokumenty',
  documentsNested: '/dokumenty/*',
  passportChangeTemplate:
    '/docs/Заявление о дополнении ранее предоставленных персональных данных.docx',
  conditions: '/usloviya-predostavleniya',
  standartMFO: '',
  press: '/press',
  partners: '/partners',
  onlineLoansFeatures: '/osobennosti_onlajn_zajmov',
  whoCanTakeOnlineLoan: '/kto_mozhet_vzjat_onlajn-zajm',
  loansToCard: '/microzaym-na-kartu',
  obtainingMethods: '/sposoby_poluchenija',
  loansAmounts: '/summy_zajmov',
  loansTypes: '/vidy_zajmov',
  loansAdvantages: '/preimuschestva_zajmov',
  ToWhomGive: '/komu_vydaem_dengi_v_dolg',
  loansToBankAccount: '/zajm-na-bankovskij-schet',
  blog: '/blog',
  blogNested: '/blog/*',
  partnerLogin: '/partner',
  contact: '/contact',
  sitemap: '/sitemap',
  sitemapxml: '/sitemap.xml',
  certificates: '/certificates',
  feedback: '/feedback',
  userLoan: '/loan',
  userHistory: '/loans',
  userData: '/personal',
  userDocuments: '/documents',
  userRequisites: '/requisites',
  userSupport: '/support',
  partnerLoan: '/loan',
  partnerBids: '/bids',
  partnerAccounts: '/accounts',
  partnerStat: '/stat',
  partnerBrokerStat: '/stat-broker',
  partnerClientBids: '/clientbids',
  history: '/history',
  requisites: '/requisites',
  requisitesNested: '/requisites/*',
  hotline: '/hotline',
  notifications: '/notifications',
  profile: '/profile',
  404: '/404',
  admin: '/admin',
  adminBanner: '/admin/banner',
  adminText: '/admin/text',
  info: '/info',
  adminPersonal: '/admin/personal-area',
  adminProductDescription: '/admin/product-description',
  adminArticles: '/admin/articles',
  adminTestimonials: '/admin/testimonials',
  adminSitemap: '/admin/sitemap',
  adminAuthors: '/admin/authors',
  logInWithToken: '/client/loginWithToken',
  logInWithTokenBroker: '/loginWithToken',
  special: '/special',
  vacancies: '/vacancies',
  subscribe: '/subscribe',
  adminPersonalNotifications: '/admin/personal-notifications',
  installment: '/il',
  adminContent: '/admin/content',
  everyDayOnline: '/onlajn-zajm-24-chasa',
  transferMoney: '/zajm-na-kartu-mastercard',
  onlyPassport: '/zajm-po-pasportu-na-kartu',
  smallPercent: '/zajm-pod-malenkij-protsent-na-kartu',
  visa: 'https://www.visa.com.ru/',
  masterCard: 'https://www.mastercard.ru/ru-ru.html',
  mirLink: 'https://mironline.ru/payment-system/about/',
  qiwi: 'https://qiwi.com/',
  elecsnet: 'https://elecsnet.ru/',
  nbki: 'https://www.nbki.ru/',
  scoring: 'https://scoring.ru/ ',
  fondy: 'https://fondy.ru/',
  scorista: 'https://scorista.ru/',
  contacts: '/contacts',
  blogPersonalFinance: '/blog/personalfinance',
  blogAboutLoansSimply: '/blog/loans',
  blogFinancialLiteracy: '/blog/financialliteracy',
  blogFamilyAndChildren: '/blog/family',
  moneyUsloviya: '/money_usloviya',
  pravila: '/rules',
  kanikuly: '/kanikuly-school-usloviya',
  klass: '/klass',
  usloviyaNaOne: '/ny1000_usloviya',
  poPromo: '/pyat-dnej-zajma-po-promo-23febr-usloviya',
  newYearUsloviya: '/ny2018_usloviya',
  newClientUSloviya: '/vsem_novym_klientam_zajm_besplatno_uslovya',
  beforeSubscribe: '/before_subscribe',
  afterSubscribe: '/after_subscribe',
  threeDaysPromocode: '/tri-dnya-zajma-po-stavke-nol-procentov',
  videoinstrukcii: '/videoinstrukcii',
  buhgalterskayaOtchetnost: '/buhgalterskaya-otchetnost',
  viberu: 'https://www.vbr.ru',
  bankiros: 'https://bankiros.ru',
  bankiru: 'https://www.banki.ru/microloans/responses/response/create/?companyId=47',
  mission: '/mission',
  crew: '/crew',
  edition: '/edition',
  services: '/services',
  remove: '/remove'
}

export const howToList = [
  ROUTES.howToUseLoan,
  ROUTES.howTakeLoan,
  ROUTES.howReturnLoan,
  ROUTES.howProlongLoan
]

export const partnerBidsAndAccountsNested = [
  `${ROUTES.partnerBids}/*`,
  `${ROUTES.partnerAccounts}/*`
]
export const partnerPagePaths = [
  ROUTES.main,
  ROUTES.partnerLoan,
  ROUTES.partnerBids,
  ROUTES.partnerAccounts,
  ROUTES.partnerStat,
  ROUTES.partnerBrokerStat,
  ROUTES.partnerClientBids
]
export const SCREEN_RESOLUTION = {
  mobile: 320,
  tablet: 768,
  desktop: 1024
}
export const LOAN_MIN_AGE = 18
export const LOAN_MAX_AGE = 75
export const RE_PATH = /^\/(user|partner|contact|rss)\/?/
export const RE_EXT = /(\.\w+)$/
export const RE_HOST = /(n?\.?4slovo)/
export const RE_HASH = /(?=#)[^/]+(?=\/?)/
export const RE_VISA = /^4/
export const RE_MASTERCARD = /^((5[1-5]))/
export const RE_MIR = /^(2|2_)(_|2)(_|0)(_|[0-4]).*|\s$/
export const RE_DATE = /\d{4}-\d{2}-\d{2}/
export const LINK_TO_EXTERNAL_SOURCE = /<a([^>]*?)href=(["'])(https?:\/\/(?!4slovo).*?)\2[^>]*>/gi

export const KEY_CODES = { up: 38, down: 40, enter: 13, esc: 27, space: 32 }
export const RF_PASSPORT_DATE = '1997-10-01'
export const CARD_IMAGE_MAX_SIZE = 5 * 1024 * 1024
export const REVIEW_IMAGE_MAX_SIZE = 2 * 1024 * 1024
export const SITEMAP_FORMAT = 'json'

// parameters for SEO
export const NOOPENER = 'noopener'
export const NOOPENER_NOFOLLOW = 'noopener nofollow'
export const BLANK_LINK = /_blank/

export const BUTTON_COLOR = 'BUTTON_COLOR'
export const BUTTON_SIZE = 'BUTTON_SIZE'
export const BUTTON_TEXT = 'BUTTON_TEXT'
export const BUTTON_TEXT_COLOR = 'BUTTON_TEXT_COLOR'
export const COOKIE_4P = 'aprt159'
export const UTM_LIST = new Map([
  ['utm_source', 1],
  ['utm_medium', 2],
  ['utm_campaign', 3],
  ['utm_content', 4],
  ['utm_term', 5],
  ['type', 6],
  ['source', 7],
  ['added', 8],
  ['block', 9],
  ['position', 10],
  ['keyword', 11],
  ['pos', 12],
  ['key', 13],
  ['campaign', 14],
  ['ad', 15],
  ['phrase', 16],
  ['aip', 17],
  ['prx', 18],
  ['click_id', 19],
  ['lh_tid', 20],
  ['affiliate_id', 21],
  ['partner_source', 22],
  ['_ga', 23]
])
// todo: import from intl
export const CREDIT_STATUS = {
  2: { color: 'rgba(154, 196, 141, 0.4)', tooltip: 'Выдан' },
  '-1': { color: 'rgba(242, 225, 183, 0.4)', tooltip: 'Не подтверждён клиентом' },
  1: { color: 'rgba(24, 225, 13, 0.4)', tooltip: 'Status deprecated' },
  0: { color: 'rgba(255, 179, 177, 0.4)', tooltip: 'В обработке' },
  3: { color: 'rgba(253, 174, 3, 0.4)', tooltip: 'Возвращен' },
  4: { color: 'rgba(231, 20, 13, 0.4)', tooltip: 'Просрочен' },
  null: { color: 'rgba(154, 16, 11, 0.4)', tooltip: 'Анкета не заполнена' },
  100: { color: 'rgba(154, 16, 11, 0.4)', tooltip: 'Отказано' }
}
export const PERSON_STATUS = [
  { color: 'rgba(255, 179, 177, 0.4)', tooltip: 'В обработке' },
  { color: 'rgba(253, 174, 3, 0.4)', tooltip: 'Завершена' }
]
// -1 // Не подтверждён при создании заявки
// 0 // В обработке
// 1 // Одобрен (не используется)
// 2 // Выдан
// 3 // Возвращён
// 4 // Просрочен
// 5 // Передан коллекторам
// 6 // Безнадёжное взыскание (Проблемное взыскание)
// 7 // Продан
// 77 // Исключение (клиент осознаёт просрочку и просит его не беспокоить)
// 78 // REFUSED_CONTACT
// 90 // Расследование (клиент стал жертвой мошенничества)
// 91 // Банкротство
// 92 // Мошенничество
// 93 // Принято судебное решение
// 100 // Отказан
// 200 // Смерть
// 801 // Передавн в СКА
// 802 // Получен судебный приказ (СКА)
// 803 // Отказ (СКА)
// 804 // Исполнительное производство (СКА)
// 805 // Акт о невозможности взыскать (СКА)
// 901 // Передан в суд
// 902 // Получен судебный приказ (СУД)
// 903 // Отказ (СУД)
// 904 // Исполнительное производство (СУД)
// 905 // Акт о невозможности взыскать (СУД)
export const CREDIT_COMMENT_STATUSES = [4, 17, 38, 65]
// статус, проставленный брокером:
// 0 - не обработан
// 1 - принят {STATUS_APPLIED}
// -1 - отказано { STATUS_REFUSED }
export const STATUS_APPLIED = 1
export const STATUS_REFUSED = -1

// Документ ПОД/ФТ
// (противодействие отмыванию доходов и финансированию терроризма)
export const AML_CFT_AGREEMENT = 'aml_cft_agreement'
// согласие на запрос данных с НБКИ
export const NBKI_AGREEMENT = 'agreements_nbki'

export const PRIVATE_TERMS = 'privateTerms'
export const GENERAL_TERMS = 'generalTerms'
// Общие условия
export const TERMS = 'terms'

export const INSURANCE_AGREEMENT = 'insurance_agreement'
export const INSURANCE_STATEMENT = 'insurance_statement'
export const INSURANCE_CONDITIONS = 'insurance_conditions'
export const INSURANCE_STATEMENT_TRANSFER = 'insurance_statement_transfer'

export const INSURANCE_AGREEMENT_CARD = 'insurance_agreement_card'
export const INSURANCE_STATEMENT_CARD = 'insurance_statement_card'
export const INSURANCE_CONDITIONS_CARD = 'insurance_conditions_card'
export const INSURANCE_STATEMENT_TRANSFER_CARD = 'insurance_statement_transfer_card'

export const INSURANCE_D2_AGREEMENT = 'd2_insurance_agreement'
export const INSURANCE_D2_AGREEMENT_CARD = 'd2_insurance_cards_agreement'
export const INSURANCE_D2_APPLICATION = 'd2_insurance_offer'
export const INSURANCE_D2_APPLICATION_CARD = 'd2_insurance_cards_offer'
export const ADDITIONAL_SERVICES_STATEMENT = 'additional_services_statement'

// Договор на предоставление микрозайма
export const LOAN_AGREEMENT = 'agreement'
// Договор на предоставление потребительского займа
export const LOAN_AGREEMENT_IL = 'agreement-il'
// Согласие на обработку ПД
export const PERSON_DATA_AGREEMENT = 'person_data_agreement'
export const LOAN_AGREEMENT_IL_MONTH = 'agreement_il_over_1_year'

export const fakeNamePrefix = 'fake-prefix-'
export const RISK_NOTICE = 'notification_pdn'

export const LOGIN_TYPE = {
  EMAIL: 'email',
  PHONE: 'mobile'
}

// Константы для логирования ошибок

/** Ключ под которым к объекту response, request будет добавляться конфиг запроса / ответа для логирования */
export const AJAX_CONFIG_FIELD_NAME = '__ajax_config'
/** Ключ под которым в объекте ответа от API хранится код ответа от API (напр. код 1 - ошибка на сервере) */
export const RESPONSE_CODE_FIELD_NAME = 'code'
/** Помечаем этим кодом все ошибки HTTP,
 * которые не являются ответами от API (например 4хх и 5хх ошибки, если зафаталилось API) */
export const MANDATORY_REPORT_CODE = 'MANDATORY_REPORT_CODE'

export const BASE_CONFIG = {
  banner: {
    tablet: {
      backgroundImage: '7588e47ebdd6732b0edcb266e074986653d96df7'
    },
    desktop: {
      backgroundImage: '9fa967f35f1bbbe67bb6d23afcc06800feb79f9a'
    }
  },
  text: {
    title: 'БЫСТРЫЕ ЗАЙМЫ ОНЛАЙН',
    description: 'Пользуйтесь деньгами 60 дней и 10 из них бесплатно!',
    link: 'vam-dostupno-10-dnej-zajma-besplatno'
  }
}

export const VIEWS = {
  main: 0,
  promo: 1
}

export const FREE_DAYS_WITH_RESTRICTIONS = 3

export const innNalogRuHref = 'https://service.nalog.ru/inn.do'

export const DEFAULT_REPEAT_COOLDOWN_TIME_S = 60
export const DEFAULT_REPEAT_COOLDOWN_TIME_MS = 60 * 1000

export const HTTP_STATUS_CODES = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  PayloadTooLarge: 413,
  InternalServerError: 500
}

export const DATE_TIME_ISO_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const mobileOffset = 95
export const desktopOffset = 115
export const INITIAL_FOLDER = 'images'
export const SERVER_FOLDER = `public/universal/${INITIAL_FOLDER}`
export const RELATIVE_SERVER_FOLDER = 'public/universal'
export const errorPayloadPath = 'payload.message'
// Cookies
export const SHOW_SUCCESS_MESSAGE_AFTER_PAYMENT_COOKIE = 'showSuccessAfterRedirect'
// Типы займов
export const CONSUMER_CREDIT = 'ConsumerCredit'
export const CONSUMER_CREDIT_MONTH = 'ConsumerCreditMonth'
export const CONSUMER_CREDIT_MONTH_SHORT = 'ConsumerCreditMonthShort'
export const MICRO_CREDIT = 'MicroCredit'

export const FREEZE_STATE_REQUEST_COOLDOWN_COOKIE = 'freezeStateCooldownRequest'
export const EMAIL_BONUS_COOLDOWN = 'emailBonusCooldown'
export const FILE_NOTIFICATION_COOLDOWN = 'fileNotificationCooldown'
export const PASSPORT_CHECK_RESULT = 'personalDataCheckResult'
export const SUCCESS_RESPONSE_CODE = 0
export const UNAUTHORIZED = 'unauthorized'
export const INVALID_TOKEN = 'Invalid token'
