import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { isEmpty, uniqBy } from 'lodash'

import { TFilterListEmitData } from '#components/AdminPage/components/Filter/FilterList'
import { TSelectOption } from '#components/Form/Form'
import intl from '#intl'
import {
  TActiveLoanConditionsWithActivePersonGroup,
  TPersonStatus,
  TProductDescription,
  TProductDescriptionByIdData
} from '#modules/api/admin/types'
import {
  getActiveLoanConditionsWithActivePersonGroup,
  getPersonGroups,
  getProductDescription,
  getProductDescriptionById,
  removeCurrentDescription,
  saveCurrentDescription
} from '#reducers/adminPage/productsInfo/effects'
import { TSelectedStatusProduct } from '#reducers/adminPage/productsInfo/types'
import { getErrorMessage } from '#reducers/helper'
import notify from '#services/notify'
import { TMainDataStateType } from '#src/reducers'

export const emptyTerm = '0-0'

export type TProductsInfoInitialState = {
  loading: boolean
  isFetched: boolean
  status: string
  filter: TFilterListEmitData
  productDescriptions: TProductDescription[]
  personGroups: TPersonStatus
  removingProductInfoIds: TNullable<number>[]
  currentDescription: TProductDescription
  currentGroup: string
  activeLoanConditions: {
    isLoading: boolean
    isFetched: boolean
    items: TActiveLoanConditionsWithActivePersonGroup[]
    selectedProductId: TNullable<number>
    selectedGroupId: TNullable<number>
  }
  currentProductDescription: {
    isLoading: boolean
    isFetched: boolean
    data: TNullable<TProductDescriptionByIdData>
  }
}

const initialState: TProductsInfoInitialState = {
  loading: false,
  isFetched: false,
  status: 'new',
  filter: { status: {} } as TSelectedStatusProduct,
  productDescriptions: [] as TProductDescription[],
  personGroups: {} as TPersonStatus,
  removingProductInfoIds: [],
  currentDescription: {} as TProductDescription,
  currentGroup: 'new',
  activeLoanConditions: {
    isLoading: false,
    isFetched: false,
    items: [],
    selectedProductId: null,
    selectedGroupId: null
  },
  currentProductDescription: {
    isLoading: false,
    isFetched: false,
    data: null
  }
}

const productInfoSlice = createSlice({
  name: 'productDescriptions',
  initialState,
  reducers: {
    setFilterData: (state, { payload }: PayloadAction<Record<'status', TFilterListEmitData>>) => {
      state.filter = payload.status
    },
    setCurrentGroup: (state, { payload }: PayloadAction<string>) => {
      const { personGroups } = state
      state.currentGroup =
        Object.keys(personGroups).find(
          (item) => personGroups[item as keyof typeof personGroups].id === Number(payload)
        ) || ''
    },
    flushCurrentDescription: (state) => {
      state.currentDescription = {} as TProductDescription
    },
    removeCurrentDescriptionRequest: (state, { payload }: PayloadAction<number>) => {
      state.removingProductInfoIds = [...new Set(state.removingProductInfoIds.concat(payload))]
    },
    removeCurrentDescriptionFailure: (state, { payload }: PayloadAction<number>) => {
      state.removingProductInfoIds = state.removingProductInfoIds.filter((id) => id !== payload)
    },
    setProductId: (state, action: PayloadAction<number>) => {
      state.activeLoanConditions.selectedProductId = action.payload
    },
    setPersonGroupId: (state, action: PayloadAction<number>) => {
      state.activeLoanConditions.selectedGroupId = action.payload
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(saveCurrentDescription.pending, (state) => {
        state.loading = true
      })
      .addCase(saveCurrentDescription.fulfilled, (state) => {
        state.currentDescription = {} as TProductDescription
        state.loading = false
        notify.push({ message: 'Описание сохранено', type: 'success' })
      })
      .addCase(saveCurrentDescription.rejected, (state, action) => {
        const message = getErrorMessage(action)
        state.loading = false
        notify.push({ message: message || intl.serverError, type: 'danger' })
      })
      .addCase(removeCurrentDescription.pending, (state) => {
        state.loading = true
      })
      .addCase(removeCurrentDescription.fulfilled, (state) => {
        state.loading = false
        notify.push({ message: 'Описание удалено', type: 'success' })
      })
      .addCase(removeCurrentDescription.rejected, (state, action) => {
        const message = getErrorMessage(action)
        notify.push({ message: message || intl.serverError, type: 'danger' })
      })
      .addCase(getProductDescription.fulfilled, (state, action) => {
        if (action.payload.data.length > 0) {
          state.productDescriptions = action.payload.data.map((productInfo) => ({
            ...productInfo,
            terms: `${productInfo.termMin} - ${productInfo.termMax} дня(дней)`
          }))
        }
        state.loading = false
        state.isFetched = true
      })
      .addCase(getPersonGroups.fulfilled, (state, action) => {
        state.personGroups = action.payload
        state.loading = false
        state.isFetched = true
      })
      .addCase(getActiveLoanConditionsWithActivePersonGroup.pending, (state) => {
        state.activeLoanConditions.isLoading = true
        state.activeLoanConditions.isFetched = false
        state.activeLoanConditions.items = []
      })
      .addCase(getActiveLoanConditionsWithActivePersonGroup.fulfilled, (state, action) => {
        state.activeLoanConditions.isLoading = false
        state.activeLoanConditions.isFetched = true
        state.activeLoanConditions.items = action.payload
      })
      .addCase(getActiveLoanConditionsWithActivePersonGroup.rejected, (state, action) => {
        state.activeLoanConditions.isLoading = false
        state.activeLoanConditions.isFetched = false
        const message = getErrorMessage(action)
        notify.push({ message: message || intl.serverError, type: 'danger' })
      })
      .addCase(getProductDescriptionById.pending, (state) => {
        state.currentProductDescription.isLoading = true
        state.currentProductDescription.isFetched = false
        state.currentProductDescription.data = null
      })
      .addCase(getProductDescriptionById.fulfilled, (state, action) => {
        state.currentProductDescription.isLoading = false
        state.currentProductDescription.isFetched = true
        state.currentProductDescription.data = action.payload
        state.activeLoanConditions.selectedProductId = action.payload.productId
        state.activeLoanConditions.selectedGroupId = action.payload.persongroupId
      })
      .addCase(getProductDescriptionById.rejected, (state, action) => {
        state.currentProductDescription.isLoading = false
        state.currentProductDescription.isFetched = false
        const message = getErrorMessage(action)
        notify.push({ message: message || intl.serverError, type: 'danger' })
      })
      .addMatcher(isAnyOf(getProductDescription.pending, getPersonGroups.pending), (state) => {
        state.loading = true
        state.isFetched = false
      })
      .addMatcher(
        isAnyOf(getProductDescription.rejected, getPersonGroups.rejected),
        (state, action) => {
          state.loading = false
          state.isFetched = false
          const message = getErrorMessage(action)
          notify.push({ message: message || intl.serverError, type: 'danger' })
        }
      )
})

export const {
  flushCurrentDescription,
  setFilterData,
  removeCurrentDescriptionRequest,
  removeCurrentDescriptionFailure,
  setCurrentGroup,
  setProductId,
  setPersonGroupId
} = productInfoSlice.actions

export default productInfoSlice.reducer

const productInfo = (state: TMainDataStateType): TProductsInfoInitialState =>
  state.adminPage.productInfo

export const personGroupsSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.personGroups
)

export const isProductDescriptionsFetched = createSelector(
  productInfo,
  (productInfoInitialState) => {
    const { personGroups } = productInfoInitialState
    return !isEmpty(personGroups)
  }
)

export const currentDescriptionSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.currentProductDescription.data
)

export const isLoadingCurrentDescriptionSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.currentProductDescription.isLoading
)

export const isFetchedCurrentDescriptionSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.currentProductDescription.isFetched
)

export const removingProductInfoIds = createSelector(
  productInfo,
  (productInfo) => productInfo.removingProductInfoIds
)

export const filteredProductsInfoData = createSelector(productInfo, (productInfoInitialState) => {
  const {
    filter: { status },
    productDescriptions
  } = productInfoInitialState
  const statusKeys = Object.keys(status)
  if (!statusKeys.length) return productDescriptions
  if (!statusKeys.filter((item) => status[item]).length) return productDescriptions
  return productDescriptions.filter((productInfo) => status[productInfo.status])
})

export const productsInfoFilterData = createSelector(
  productInfo,
  (productInfo) => productInfo.filter
)

export const isLoadingActiveLoanConditionsSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.activeLoanConditions.isLoading
)

export const isFetchedActiveLoanConditionsSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.activeLoanConditions.isFetched
)

export const activeLoanConditionsItemsSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.activeLoanConditions.items
)

export const uniqueProductTypesSelector = createSelector(
  activeLoanConditionsItemsSelector,
  (items) =>
    uniqBy(items, 'productName').map((item) => ({
      value: item.productId,
      text: item.productName,
      enabled: 1,
      disableReason: null
    })) as TSelectOption[]
)

const selectedProductIdSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.activeLoanConditions.selectedProductId
)

const selectedGroupIdSelector = createSelector(
  productInfo,
  (productInfo) => productInfo.activeLoanConditions.selectedGroupId
)

export const availableGroupOptionsSelector = createSelector(
  activeLoanConditionsItemsSelector,
  selectedProductIdSelector,
  (items, selectedProductId) => {
    if (selectedProductId) {
      // первоначально получаем отфильтрованный список по выбранному продукту
      const result = items
        .filter((item) => item.productId === selectedProductId)
        .map((item) => ({
          value: item.persongroupId,
          text: item.persongroupName,
          enabled: 1,
          disableReason: null
        }))
      // удаляем дубли persongroup
      return uniqBy(result, 'value')
    }
    return []
  }
)

export const availableTermsOptionsSelector = createSelector(
  activeLoanConditionsItemsSelector,
  selectedProductIdSelector,
  selectedGroupIdSelector,
  (items, selectedProductId, selectedGroupId) => {
    if (selectedProductId && selectedGroupId) {
      const result = items
        .filter(
          (item) => item.productId === selectedProductId && item.persongroupId === selectedGroupId
        )
        .map((item) => ({
          value: `${item.termMin}-${item.termMax}`,
          text: `От ${item.termMin} - До ${item.termMax} дней`,
          enabled: 1,
          disableReason: null
        }))
      // удаляем дубли
      return uniqBy(result, 'value')
    }
    return []
  }
)
