import './form.scss'

import cn from 'classnames'
import { CSSProperties, FC, forwardRef, ReactNode } from 'react'

import { nl2br } from '#services/helper'

export interface IFormItemProps {
  style?: CSSProperties
  className?: string
  type?: string
  name: string
  weight?: number
  combineText?: boolean
  bottomTextPermanent?: boolean
  hint?: string | JSX.Element
  label?: string
  labelStyle?: CSSProperties
  error?: string | JSX.Element | null
  bottomText?: string | JSX.Element
  customLabel?: string | JSX.Element
  hintStyle?: CSSProperties
  renderProp?: () => ReactNode
  bodyStyle?: CSSProperties
  bottomHint?: JSX.Element
  children: ReactNode
}

type THintElementProps = {
  hint: string | JSX.Element
  hintStyle?: CSSProperties
}
const TopHintElement: FC<THintElementProps> = ({ hint, hintStyle }) =>
  typeof hint === 'string' ? (
    <div
      className='form__item-hint'
      data-qa='formItem_hint'
      dangerouslySetInnerHTML={{ __html: hint }}
      style={hintStyle}
    />
  ) : (
    <div className='form__item-hint' data-qa='formItem_hint'>
      {hint}
    </div>
  )

type TBottomHintElement = {
  hint: JSX.Element
}
const BottomHintElement: FC<TBottomHintElement> = ({ hint }) => (
  <div className={'form__item__bottom-hint'}>{hint}</div>
)

export const FormItem = forwardRef<HTMLDivElement, IFormItemProps>((props, ref) => {
  const {
    weight,
    type,
    name: itemName,
    label,
    customLabel,
    hint,
    error,
    bottomText,
    children,
    style = {},
    combineText = false,
    hintStyle,
    className,
    renderProp = () => null,
    labelStyle,
    bodyStyle,
    bottomTextPermanent: isBottomTextPermanent = false,
    bottomHint
  } = props

  const weightClass = weight ? `form__item_w_${weight}` : ''

  const classes = cn(
    {
      form__item: true,
      [`form__item_name_${itemName}`]: itemName,
      [`form__item_type_${type ? type : ''}`]: Boolean(type)
    },
    weightClass,
    className
  )

  return (
    <div ref={ref} className={classes} style={style} data-qa={`formItem_${itemName}`}>
      {label && (
        <div
          className='form__item-label'
          data-qa='formItem_label'
          dangerouslySetInnerHTML={{ __html: label }}
          style={labelStyle}
        />
      )}
      {customLabel && <div data-qa='formItem_customLabel'>{customLabel}</div>}
      {typeof hint !== 'undefined' && <TopHintElement hint={hint} hintStyle={hintStyle} />}
      <div className='form__item-body' style={bodyStyle} data-qa='formItem_body'>
        {children || renderProp()}
      </div>
      {typeof bottomHint !== 'undefined' && <BottomHintElement hint={bottomHint} />}
      {Boolean(error) &&
        (typeof error === 'string' ? (
          <div
            className='form__item-error'
            data-qa='formItem_error'
            dangerouslySetInnerHTML={{ __html: nl2br(error) }}
          />
        ) : (
          <div className='form__item-error' data-qa='formItem_error'>
            {error}
          </div>
        ))}
      {Boolean(error) &&
        Boolean(bottomText) &&
        combineText &&
        (typeof bottomText === 'string' ? (
          <div
            className='form__item-bottom-text'
            dangerouslySetInnerHTML={{ __html: nl2br(bottomText) }}
          />
        ) : (
          <div className='form__item-bottom-text'>{bottomText}</div>
        ))}
      {(!error || isBottomTextPermanent) &&
        Boolean(bottomText) &&
        (typeof bottomText === 'string' ? (
          <div
            className='form__item-bottom-text'
            dangerouslySetInnerHTML={{ __html: nl2br(bottomText) }}
          />
        ) : (
          <div className='form__item-bottom-text'>{bottomText}</div>
        ))}
    </div>
  )
})
