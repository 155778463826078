import { combineReducers } from '@reduxjs/toolkit'

import accounts from './accountsSlice'
import creditHistory from './creditHistorySlice'
import criticalError from './criticalErrorSlice'
import documents from './documentsSlice'
import freeze from './freezeSlice'
import loan from './loanSlice'
import notifications from './notificationsSlice'
import personGroups from './personGroups'
import profile from './profileSlice'
import requisites from './requisitesSlice'
import services from './servicesSlice'

const clientData = combineReducers({
  profile,
  accounts,
  requisites,
  loan,
  documents,
  creditHistory,
  notifications,
  freeze,
  criticalError,
  personGroups,
  services
})

export type TClientDataReducerType = typeof clientData
export type TClientDataStateType = ReturnType<TClientDataReducerType>

export default clientData
