import ReactNotification from 'react-notifications-component'
import { Portal } from 'react-portal'

import App from '#components/App/App'
import Layout from '#components/App/PersonalAreaLayout'

const personalContainer = ({ notificationsContainer }) => (
  <App>
    <Portal node={notificationsContainer}>
      <ReactNotification />
    </Portal>
    <Layout />
  </App>
)

export default personalContainer
