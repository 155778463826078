import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { fakeSeoText } from '#src/constants/seoText'
import { TMainDataStateType } from '#src/reducers'
import { buildNormalizedPaths, loadContent } from '#src/reducers/article/effects'

const SPLIT_PATTERN = '</p>'

export const fetchSeo = createAsyncThunk('seo/fetchSeo', async (seoPath: string, thunkAPI) => {
  try {
    const [pathsArray] = buildNormalizedPaths(seoPath) as string[]
    const {
      data: { text, title, description }
    } = (await loadContent(pathsArray)) as {
      data: { text: string; title: string; description: string }
    }
    const result = Object.assign({}, { allText: text, description, title })
    const primaryText = result.allText
      .split(SPLIT_PATTERN)
      .reduce((acc: string, cur: string, index: number) => {
        if (index < 2) return acc + cur + SPLIT_PATTERN
        return acc
      }, '')
    return {
      primaryText,
      allText: result.allText,
      title: result.title,
      description: result.description
    }
  } catch (err) {
    const data = { ...fakeSeoText, title: '', description: '', error: err }
    return thunkAPI.fulfillWithValue(data)
  }
})

export type TSeoInitialState = {
  loading?: boolean
  primaryText?: string
  allText: string
  title: string
  description: string
  error: unknown
}
const initialState: TSeoInitialState = {
  loading: false,
  primaryText: '',
  allText: '',
  title: '',
  description: '',
  error: null
}

const seoSlice = createSlice({
  name: 'seoSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSeo.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSeo.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchSeo.fulfilled, (state, action) => {
        state.loading = false
        state.primaryText = action.payload.primaryText
        state.allText = action.payload.allText
        state.title = action.payload.title
        state.description = action.payload.description
        if (!state.error && action.payload?.error) state.error = action.payload.error
      })
  }
})

export default seoSlice.reducer

const seo = (state: TMainDataStateType): TSeoInitialState => state.seo
export const seoLoading = createSelector(seo, (seo) => seo.loading)
export const seoAllText = createSelector(seo, (seo) => seo.allText)
export const seoPrimaryText = createSelector(seo, (seo) => seo.primaryText)
