import { AxiosRequestConfig } from 'axios'

import appConfig from '#config'
import { HttpClient } from '#src/modules/api/httpClient'

class ContactClientApi extends HttpClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
  }
}

const osTicketClient = new ContactClientApi({
  baseURL: new URL('/osTicket', appConfig.host).href
})

export default osTicketClient.instance
