import { noop } from 'lodash'
import { Component, CSSProperties, ReactNode } from 'react'

import intl from '#intl'
import withErrorLogger from '#src/hoc/withErrorLogger'
import { TLogError } from '#src/hook/useErrorLogger'

import ChunkLoadError from './ChunkLoadError'
import {
  ATTEMPTS_TO_RECONNECT,
  CHUNK_LOAD_ERROR,
  RECONNECT_INTERVAL_MS,
  UNKNOWN_ERROR
} from './constants'
import UnknownError from './UnknownError'

interface IDerivedStateFromError {
  errorType: string | null
  errorMessage: string
  errObj: Error | null
}

interface IChunksErrorBoundaryState extends IDerivedStateFromError {
  attemptsCount: number
  isAllAttemptsFailed: boolean
}

interface IChunksErrorBoundaryProps {
  children: ReactNode
  className?: string
  style?: CSSProperties
  logError: TLogError
}

class ErrorBoundary extends Component<IChunksErrorBoundaryProps, IChunksErrorBoundaryState> {
  attemptsTotal = ATTEMPTS_TO_RECONNECT
  unlistenHistory: () => void = noop
  reconnectTimerId: number | undefined

  state = {
    errorType: null,
    errorMessage: '',
    errObj: null,
    attemptsCount: 0,
    isAllAttemptsFailed: false
  }

  static getDerivedStateFromError(error: Error): IDerivedStateFromError {
    const errorType = error.name === CHUNK_LOAD_ERROR ? CHUNK_LOAD_ERROR : UNKNOWN_ERROR
    const errorMessage = error.message || intl.serverError
    return { errorType, errorMessage, errObj: error }
  }

  componentDidMount(): void {
    this.unlistenHistory = this.handleResetError
  }

  componentWillUnmount(): void {
    this.unlistenHistory()
  }

  componentDidCatch(error, errorInfo): void {
    this.props.logError(
      error,
      `ErrorBoundary.componentDidCatch with stack: ${JSON.stringify(errorInfo)}`
    )
    const { errorType, errObj } = this.state
    if (errorType === CHUNK_LOAD_ERROR) this.handleChunkLoadError()
    else this.props.logError(errObj, 'ErrorBoundary.componentDidCatch')
  }

  handleChunkLoadError = (): void => {
    const { attemptsCount } = this.state
    if (attemptsCount >= this.attemptsTotal) {
      clearTimeout(this.reconnectTimerId)
      this.setState({ isAllAttemptsFailed: true })
      window.location.reload()
    } else {
      this.setState((state) => ({ attemptsCount: state.attemptsCount + 1 }))
      this.reconnectTimerId = window.setTimeout(
        () => this.setState({ errorType: null }),
        RECONNECT_INTERVAL_MS
      )
    }
  }

  handleResetError = (): void => {
    this.setState({
      errorType: null,
      attemptsCount: 0,
      isAllAttemptsFailed: false,
      errorMessage: ''
    })
    clearTimeout(this.reconnectTimerId)
  }

  render(): ReactNode {
    const { errorType, attemptsCount, isAllAttemptsFailed, errorMessage } = this.state
    const { className, style } = this.props
    if (errorType === CHUNK_LOAD_ERROR) {
      return (
        <ChunkLoadError
          attempt={attemptsCount}
          attempts={this.attemptsTotal}
          isAllAttemptsFailed={isAllAttemptsFailed}
          onManualReset={this.handleResetError}
          className={className}
          style={style}
        />
      )
    }

    if (errorType === UNKNOWN_ERROR)
      return <UnknownError errorMessage={errorMessage} className={className} style={style} />

    return this.props.children
  }
}

export default withErrorLogger(ErrorBoundary)
