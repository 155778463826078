import { createSlice } from '@reduxjs/toolkit'

export type TInitialState = {
  isCameraPreviousPage: boolean
}

const initialState: TInitialState = {
  isCameraPreviousPage: false
}
const cameraSlice = createSlice({
  name: 'cameraSlice',
  initialState,
  reducers: {
    setCameraStateOn: (state) => {
      state.isCameraPreviousPage = true
    },
    setCameraStateOff: (state) => {
      state.isCameraPreviousPage = false
    }
  }
})
export const { setCameraStateOn, setCameraStateOff } = cameraSlice.actions
export default cameraSlice.reducer
