import { createAsyncThunk } from '@reduxjs/toolkit'

import { mainSiteApi } from '#modules/api'
import { IGetAnnouncesSuccess } from '#modules/api/main/mainSiteApi'
import { CreatePrefix } from '#reducers/helper'
import CacheResponse from '#src/modules/CacheResponse'

const CACHE_PATH = '[fetchBlog][getAnnounces]'
const prefixHelper = new CreatePrefix('blog')

export const fetchBlog = createAsyncThunk(prefixHelper.create('fetchBlog'), async (_, thunkAPI) => {
  let result: IGetAnnouncesSuccess | undefined
  if (CacheResponse.getData(CACHE_PATH)) {
    result = CacheResponse.getData(CACHE_PATH)
  } else {
    const response = await mainSiteApi.getAnnounces({
      count: 3,
      types: '3'
    })
    if (response) CacheResponse.setData(CACHE_PATH, response)
    result = response as IGetAnnouncesSuccess
  }
  return thunkAPI.fulfillWithValue((result as IGetAnnouncesSuccess) || [])
})
