import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { mainSiteApi } from '#modules/api'
import { IGetTestimonialsSuccess, ITestimonial } from '#modules/api/main/types'
import CacheResponse from '#src/modules/CacheResponse'

const CACHE_PATH = '[fetchTestimonials][getTestimonial]'
const initialCondition = {
  limit: 10,
  published: 1,
  offset: 0
}
type TNotHaveTestimonials = {
  id: string
  text: string
  author: string
}
type TUnionTypesTestimonials = (ITestimonial | TNotHaveTestimonials)[]
type TInitialState = {
  loading: boolean
  items: TUnionTypesTestimonials
  nothingToLoad: boolean
  count: number
  countAll: number
}

export const fetchTestimonials = createAsyncThunk(
  'settings/fetchStatistic',
  async (fp: { fp: number } | undefined, thunkAPI) => {
    let response
    if (CacheResponse.hasData(CACHE_PATH)) {
      response = CacheResponse.getData(CACHE_PATH) as IGetTestimonialsSuccess
    } else {
      const dataOptions = Object.assign(initialCondition, fp)
      response = await mainSiteApi.getTestimonials(dataOptions)
      CacheResponse.setData(CACHE_PATH, response)
    }
    if (response.code !== 0) return thunkAPI.rejectWithValue(response.message)
    const {
      data: { testimonials: responseTestimonials = [], count_all: countAll = 0, count = 0 }
    } = response

    let isNothingToLoad = false
    let testimonials: TUnionTypesTestimonials = [...responseTestimonials]
    if (count === 0) {
      // @ts-ignore
      testimonials = responseTestimonials.concat({
        id: 'system',
        text: 'Отзывов больше нет',
        author: 'Администрация'
      })
      isNothingToLoad = true
    }

    return {
      items: testimonials,
      isNothingToLoad,
      count,
      countAll
    }
  }
)

const initialState: TInitialState = {
  loading: false,
  items: [],
  nothingToLoad: true,
  count: 0,
  countAll: 0
}

const testimonialsSlice = createSlice({
  name: 'statisticsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonials.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchTestimonials.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchTestimonials.fulfilled, (state, action) => {
        state.loading = false
        state.items = action.payload.items
        state.nothingToLoad = action.payload.isNothingToLoad
        state.count = action.payload.count
        state.countAll = action.payload.countAll
      })
  }
})

export default testimonialsSlice.reducer
