import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { Cookies } from 'react-cookie'

import { ROUTES } from '#constants/common'
import intl from '#intl'
import { adminApi, authApi } from '#modules/api'
import {
  AuthResponseCodes,
  BaseResponseCodes,
  PhoneCodeResponseCodes,
  PhoneResponseCodes
} from '#modules/api/auth/types'
import { ResponseCode } from '#modules/api/types/common'
import {
  loggedInToken,
  logoutSuccess,
  phoneCodeSuccess,
  saveRefreshToken,
  saveVerificationToken,
  smsCodeVerifySuccess,
  verifyTokenSuccess
} from '#reducers/adminPage/auth/authSlice'
import { fetchProfileSucceed } from '#reducers/adminPage/profileSlice'
import { CreatePrefix, errorPath } from '#reducers/helper'
import reportError from '#services/reportError'
import { TMainDataStateType } from '#src/reducers'

export const RENEW_ACCESS_TOKEN = 'RENEW_ACCESS_TOKEN'
const prefixHelper = new CreatePrefix('auth')
const cookies = new Cookies()

export function setCookie(key: string, value: string): void {
  cookies.set(key, value, {
    secure: !process.env.__IS_DEVELOPMENT__,
    path: ROUTES.admin
  })
}

export function removeCookie(key: string): void {
  cookies.remove(key, {
    secure: !process.env.__IS_DEVELOPMENT__,
    path: ROUTES.admin
  })
  cookies.remove(key, {
    path: '/',
    sameSite: true
  })
}

export const getPhoneCode = createAsyncThunk(
  prefixHelper.create('getPhoneCode'),
  async (token: string, thunkAPI) => {
    const phoneCodeData: FormData = new FormData()
    phoneCodeData.append('token', token)
    phoneCodeData.append('channel', 'admin')
    const response = await authApi.requestPhoneCode(phoneCodeData)
    const { code } = response
    if (response.code === BaseResponseCodes.Success) {
      const { codeTs } = response
      thunkAPI.dispatch(phoneCodeSuccess({ codeTs }))
    } else {
      if (
        code === PhoneResponseCodes.AuthError ||
        code === PhoneResponseCodes.SessionError ||
        code === PhoneResponseCodes.IncorrectToken ||
        code === BaseResponseCodes.UserBanned
      ) {
        const message = get(response, errorPath) as string
        thunkAPI.rejectWithValue({ message })
      }
      if (code === BaseResponseCodes.InternalError || code === PhoneResponseCodes.ServerError) {
        const message = intl.serverError
        thunkAPI.rejectWithValue({ message })
        void reportError(
          {
            status: 'error',
            type: 'InternalError or ServerError',
            code,
            response
          },
          {},
          getPhoneCode
        )
      }
    }
    const message = get(response, errorPath) || intl.serverError
    thunkAPI.rejectWithValue({ message })
    void reportError(
      {
        status: 'error',
        type: 'Catch error in function getPhoneCode',
        error: message
      },
      {},
      getPhoneCode
    )
  }
)

export const login = createAsyncThunk(
  prefixHelper.create('login'),
  async (formData: FormData, thunkAPI) => {
    const response = await authApi.auth(formData)
    const { code } = response
    if (response.code === BaseResponseCodes.Success) {
      const { token, isNeedSmsVerify, refreshToken, roles } = response
      thunkAPI.dispatch(saveRefreshToken({ refreshToken }))
      thunkAPI.dispatch(fetchProfileSucceed({ roles }))
      if (isNeedSmsVerify) {
        // получили токен верификации
        // данный токен необходимо сохранить в куку, он будет использоваться в дальнейшем для верификации
        setCookie('verificationToken', token)
        thunkAPI.dispatch(saveVerificationToken({ verificationToken: token }))
        // далее необходимо запросить OTP код
        return thunkAPI.dispatch(getPhoneCode(token))
      }
      setCookie('adminToken', token)
      return thunkAPI.dispatch(loggedInToken({ token }))
    }
    if (
      code === AuthResponseCodes.NoSuchUserOrPassword ||
      code === AuthResponseCodes.UserBanned ||
      code === AuthResponseCodes.UserBlocked ||
      code === AuthResponseCodes.UserRemoved ||
      code === AuthResponseCodes.NeededCodeRequest ||
      code === AuthResponseCodes.NotAccessChannel ||
      code === BaseResponseCodes.UserBanned
    ) {
      const message = get(response, errorPath) as string
      return thunkAPI.rejectWithValue({ message })
    }
    const message = get(response, errorPath) || intl.serverError
    void reportError(
      {
        status: 'error',
        type: 'Catch error in function login',
        error: message
      },
      {},
      login
    )
    return thunkAPI.rejectWithValue({ message })
  }
)

export const logout = createAsyncThunk(
  prefixHelper.create('logout'),
  async (formData: FormData, thunkAPI) => {
    const response = await authApi.logout(formData)
    const { code } = response
    if (code === ResponseCode.success) {
      removeCookie('adminToken')
      thunkAPI.dispatch(logoutSuccess())
    } else {
      const message = intl.serverError
      thunkAPI.rejectWithValue({ message })
    }
    const message = get(response, errorPath) || intl.serverError
    thunkAPI.rejectWithValue({ message })
  }
)

export const checkPhoneCode = createAsyncThunk<void, FormData, { state: TMainDataStateType }>(
  prefixHelper.create('checkPhoneCode'),
  async (smsData: FormData, thunkAPI) => {
    const response = await authApi.checkPhoneCode(smsData)
    const { code } = response
    if (response.code === BaseResponseCodes.Success) {
      const { verificationToken } = thunkAPI.getState().adminPage.auth
      if (verificationToken) {
        setCookie('adminToken', verificationToken)
        removeCookie('verificationToken')
        thunkAPI.dispatch(smsCodeVerifySuccess({ verificationToken }))
      }
    } else if (code === PhoneCodeResponseCodes.WrongCode || code === BaseResponseCodes.UserBanned) {
      const message = get(response, errorPath) as string
      thunkAPI.rejectWithValue({ message })
    } else {
      const message = intl.serverError
      thunkAPI.rejectWithValue({ message })
    }
    const message = get(response, errorPath) || intl.serverError
    thunkAPI.rejectWithValue({ message })
  }
)

export const unauthorizedToken = createAsyncThunk(
  prefixHelper.create('unauthorizedToken'),
  (_, thunkAPI) => {
    removeCookie('adminToken')
    thunkAPI.dispatch(logoutSuccess())
    thunkAPI.rejectWithValue({ message: intl.serverError })
  }
)

export const checkToken = createAsyncThunk(
  prefixHelper.create('checkToken'),
  async (token: string, thunkAPI) => {
    const response = await adminApi.checkToken(token)
    if (response.code === 0) return thunkAPI.dispatch(verifyTokenSuccess())
    unauthorizedToken()
  }
)
