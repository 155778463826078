import intl from '#intl'

import BaseHttpClientError from './BaseHttpClientError'

export default class ValidationError extends BaseHttpClientError {
  constructor(message = intl.clientValidationError) {
    super(message)
    this.name = 'ValidationError'
    this.code = 412
  }
}
