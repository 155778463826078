import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'

import { TProductDescription } from '#modules/api/admin/types'
import { TPersonalRootState } from '#reducers/entries/personal'
import {
  fetchConsumerCreditCondition,
  fetchLoanConditionsWithDefaultPromoCode,
  fetchProductDescription,
  updateLoanConditions
} from '#reducers/loanConditions/effects'
import { TLoanConditionData } from '#reducers/loanConditions/types'

export type TLoanConditionsState = {
  loading: boolean
  isFetched: boolean
  productInfo: TProductDescription[]
  data: TLoanConditionData | TEmptyObject
}

export const initialState: TLoanConditionsState = {
  loading: false,
  isFetched: false,
  productInfo: [],
  data: {} as TLoanConditionData
}

const loanConditionsSlice = createSlice({
  name: 'loanConditions',
  initialState,
  reducers: {
    disableProductType: (state) => {
      state.data = omit(state.data, 'fetchProductType')
    },
    setLoanConditions: (state, { payload }: PayloadAction<TLoanConditionData>) => {
      state.data = payload
      state.loading = false
      state.isFetched = true
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchProductDescription.fulfilled, (state, action) => {
        state.productInfo = action.payload
      })
      .addMatcher(
        isAnyOf(
          fetchLoanConditionsWithDefaultPromoCode.pending,
          fetchConsumerCreditCondition.pending,
          updateLoanConditions.pending
        ),
        (state) => {
          state.loading = true
          state.isFetched = false
        }
      )
      .addMatcher(
        isAnyOf(
          fetchLoanConditionsWithDefaultPromoCode.fulfilled,
          fetchConsumerCreditCondition.fulfilled
        ),
        (state, action) => {
          state.data = action.payload
          state.loading = false
          state.isFetched = true
        }
      )
      .addMatcher(
        isAnyOf(fetchLoanConditionsWithDefaultPromoCode.rejected, updateLoanConditions.rejected),
        (state) => {
          state.loading = false
          state.isFetched = false
        }
      )
})

export const { disableProductType, setLoanConditions } = loanConditionsSlice.actions
export default loanConditionsSlice.reducer
export const loanConditions = (state: TPersonalRootState): TLoanConditionsState =>
  state.loanConditions
const loanConditionsDataSelector = createSelector(loanConditions, (conditions) => conditions.data)
export const productTypeSelector = createSelector(loanConditionsDataSelector, (data) =>
  Boolean(data?.fetchProductType)
)
