import { ICodeError, ICodeSuccess } from '#src/modules/api/types/common'

interface IAuthCodeSuccess {
  code: BaseResponseCodes.Success
}

export interface ICodeWithMessage extends IAuthCodeSuccess {
  message: string
}

export enum BaseResponseCodes {
  Success = 200,
  InternalError = 5,
  UserBanned = 9
}

export enum AuthResponseCodes {
  NoSuchUserOrPassword = 1,
  UserBanned = 2,
  UserBlocked = 3,
  UserRemoved = 4,
  NeededCodeRequest = 5,
  NotAccessChannel = 13
}

export enum AuthExceptionCodes {
  NeededCodeRequest = 5
}

export enum PhoneResponseCodes {
  AuthError = 3,
  ServerError = 1,
  SessionError = 7,
  IncorrectToken = 10
}

export enum PhoneCodeResponseCodes {
  WrongCode = 8
}

export interface IAuthSuccessResponse extends ICodeWithMessage {
  token: string
  refreshToken: string
  isNeedSmsVerify?: boolean
  roles: string[]
}

type TOTPErrors =
  | PhoneResponseCodes.AuthError
  | PhoneResponseCodes.SessionError
  | PhoneResponseCodes.IncorrectToken
  | BaseResponseCodes.UserBanned
  | BaseResponseCodes.InternalError
  | PhoneResponseCodes.ServerError

type TAuthErrors =
  | AuthResponseCodes.NoSuchUserOrPassword
  | AuthResponseCodes.UserBanned
  | AuthResponseCodes.UserBlocked
  | AuthResponseCodes.UserRemoved
  | AuthResponseCodes.NeededCodeRequest
  | AuthResponseCodes.NotAccessChannel
  | BaseResponseCodes.UserBanned

type TOTPCheckErrors = PhoneCodeResponseCodes.WrongCode | BaseResponseCodes.UserBanned

export interface IErrorResponse<T> {
  code: T
  message?: string
  error?: {
    message: string
    type: string
  }
}

interface IOTPResponseSuccess extends IAuthCodeSuccess {
  codeTs: number
}

export interface IAccessTokenSuccess extends ICodeWithMessage {
  token: string
  expiresIn: number
}

export type TAuthResponse = IAuthSuccessResponse | IErrorResponse<TAuthErrors>
export type TOTPResponse = IOTPResponseSuccess | IErrorResponse<TOTPErrors>
export type TOTPCheckResponse = IAuthCodeSuccess | IErrorResponse<TOTPCheckErrors>
export type TLogoutResponse = ICodeSuccess | ICodeError
export type TAccessToken = IAccessTokenSuccess | ICodeError
