export interface IQueue<T> {
  enqueueArray: (queue: T[]) => void
  dequeue: () => TNullable<T>
  getQueueLength: () => number
  isEmpty: () => boolean
  showQueue: () => T[]
  enqueue: (item: T) => void
}

type TQueueOptions = {
  maxLength?: number
}

/**
 * Класс для работы с очередями
 */
export class Queue<T> implements IQueue<T> {
  queue: T[] = []
  private readonly options: TQueueOptions = {}
  constructor(options: TQueueOptions = {}) {
    this.options = options
  }
  public enqueueArray(queue: T[]): void {
    this.queue = this.queue.concat(queue)
  }
  public dequeue(): TNullable<T> {
    if (this.isEmpty()) return null
    return this.queue.shift() as T
  }
  public getQueueLength(): number {
    return this.queue.length
  }
  public isEmpty(): boolean {
    return this.getQueueLength() === 0
  }
  public showQueue(): T[] {
    return this.queue
  }
  public enqueue(item: T): void {
    const { maxLength } = this.options
    if (maxLength && this.getQueueLength() === maxLength) {
      this.queue.shift()
      this.queue.push(item)
    } else {
      this.queue.push(item)
    }
  }
}
