import { ComponentType, FC } from 'react'

import useErrorLogger, { TLogError } from '#hook/useErrorLogger'

export interface IWithErrorLoggerInjectedProps {
  logError: TLogError
}

function withErrorLogger<T extends IWithErrorLoggerInjectedProps>(
  component: ComponentType<T>
): FC<Omit<T, keyof IWithErrorLoggerInjectedProps>> {
  const Component = component
  function WithErrorLogger(props: Omit<T, keyof IWithErrorLoggerInjectedProps>): JSX.Element {
    const [logError] = useErrorLogger()
    return <Component {...(props as T)} logError={logError} />
  }

  return WithErrorLogger
}

export default withErrorLogger
