/* eslint-disable sonarjs/no-duplicate-string */
import { AxiosRequestConfig, CancelToken } from 'axios'
import https from 'https'
import { isEmpty, pick } from 'lodash'

import appConfig from '#config'
import intl from '#intl'
import {
  ICreateTestimonialsRequest,
  ICreateTestimonialSuccess,
  IGetTestimonialsRequest,
  IUpdateTestimonialsRequest,
  TGetTestimonialByIdResponse,
  TGetTestimonialsListResponse,
  TRemoveTestimonialResponse,
  TSendTestimonialToSupportResponse,
  TTestimonialsRequestById
} from '#modules/api/admin/testimonials'
import WithRefreshTokenClient from '#modules/api/withRefreshTokenClient'
import { objectToFormData } from '#services/helper'
import * as AdminApiTypes from '#src/modules/api/admin/types'
import { ICodeWithMessage } from '#src/modules/api/types/common'

import ValidationError from '../exceptions/ValidationError'

class AdminApi extends WithRefreshTokenClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this.observeNode = 'admin'
    this._initializeResponseInterceptor()
  }

  static mainBannerData(data: AdminApiTypes.TBannerDescription): FormData {
    const {
      promoHeader = '',
      promoDescription = '',
      promoLinkUrl = '',
      promoLinkText = '',
      periodStart = null,
      periodFinish = null,
      file
    } = data

    const bannerData = new FormData()

    bannerData.append('promoHeader', promoHeader)
    bannerData.append('promoDescription', promoDescription)
    bannerData.append('promoLinkUrl', promoLinkUrl)
    bannerData.append('promoLinkText', promoLinkText)

    periodStart && bannerData.append('periodStart', periodStart)
    periodFinish && bannerData.append('periodFinish', periodFinish)
    file && bannerData.append('file', file)
    return bannerData
  }

  public async checkToken(token: string): Promise<AdminApiTypes.TCheckTokenResponse> {
    try {
      const formData = new FormData()
      formData.append('token', token)
      return this.instance.post('/checkToken', formData)
    } catch (err) {
      return Promise.resolve({ code: 5, message: intl.serverError })
    }
  }

  public getLandingConfig(product: string): Promise<AdminApiTypes.TLandingConfig> {
    return this.instance.get('/landing/getConfig', { params: { product } })
  }

  public getTextConfig(token: string): Promise<AdminApiTypes.TLandingTextConfig> {
    return this.instance.get('/landing/getTextConfig', { params: { token } })
  }

  public editLandingBanner(data: FormData): Promise<AdminApiTypes.TEditLandingConfig> {
    return this.instance.post('/landing/editBanner', data)
  }

  public deletePromoBanner(data: FormData): Promise<AdminApiTypes.TEditLandingConfig> {
    return this.instance.post('/landing/deletePromoBanner', data)
  }

  public editLandingText(data: FormData): Promise<AdminApiTypes.TEditLandingConfig> {
    return this.instance.post('/landing/editText', data)
  }

  public deletePromoText(data: FormData): Promise<AdminApiTypes.TCustomResponse> {
    return this.instance.post('/landing/deletePromoText', data)
  }

  public bannersInfo(token: string): Promise<AdminApiTypes.TLandingBannerConfig> {
    return this.instance.get('/landing/getBannersInfo', { params: { token } })
  }

  /**
   * Gets promo banner data for personal area
   * @param {string=} status - User status, if not specified - returns full config
   */
  public getPaPromoData(
    status?: AdminApiTypes.TPersonGroups
  ): Promise<AdminApiTypes.TPersonalBanners | AdminApiTypes.TPersonalBannerWithStatus> {
    if (status) return this.personalBannerWithStatus(status)
    return this.personalBanners()
  }

  public getPersonGroups(cancelToken?: CancelToken): Promise<AdminApiTypes.TPersonGroupInfo> {
    return this.instance.get('/getPersonGroups', {
      cancelToken
    })
  }

  public getDeviceTypes(cancelToken?: CancelToken): Promise<AdminApiTypes.TDeviceTypes> {
    return this.instance.get('/getDeviceTypes', {
      cancelToken
    })
  }

  /**
   * Gets product description for loan form
   * @param {string=} status - User status, if not specified - returns full config
   */
  public getProductDescription(
    status?: AdminApiTypes.TPersonGroups
  ): Promise<AdminApiTypes.TProductDescriptionWithStatus | AdminApiTypes.TProductDescriptions> {
    if (status) return this.productDescriptionWithStatus(status)
    return this.productDescriptions()
  }

  public getProductDescriptionById({
    id,
    token,
    cancelToken
  }: AdminApiTypes.TGetProductDescriptionByIdParams): Promise<AdminApiTypes.TProductDescriptionById> {
    return this.instance.get(`/personal/${id}/getProductDescription`, {
      cancelToken,
      params: { token }
    })
  }

  public saveProductDescription(data: FormData): Promise<AdminApiTypes.TEditLandingConfig> {
    return this.instance.post('/personal/editProductDescription', data)
  }

  /**
   * Deletes product description for loan form for specified user status
   */
  public deleteProductDescription(data: FormData): Promise<AdminApiTypes.TCustomResponse> {
    return this.instance.post('/personal/deleteProductDescription', data)
  }

  public addProductDescription(data: FormData): Promise<AdminApiTypes.TEditLandingConfig> {
    return this.instance.post('/personal/addProductDescription', data)
  }

  public getActiveLoanConditionsWithActivePersonGroup(
    token: string
  ): Promise<AdminApiTypes.TGetActiveLoanConditionsWithActivePersonGroup> {
    return this.instance.get('/personal/getActiveLoanConditionsWithActivePersonGroup', {
      params: { token }
    })
  }

  public updatePersonalBanner(
    data: AdminApiTypes.TUpdatePersonalBannerData
  ): Promise<AdminApiTypes.TEditLandingConfig> {
    const { id, token, ...rest } = data
    const bannerData = AdminApi.mainBannerData(rest)
    bannerData.append('id', String(id))
    bannerData.append('token', token)
    return this.editBannerData(bannerData)
  }

  public updatePaPromoData(
    data: AdminApiTypes.ISelectedPersonalBanner
  ): Promise<AdminApiTypes.TEditLandingConfig[]> {
    const { selectedRows, token } = data

    if (isEmpty(selectedRows))
      return Promise.reject(new ValidationError('Переданы данные неверного формата'))

    const promises: AdminApiTypes.TUpdatePaPromoDataResult[] = []
    selectedRows.forEach(({ id }) => {
      const bannerData = AdminApi.mainBannerData(data)
      bannerData.append('id', String(id))
      bannerData.append('token', token)
      promises.push(this.editBannerData(bannerData))
    })

    return Promise.all(promises)
  }

  public addPaPromoData(
    data: AdminApiTypes.IAddPersonalBanner
  ): Promise<AdminApiTypes.TEditLandingConfig[]> {
    const { statuses, types, personGroups, deviceTypes, token } = data

    if ([statuses, types, personGroups, deviceTypes].some(isEmpty))
      return Promise.reject(new ValidationError('Переданы данные неверного формата'))

    const promises: AdminApiTypes.TUpdatePaPromoDataResult[] = []
    statuses.forEach((status) => {
      types.forEach((type) => {
        const bannerData = AdminApi.mainBannerData(data)
        bannerData.append('deviceId', String(deviceTypes[type]?.id) || '1')
        bannerData.append('groupId', String(personGroups[status]?.id) || '1')
        bannerData.append('token', token)
        promises.push(this.addBannerData(bannerData))
      })
    })

    return Promise.all(promises)
  }

  public deletePaPromoData(
    data: AdminApiTypes.IDeletePersonalBanner
  ): Promise<AdminApiTypes.TCustomResponse> {
    const { id, token } = data
    const bannerData = new FormData()
    bannerData.append('id', String(id))
    bannerData.append('token', token)
    return this.deleteBannerData(bannerData)
  }

  public getPersonalBannerConfig(params: {
    [key: string]: string | boolean | number | null
  }): Promise<AdminApiTypes.TPersonalBanners> {
    return this.instance.get('/personal/getBannerConfig', { params })
  }

  public getPersonalBannerConfigById(
    token: string,
    bannerId: number
  ): Promise<AdminApiTypes.TPersonalBanner> {
    return this.instance.get(`/personal/getBannerConfig/${bannerId}`, { params: { token } })
  }

  /**
   * Добавление кнопки
   * @param {FormData} data { text, type, colorButton, colorText, token}
   */
  public addNotificationButton(data: FormData): Promise<AdminApiTypes.TAddNotificationButton> {
    return this.instance.post('/notification/button-create', data)
  }

  /**
   * Удаление кнопки
   * @param {FormData} data {token, id}
   */
  public deleteNotificationButton(data: FormData): Promise<AdminApiTypes.TCustomResponse> {
    return this.instance.post('/notification/button-delete', data)
  }

  /**
   * Получение информации по кнопке
   * @param {string} token
   * @param {number} id
   */
  public getNotificationButton(
    token: string,
    id: number
  ): Promise<AdminApiTypes.TNotificationButton> {
    return this.instance.get('/notification/button-read', { params: { token, id } })
  }

  /**
   * Обновление информации по кнопке
   * @param {FormData} data {text, type, colorButton, colorText, token, id}
   */
  public updateNotificationButton(
    data: FormData
  ): Promise<AdminApiTypes.TUpdateNotificationButton> {
    return this.instance.post('/notification/button-update', data)
  }

  /**
   * Получение списка кнопок
   * @param token
   */
  public getNotificationButtonList(token: string): Promise<AdminApiTypes.TNotificationButtonList> {
    return this.instance.get('/notification/button-list', { params: { token } })
  }

  /**
   * Добавление уведомление
   * @param {FormData} data
   * { type, buttons, text, frequency, rating, gid, pid, file, dateFrom, dateTill, active}
   */
  public addNotification(data: FormData): Promise<AdminApiTypes.TAddNotification> {
    return this.instance.post('/notification/create', data)
  }

  /**
   * Получение информации по уведомлению
   * @param token
   * @param id
   */
  public getNotification(token: string, id: number): Promise<AdminApiTypes.TNotificationInfo> {
    return this.instance.get('/notification/read', { params: { token, id } })
  }

  /**
   * Получение списка заведенных уведомлений
   * @param {string} token
   */
  public getNotificationList(token: string): Promise<AdminApiTypes.TNotificationList> {
    return this.instance.get('/notification/list', { params: { token } })
  }

  /**
   * Удаление уведомления
   * @param {FormData} data {token, id}
   */
  public deleteNotification(data: FormData): Promise<AdminApiTypes.TCustomResponse> {
    return this.instance.post('/notification/delete', data)
  }

  /**
   * Обновление данных по выбранному уведомлению
   * @param {FormData} data
   *  id
   *  type
   *  text
   *  dateFrom
   *  dateTill
   *  frequency
   *  rating
   *  gid
   *  pid
   *  buttons
   *  desktopImage
   *  tabletImage
   *  mobileImage
   */
  public updateNotification(data: FormData): Promise<AdminApiTypes.TUpdateNotification> {
    return this.instance.post('/notification/update', data)
  }

  /**
   * Получение списка активных групп
   * @param {string} token
   */
  public getNotificationPersonGroups(
    token: string
  ): Promise<AdminApiTypes.TNotificationPersonGroups> {
    return this.instance.get('/notification/personGroups', { params: { token } })
  }

  public getArticlesList(
    params: AdminApiTypes.IGetArticlesRequest
  ): Promise<AdminApiTypes.TArticlesListResponse> {
    const {
      token,
      dataSet = 'short',
      pageNumber = 1,
      itemsPerPage = 20,
      filterByType,
      filterByActive,
      filterByTitle,
      filterByUrl
    } = params
    return this.instance.get('/articles', {
      params: {
        token,
        dataSet,
        pageNumber,
        itemsPerPage,
        filterByType,
        filterByActive,
        filterByTitle,
        filterByUrl
      }
    })
  }

  public removeArticle(params: AdminApiTypes.IRemoveArticlesRequest): Promise<ICodeWithMessage> {
    const { token, articleId } = params
    return this.instance.delete(['/articles', articleId].join('/'), { params: { token } })
  }

  public createArticle(
    params: AdminApiTypes.ICreateArticlesRequest
  ): Promise<AdminApiTypes.TCreateArticleResponse> {
    const { token } = params
    const formData = this.buildArticleFormData(params)
    formData.append('token', token)
    return this.instance.post('/articles', formData)
  }

  public updateArticle(params: AdminApiTypes.IUpdateArticlesRequest): Promise<ICodeWithMessage> {
    const { token, articleId } = params
    const formData = this.buildArticleFormData(params)
    formData.append('token', token)
    formData.append('articleId', String(articleId))
    return this.instance.post(['/articles', articleId].join('/'), formData)
  }

  public getArticle(
    token: string,
    articleId: number
  ): Promise<AdminApiTypes.TGetFullArticleDataResponse> {
    return this.instance.get(['/articles', articleId].join('/'), { params: { token, articleId } })
  }

  public createTestimonial(params: ICreateTestimonialsRequest): Promise<ICreateTestimonialSuccess> {
    const { token } = params
    const formData = this.buildTestimonialFormData(params)
    formData.append('token', token)
    return this.instance.post('/testimonials', formData)
  }

  public getTestimonialsList(
    params: IGetTestimonialsRequest
  ): Promise<TGetTestimonialsListResponse> {
    const { token, limit = 7, offset = 0 } = params
    return this.instance.get('/testimonials', {
      params: {
        token,
        limit,
        offset
      }
    })
  }

  public getTestimonial(params: TTestimonialsRequestById): Promise<TGetTestimonialByIdResponse> {
    const { token, id } = params
    return this.instance.get(['/testimonials', id].join('/'), {
      params: { id, token }
    })
  }

  public removeTestimonial(params: TTestimonialsRequestById): Promise<TRemoveTestimonialResponse> {
    const { token, id } = params
    return this.instance.delete(['/testimonials', id].join('/'), { params: { token } })
  }

  public updateTestimonial(params: IUpdateTestimonialsRequest): Promise<ICodeWithMessage> {
    const { token, id } = params
    const formData = this.buildTestimonialFormData(params)
    formData.append('token', token)
    formData.append('id', String(id))
    return this.instance.post(['/testimonials', id].join('/'), formData)
  }

  public sendTestimonialToSupport(
    params: TTestimonialsRequestById
  ): Promise<TSendTestimonialToSupportResponse> {
    const { token, id } = params
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post(['/testimonials/send', id].join('/'), formData)
  }

  public pathTest(
    token: string,
    type: string,
    url: string,
    id: number | null
  ): Promise<AdminApiTypes.TArticlesPathTestResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('type', type)
    formData.append('url', url)
    id && formData.append('id', String(id))
    return this.instance.post('/articles/pathTest', formData)
  }

  public getProfileRoles(token: string): Promise<AdminApiTypes.TGetProfileResponse> {
    return this.instance.get('/getProfileRoles', { params: { token } })
  }

  public getPageList({
    token,
    cancelToken,
    itemsPerPage,
    currentPage,
    filterByActive,
    filterByName,
    filterByUrl
  }: AdminApiTypes.IGetPageListParams): Promise<AdminApiTypes.TGetPageList> {
    return this.instance.get('/page/list', {
      cancelToken,
      params: {
        token,
        itemsPerPage,
        pageNumber: currentPage,
        filterByActive,
        filterByName,
        filterByUrl
      }
    })
  }

  public getPageItemHistory({
    token,
    cancelToken,
    url
  }: AdminApiTypes.IGetPageItemHistory): Promise<AdminApiTypes.TGetPageItemHistory> {
    return this.instance.get('/page/history', { cancelToken, params: { token, url } })
  }

  public hideAllPages({
    token,
    url
  }: AdminApiTypes.THideAllPages): Promise<AdminApiTypes.THideAllPAges> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('url', url)
    return this.instance.post('/page/hideAll', formData)
  }

  public changePageItemVisibility({
    token,
    id,
    isPublished
  }: AdminApiTypes.TChangePageItemVisibility): Promise<AdminApiTypes.TPageItemVisibility> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('isPublished', String(isPublished))
    return this.instance.post(`/page/${id}/changeItemVisibility`, formData)
  }

  public getPageItemContent(
    id: number,
    token: string,
    cancelToken?: CancelToken
  ): Promise<AdminApiTypes.TGetPageItemContent> {
    return this.instance.get(`/page/${id}/info`, { cancelToken, params: { token } })
  }

  public updatePageItemContent(
    params: AdminApiTypes.IUpdatePageItemContentRequest
  ): Promise<AdminApiTypes.TGetPageItemContent> {
    const { token, contentId } = params
    const formData = objectToFormData(params, 'page')
    formData.append('token', token)
    formData.append('contentId', contentId)
    return this.instance.post(`/page/${contentId}/updateItem`, formData)
  }

  public createPageItemContent(
    params: AdminApiTypes.ICreatePageItemContentRequest
  ): Promise<AdminApiTypes.TCreatePageItemContent> {
    const { token } = params
    const formData = objectToFormData(params, 'page')
    formData.append('token', token)
    return this.instance.post('/page', formData)
  }

  /**
   * Получение активных либо не активных элементов в виде списка
   */
  public getPagesByParams({
    token,
    inSitemap
  }: {
    token: string
    inSitemap: number
  }): Promise<AdminApiTypes.TGetSitemapList> {
    return this.instance.get('/sitemap/page/list', { params: { token, inSitemap } })
  }

  public updateRelationship(
    params: AdminApiTypes.TUpdateRelationshipRequest
  ): Promise<AdminApiTypes.TGetSitemapList> {
    const formData = objectToFormData(params)
    return this.instance.post('/sitemap/page/relationship', formData)
  }

  public toggleSitemapValueInPage({
    token,
    pageId,
    siteMap,
    groupId
  }: {
    token: string
    pageId: number
    siteMap: number
    groupId?: number
  }): Promise<AdminApiTypes.TPageItemSitemap> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('pageId', String(pageId))
    formData.append('siteMap', String(siteMap))
    groupId && formData.append('groupId', String(groupId))
    return this.instance.post('/sitemap/page/toggleSitemapValueInPage', formData)
  }

  public getSitemapCard(token: string): Promise<AdminApiTypes.TGetPagesWithPageGroup> {
    return this.instance.get('/sitemap/getSitemapCard', { params: { token } })
  }

  public addGroupToPage({
    groupId,
    pageId,
    token
  }: {
    groupId: number
    pageId: number
    token: string
  }): Promise<AdminApiTypes.TCustomResponse> {
    const formData = new FormData()
    formData.append('pageId', String(pageId))
    formData.append('groupId', String(groupId))
    formData.append('token', token)
    return this.instance.post('/sitemap/page/addGroupToPage', formData)
  }

  public removeGroupFromPage({
    pageId,
    token
  }: {
    pageId: number
    token: string
  }): Promise<AdminApiTypes.TCustomResponse> {
    const formData = new FormData()
    formData.append('pageId', String(pageId))
    formData.append('token', token)
    return this.instance.post('/sitemap/page/removeGroupFromPage', formData)
  }

  public getPageGroupList({
    token,
    itemsPerPage
  }: {
    token: string
    itemsPerPage?: number
  }): Promise<AdminApiTypes.TGetPageGroupList> {
    return this.instance.get('/page/groups', { params: { token, itemsPerPage } })
  }

  public createPageGroup({
    name,
    token
  }: {
    name: string
    token: string
  }): Promise<AdminApiTypes.TPageGroupResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('name', name)
    return this.instance.post('/page/groups', formData)
  }

  public editPageGroup({
    id,
    token,
    isUse,
    orderId,
    name
  }: {
    id: number
    token: string
    isUse?: number
    orderId?: number
    name?: string
  }): Promise<AdminApiTypes.TPageGroupResponse> {
    const formData = new FormData()
    formData.append('token', token)
    name && formData.append('data[name]', name)
    isUse && formData.append('data[isUse]', String(isUse))
    orderId && formData.append('data[orderId]', String(orderId))
    return this.instance.post(`/page/groups/${id}`, formData)
  }

  public addGroupToSitemapCard({
    token,
    id
  }: {
    token: string
    id: number
  }): Promise<AdminApiTypes.TPageGroupResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('id', String(id))
    return this.instance.post(`/page/groups/${id}/addGroupToSitemapCard`, formData)
  }

  public changeOrder({
    token,
    id,
    actionType
  }: {
    token: string
    id: number
    actionType: 'orderUp' | 'orderDown'
  }): Promise<AdminApiTypes.TCustomResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('id', String(id))
    formData.append('data[actionType]', actionType)
    return this.instance.post(`/page/groups/${id}/order`, formData)
  }

  public setGroupToPage({
    pageId,
    groupId,
    token
  }: AdminApiTypes.TModifyGroupInPageParams): Promise<AdminApiTypes.TCustomResponse> {
    const formData = new FormData()
    formData.append('pageId', String(pageId))
    formData.append('groupId', String(groupId))
    formData.append('token', token)
    formData.append('actionType', 'set')
    return this.instance.post('/page/groups/groupInPage', formData)
  }

  public removePageGroupFromSitemapCard({
    id,
    token
  }: {
    id: number
    token: string
  }): Promise<AdminApiTypes.TCustomResponse> {
    return this.instance.delete(`/page/groups/${id}`, { params: { token } })
  }

  public pageGroupToggleUsage({
    groupId,
    isUse,
    token
  }: {
    groupId: number
    isUse: number
    token: string
  }): Promise<AdminApiTypes.TGetPageGroupList> {
    const formData = new FormData()
    formData.append('isUse', String(isUse))
    formData.append('token', token)
    return this.instance.post(`/page/groups/${groupId}/toggleUsage`, formData)
  }

  public deletePageGroup({
    groupId,
    token
  }: {
    groupId: number
    token: string
  }): Promise<AdminApiTypes.TCustomResponse> {
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post(`/page/groups/${groupId}/hide`, formData)
  }

  public addAuthor(
    params: AdminApiTypes.ICreateAuthorRequest
  ): Promise<AdminApiTypes.TCustomResponse> {
    const formData = objectToFormData(params, 'author')
    return this.instance.post('/authors/add', formData)
  }

  public editAuthor(
    params: AdminApiTypes.TEditAuthorRequest
  ): Promise<AdminApiTypes.TCustomResponse> {
    const formData = objectToFormData(params, 'author')
    return this.instance.post('/authors/edit', formData)
  }

  public removeAuthor({
    id,
    token
  }: {
    id: number
    token: string
  }): Promise<AdminApiTypes.TRemoveAuthorResponse> {
    const formData = objectToFormData({ id, token })
    return this.instance.post('/authors/remove', formData)
  }

  public getAuthors(token: string): Promise<AdminApiTypes.TGetAuthorsResponse> {
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post('/authors/getAuthors', formData)
  }

  public getAuthor({
    id,
    token
  }: {
    id: number
    token: string
  }): Promise<AdminApiTypes.TGetAuthorResponse> {
    const formData = objectToFormData({ id, token })
    return this.instance.post('/authors/getAuthor', formData)
  }

  private personalBannerWithStatus(
    status: AdminApiTypes.TPersonGroups
  ): Promise<AdminApiTypes.TPersonalBannerWithStatus> {
    return this.instance.get('/personal/getBannerData', { params: { status } })
  }

  private personalBanners(): Promise<AdminApiTypes.TPersonalBanners> {
    return this.instance.get('/personal/getBannerData')
  }

  private productDescriptionWithStatus(
    status: AdminApiTypes.TPersonGroups
  ): Promise<AdminApiTypes.TProductDescriptionWithStatus> {
    return this.instance.get('/personal/getProductDescription', { params: { status } })
  }

  private productDescriptions(): Promise<AdminApiTypes.TProductDescriptions> {
    return this.instance.get('/personal/getProductDescription')
  }

  private addBannerData(data: FormData): Promise<AdminApiTypes.TEditLandingConfig> {
    return this.instance.post('/personal/addBannerData', data)
  }

  private editBannerData(data: FormData): Promise<AdminApiTypes.TEditLandingConfig> {
    return this.instance.post('/personal/editBannerData', data)
  }

  private deleteBannerData(data: FormData): Promise<AdminApiTypes.TCustomResponse> {
    return this.instance.post('/personal/deleteBannerData', data)
  }

  private buildArticleFormData(
    params: AdminApiTypes.ICreateArticlesRequest | AdminApiTypes.IUpdateArticlesRequest
  ): FormData {
    const requestParams = pick(params, [
      'title',
      'description',
      'type',
      'rubric',
      'url',
      'publishDate',
      'isPublished',
      'isIncludedToSiteMap',
      'seoTitle',
      'seoDescription',
      'seoKeyWords',
      'text',
      'isShowRecommendedList',
      'isShowUpdateDate',
      'isShowDate',
      'pressAuthor',
      'author',
      'externalLink',
      'timeToRead'
    ])
    const formData = new FormData()
    Object.entries(requestParams).forEach(([key, value]) =>
      formData.append(`article[${key}]`, String(value ?? ''))
    )
    return formData
  }

  private buildTestimonialFormData(
    params: ICreateTestimonialsRequest | IUpdateTestimonialsRequest
  ): FormData {
    const requestParams = pick(params, ['fp', 'published', 'name', 'city', 'email', 'info', 'date'])
    const formData = new FormData()
    Object.entries(requestParams).forEach(([key, value]) =>
      formData.append(`data[${key}]`, String(value ?? ''))
    )
    return formData
  }
}

let adminApiConfig: AxiosRequestConfig
if (process.env.__CLIENT__ && process.env.NODE_ENV === 'production') {
  adminApiConfig = {
    baseURL: new URL('/admin', appConfig.apiHost).href
  }
} else {
  adminApiConfig = {
    baseURL: new URL('/admin', appConfig.apiHost).href,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    })
  }
}

export const adminApi = new AdminApi(adminApiConfig)
