type TPredicate<T> = (value: T, key?: string) => boolean
type TValueOf<T> = T[keyof T]

/**
 * Выполняет фильтрацию объекта
 * @param {object} obj Объект который требуется отфильтровать
 * @param {function} predicate Функция предикат, вызывается с двумя параметрами: значение, ключ
 */
export const filterObject = <T>(obj: T, predicate: TPredicate<TValueOf<T>> = Boolean): T =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => (predicate(value, key) ? { ...acc, [key]: <TValueOf<T>>value } : acc),
    {} as T
  )

export default filterObject
