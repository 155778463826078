import { combineReducers } from '@reduxjs/toolkit'

import settingsSlice from '../../settingsSlice'

export const systemReducer = combineReducers({
  settings: settingsSlice
})

export type TPersonalRootState = ReturnType<typeof systemReducer>

export default systemReducer
