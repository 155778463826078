import './button.scss'

import classnames from 'classnames'
import { noop } from 'lodash'
import { FC, PropsWithChildren, RefObject, useRef } from 'react'
import { Link } from 'react-router-dom'

import {
  ButtonComponentTypes,
  TButtonProps,
  TLabelProps,
  TLinkProps,
  TOnClickEvent,
  TSubmitProps
} from '#components/Button/types'

import { Spinner } from '../Spinner/Spinner'
import { Timer } from '../Timer'

// eslint-disable-next-line @typescript-eslint/ban-types
function withBlur<T extends Function>(handler: T, ref: RefObject<HTMLElement>) {
  return function (
    event:
      | TOnClickEvent<ButtonComponentTypes.BUTTON>
      | TOnClickEvent<ButtonComponentTypes.LABEL>
      | TOnClickEvent<ButtonComponentTypes.LINK>
  ) {
    ref && ref.current && ref.current.blur()
    handler && handler(event)
  }
}

export const Button: FC<PropsWithChildren<TButtonProps>> = (props) => {
  const {
    size = 'm',
    color = 'green',
    inverted,
    fluid,
    id,
    type = ButtonComponentTypes.BUTTON,
    url,
    focus,
    disabled,
    loading,
    loadingTimer,
    children,
    onFocus,
    onBlur,
    onClick,
    style,
    width,
    ariaLabel = ''
  } = props
  const ref = useRef<HTMLElement>(null)

  const attrs = {
    id,
    disabled,
    onFocus,
    onBlur,
    onClick: onClick ? withBlur(onClick, ref) : noop,
    ref
  }

  if (props['data-qa']) {
    // @ts-ignore
    attrs['data-qa'] = props['data-qa']
  }

  const className = classnames(
    {
      button: true,
      button_inverted: inverted,
      button_focus: focus,
      button_fluid: fluid,
      button_loading: loading,
      button_disabled: disabled,
      [`button_size_${size}`]: true,
      [`button_color_${color}`]: true,
      [`button_width_${width ? width : ''}`]: Boolean(width)
    },
    props.className
  )

  const spinnerClasses = classnames({
    button__spinner: true,
    'button__spinner--custom': size === 'custom'
  })

  switch (type) {
    case ButtonComponentTypes.LABEL:
      return (
        <label className={className} {...(attrs as TLabelProps)} style={style}>
          <span className='button__text'>{children}</span>
          {loading && <Spinner className='button__spinner' />}
        </label>
      )
    case ButtonComponentTypes.LINK:
      return (
        <Link className={className} to={url as string} {...(attrs as TLinkProps)} style={style}>
          <span className='button__text'>{children}</span>
          {loading && <Spinner className='button__spinner' />}
        </Link>
      )
    case ButtonComponentTypes.ANCHOR:
      return (
        <a className={className} href={url} {...(attrs as TLinkProps)} style={style}>
          <span className='button__text'>{children}</span>
          {loading && <Spinner className='button__spinner' />}
        </a>
      )
    default:
      return (
        <button
          className={className}
          {...(attrs as TSubmitProps)}
          aria-label={ariaLabel}
          style={style}
        >
          <span className='button__text'>{children}</span>
          {loading && <Spinner className={spinnerClasses} />}
          {loading && loadingTimer && (
            <span className='button__timer'>
              <Timer initialSeconds={20} />
            </span>
          )}
        </button>
      )
  }
}
