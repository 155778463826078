import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import intl from '#intl'
import { LoginType } from '#modules/api/personal/enums'
import { personGroupsSelector } from '#reducers/clientData/selectors'
import { TPersonalRootState } from '#reducers/entries/personal'
import { formatAsMoney } from '#services/helper'
import buildPersonStatusData, {
  normalizeStatusName
} from '#src/components/PersonalAreaPage/buildPersonStatusData'
import { personalApi } from '#src/modules/api'
import { ResponseCode } from '#src/modules/api/admin/types'
import { DataSetType, TInnFromService, TPersonalDataFull } from '#src/modules/api/personal/types'

import { CreatePrefix } from '../helper'

export type TUserEmail = {
  email: string
}

export type TWriteOffBonuses = {
  bonus: number
  lastActivePhone: string
  lastSpecifiedPhoneNumber: string
  isLastSpecifiedPhoneNumberConfirmed: boolean
}

export type TLastActivePhoneNumber = {
  lastActivePhone: string
}

export type TLastSpecifiedPhoneNumber = {
  lastSpecifiedPhoneNumber: string
}

export type TLastSpecifiedPhoneNumberStatus = {
  isLastSpecifiedPhoneNumberConfirmed: boolean
}

type TLoginTypeKeys = keyof typeof LoginType
type TLoginTypeValues = typeof LoginType[TLoginTypeKeys]
type TScorista = {
  createTs: string
  score: number
}
export interface IProfile {
  firstName: string
  lastName: string
  middleName: string
  email?: string
  oldEmail?: string
  lastActivePhone: string
  mobileNumber?: string
  passport: string
  groupName: string
  prevTotalIncome?: string
  currentTotalIncome?: string
  salary: string
  innFromService: string
  sex: string
  birthDate: string
  birthPlace: string
  passportWho: string
  passportCityGuid: string
  passportCity: string
  passportDate: string
  registration: string
  placeOfResidence: string
  inn?: string
  maritalStatus: string
  education: string
  activeCredits: string
  bonus: number
  isEmailConfirmed: boolean
  registrationShort: string
  lastSpecifiedPhoneNumber: string
  isLastSpecifiedPhoneNumberConfirmed: boolean
  loginType: TLoginTypeValues
  uid: string
  scoristaStructure?: TScorista
}

export interface IProfileInitialState {
  loading: boolean
  data: IProfile | Partial<IProfile>
  isRemoved: boolean
}

const initialState: IProfileInitialState = {
  loading: false,
  data: {},
  isRemoved: false
}

const prefixHelper = new CreatePrefix('profile')

const fetchUserDataRequest = createAction(prefixHelper.create('fetchUserDataRequest'))
const fetchUserDataSuccess = createAction<TPersonalDataFull>(
  prefixHelper.create('fetchUserDataSuccess')
)
const fetchUserDataFailure = createAction(prefixHelper.create('fetchUserDataFailure'))
export const updateUserEmail = createAction<TUserEmail>(prefixHelper.create('updateUserEmail'))
export const writeOffBonuses = createAction<TWriteOffBonuses>(
  prefixHelper.create('writeOffBonuses')
)
export const updateLastActivePhoneNumber = createAction<TLastActivePhoneNumber>(
  prefixHelper.create('updateLastActivePhoneNumber')
)
export const updateLastSpecifiedPhoneNumber = createAction<TLastSpecifiedPhoneNumber>(
  prefixHelper.create('updateLastSpecifiedPhoneNumber')
)
export const updateLastSpecifiedPhoneNumberStatus = createAction<TLastSpecifiedPhoneNumberStatus>(
  prefixHelper.create('updateLastSpecifiedPhoneNumberStatus')
)
export const removeProfile = createAction(prefixHelper.create('removeProfile'))

export const fetchUserData = createAsyncThunk(
  prefixHelper.create('profile/getPersonalData'),
  async (token: string, thunkAPI) => {
    thunkAPI.dispatch(fetchUserDataRequest())
    try {
      const response = (await personalApi.getPersonalData({
        token,
        dataSet: DataSetType.full
      })) as TPersonalDataFull
      if (response.code !== 0) throw response

      if (!response.data.inn) {
        const inn = await fetchInnFromService(token)
        response.data.inn = inn
        response.data.innFromService = inn
      }

      thunkAPI.dispatch(fetchUserDataSuccess(response))
    } catch (err) {
      thunkAPI.dispatch(fetchUserDataFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const fetchInnFromService = async (token: string): Promise<string> => {
  const response = (await personalApi.getPersonalData({
    token,
    dataSet: DataSetType.innFromService
  })) as TInnFromService

  if (response.code === ResponseCode.success) return response.data.innFromService
  return ''
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserDataRequest, (state) => {
        state.loading = true
      })
      .addCase(fetchUserDataSuccess, (state, action) => {
        state.data = action.payload.data
        state.loading = false
      })
      .addCase(fetchUserDataFailure, (state) => {
        state.loading = false
      })
      .addCase(updateUserEmail, (state, action) => {
        state.data.email = action.payload.email
        state.data.isEmailConfirmed = false
      })
      .addCase(writeOffBonuses, (state, action) => {
        state.data.bonus = Number(state.data.bonus) - Number(action.payload.bonus)
      })
      .addCase(updateLastSpecifiedPhoneNumber, (state, action) => {
        state.data.lastSpecifiedPhoneNumber = action.payload.lastSpecifiedPhoneNumber
      })
      .addCase(updateLastSpecifiedPhoneNumberStatus, (state, action) => {
        state.data.isLastSpecifiedPhoneNumberConfirmed =
          action.payload.isLastSpecifiedPhoneNumberConfirmed
      })
      .addCase(updateLastActivePhoneNumber, (state, action) => {
        state.data.lastActivePhone = action.payload.lastActivePhone
      })
      .addCase(removeProfile, (state) => {
        state.isRemoved = true
      })
})

const getProfile = (state: TPersonalRootState): IProfileInitialState => state.clientData.profile
const getProfileGroupName = (state: TPersonalRootState): string | undefined =>
  state.clientData.profile.data.groupName

export const clientStatusSelector = createSelector(getProfileGroupName, (groupName) =>
  groupName ? normalizeStatusName(groupName) : null
)

export const profileSelector = createSelector(getProfile, (profile) => profile.data)

export const getPersonalDataSelector = createSelector(profileSelector, (data) => {
  if (isEmpty(data)) return {}

  const {
    birthDate,
    birthPlace,
    education,
    firstName = '',
    lastName = '',
    middleName = '',
    inn = '-',
    maritalStatus,
    passport = '',
    passportDate,
    passportWho,
    passportCityGuid,
    placeOfResidence,
    registration,
    sex,
    email = '',
    oldEmail = '', // заполнен если текущий емейл не подтвержден и есть предыдущий подтвержденный
    isEmailConfirmed = false,
    salary = '-',
    currentTotalIncome = '-',
    loginType,
    lastSpecifiedPhoneNumber,
    isLastSpecifiedPhoneNumberConfirmed
  } = data

  const [passportBatch, passportNumber] = passport.split(' ')
  const passportData = [
    intl.batch.toLowerCase(),
    passportBatch,
    intl.number.toLowerCase(),
    passportNumber,
    ',',
    intl.issuedBy.toLowerCase(),
    passportWho,
    ',',
    passportCityGuid,
    ',',
    intl.dateOfIssuance.toLowerCase(),
    passportDate
  ]
    .join(' ')
    .replace(/\s,\s/g, ', ')
  const actualResidence =
    placeOfResidence === registration ? intl.sameAsRegistration : placeOfResidence
  const currentTotalIncomeValue =
    isNaN(Number(currentTotalIncome)) || !Number(currentTotalIncome)
      ? ''
      : formatAsMoney(currentTotalIncome)
  return {
    fullName: { title: intl.fio, value: `${lastName} ${firstName} ${middleName}` },
    sex: { title: intl.sex, value: sex },
    birthDate: { title: intl.birthDate, value: birthDate },
    birthPlace: { title: intl.birthPlace, value: birthPlace },
    passport: { title: intl.passport, value: passportData },
    education: { title: intl.education, value: education },
    mobileNumber: {
      title: intl.mobileNumber,
      value: lastSpecifiedPhoneNumber,
      isConfirmed: Boolean(isLastSpecifiedPhoneNumberConfirmed),
      readOnly: loginType === LoginType.PHONE
    },
    email: {
      currentEmail: {
        title: intl.email,
        value: email,
        isConfirmed: isEmailConfirmed,
        readOnly: loginType === LoginType.EMAIL
      },
      oldEmail: {
        value: oldEmail
      }
    },
    inn: { title: intl.inn, value: inn },
    maritalStatus: { title: intl.maritalStatus, value: maritalStatus },
    registration: { title: intl.registrationAddress, value: registration },
    placeOfResidence: { title: intl.actualAddress, value: actualResidence },
    salary: { title: intl.averageSalay, value: salary },
    currentTotalIncome: {
      title: intl.averageTotalIncome,
      value: currentTotalIncomeValue
    }
  }
})

export const profileLoadingSelector = createSelector(getProfile, (profile) => profile.loading)
export const personUidSelector = createSelector(profileSelector, (data) => data.uid ?? '')
export const getLoyaltyProgramDataSelector = createSelector(
  profileSelector,
  personGroupsSelector,
  (profile, personGroups) => {
    const { bonus, groupName } = profile
    const status = buildPersonStatusData(personGroups, groupName) as unknown
    return { status, bonuses: bonus }
  }
)
export const getUserScoristaSelector = createSelector(
  profileSelector,
  (profile) => profile.scoristaStructure
)
export const getPrefilledDataSelector = createSelector(profileSelector, (profile) =>
  isEmpty(profile)
    ? {}
    : {
        firstname: profile.firstName,
        lastname: profile.lastName,
        fathername: profile.middleName,
        // если текущий емейл не подтвержден, то берем предыдущий подтвержденный при наличии
        email: profile.oldEmail ? profile.oldEmail : profile.email
      }
)

export default profileSlice.reducer
