import '../PersonalAreaPage/scss/personalAreaPage.scss'

import { isEmpty } from 'lodash'
import { Cookies } from 'react-cookie'
import { connect } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { LogInWithToken } from '#components/App/LogInWithToken'
import { FREEZE_STATE_REQUEST_COOLDOWN_COOKIE, ROUTES } from '#constants/common'
import { withRouter } from '#hoc/withRouter'
import intl from '#intl'
import { personalApi } from '#modules/api'
import { initAbSettings } from '#reducers/abTest/abTestSlice'
import { closeModal, openModal } from '#reducers/ui/modalSlice'
import noty from '#services/notify'
import ChangePasswordPageClient from '#src/components/ChangePasswordForm/ChangePasswordFormClient'
import metrika, { MetrikaComponent } from '#src/modules/metrica'

import ErrorBoundary from '../ErrorBoundary'
import { Footer } from '../Footer/Footer'
import { Modal } from '../Modal/Modal'
import NavBar from '../NavBar/NavBar'
import { Page404 } from '../Page404/Page404'
import AuthErrorComponent from '../PersonalAreaPage/components/AuthErrorComponent'
import { LoginPageClient, PersonalAreaPage } from '../PersonalAreaPage/loadableComponents'
import ScrollButton from '../ScrollButton/ScrollButton'
import { Layout } from './Layout'

const cookies = new Cookies()

class PersonalAreaLayout extends Layout {
  constructor(props) {
    super(props)
    const { settings: { abTest } = {}, dispatch } = props
    if (!isEmpty(abTest)) dispatch(initAbSettings(abTest))
  }

  loggedInToken() {
    return cookies.get('clientToken')
  }

  logout = async () => {
    const isCrmUser = Boolean(cookies.get('crmUser'))
    if (isCrmUser) {
      cookies.remove('clientToken', { path: ROUTES.main })
      cookies.remove('crmUser', { path: ROUTES.main })
    } else {
      cookies.remove(FREEZE_STATE_REQUEST_COOLDOWN_COOKIE, { path: ROUTES.main })
      const token = cookies.get('clientToken')
      await personalApi.logout(token)
      cookies.remove('clientToken', { path: ROUTES.main })
    }
    window.location.reload()
  }

  componentDidMount() {
    const { location } = this.props
    if ([ROUTES.login, ROUTES.changePassword].includes(location.pathname)) noty.clear()

    this.sendIncomingStatOnC4sReceived()
    metrika.sendSessionParams({ channel: process.env.__BUILD__ })
  }

  handleCloseModal = () => this.setState({ modalDocument: null })

  renderModal = () => {
    const { modalDocument } = this.state
    return modalDocument ? <Modal {...modalDocument} onClose={this.handleCloseModal} /> : null
  }

  renderRoutes = () => {
    const {
      location: { pathname },
      navigate
    } = this.props

    const isAuth = this.loggedInToken()
    if (!isAuth && ![ROUTES.login, ROUTES.changePassword, ROUTES.logInWithToken].includes(pathname))
      return <Navigate to={ROUTES.login} replace />
    if (isAuth && pathname === ROUTES.login) return <Navigate to={ROUTES.main} replace />
    if (isAuth && pathname === ROUTES.changePassword) {
      navigate(-1)
      return null
    }

    return (
      <ErrorBoundary style={{ margin: '10% 0' }}>
        <Routes>
          <Route path={'/*'} element={<PersonalAreaPage {...this.props} />} />
          <Route
            path={ROUTES.logInWithToken}
            element={<LogInWithToken logInFrom={'client'} {...this.props} />}
          />
          <Route path={ROUTES.login} element={<LoginPageClient {...this.props} />} />
          <Route
            path={ROUTES.changePassword}
            element={<ChangePasswordPageClient {...this.props} />}
          />
          <Route path={'*'} element={<Page404 />} />
        </Routes>
      </ErrorBoundary>
    )
  }

  renderCriticalError = () => {
    const { error } = this.props
    const message = error.message || intl.serverError
    return (
      <div
        data-qa='criticalError'
        className='d-flex flex-column justify-content-center align-items-center min-height-50vh'
      >
        <h1 className='text-muted'>{intl.errorOccured}</h1>
        <p className='font-italic'>{message}</p>
        <p>{intl.reloginOrTryLater}</p>
      </div>
    )
  }

  render() {
    const { error } = this.props
    const isCriticalErrorOccurred = error instanceof Error || !isEmpty(error)
    return (
      <>
        <MetrikaComponent />
        <header className='pa-header'>
          <NavBar className='shadow' dontKeepInternalHistory />
        </header>
        <main id='main' className='pa-main py-4'>
          <AuthErrorComponent error={error}>
            {isCriticalErrorOccurred ? this.renderCriticalError() : this.renderRoutes()}
          </AuthErrorComponent>
        </main>
        <Footer className='pa-footer border-top shadow' />
        <ScrollButton />
        {this.renderModal()}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.app,
  error: state.clientData.criticalError.data,
  settings: state.settings.data
})

const mapDispatchToProps = (dispatch) => ({
  openModal: (modalType, modalProps) => dispatch(openModal(modalType, modalProps)),
  closeModal: () => dispatch(closeModal()),
  dispatch
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalAreaLayout))
