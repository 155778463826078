import { AxiosError, AxiosRequestConfig } from 'axios'

import appConfig from '#config'
import intl from '#intl'
import { BrokerApiTypes as Types } from '#modules/api/broker/types'
import { HttpClient } from '#modules/api/httpClient'
import { TSendRecoveryCodeResponse } from '#modules/api/personal/types'

const brokerConfig: AxiosRequestConfig = {
  baseURL: new URL('/broker', appConfig.apiHost).href
}
class BrokerApi extends HttpClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
  }

  /**
   * Логирование пользователя
   * @param {FormData} data { login, password }
   */
  public brokerLogin = (data: FormData): Promise<Types.TLoginResponse> =>
    this.instance.post('/login', data)

  public brokerLoginWithToken = (token: string): Promise<Types.TLoginResponse> =>
    this.instance.get('/loginWithToken', { params: { brokerToken: token } })

  /**
   * Разлогирование пользователя
   * @param {FormData} data { brokerToken }
   */
  public brokerLogout = (data: FormData): Promise<Types.TLogoutResponse> =>
    this.instance.post('/logout', data)

  /**
   * Получение информации о брокере
   */
  public getPartnerInfo = (
    params: Types.IGetPartnerInfoRequest
  ): Promise<Types.TGetPartnerInfoResponse> => this.instance.get('/getInfo', { params })

  /**
   * Выгрузка списка переданных брокеру заявок
   */
  public getPartnerBids = (params: Types.IGetPersonsRequest): Promise<Types.TGetPersonsResponse> =>
    this.instance.get('/getPersons', { params })

  /**
   * Выгрузка списка заявок брокера
   */
  public getPartnerAccounts = (
    params: Types.IGetAccountsRequest
  ): Promise<Types.TGetAccountsResponse> => this.instance.get('/getAccounts', { params })

  /**
   * Получение партнерской статистики
   */
  public getPartnerStatistics = (
    params: Types.IGetPartnerStatisticsRequest
  ): Promise<Types.TGetPartnerStatisticsResponse> => this.instance.get('/getStatistic', { params })

  /**
   * Получить список кредитов
   */
  public getPartnerLoans = (
    params: Types.IGetPartnerLoansRequest
  ): Promise<Types.TGetPartnerLoansResponse> => this.instance.get('/getLoans', { params })

  /**
   * Получить токен персоны
   */
  public getPerson2BrokerToken = (
    params: Types.IGetPerson2BrokerTokenRequest
  ): Promise<Types.TGetPerson2BrokerTokenResponse> => this.instance.get('/getToken', { params })

  /**
   * Вставка кредита
   */
  public brokerSubmitRegistration = (
    params: Types.IBrokerSubmitRegistrationRequest
  ): Promise<Types.TBrokerSubmitRegistrationResponse> => this.instance.get('/submit', { params })

  /**
   * Подтверждение заявки
   */
  public brokerConfirmCredit = (
    params: Types.IBrokerConfirmCreditRequest
  ): Promise<Types.TBrokerConfirmCreditResponse> => {
    const {
      veonProcessingPersonalDataChecked,
      megafonProcessingPersonalDataChecked,
      mtsProcessingPersonalDataChecked,
      t2ProcessingPersonalDataChecked,
      tkbProcessingPersonalDataChecked,
      fincardProcessingPersonalDataChecked,
      absolutProcessingPersonalDataChecked,
      d2ProcessingPersonalDataChecked,
      doctorNearbyProcessingPersonalDataChecked,
      scoristaProcessingPersonalDataChecked,
      ...other
    } = params

    const personDataAgreement: string[] = []
    veonProcessingPersonalDataChecked &&
      personDataAgreement.push('veonProcessingPersonalDataChecked')
    megafonProcessingPersonalDataChecked &&
      personDataAgreement.push('megafonProcessingPersonalDataChecked')
    mtsProcessingPersonalDataChecked && personDataAgreement.push('mtsProcessingPersonalDataChecked')
    t2ProcessingPersonalDataChecked && personDataAgreement.push('t2ProcessingPersonalDataChecked')
    tkbProcessingPersonalDataChecked && personDataAgreement.push('tkbProcessingPersonalDataChecked')
    fincardProcessingPersonalDataChecked &&
      personDataAgreement.push('fincardProcessingPersonalDataChecked')
    absolutProcessingPersonalDataChecked &&
      personDataAgreement.push('absolutProcessingPersonalDataChecked')
    d2ProcessingPersonalDataChecked && personDataAgreement.push('d2ProcessingPersonalDataChecked')
    doctorNearbyProcessingPersonalDataChecked &&
      personDataAgreement.push('doctorNearbyProcessingPersonalDataChecked')
    scoristaProcessingPersonalDataChecked &&
      personDataAgreement.push('scoristaProcessingPersonalDataChecked')

    return this.instance.get('/confirmCredit', {
      params: {
        ...other,
        personDataAgreement
      }
    })
  }

  /**
   * Получение одного счета
   */
  public brokerGetClientAccount = (
    params: Types.IIdAndTokenParams
  ): Promise<Types.TBrokerGetClientAccountResponse> =>
    this.instance.get('/person/getAccount', { params })

  /**
   * Process application from user transferred to broker
   */
  public brokerProcess = (
    data: Types.TBrokerProcessData,
    params: Types.IBrokerProcessRequest
  ): Promise<Types.TBrokerProcessResponse> => {
    const { treatment, brokerComment } = data
    const { brokerToken, personId, person2brokerId } = params
    const formData = new FormData()
    formData.append('treatment', treatment)
    formData.append('brokerComment', brokerComment || '')
    return this.instance.post('/process', formData, {
      params: { brokerToken, personId, person2brokerId }
    })
  }

  /**
   * Добавление фото карты
   * @param {FormData} data { cardImage, personToken, brokerToken, isNewCardPhoto }
   */
  public brokerAddCardPhoto = (
    data: FormData,
    config = {}
  ): Promise<Types.TBrokerAddCardPhotoResponse> =>
    this.instance.post('/person/addCardPhoto', data, config)

  /**
   * Обрезка фото карты
   * @param {FormData} data
   * matrix[0][0]
   * matrix[0][1]
   * matrix[0][2]
   * matrix[1][0]
   * matrix[1][1]
   * matrix[1][2]
   * personToken
   * brokerToken
   * cardPhotoId
   */
  public brokerCropCardPhoto = (
    data: FormData,
    config = {}
  ): Promise<Types.TBrokerCropCardPhotoResponse> =>
    this.instance.post('/person/cropCardPhoto', data, config)

  /**
   * Удаление фото карты
   * @param {FormData} data { personToken, brokerToken }
   */
  public brokerRemoveCardPhoto = (data: FormData): Promise<Types.TBrokerRemoveCardPhotoResponse> =>
    this.instance.post('/person/removeCardPhoto', data)

  /**
   * Добавление нового счета
   * @param {FormData} data { personToken, brokerToken, personId, cardNumber, cardName, billBic, billNumber }
   */
  public brokerAddBillAccount = (
    data: FormData
  ): Promise<Types.TBrokerAddBillAndCardAccountResponse> =>
    this.instance.post('/person/addBillAccount', data)

  /**
   * Добавление новой карты
   * @param {FormData} data { personToken, brokerToken, personId, cardNumber, cardName, cardDate }
   */
  public brokerAddCardAccount = (
    data: FormData
  ): Promise<Types.TBrokerAddBillAndCardAccountResponse> =>
    this.instance.post('/person/addCardAccount', data)

  /**
   * Добавление нового займа пользователю
   * @param {FormData} data { personToken, brokerToken, c4s, promoCode, amount, period, personAccountId }
   */
  public brokerAddLoan = (data: FormData): Promise<Types.TBrokerAddLoanResponse> =>
    this.instance.post('/person/addLoan', data)

  /**
   * Uploads documents required for credit
   */
  public brokerUploadPersonDocument = async (
    params: Types.IBrokerUploadPersonDocumentRequest
  ): Promise<unknown> => {
    const { personToken, brokerToken, type, file, onUploadProgress, signal } = params
    const formData = new FormData()
    const mapTypeToInt = { passport: 36, selfie: 37 }
    const typeValue = mapTypeToInt[type] || null
    typeValue && formData.append('type', String(typeValue))
    formData.append('personToken', personToken)
    formData.append('brokerToken', brokerToken)
    formData.append('file', file)
    return this.instance
      .post('person/uploadPhotoData', formData, { onUploadProgress, signal })
      .catch((err: AxiosError) => {
        throw err?.response?.status === 413 ? new Error(intl.filesSizesLimitExceeded) : err
      })
  }

  /**
   * Получение документа по кредиту
   */
  public brokerDownloadDocument = (params: Types.IBrokerDownloadDocumentRequest): Promise<Blob> =>
    this.instance.get('/downloadDocument', { params, responseType: 'blob' })

  /**
   * Проверка кредита
   */
  public brokerCheckCredit = (
    params: Types.IBrokerCheckCreditRequest
  ): Promise<Types.TBrokerCheckCreditResponse> => this.instance.get('/checkCredit', { params })

  /**
   * Получение партнерской статистики в формате для экспорта в файл
   */
  public brokerExportStatistic = (
    params: Types.IBrokerExportStatisticRequest
  ): Promise<Types.TBrokerExportStatisticResponse> =>
    this.instance.get('/exportStatistic', { params })

  /**
   * Повторная отправка кода подтверждения заявки
   */
  public repeatCreditConfirmCode = (
    params: Types.IRepeatCreditConfirmCodeRequest
  ): Promise<Types.TRepeatCreditConfirmCodeResponse> =>
    this.instance.get('/repeatCreditConfirmCode', { params })

  /**
   * Восстановить ИНН
   */
  public restoreINN = (params: Types.IIdAndTokenParams): Promise<Types.TRestoreINNResponse> =>
    this.instance.get('/person/info/inn', { params })

  /**
   * Восстановить доход
   */
  public restoreIncome = (params: Types.IIdAndTokenParams): Promise<Types.TRestoreIncomeResponse> =>
    this.instance.get('/person/info/totalIncome', { params })

  /**
   * Получение партнерской статистики (переданные брокеру)
   */
  public getStatisticTransferred = (
    params: Types.IGetPartnerStatisticsRequest
  ): Promise<Types.TGetPartnerStatisticsResponse> =>
    this.instance.get('/getStatisticTransferred', { params })

  /**
   * Gets list person's card images for further broker processing
   */
  public getCardPhotosForBroker = async (
    params: Types.IGetCardPhotosForBrokerRequest
  ): Promise<Types.IGetCardPhotosForBrokerResponse> =>
    this.instance.get('/getCardPhotosForBrokerList', { params })

  /**
   * Gets personal link for personal to card uploading special page
   */
  public getCardPhotosForBrokerLink = async (
    params: Types.IGetCardPhotosForBrokerRequest
  ): Promise<Types.IGetCardPhotosForBrokerLinkResponse> =>
    this.instance.get('/getCardPhotosForBrokerPersonaLink', { params })

  /**
   * Gets card photo file uploaded by person for further broker processing
   */
  deleteCardPhotoForBroker = async (
    params: Types.IDeleteCardPhotosForBrokerRequest
  ): Promise<Types.TDeleteCardPhotosForBrokerResponse> => {
    const { brokerToken, personToken, id } = params
    const formData = new FormData()
    formData.append('brokerToken', brokerToken)
    formData.append('personToken', personToken)
    formData.append('id', String(id))
    return this.instance.post('/deleteCardPhotoForBroker', formData)
  }

  /**
   * Gets card photo file uploaded by person for further broker processing
   */
  public getCardPhotoFile = async (
    params: Types.IGetCardPhotoFileRequest
  ): Promise<Types.TGetCardPhotoFileResponse> => {
    const { brokerToken, personToken, hash } = params
    const formData = new FormData()
    formData.append('brokerToken', brokerToken)
    formData.append('personToken', personToken)
    formData.append('hash', hash)
    return this.instance.post('/getCardPhotoFile', formData, { responseType: 'blob' })
  }

  /**
   * Sends request to initiate password restoration
   */
  public sendRecoveryCode({
    login,
    recaptcha
  }: {
    login: string
    recaptcha?: string
  }): Promise<TSendRecoveryCodeResponse> {
    const formData = new FormData()
    formData.append('login', login)
    recaptcha && formData.append('g-recaptcha-response', recaptcha)
    return this.instance.post('/sendRecoveryCode', formData)
  }

  /**
   * Sends confirmation code to receive new password
   */
  public sendNewPassword({
    login,
    code
  }: {
    login: string
    code: string
  }): Promise<TSendRecoveryCodeResponse> {
    const formData = new FormData()
    formData.append('login', login)
    formData.append('code', code)
    return this.instance.post('/sendNewPassword', formData)
  }
}

export const brokerApi = new BrokerApi(brokerConfig)
