import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf
} from '@reduxjs/toolkit'

import { adminApi } from '#modules/api'
import { ResponseCode } from '#modules/api/types/common'
import { CreatePrefix } from '#reducers/helper'
import { TMainDataStateType } from '#src/reducers'

export type TProfileInitialState = {
  isFetched: boolean
  loggedIn: boolean
  roles: string[]
}

const initialState: TProfileInitialState = {
  isFetched: false,
  loggedIn: false,
  roles: []
}

export type TFetchProfileSucceed = typeof fetchProfileSucceed

const prefixHelper = new CreatePrefix('profile')

export const fetchProfileSucceed = createAction<{
  roles: string[]
}>(prefixHelper.create('fetchProfileSucceed'))

export const getProfileRoles = createAsyncThunk(
  prefixHelper.create('getProfileRoles'),
  async (token: string, thunkAPI) => {
    const response = await adminApi.getProfileRoles(token)
    if (response.code !== ResponseCode.success)
      return thunkAPI.rejectWithValue({ message: response.message })
    const { data } = response
    return { roles: data }
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(isAnyOf(fetchProfileSucceed, getProfileRoles.fulfilled), (state, action) => {
        state.loggedIn = true
        state.isFetched = true
        state.roles = action.payload.roles
      })
      .addMatcher(isAnyOf(getProfileRoles.pending, getProfileRoles.rejected), (state) => {
        state.loggedIn = false
        state.isFetched = false
      })
})

export default profileSlice.reducer
const profile = (state: TMainDataStateType): TProfileInitialState => state.adminPage.profile
export const userLoggedInSelector = createSelector(profile, (profile) => profile.loggedIn)
export const userRolesSelector = createSelector(profile, (profile) => profile.roles)
export const isFetchingProfile = createSelector(profile, (profile) => profile.isFetched)
