export enum Actions {
  CARD_UPLOAD = 'CARD_UPLOAD',
  ADDITIONAL_SERVICES_MESSAGE = 'ADDITIONAL_SERVICES_MESSAGE',
  CARD_PHOTO_ARTICLE = 'CARD_PHOTO_ARTICLE',
  UPLOAD_BUTTONS = 'UPLOAD_BUTTONS'
}

export enum ActionStages {
  REQUEST = 'REQUEST',
  PROCESS = 'PROCESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  FULL = 'FULL',
  SHORT = 'SHORT',
  UPLOAD_BUTTONS_BOTH = 'UPLOAD_BUTTONS_BOTH',
  UPLOAD_BUTTONS_SINGLE = 'UPLOAD_BUTTONS_SINGLE'
}

export const UserActionsWithStage = {
  [Actions.UPLOAD_BUTTONS]: {
    [ActionStages.UPLOAD_BUTTONS_SINGLE]: ActionStages.UPLOAD_BUTTONS_SINGLE,
    [ActionStages.UPLOAD_BUTTONS_BOTH]: ActionStages.UPLOAD_BUTTONS_BOTH
  },
  [Actions.CARD_UPLOAD]: {
    [ActionStages.REQUEST]: ActionStages.REQUEST,
    [ActionStages.PROCESS]: ActionStages.PROCESS,
    [ActionStages.SUCCESS]: ActionStages.SUCCESS,
    [ActionStages.FAILURE]: ActionStages.FAILURE
  },
  [Actions.ADDITIONAL_SERVICES_MESSAGE]: {
    [ActionStages.SHOW]: ActionStages.SHOW,
    [ActionStages.HIDE]: ActionStages.HIDE
  },
  [Actions.CARD_PHOTO_ARTICLE]: {
    [ActionStages.FULL]: ActionStages.FULL,
    [ActionStages.SHORT]: ActionStages.SHORT
  }
}
