import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fetchUserAccounts } from '#reducers/clientData/accountsSlice'
import { CreatePrefix } from '#reducers/helper'
import { personalApi } from '#src/modules/api'

type TAddCardParam = {
  cardNumber: string
  cardName: string
  cardDate: string
  cardPhotoId: number
}

type TAddBillAccountParam = {
  billNumber: number
  billBic: number
}

type TParam = TAddCardParam & TAddBillAccountParam

export interface IRequisitesInitialState {
  loading: boolean
}

const initialState: IRequisitesInitialState = {
  loading: false
}

const prefixHelper = new CreatePrefix('requisites')

const addUserRequisitesRequest = createAction(prefixHelper.create('addUserRequisitesRequest'))
const addUserRequisitesSuccess = createAction(prefixHelper.create('addUserRequisitesSuccess'))
const addUserRequisitesFailure = createAction(prefixHelper.create('addUserRequisitesFailure'))

export const addUserRequisites = createAsyncThunk(
  prefixHelper.create('requisites/addCardAccount'),
  async ({ token, type, data }: { token: string; type: string; data: TParam }, thunkAPI) => {
    thunkAPI.dispatch(addUserRequisitesRequest())
    try {
      const requestData = {
        ...data,
        token
      }
      if (!['bankCard', 'bankAccount'].includes(type))
        throw new Error('Не указан тип добавляемых реквизитов')
      let response
      if (type === 'bankCard') response = await personalApi.addCardAccount(requestData)
      else response = await personalApi.addBillAccount(requestData)

      if (response.code !== 0) throw response
      void thunkAPI.dispatch(fetchUserAccounts(token))
      thunkAPI.dispatch(addUserRequisitesSuccess())
    } catch (err) {
      thunkAPI.dispatch(addUserRequisitesFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const requisitesSlice = createSlice({
  name: 'requisites',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(addUserRequisitesRequest, (state) => {
        state.loading = true
      })
      .addCase(addUserRequisitesSuccess, (state) => {
        state.loading = false
      })
      .addCase(addUserRequisitesFailure, (state) => {
        state.loading = false
      })
})

export default requisitesSlice.reducer
