import intl from '#intl'

export default class ErrorWithData extends Error {
  protected data: Record<string, unknown>
  protected constructor(message: string = intl.unknownError, data: Record<string, unknown> = {}) {
    super(message)
    this.name = 'ErrorWithData'
    this.data = data
  }
}
