import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { ResponseCode } from '#modules/api/types/common'
import { TMainDataStateType } from '#reducers/index'
import { parseDate } from '#services/datetime'
import { brokerApi } from '#src/modules/api'
import { BrokerApiTypes } from '#src/modules/api/broker/types'
import notify from '#src/services/notify'

export const fetchStatistic = createAsyncThunk(
  'settings/fetchStatistic',
  async (requestRules: BrokerApiTypes.IGetPartnerStatisticsRequest, thunkAPI) => {
    const response = await brokerApi.getPartnerStatistics(requestRules)

    if (response.code !== ResponseCode.success) {
      const { message } = response
      notify.push({ message, type: 'danger' })
      return thunkAPI.rejectWithValue(message)
    }

    return response.data
  }
)

export const fetchTransferredStatistic = createAsyncThunk(
  'settings/fetchStatistic',
  async (requestRules: BrokerApiTypes.IGetPartnerStatisticsRequest) => {
    const response = await brokerApi.getStatisticTransferred(requestRules)
    if (response.code === 0) return response.data
  }
)

type TInitialState = {
  loading: boolean
  amount: number
  bonus: number
  confirmedRequestCount: number
  issueCount: number
  timeGrouperRowStatisticStructureList: BrokerApiTypes.TTimeGrouperRowStatisticStructureList[]
}

const initialState: TInitialState = {
  loading: false,
  amount: 0,
  bonus: 0,
  confirmedRequestCount: 0,
  issueCount: 0,
  timeGrouperRowStatisticStructureList: []
}

const statisticsSlice = createSlice({
  name: 'statisticsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatistic.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchStatistic.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchStatistic.fulfilled, (state, action) => {
        state.loading = false
        state.amount = action.payload.amount
        state.bonus = action.payload.bonus
        state.confirmedRequestCount = action.payload.confirmedRequestCount
        state.issueCount = action.payload.issueCount
        state.timeGrouperRowStatisticStructureList =
          action.payload.timeGrouperRowStatisticStructureList
      })
  }
})

export default statisticsSlice.reducer

export const selectStatLoading = (state: TMainDataStateType): boolean => state.statistics.loading
export const selectStatList = (
  state: TMainDataStateType
): BrokerApiTypes.TTimeGrouperRowStatisticStructureList[] =>
  state.statistics.timeGrouperRowStatisticStructureList

export const selectSortedStatList = createSelector(selectStatList, (list) =>
  list.slice().sort((a, b) => parseDate(a.grouper) - parseDate(b.grouper))
)
