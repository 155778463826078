import { IObserver } from '#src/modules/api/admin/Observer'

/**
 * Интферфейс издателя объявляет набор методов для управлениями подписчиками.
 */
export interface ISubject {
  // Присоединяет наблюдателя к издателю.
  attach(observer: IObserver): void

  // Отсоединяет наблюдателя от издателя.
  detach(observer: IObserver): void

  // Уведомляет всех наблюдателей о событии.
  notify(): void
}

type TStates = 'ready' | 'pending'

/**
 * Издатель владеет некоторым важным состоянием и оповещает наблюдателей о его
 * изменениях.
 */
export class Subject implements ISubject {
  /**
   * @type {number} Для удобства в этой переменной хранится состояние
   * Издателя, необходимое всем подписчикам.
   */
  private _state: TStates = 'ready'

  /**
   * @type {IObserver[]} Список подписчиков. В реальной жизни список
   * подписчиков может храниться в более подробном виде (классифицируется по
   * типу события и т.д.)
   */
  private readonly observers: IObserver[] = []

  /**
   * Методы управления подпиской.
   */
  public attach(observer: IObserver): void {
    const isExist = this.observers.includes(observer)
    if (isExist) return
    this.observers.push(observer)
  }

  public detach(observer: IObserver | null): void {
    if (observer === null) return
    const observerIndex = this.observers.indexOf(observer)
    if (observerIndex === -1) return
    this.observers.splice(observerIndex, 1)
  }

  /**
   * Запуск обновления в каждом подписчике.
   */
  public notify(): void {
    for (const observer of this.observers) observer.update(this)
  }

  /**
   * Установка нового состояния
   * @param state
   */
  public setState(state: TStates): void {
    this._state = state
    this.notify()
  }

  /**
   * Получение текущего состояния
   */
  public getState(): TStates {
    return this._state
  }
}
