import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import * as AdminApiTypes from '#modules/api/admin/types'
import {
  createPageItemContent,
  fetchPageItemContent,
  fetchPageItemHistory,
  fetchPageList,
  updatePageItemContent
} from '#reducers/adminPage/content/effects'
import { PublishedStateText } from '#reducers/adminPage/content/types'
import { getErrorMessage } from '#reducers/helper'
import notify from '#services/notify'
import { TMainDataStateType } from '#src/reducers'

export type TContentInitialState = {
  countPageUpdates: number
  pageVersionList: {
    items: AdminApiTypes.TPageListItem[]
    isLoading: boolean
  }
  pageList: {
    isLoading: boolean
    currentPage: number
    itemsPerPage: number
    items: AdminApiTypes.TPageListItem[]
    totalItems: number
  }
  pageItemContent: {
    isLoading: boolean
    data?: TNullable<AdminApiTypes.TPageItemContent>
  }
}

const initialState: TContentInitialState = {
  countPageUpdates: 0,
  pageVersionList: {
    items: [],
    isLoading: false
  },
  pageList: {
    isLoading: false,
    currentPage: 1,
    itemsPerPage: 7,
    items: [],
    totalItems: 0
  },
  pageItemContent: {
    isLoading: false,
    data: null
  }
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    hideAllPages: (state, { payload }: PayloadAction<number>) => {
      state.pageList.items = state.pageList.items.map((item) => {
        if (item.id === payload)
          return { ...item, published: 0, publishedText: PublishedStateText.unPublished }
        return item
      })
    },
    pageItemVisibilityChanged: (state, { payload }: PayloadAction<AdminApiTypes.TPageListItem>) => {
      state.pageVersionList.items = state.pageVersionList.items.map((item) => {
        if (item.id === payload.id) return payload
        return item
      })
      state.pageList.items = state.pageList.items.map((item) => {
        if (item.id === payload.id) return payload
        return item
      })
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.pageList.currentPage = payload
    },
    setItemsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.pageList.itemsPerPage = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageItemContent.fulfilled, (state, action) => {
        state.pageItemContent.isLoading = false
        state.pageItemContent.data = action.payload
      })
      .addCase(createPageItemContent.fulfilled, (state) => {
        state.pageItemContent.isLoading = false
        notify.push({ message: 'Данные успешно сохранены', type: 'success' })
      })
      .addCase(createPageItemContent.rejected, (state, action) => {
        state.pageItemContent.isLoading = false
        const message = getErrorMessage(action)
        notify.push({ message, type: 'danger' })
      })
      .addCase(updatePageItemContent.fulfilled, (state) => {
        state.pageVersionList.isLoading = false
        notify.push({ message: 'Данные успешно сохранены', type: 'success' })
      })
      .addCase(fetchPageItemHistory.fulfilled, (state, action) => {
        state.pageVersionList.isLoading = false
        state.pageVersionList.items = action.payload
      })
      .addCase(fetchPageList.pending, (state) => {
        state.pageList.isLoading = true
      })
      .addCase(fetchPageList.fulfilled, (state, action) => {
        state.pageList.isLoading = false
        state.pageList.items = action.payload.items
        state.pageList.totalItems = action.payload.totalItems
        state.pageList.itemsPerPage = action.payload.itemsPerPage
        state.pageList.currentPage = action.payload.currentPage
      })
      .addCase(fetchPageList.rejected, (state) => {
        state.pageList.items = []
        state.pageList.isLoading = false
      })
      .addMatcher(
        isAnyOf(
          fetchPageItemContent.pending,
          updatePageItemContent.pending,
          createPageItemContent.pending
        ),
        (state) => {
          state.pageItemContent.isLoading = true
        }
      )
      .addMatcher(isAnyOf(fetchPageItemHistory.pending, fetchPageList.pending), (state) => {
        state.pageVersionList.isLoading = true
      })
      .addMatcher(
        isAnyOf(
          fetchPageItemHistory.rejected,
          updatePageItemContent.rejected,
          fetchPageItemContent.rejected
        ),
        (state, action) => {
          state.pageVersionList.isLoading = false
          const message = getErrorMessage(action)
          notify.push({ message, type: 'danger' })
        }
      )
  }
})

export const {
  hideAllPages,
  pageItemVisibilityChanged,
  setCurrentPage,
  setItemsPerPage
} = contentSlice.actions

export default contentSlice.reducer

const content = (state: TMainDataStateType): TContentInitialState => state.adminPage.content
export const pageVersionList = createSelector(content, (content) => content.pageVersionList)
export const pageList = createSelector(content, (content) => content.pageList)
export const pageItemContent = createSelector(content, (content) => content.pageItemContent)
export const pageItemContentLoading = createSelector(
  pageItemContent,
  (pageItemContent) => pageItemContent.isLoading
)
