import { createAsyncThunk } from '@reduxjs/toolkit'

import { nodeApi } from '#modules/api'
import { CreatePrefix } from '#reducers/helper'

const prefixHelper = new CreatePrefix('serverFiles')

export const getServerFiles = createAsyncThunk(
  prefixHelper.create('getServerFiles'),
  async (token: string, thunkAPI) => {
    try {
      const response = await nodeApi.showServerFiles(token)
      if (response.code === 0) {
        const { treeStructure } = response
        return thunkAPI.fulfillWithValue({ treeStructure })
      }
      return thunkAPI.rejectWithValue({ message: 'Не удалось получить список файлов с сервера' })
    } catch {
      return thunkAPI.rejectWithValue({ message: 'Обновите страницу и повторите попытку' })
    }
  }
)
