import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TMainDataStateType } from '#reducers/index'

export type TInitialState = {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

const initialState: TInitialState = {
  isMobile: true,
  isTablet: false,
  isDesktop: false
}

const deviceTypeSlice = createSlice({
  name: 'deviceType',
  initialState,
  reducers: {
    updateDeviceType: (state, { payload }: PayloadAction<TInitialState>) => {
      state.isDesktop = payload.isDesktop
      state.isMobile = payload.isMobile
      state.isTablet = payload.isTablet
    }
  }
})

export const { updateDeviceType } = deviceTypeSlice.actions
export default deviceTypeSlice.reducer

const device = (state: TMainDataStateType): TInitialState => state.device

export const deviceState = createSelector(device, (state) => state)
