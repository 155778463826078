/* eslint-disable max-len */
export const fakeSeoText = {
  allText: `<p style="text-align: justify;">Не обойтись без финансовой поддержки? Не тратьте время на сбор пакета документов и ожидание в очередях: оформляйте быстрый займ в онлайн-сервисе микрокредитования «Честное слово». Мы знаем, как важно оперативно получить деньги в долг, когда каждая минута на счету, поэтому предлагаем простую процедуру оформления микрозаймов и перечисляем средства на счёт клиента уже в день подписания договора.</p>
  <p style="text-align: justify;">Регистрация на сайте «Честного слова» - это возможность получать быстрые займы онлайн в любое время и не бояться денежных форс-мажоров.</p>
  <h2 style="text-align: justify;">Почему стоит обратиться в «Честное слово»</h2>
  <p style="text-align: justify;">Для многих россиян микрокредиты стали оптимальным способом получить деньги в долг и в минимальные сроки решить любые финансовые вопросы. Лучше всего в плане удобства и выгоды зарекомендовали себя онлайн микрозаймы, которые выдаёт «Честное слово». Обратившись в наш онлайн-сервис, можно в дистанционном режиме взять быстрый займ на простых и прозрачных условиях.</p>
  <p style="text-align: justify;">Быстрый заём денег онлайн в «Честном слове» – это:</p>
  <ul>
    <li style="text-align: justify;"><strong>доступно</strong> – сайт компании работает круглосуточно, поэтому деньги в долг можно получить в любое время даже клиентам из отдалённых населённых пунктов страны;</li>
    <li style="text-align: justify;"><strong>оперативно</strong> – подача заявки на онлайн-займ занимает не более 10-15 минут, а средства на счёт заёмщика компания перечисляет сразу же после подписания договора;</li>
    <li style="text-align: justify;"><strong>удобно</strong> – оформление онлайн-микрозайма возможно с компьютера, смартфона или планшета с доступом в интернет, поэтому россияне получают деньги под проценты, как только они им понадобятся;</li>
    <li style="text-align: justify;"><strong>выгодно</strong> – взять быстрый займ срочно онлайн можно даже на очень короткий срок, не переплачивая за дни, когда средства уже не нужны.</li>
  </ul>
  <p style="text-align: justify;">Клиентам «Честного слова» займы через интернет также помогают в короткий срок улучшить кредитную историю и подтвердить репутацию ответственного плательщика.</p>
  <h2 style="text-align: justify;">Условия получения онлайн микрозаймов</h2>
  <p style="text-align: justify;">Нашим клиентам доступны микрозаймы в размере от 3 000 до 70 000 рублей сроком от 10 до 364 дней. Если вы подаёте заявку на займ в первый раз, то максимальная сумма микрозайма составит 30 000 рублей, а срок – 31 день, ставка всего 1 % в день.</p>
  <p style="text-align: justify;">Получить займ <b>по ставке 0%</b> может любой клиент, который обратится в онлайн-сервис впервые и оформит займ на сумму <b>от 3 000 до 30 000 рублей</b> на срок <b>от 10 до 15 дней</b>.</p>
  <p style="text-align: justify;">С каждым новым обращением вам будет открываться больше возможностей для получения финансовой поддержки!</p>
  <p style="text-align: justify;">Став нашим постоянным клиентом и участником <a href="https://n.4slovo.ru/privileges/">программы привилегий</a>, вы получите уникальную возможность получать максимально крупные займы на срок до 364 дней по сниженной ставке</p>
  <p style="text-align: justify;">Мы выдаём деньги в долг всем гражданам РФ в возрасте от 18 до 75 лет, у которых есть паспорт, постоянный источник дохода, а также активный банковский счёт или карта. Наше кредо – лояльность к клиентам, поэтому вы получите быстрый займ, даже если работаете неофициально и обладаете не идеальной кредитной историей.</p>
  <h2 style="text-align: justify;">Как взять быстрый онлайн займ</h2>
  <p style="text-align: justify;">Для удобства заёмщиков мы максимально упростили процесс выдачи онлайн микрозайма и свели его к 4 простым шагам:</p>
  <ol>
    <li style="text-align: justify;">Подача заявления на предоставление микрозайма – процедура <a href="https://n.4slovo.ru/register/">регистрации</a> на сайте «Честного слова» является одновременно заполнением заявки на микрозайм, которая сразу отправляется на рассмотрение</li>
    <li style="text-align: justify;">Автоматическая проверка – все сведения о клиенте, указанные в анкете, анализирует автоматическая скоринговая система, которая проверяет правильность и достоверность информации.</li>
    <li style="text-align: justify;">Подписание договора микрозайма – после одобрения заявки заёмщик подписывает контракт, указав специальный код, который приходит в SMS-сообщении.</li>
    <li style="text-align: justify;">Получение денег – сразу после заключения сделки компания перечисляет средства по указанным в заявке реквизитам. Скорость поступления денег зависит от банка, в котором у клиента открыт счёт или карта, но обычно заёмщики получают желаемую сумму уже в день обращения.</li>
  </ol>
  <p style="text-align: justify;">На нашем ресурсе граждане оформляют быстрые займы круглосуточно, самостоятельно подавая заявку и подписывая документы. Но мы не оставляем их без поддержки! На сайте работает форма обратной связи, при помощи которой легко связаться с нашим консультантом и задать любой интересующий вопрос.</p>
  <p style="text-align: justify;">Онлайн-сервис позволяет взять быстрый займ через интернет, который требуется именно вам. Вы сами выбираете сумму и срок микрозайма, и оформляете её в удобное время. «Честное слово» - это постоянный доступ к заёмным средствам, без справок, поручителей и залогов.</p>
  `,
  primaryText:
    '<p style="text-align: justify;">Не обойтись без финансовой поддержки? Не тратьте время на сбор пакета документов и ожидание в очередях: оформляйте быстрый займ в онлайн-сервисе микрокредитования «Честное слово». Мы знаем, как важно оперативно получить деньги в долг, когда каждая минута на счету, поэтому предлагаем простую процедуру оформления микрозаймов и перечисляем средства на счёт клиента уже в день подписания договора.</p><p style="text-align: justify;">Регистрация на сайте «Честного слова» - это возможность получать быстрые займы онлайн в любое время и не бояться денежных форс-мажоров.</p>'
}
