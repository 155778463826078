/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import './icon.scss'

import classnames from 'classnames'
import { CSSProperties, FC, MouseEvent, MutableRefObject } from 'react'

interface IIconProps {
  className?: string
  name: string
  onClick?: (event: MouseEvent) => void
  style?: CSSProperties
  'data-qa'?: string
  ref?: MutableRefObject<any>
}

export const Icon: FC<IIconProps> = (props) => {
  const { name, onClick, style, className, ref } = props
  const classes = classnames(
    {
      icon: true,
      [`icon_name_${name}`]: true
    },
    className
  )
  return (
    <i
      ref={ref}
      className={classes}
      style={style}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: require(`../../assets/svg/${name}.svg`) }}
      data-qa={props['data-qa']}
    />
  )
}

Icon.defaultProps = {
  style: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {}
}

export default Icon
