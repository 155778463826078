import { osTicketClientApi } from '#modules/api'
import { OsticketCreateResponseCode, ResponseCode } from '#modules/api/types/common'
import {
  TFeedbackFormResponse,
  TFeedbackSearchTicketListResponse,
  TFeedbackSearchTicketResponse
} from '#services/api/osTicketClient/types'

interface ICreateTicketResponse {
  code: OsticketCreateResponseCode.notFound | ResponseCode.success
  message: string
  ticketId: number | null
}

export const getTicketForm = (): Promise<TFeedbackFormResponse> =>
  osTicketClientApi.get('/getTicketForm')

export const getTicketListForEmail = (
  email: string,
  ticketId: string
): Promise<TFeedbackSearchTicketListResponse> =>
  osTicketClientApi.post('/searchTickets', { email, ticketId })

export const getTicketDetails = (
  email: string,
  ticketId: string
): Promise<TFeedbackSearchTicketResponse> =>
  osTicketClientApi.post('/searchTicket', { email, ticketId })

export const createTicket = (data: {
  topicId: number
  attachments: []
  cid: string
  email: string
  fathername: string
  firstname: string
  lastname: string
  message: string
  privateAgreement: number
  recaptcha: string
  subject: string
}): Promise<ICreateTicketResponse> => osTicketClientApi.post('/createTicket', data)
