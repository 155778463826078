import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { omit, uniq, without } from 'lodash'

import {
  fetchFeedbackForm,
  fetchTicketDetails,
  fetchTicketList
} from '#reducers/feedbackForm/effects'
import { TFeedbackForms, TFeedbackTopic } from '#services/api/osTicketClient/types'
import { TMainDataStateType } from '#src/reducers'

export type TTicketList = {
  createdAt: string
  email: string
  id: string
  status: string
  subject: string
  updateDate: string
}

export type TFeedbackInitialState = {
  loading: boolean
  topic: TFeedbackTopic
  forms: TFeedbackForms[]
  ticketList: TTicketList[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  tickets: { byId: {}; allIds: string[] }
}
const initialState: TFeedbackInitialState = {
  loading: false,
  topic: {} as TFeedbackTopic,
  forms: [],
  ticketList: [],
  tickets: { byId: {}, allIds: [] }
}

const feedbackFormSlice = createSlice({
  name: 'feedbackForm',
  initialState,
  reducers: {
    purgeTicketList: (state) => {
      state.ticketList = []
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchFeedbackForm.fulfilled, (state, action) => {
        state.topic = action.payload.topic
        state.forms = action.payload.forms
        state.loading = false
      })
      .addCase(fetchTicketList.fulfilled, (state, action) => {
        state.ticketList = action.payload.data
        state.loading = false
      })
      .addCase(fetchTicketDetails.pending, (state, action) => {
        state.loading = true
        state.tickets = {
          byId: action.meta.arg.ticketId,
          allIds: uniq([...state.tickets.allIds, action.meta.arg.ticketId])
        }
      })
      .addCase(fetchTicketDetails.fulfilled, (state, action) => {
        state.loading = false
        state.tickets = {
          byId: {
            [action.payload.id]: { data: action.payload.data, loading: false }
          },
          allIds: [...state.tickets.allIds]
        }
      })
      .addCase(fetchTicketDetails.rejected, (state, action) => {
        state.loading = false
        state.tickets = {
          byId: omit(state.tickets.byId, action.meta.arg.ticketId),
          allIds: without(state.tickets.allIds, action.meta.arg.ticketId)
        }
      })
      .addMatcher(isAnyOf(fetchFeedbackForm.pending, fetchTicketList.pending), (state) => {
        state.loading = true
      })
      .addMatcher(isAnyOf(fetchFeedbackForm.rejected, fetchTicketList.rejected), (state) => {
        state.loading = false
      })
})

export const { purgeTicketList } = feedbackFormSlice.actions

const getFeedbackForm = (state: TMainDataStateType): TFeedbackInitialState => state.feedbackForm

export const feedbackLoadingSelector = createSelector(
  getFeedbackForm,
  (feedbackForm: TFeedbackInitialState) => feedbackForm.loading
)
export const feedbackTopicSelector = createSelector(
  getFeedbackForm,
  (feedbackForm: TFeedbackInitialState) => feedbackForm.topic
)
export const feedbackFormsSelector = createSelector(
  getFeedbackForm,
  (feedbackForm: TFeedbackInitialState) => feedbackForm.forms
)
export const feedbackTicketListSelector = createSelector(
  getFeedbackForm,
  (feedbackForm: TFeedbackInitialState) => feedbackForm.ticketList
)
export const feedbackTicketsSelector = createSelector(
  getFeedbackForm,
  (feedbackForm: TFeedbackInitialState) => feedbackForm.tickets
)
export const feedbackTopicFormsSelector = createSelector(
  feedbackTopicSelector,
  feedbackFormsSelector,
  (topic, forms) => ({ topic, forms })
)

export default feedbackFormSlice.reducer
