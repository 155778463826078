import theme from '#src/assets/scss/Exports.module.scss'

export const CHUNK_LOAD_ERROR = 'ChunkLoadError'
export const UNKNOWN_ERROR = 'unknownError'
export const RECONNECT_INTERVAL_S = 15
export const RECONNECT_INTERVAL_MS = RECONNECT_INTERVAL_S * 1000
export const ATTEMPTS_TO_RECONNECT = 3

export const DEFAULT_CLASSES =
  'd-flex-centered border rounded flex-column p-4 mx-auto shadow position-relative'
export const DEFAULT_STYLE = { maxWidth: theme.maxPageWidth }
