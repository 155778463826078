import { v4 as uuidv4 } from 'uuid'

import appConfig from '#config'

import { BROWSER_ENVIRONMENT, NODE_ENVIRONMENT, SERVICE_NAME_PREFIX } from './constants'

export const generateGuid = (): string => uuidv4()
export const isReportEnabled = (): boolean => Boolean(Number(appConfig.errorReportEnabled))
export const isBrowserEnvironment = (): boolean => Boolean(process.env.__CLIENT__)
export const isNodeEnvironment = (): boolean => !isBrowserEnvironment()

export const buildServiceName = (): string => {
  const prefix = SERVICE_NAME_PREFIX
  const build = process.env.__BUILD__
  const environment = process.env.__CLIENT__ ? BROWSER_ENVIRONMENT : NODE_ENVIRONMENT
  return [prefix, build, environment].filter(Boolean).join('_')
}
