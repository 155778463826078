import { combineReducers } from '@reduxjs/toolkit'

import abTestSlice from '#reducers/abTest/abTestSlice'
import appSlice from '#reducers/app/appSlice'
import articleSlice from '#reducers/article/articleSlice'
import backgroundWithTextSlice from '#reducers/backgroundWithText/backgroundWithTextSlice'
import blogSlice from '#reducers/blog/blogSlice'
import notifications from '#reducers/clientData/notificationsSlice'
import feedbackFormSlice from '#reducers/feedbackForm/feedbackFormSlice'
import loanConditionsSlice from '#reducers/loanConditions/loanConditionsSlice'
import loanFormStateSlice from '#reducers/loanFormState/loanFormStateSlice'

import deviceReducer from '../../deviceTypeSlice'
import newsReducer from '../../newsSlice'
import seoSlice from '../../seoSlice'
import settingsSlice from '../../settingsSlice'
import sitemapReducer from '../../sitemapSlice'
import testimonialsSlice from '../../testimonialsSlice'
import uiReducer from '../../ui'

export const mainReducer = combineReducers({
  app: appSlice,
  news: newsReducer,
  seo: seoSlice,
  testimonials: testimonialsSlice,
  blog: blogSlice,
  article: articleSlice,
  sitemap: sitemapReducer,
  device: deviceReducer,
  feedbackForm: feedbackFormSlice,
  loanConditions: loanConditionsSlice,
  loanFormState: loanFormStateSlice,
  ui: uiReducer,
  notifications,
  abTest: abTestSlice,
  backgroundWithText: backgroundWithTextSlice,
  settings: settingsSlice
})
