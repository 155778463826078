import 'animate.css/animate.min.css'

import classnames from 'classnames'
import { ComponentType, CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react'

export interface IInnerProps {
  className?: string
  style?: CSSProperties
}

function withDelay<T extends IInnerProps>(
  wrappedComponent: ComponentType<Omit<T, keyof IInnerProps>>,
  delay = 750
): FunctionComponent<T> {
  const Component = wrappedComponent
  return function WithDelay(props): JSX.Element | null {
    const { className, style, ...otherProps } = props
    const [shouldRender, setShouldRender] = useState(false)
    const timerRef = useRef<TNullable<number>>(null)

    useEffect(() => {
      timerRef.current = window.setTimeout(() => setShouldRender(true), delay)
      return () => clearTimeout(timerRef.current as number)
    }, [])
    const classes = classnames({ 'animated fadeIn': true }, className)

    if (shouldRender) {
      return (
        <div className={classes} style={style}>
          <Component {...(otherProps as Omit<T, keyof IInnerProps>)} />
        </div>
      )
    }
    return null
  }
}

export default withDelay
