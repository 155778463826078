import { TFaqItem } from '#components/FAQ/types'

export const items: TFaqItem[] = [
  {
    title: 'Кто может обратиться за кредитными каникулами?',
    text:
      'Заёмщик, доход которого за 2 месяца, предшествующему месяцу обращения упал более чем на 30% по сравнению с его среднемесячным доходом за предыдущие 12 месяцев или если заемщик пострадал  от чрезвычайной ситуации в результате которой были нарушены его условия жизнедеятельности и утрачено имущество (353-ФЗ от 21.12.2013).</br></br>Также за кредитными каникулами могут обратиться <strong>участники СВО или члены их семей</strong> (377-ФЗ от 07.10.2022) — по договорам, которые оформлены до дня мобилизации, либо до дня участия лица в специальной военной операции, либо до дня подписания контракта.</br></br>Запрос на кредитные каникулы можно отправить службе клиентской поддержки в форме <a href="https://4slovo.ru/contact">обратной связи</a>. Укажите, по какой причине и на какой период хотите взять отсрочку, а также приложите при необходимости подтверждающие документы.'
  },
  {
    title: 'Кто относится к членам семьи участника СВО?',
    text:
      'Это супруга (супруг); несовершеннолетние дети; дети старше 18 лет, ставшие инвалидами до достижения ими возраста 18 лет; дети в возрасте до 23 лет, обучающиеся в образовательных организациях по очной форме обучения, и лица, находящиеся на иждивении военнослужащего.'
  },
  {
    title: 'В чём особенности кредитных каникул?',
    text:
      'Кредитные каникулы по 353-ФЗ дают по договорам, которые оформлены в любое время. Для <strong>участников СВО и членов их семей</strong> — по договорам, оформленным до дня мобилизации, либо дня участия лица в специальной военной операции, либо дня подписания контракта.</br></br>Во время отсрочки можно не погашать заём или оплачивать его по возможности. Но МФК продолжит начислять проценты. Их размер по 353-ФЗ - не меняется, то есть проценты продолжат начисляться полностью, по 377- ФЗ - 2/3 среднерыночной ставки ПСК для микрозаймов на дату обращения за каникулами, но для участников СВО начисленные проценты не подлежат уплате и по окончании кредитных каникул обязательства по их уплате прекращаются. По общим правилам долг (остаток займа + проценты в льготный период + начисленные до льготного периода проценты, пени и штрафы) придётся погасить по окончании каникул в соответствии с новым графиком платежей. Для договоров с одной датой платежа срок оплаты продлевается на время льготного периода.'
  },
  {
    title: 'Когда можно обратиться за кредитными каникулами?',
    text:
      'Подать запрос на кредитные каникулы по обстоятельствам, связанным со снижением дохода более чем на 30 % можно в любое время, а вот если заемщик пострадал от чрезвычайной ситуации, то в этом случае обратиться можно только в течение 60 дней со дня установления соответствующих фактов.</br></br>Участникам СВО или членам их семей подать запрос на кредитные каникулы можно до 31 декабря 2024 года включительно.</br></br> Взять отсрочку разрешено всего 1 раз, поэтому тщательно планируйте период и необходимость активации кредитных каникул именно сейчас. Брать каникулы по 353-ФЗ можно на срок до 6 месяцев. Участникам СВО и членам их семей отсрочку дают на срок мобилизации, срок участия в специальной военной операции или срок, на который был заключен контракт, увеличенные на 30 дней.</br></br>Длину кредитных каникул клиенты МФК «Честное слово» могут выбрать самостоятельно — в зависимости от своей ситуации.'
  },
  {
    title: 'Как подать заявку на кредитные каникулы?',
    text:
      'Проще всего оставить запрос на кредитные каникулы через службу клиентской поддержки в форме <a href="https://4slovo.ru/contact">обратной связи</a> или по электронной почте <a href="maito:info@4slovo.ru">info@4slovo.ru</a>.</br></br>Полный перечень способов оставить заявку на кредитные каникулы есть в памятках <a href="https://4slovo.ru/images/upload/files/7c57a72e2d40e630c792f7d48432faa9.pdf">Об условиях «кредитных каникул» для заемщиков находящихся в трудной жизненной ситуации</a> и <a href="https://4slovo.ru/images/upload/files/6b753b8505474c99176d22101978641a.pdf">Об условиях «кредитных каникул» для мобилизованных и участников специальной военной операции (СВО)</a>. '
  }
]
