import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'

import { CONSUMER_CREDIT_MONTH, CONSUMER_CREDIT_MONTH_SHORT } from '#constants/common'
import { updateLoanConditions } from '#reducers/loanConditions/effects'
import { TLoanConditionData } from '#reducers/loanConditions/types'
import {
  buildSlidersData,
  restoreLoanFormState,
  USE_DEFAULT_VALUES,
  USE_EXACT_VALUES
} from '#reducers/loanFormState/effects'
import { TLoanFormInitialState } from '#reducers/loanFormState/types'
import { TMainDataStateType } from '#src/reducers'

type TRestoreLoanFormStateProps = {
  loanConditions: TLoanConditionData
  loanFormState: TLoanFormInitialState
}

export const initialState: TLoanFormInitialState = {
  isPromoCodeVisible: false, // отображается ли промокод
  appliedPromoCode: null, // Тип примененного промокода
  isPromoBlocked: false, // Можно ли применить промокод в зависимости от выставленных параметров на слайдерах
  submitting: false, // Выполняется submit формы
  updating: false, // Выполняется обновление условий займа (например при применении промокода)
  fetching: false, // Выполняется первичный запрос на условия займа или восстановление условий при регистрации
  amount: { min: 0, max: 0, step: 0, indexList: [] }, // Параметры amount слайдера
  term: { min: 0, max: 0, step: 0, indexList: [] }, // Параметры term слайдера
  index: null, // Индекс условий займа
  // Объект data для компонента Form
  data: {
    creditType: '', // Тип выбранного продукта
    term: 0, // Значение term слайдера у текущий момент
    amount: 0, // Значение amount слайдера у текущий момент
    promoCode: null, // Значение input поля для ввода промокода в текущий момент
    promoType: null, // tid примененного промокода (3 - FreeDays со своими доп условиями, 13 - FreeDaysInMaxPeriod)
    clientAccount: null // id personAccount в форме взятия нового займа в НЛК
  },
  // Объект с ошибками (обрабатываются в форме)
  errors: { common: '', critical: '' },
  dimension: 'day', // размерность дни или месяцы
  tkbAgreementValue: 0
}

const loanFormStateSlice = createSlice({
  name: 'loanFormState',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    setLoanFormState: (state, action) => merge(state, action.payload),
    updateLoanConditionsSliderData: (state, { payload }: PayloadAction<TLoanConditionData>) =>
      merge(
        state,
        buildSlidersData({
          loanConditionsSliceState: payload,
          loanFormSliceState: state,
          mode: USE_DEFAULT_VALUES
        })
      ),
    fetchLoanConditionsSuccess: (state, { payload }: PayloadAction<TLoanConditionData>) => {
      merge(
        state,
        { fetching: false, updating: false },
        buildSlidersData({
          loanConditionsSliceState: payload,
          loanFormSliceState: state,
          mode: USE_DEFAULT_VALUES
        })
      )
    },
    restoreLoanFormStateSuccess: (state, { payload }: PayloadAction<TRestoreLoanFormStateProps>) =>
      merge(
        state,
        { fetching: false, updating: false },
        buildSlidersData({
          loanConditionsSliceState: payload.loanConditions,
          loanFormSliceState: payload.loanFormState,
          mode: USE_EXACT_VALUES
        })
      ),
    updateLoanConditionsSuccess: (state) => {
      state.fetching = false
      state.updating = false
    },
    setFetchingRequest: (state) => {
      state.fetching = true
    },
    setTkbInsuranceAgreementValue: (state, { payload }: PayloadAction<number>) => {
      state.tkbAgreementValue = payload
    },
    clearClientAccountIfRequired: (state, action) => {
      const { data: { clientAccount } = {} } = state
      if (Number(clientAccount) === Number(action.payload)) state.data.clientAccount = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(restoreLoanFormState.rejected, (state) => {
        state.fetching = false
        state.updating = false
      })
      .addCase(updateLoanConditions.pending, (state) => {
        state.updating = true
      })
      .addMatcher(
        isAnyOf(updateLoanConditions.fulfilled, updateLoanConditions.rejected),
        (state) => {
          state.fetching = false
          state.updating = false
        }
      )
  }
})

export const {
  setLoanFormState,
  updateLoanConditionsSliderData,
  restoreLoanFormStateSuccess,
  updateLoanConditionsSuccess,
  fetchLoanConditionsSuccess,
  setFetchingRequest,
  setTkbInsuranceAgreementValue,
  clearClientAccountIfRequired
} = loanFormStateSlice.actions

export default loanFormStateSlice.reducer

export const loanFormStateSelector = (state: TMainDataStateType): TLoanFormInitialState =>
  state.loanFormState
// является ли займ tkb продуктом в брокерском кабинете
export const isTkbForPartnerSelector = createSelector(loanFormStateSelector, (loanFormState) => {
  const {
    data: { creditType }
  } = loanFormState
  return [CONSUMER_CREDIT_MONTH, CONSUMER_CREDIT_MONTH_SHORT].includes(creditType)
})
