import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined'
import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'

import { Button } from '#components/Button/Button'
import { scrollTo } from '#services/helper'

const DEBOUNCE_TIME = 500
const SCROLLED = 300

const ScrollButton: FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisible = debounce(() => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > SCROLLED) setIsVisible(true)
    else if (scrolled <= SCROLLED) setIsVisible(false)
  }, DEBOUNCE_TIME)

  const scrollToTop = useCallback(() => {
    scrollTo(0)
  }, [])

  const styleButton = {
    display: isVisible ? 'inline' : 'none',
    position: 'fixed',
    left: '84%',
    bottom: '40px',
    zIndex: '1',
    cursor: 'pointer'
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible)
    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  }, [toggleVisible])

  return (
    <Button
      className='btn-scroll'
      size='l'
      onClick={scrollToTop}
      data-qa='scrollToTop'
      style={styleButton}
    >
      <ArrowUpwardOutlined />
    </Button>
  )
}

export default ScrollButton
