import './form.scss'

import loadable from '@loadable/component'
import { ChangeEvent, Component, CSSProperties, FocusEvent, FormEvent } from 'react'
import { FileWithPreview } from 'react-dropzone'
import { RawEditorSettings } from 'tinymce'

import { AbstractForm } from '#components/Form/AbstractForm'
import { IFormItemProps } from '#components/Form/FormItem'
import { TSMartControlFocusEvent, TTypes } from '#components/SmartControl/SmartControl'
import { TAddFileEvent } from '#components/SmartControl/SmartControlBase'
import appConfig from '#config'
import intl from '#intl'
import {
  TItemWrapper,
  TValidationResult,
  validateItem as validateItemHelper
} from '#services/validator'

const SmartControl = loadable(() => import('#components/SmartControl'))
const FormItem = loadable(() => import('#components/Form/FormItem'), {
  resolveComponent: (component) => component.FormItem
})

export type TObjectValue<T> = {
  [key: string]: T
}
export interface IFormProps {
  loan?: TObjectValue<string | number>
}

export type TErrorObject = {
  [key in TErrorsKey]?: string | null
}

export type TErrorsKey<T = Record<string, unknown>> = keyof T | 'common'

export type TDataValues = string | number | Blob | null | boolean | File | File[] | Date

export type TState = {
  data: TObjectValue<TDataValues>
  errors: TErrorObject
  options: TObjectValue<string | number>
  suggestions: TObjectValue<string | number>
  icons: TObjectValue<string | number>
  notes: TObjectValue<string | number>
  disabledItems: TObjectValue<string | number>
  loadingItems: TObjectValue<string | number>
  form: TFormModel
  fileBlob: FileWithPreview | null
  filePreview?: string | null
  valid: boolean
  validationApiErrors: any
  loading: boolean
}

export type TParentState = {
  valid?: boolean
  validationApiErrors?: any
  loading?: boolean
}

type TCounter = {
  counterId?: string
}
type TAppConfig = typeof appConfig & TCounter

export type TSelectOption = {
  disableReason?: TNullable<string>
  enabled?: TNullable<number>
  text: string
  value: string | number
}

export type TModelItem = {
  bottomText?: string | JSX.Element
  default?: string | number
  depends?: string
  elementType?: TTypes
  type?: TTypes
  emptyMessage?: string
  googleId?: string | number
  label?: string
  line: number
  mask?: string | boolean
  options?: TSelectOption[]
  orderId?: number
  placeholder?: string
  required?: number | boolean
  service?: string
  step?: number
  systemName?: string
  name?: string
  validationMessageError?: string
  amountLimitWithoutDocuments?: number
  message?: string
  warning?: boolean
  validationRule?: string
  yandexId?: string
  multiple?: boolean
  accept?: string
  hint?: string | JSX.Element
  dataQa?: string
  init?: RawEditorSettings
  disabled?: boolean
  style?: CSSProperties
  ruleMaxLength?: number
  autoComplete?: boolean
  noWhiteSpaces?: boolean
  maxLength?: number
  child?: TModelItem[]
  icon?: string
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url'
}

export type TFormLine = {
  items: TModelItem[]
}

export type TFormModel = {
  lines: TFormLine[]
}

type TResponseWithoutData = {
  code: number
  message: string
}

export interface IResponse<T> {
  code: number
  message: string
  data: T
}

type TReturnCheckResponseCode<T> = {
  errors: {
    [key in keyof T | 'common']?: string
  }
}

type TItemResponseErrors = Partial<TObjectValue<string>>

// eslint-disable-next-line @typescript-eslint/ban-types
export class Form<P, S extends Partial<TState>>
  extends Component<P & IFormProps, S & TState>
  implements AbstractForm {
  errorCodes: number[]
  appConfig: TAppConfig
  model: TModelItem[] | undefined

  constructor(props: P & IFormProps) {
    super(props)
    this.state = {
      ...this.state,
      data: {},
      errors: {},
      options: {},
      suggestions: {},
      icons: {},
      notes: {},
      disabledItems: {},
      loadingItems: {},
      form: this.computeForm()
    }
    this.appConfig = appConfig
    this.errorCodes = [1, 2, 3, 5, 6, 429]
    this.checkDepends = this.checkDepends.bind(this)
    this.checkResponseCode = this.checkResponseCode.bind(this)
    this.getItemResponseErrors = this.getItemResponseErrors.bind(this)
    this.validateItem = this.validateItem.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleControlFocus = this.handleControlFocus.bind(this)
    this.handleControlChange = this.handleControlChange.bind(this)
    this.handleControlBlur = this.handleControlBlur.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleResponse = this.handleResponse.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.computeForm = this.computeForm.bind(this)
  }

  computeForm(): TFormModel {
    const form: TFormModel = { lines: [] }
    if (this.model) {
      this.model.forEach((item) => {
        const line = item.line - 1

        form.lines[line] = form.lines[line] || { items: [] }
        form.lines[line].items.push(item)
      })
    }
    return form
  }

  checkDepends(item: TModelItem): boolean {
    const { data } = this.state
    const depends = (item.depends || '').split('|')
    for (const depend of depends) {
      const dependValue = depend.split(':')
      if (data && !new RegExp(dependValue[1]).test(data[dependValue[0]] as string)) return true
    }
    return false
  }

  checkResponseCode<T>(response: IResponse<T> | TResponseWithoutData): TReturnCheckResponseCode<T> {
    let responseErrors = {}

    if (!response || response.code === 1) {
      responseErrors = { ...responseErrors, common: intl.serverError }
    } else if ([3, 5, 6, 429].includes(response.code)) {
      const { data = {} } = response as IResponse<T>
      responseErrors = { ...responseErrors, ...data }

      if (!Object.keys(responseErrors).length)
        responseErrors = { ...responseErrors, common: response.message || intl.serverError }
    }

    return { errors: { ...responseErrors } }
  }

  getItemResponseErrors<T>(name: string, response: IResponse<T>): TItemResponseErrors {
    let errors = {}
    if (!response || typeof response.code === 'undefined') {
      errors = { ...errors, [name]: intl.serverError }
    } else if (response.code === 1) {
      const message = this.appConfig.env === 'dev' ? response.message : intl.serverError
      errors = { ...errors, [name]: message }
    } else if ([3, 5, 6, 429, 503].includes(response.code)) {
      errors = response.data || {}

      if (!Object.keys(errors).length) errors = { [name]: response.message || intl.serverError }
    }

    return errors
  }

  getItemValue(item: TItemWrapper<TModelItem>): NonNullable<TDataValues> {
    const { data } = this.state
    const { loan } = this.props
    const itemName = item.name as string
    if (item.value) return item.value
    if (data?.[itemName]) return data[itemName] as NonNullable<TDataValues>
    if (loan?.[itemName]) return loan[itemName]
    return ''
  }

  validateItem(_item: TItemWrapper<TModelItem>, showError = true): TValidationResult {
    const { errors: stateErrors } = this.state
    let validateErrors = {}
    const item = {
      ..._item,
      value: this.getItemValue(_item),
      type: _item.type || _item.elementType
    }
    const itemName = item.name as string

    if (item.type === 'checkbox') item.value = Number(item.value)
    const { isValid, validationErrorMessage } = validateItemHelper(item)
    if (!isValid) validateErrors = { ...validateErrors, [itemName]: validationErrorMessage }
    else validateErrors = { ...validateErrors, [itemName]: null }

    showError && this.setState({ errors: { ...stateErrors, ...validateErrors } })

    return { isValid, validationErrorMessage }
  }

  getItemName = (item: TModelItem): string => (item.name || item.systemName) as string

  validateForm(showErrors = false): boolean {
    let formErrors = {}
    let isFormValid = true

    this.model?.map((_item) => {
      const item = { ..._item }
      if (item?.child) {
        item.child.forEach((el) => {
          const elName = el.name as string
          if (!document.getElementById(this.getItemName(el))) return
          const { isValid, validationErrorMessage } = this.validateItem(el, showErrors)
          if (!isValid) {
            isFormValid = false
            formErrors = { ...formErrors, [elName]: validationErrorMessage }
          }
        })
      }
      item.name = this.getItemName(item)
      if (!document.getElementById(this.getItemName(item))) return
      const { isValid, validationErrorMessage } = this.validateItem(item, showErrors)
      if (!isValid) {
        isFormValid = false
        formErrors = { ...formErrors, [item.name]: validationErrorMessage }
      }
    })

    if (showErrors) this.setState({ valid: isFormValid, errors: { ...formErrors } })
    else this.setState({ valid: isFormValid })

    return isFormValid
  }

  canCollectData = (name: string): boolean => {
    const { data } = this.state
    return Boolean(data?.[name] && data[name] !== null)
  }

  collectData(): FormData {
    const { data } = this.state
    const fd = new FormData()

    this.model?.map((item) => {
      const name = (item.name || item.systemName) as string
      if (data && this.canCollectData(name)) fd.append(name, data[name] as string | Blob)
    })

    return fd
  }

  collectFromStateData(): FormData {
    const { data } = this.state
    const fd = new FormData()

    for (const prop in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data && data.hasOwnProperty(prop) && this.canCollectData(prop))
        fd.append(prop, data[prop] as string | Blob)
    }
    return fd
  }

  handleControlFocus({ target }: TSMartControlFocusEvent): void {
    const { name } = target as HTMLInputElement
    this.setState((state) => ({ errors: { ...state.errors, [name]: null } }))
  }

  parseBik(string: string): string | number {
    const parsedString = parseInt(string, 10)
    if (string.charAt(0) === '0' && parsedString !== 0) return `0${parsedString}`

    return parsedString
  }

  handleControlChange({ target }: ChangeEvent): void {
    const { value: inputValue, name } = target as HTMLInputElement
    const value = name === 'billBic' ? this.parseBik(inputValue) : inputValue
    this.setState((state) => ({ data: { ...state.data, [name]: value } }))
  }

  handleControlBlur({ type, target }: FocusEvent): void {
    const { name } = target as HTMLInputElement
    const item = this.model?.find((item) => (item.name || item.systemName) === name) as TModelItem
    setTimeout(() => {
      const { data } = this.state
      // результат валидации не важен, главное его исполнение
      data?.[name] && type !== 'date' && this.validateItem({ ...item, name })
    }, 1)
  }

  handleSubmit(event?: FormEvent): void {
    event && event.preventDefault()
  }

  handleResponse<T>(
    response: IResponse<T> | TResponseWithoutData
  ): IResponse<T> | TResponseWithoutData {
    this.setState({ loading: false })
    const responseCheckedResult = this.checkResponseCode(response)

    if (this.errorCodes.includes(response.code)) {
      this.setState({
        validationApiErrors: {
          ...responseCheckedResult.errors
        },
        ...responseCheckedResult
      })
    }

    return response
  }

  handleFileChange({ target }: TAddFileEvent): void {
    const { value } = target
    this.setState((state) => ({
      ...state,
      data: { ...state.data, file: value[0] },
      fileBlob: value[0],
      filePreview: URL.createObjectURL(value[0])
    }))
  }

  renderCommonError = (): JSX.Element | null => {
    const { errors } = this.state
    if (errors?.common) return <div className='form__error'>{errors.common}</div>
    return null
  }

  renderItem(item: TModelItem): JSX.Element {
    const { data, errors } = this.state
    const itemName = this.getItemName(item)
    const value = data?.[itemName] as string | number
    return (
      <FormItem
        key={itemName}
        {...(item as IFormItemProps & TModelItem)}
        error={errors?.[itemName]}
      >
        <SmartControl
          {...item}
          name={itemName}
          value={value}
          valid={!errors?.[itemName]}
          onFilesAdd={this.handleFileChange.bind(this)}
          onFocus={this.handleControlFocus.bind(this)}
          onBlur={this.handleControlBlur.bind(this)}
          onChange={this.handleControlChange.bind(this)}
        />
      </FormItem>
    )
  }
}
