import { startOfMonth, subMonths } from 'date-fns'

import Icon from '#components/Icon'
import { STATUS_APPLIED, STATUS_REFUSED } from '#constants/common'
import intl from '#intl'
import { formatDate, now } from '#services/datetime'

const monthBefore = formatDate(startOfMonth(subMonths(now(), 1)), 'yyyy-MM-dd')
const today = formatDate(now(), 'yyyy-MM-dd')

const renderExpander = ({ row }) => (
  <span {...row.getToggleRowExpandedProps()}>
    <Icon className='partner-page__bids-item-more' name='more' />
  </span>
)

export const partnerDataDefaultState = {
  loading: true,
  className: '',
  title: '',
  filters: [
    'treatment',
    'period',
    'periodStart',
    'periodEnd',
    'query',
    'dateFrom',
    'dateTo',
    'onlyNew',
    'groupingType'
  ],
  requestRules: {
    bids: {
      periodStart: today,
      periodEnd: today,
      treatment: '0'
    },
    accounts: {
      periodStart: today,
      periodEnd: today
    },
    statistics: {
      dateFrom: monthBefore,
      dateTo: today,
      onlyNew: 0,
      groupingType: 'by_month'
    },
    loans: {
      createDateFrom: today,
      createDateTo: today,
      status: '-1,2,0,100,3,4',
      limit: 300,
      offset: 0
    }
  },
  columns: [
    {
      width: 30,
      Cell: renderExpander,
      id: 'expander'
    },
    { Header: '№', accessor: 'personId', width: 80 },
    { Header: intl.fio, accessor: 'fio', width: 245 },
    { Header: intl.phone, accessor: 'phoneNumber', width: 136 },
    {
      Header: intl.amount,
      accessor: 'sum',
      width: 59,
      sortMethod: (a, b) => Number(a) - Number(b)
    },
    { Header: intl.term, accessor: 'period', width: 50 },
    {
      Header: intl.processingStatus,
      width: 130,
      id: 'treatment',
      accessor: (item) => {
        if (Number(item.treatment) === Number(STATUS_APPLIED))
          return <span className='color-green'>{intl.accepted}</span>
        if (Number(item.treatment) === Number(STATUS_REFUSED))
          return <span className='color-red'>{intl.refused}</span>

        return intl.notProcessed
      }
    }
  ],
  data: [],
  chart: [],
  info: {},
  menuOpened: false,
  errorMessage: ''
}
