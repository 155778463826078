import { useEffect, useState } from 'react'

export const Timer = ({ initialSeconds }: { initialSeconds: number }) => {
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) setSeconds(seconds - 1)

      if (seconds === 0) clearInterval(myInterval)
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return <span>{seconds > 0 ? seconds : ''}</span>
}
