import { createAction, createSlice } from '@reduxjs/toolkit'

import { IUnauthorized } from '#modules/api/types/common'
import { CreatePrefix } from '#reducers/helper'

interface IOtherCriticalError {
  code: number
  message: string
}
type TCriticalError = IUnauthorized | IOtherCriticalError | null

export interface ICriticalErrorState {
  data: TCriticalError
}

const initialState: ICriticalErrorState = {
  data: null
}

const prefixHelper = new CreatePrefix('criticalError')

export const dispatchCriticalError = createAction<TCriticalError>(
  prefixHelper.create('dispatchCriticalError')
)

const criticalErrorSlice = createSlice({
  name: 'criticalError',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(dispatchCriticalError, (state, action) => {
      state.data = action.payload
    })
})

export default criticalErrorSlice.reducer
