import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { FileWithPreview } from 'react-dropzone'
import { NavigateFunction } from 'react-router-dom'

import { adminApi } from '#modules/api'
import { ResponseCode } from '#modules/api/types/common'
import {
  flushNotificationForm,
  saveBannerWithToken
} from '#reducers/adminPage/personNotification/notificationsSlice'
import { getErrorMessage } from '#reducers/helper'
import { closeModal } from '#reducers/ui/modalSlice'
import * as AdminApiTypes from '#src/modules/api/admin/types'
import { TMainDataStateType } from '#src/reducers'

type TNotificationInitialState = {
  isLoading: boolean
  error: string
}

type TEditNotificationArgs = {
  data: FormData
  token: string
  navigate: NavigateFunction
  file?: FileWithPreview | null
}

type TDeleteNotificationArgs = {
  notification: AdminApiTypes.TNotificationListItem
  token: TNullable<string>
}

const initialState: TNotificationInitialState = {
  isLoading: false,
  error: ''
}

export const editNotification = createAsyncThunk<
  AdminApiTypes.TNotificationListItem,
  TEditNotificationArgs,
  { rejectValue: { message: string } }
>(
  'personNotification/edit',
  async ({ data, token, navigate, file }, { rejectWithValue, dispatch }) => {
    /**
     * Блок для добавления файла
     */
    if (file) {
      const fileName = await saveBannerWithToken(token, file)
      data.append('desktopImage', fileName)
      data.append('tabletImage', fileName)
      data.append('mobileImage', fileName)
    }

    const response = await adminApi.updateNotification(data)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return rejectWithValue({
        message
      })
    }
    dispatch(flushNotificationForm())
    navigate('/admin/personal-notifications')
    return response.data
  }
)

export const deleteNotification = createAsyncThunk<
  AdminApiTypes.TNotificationListItem,
  TDeleteNotificationArgs
>('personNotification/delete', async ({ notification, token }, { rejectWithValue, dispatch }) => {
  const formData = new FormData()
  formData.append('id', String(notification.id))
  token && formData.append('token', token)
  const response = await adminApi.deleteNotification(formData)
  if (response.code !== ResponseCode.success) {
    const { message } = response
    return rejectWithValue({
      message
    })
  }
  dispatch(closeModal())
  return { ...notification, active: false }
})

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(closeModal, (state) => {
        state.isLoading = false
        state.error = ''
      })
      .addMatcher(isAnyOf(editNotification.pending, deleteNotification.pending), (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isAnyOf(editNotification.fulfilled, deleteNotification.fulfilled), (state) => {
        state.isLoading = false
      })
      .addMatcher(
        isAnyOf(editNotification.rejected, deleteNotification.rejected),
        (state, action) => {
          state.isLoading = false
          state.error = getErrorMessage(action)
        }
      )
  }
})

export default notificationSlice.reducer

export const notificationItemSelector = (state: TMainDataStateType): TNotificationInitialState =>
  state.adminPage.notification.notification
