import './smart-control.scss'

import loadable from '@loadable/component'
import { CSSProperties, FocusEvent } from 'react'

import { TModelItem } from '#components/Form/Form'
import { TCheckboxProps } from '#components/SmartControl/SmartControlCheckbox'
import { TSmartControlColorPickerProps } from '#components/SmartControl/SmartControlColorPicker'

import {
  ISmartControlBaseState,
  SmartControlBase,
  TSmartControlBaseProps,
  TSmartControlInputProps
} from './SmartControlBase'
import { TSmartControlRichTextProps } from './SmartControlRichText'

const SmartControlRichText = loadable(() => import('./SmartControlRichText'), {
  resolveComponent: (component) => component.SmartControlRichText
})
const SmartControlTextarea = loadable(() => import('./SmartControlTextarea'), {
  resolveComponent: (component) => component.SmartControlTextarea
})
const SmartControlButtonGroup = loadable(() => import('./SmartControlButtonGroup'), {
  resolveComponent: (component) => component.SmartControlButtonGroup
})
const SmartControlCheckbox = loadable(() => import('./SmartControlCheckbox'), {
  resolveComponent: (component) => component.SmartControlCheckbox
})
const SmartControlDate = loadable(() => import('./SmartControlDate'), {
  resolveComponent: (component) => component.SmartControlDate
})
const SmartControlInput = loadable(() => import('./SmartControlInput'), {
  resolveComponent: (component) => component.SmartControlInput
})
const SmartControlInputAsync = loadable(
  () => import(/* webpackChunkName: "SmartControlInputAsync" */ './SmartControlInputAsync')
)
const SmartControlReactSelect = loadable(
  () => import(/* webpackChunkName: "SmartControlReactSelect" */ './SmartControlReactSelect')
)
const SmartControlFile = loadable(
  () => import(/* webpackChunkName: "SmartControlFile" */ './SmartControlFile')
)
const SmartControlRange = loadable(
  () => import(/* webpackChunkName: "SmartControlRange" */ './SmartControlRange')
)
const SmartControlColorPicker = loadable(
  () => import(/* webpackChunkName: "SmartControlColorPicker" */ './SmartControlColorPicker')
)
const SmartControlLink = loadable(
  () => import(/* webpackChunkName: "SmartControlLink" */ './SmartControlLink')
)

type TCreatedCustomEvent = { target: { name: string } }

export type TSMartControlFocusEvent = FocusEvent | TCreatedCustomEvent
export type TSmartControlReactSelectProps = TModelItem & {
  id: number | string
  name: string
  disabled: boolean
  multiple: boolean
  isSearchable: boolean
  style: CSSProperties
  valid: boolean
  noOptionsMessage?: JSX.Element | string | null
  formatOptionLabel?: JSX.Element | string | null
  type: 'reactSelect' | 'select'
  maxMenuHeight?: number
}
export type TTypes =
  | 'text'
  | 'password'
  | 'textarea'
  | 'select'
  | 'asyncSelect'
  | 'email'
  | 'number'
  | 'hidden'
  | 'tel'
  | 'date'
  | 'checkbox'
  | 'radio'
  | 'file'
  | 'range'
  | 'button'
  | 'button-group'
  | 'reactSelect'
  | 'link'
  | 'richText'
  | 'color'
  | 'datePicker'
  | 'calendarPicker'
  | 'dateTimePicker'
  | 'recaptcha'
  | 'html'

interface ISmartControl {
  id?: number | string
  name: string
  type?: TTypes
  noOptionsMessage?: JSX.Element
}

type TInnerSmartControlProps = ISmartControl & TSmartControlBaseProps

export default class SmartControl extends SmartControlBase<
  TInnerSmartControlProps,
  ISmartControlBaseState
> {
  render(): JSX.Element {
    const { type, id, name } = this.props
    const props = { ...this.props, id: id || name }

    switch (type) {
      case 'button-group':
        return <SmartControlButtonGroup {...props} />
      case 'checkbox':
        return <SmartControlCheckbox {...(props as TCheckboxProps)} />
      case 'date':
        return <SmartControlDate {...props} />
      case 'file':
        return <SmartControlFile {...props} />
      case 'range':
        return <SmartControlRange {...props} />
      case 'select':
        return <SmartControlReactSelect {...props} />
      case 'reactSelect': {
        const reactSelectProps = props as TSmartControlReactSelectProps
        return <SmartControlReactSelect {...reactSelectProps} />
      }
      case 'textarea':
        return <SmartControlTextarea {...props} />
      case 'link':
        return <SmartControlLink {...props} />
      case 'asyncSelect':
        return <SmartControlInputAsync {...props} />
      case 'richText': {
        const richTextProps = props as TSmartControlRichTextProps
        return <SmartControlRichText {...richTextProps} />
      }
      case 'color': {
        const colorPickerProps = props as TSmartControlColorPickerProps
        return <SmartControlColorPicker {...colorPickerProps} />
      }
      default: {
        const inputProps = props as TSmartControlInputProps
        return <SmartControlInput {...inputProps} />
      }
    }
  }
}
