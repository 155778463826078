import appConfig from '#config'
import { ROUTES } from '#constants/common'
import intl from '#intl'

type TBaseRouteItem = {
  text: string
  icon: TNullable<string>
}
type TExternalRouteItem = TBaseRouteItem & {
  pathname: string
}
type TInternalRouteItem = TBaseRouteItem & {
  url: string
  subPaths?: (TBaseRouteItem & { url: string })[]
  order: number
}
export const external: TExternalRouteItem[] = [
  { pathname: ROUTES.about, text: intl.aboutUs, icon: null },
  { pathname: ROUTES.howTakeLoan, text: intl.howTakeLoan, icon: null },
  { pathname: ROUTES.howReturnLoan, text: intl.howReturnLoan, icon: null },
  { pathname: ROUTES.faq, text: intl.faq, icon: null },
  { pathname: ROUTES.privileges, text: intl.privileges, icon: null }
].map((item) => ({ ...item, url: new URL(item.pathname, appConfig.host).href }))

export const internal: TInternalRouteItem[] = [
  { order: 0, url: ROUTES.main, text: intl.takeLoan, icon: 'rotate-left' },
  { order: 1, url: ROUTES.history, text: intl.history, icon: 'calendar' },
  {
    order: 2,
    url: ROUTES.requisites,
    text: intl.requisites,
    icon: 'card',
    subPaths: [
      { url: `${ROUTES.requisites}/checkStatus`, text: intl.requisites, icon: 'card' },
      {
        url: `${ROUTES.requisites}/checkStatus/\\d+`,
        text: intl.requisites,
        icon: 'card'
      }
    ]
  },
  { order: 3, url: ROUTES.feedback, text: intl.feedback, icon: 'chat' },
  // Роут временно отключен
  // { url: ROUTES.notifications, text: intl.notifications, icon: 'bell' },
  { order: 4, url: ROUTES.profile, text: intl.myProfile, icon: 'user' },
  { order: 5, url: ROUTES.services, text: intl.myServices, icon: 'services' },
  { order: 6, url: ROUTES.remove, text: intl.removePersonalAccount, icon: 'user_delete' }
  // Роут временно отключен
  // { url: ROUTES.friends, text: intl.takeBonus, icon: 'handshake' }
]

export const system = [{ url: ROUTES.info }, { url: ROUTES.special }]
