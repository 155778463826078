import axios from 'axios'

import { fiasApi } from '#src/modules/api'

let cancel

export const getSuggestions = async (query, options, params = {}) => {
  if (cancel) {
    const response = { message: 'Одновременно может быть отправлен только один запрос.', code: 22 }
    cancel(response)
    cancel = null
  }
  const mutableOptions = options.split(':')
  const [firstOption] = mutableOptions
  let path = firstOption

  if (path === 'city') {
    path = 'cityWithState'
    params.placename = query
  }
  if (path === 'street') params.name = query
  const cancelToken = new axios.CancelToken((c) => {
    cancel = c
  })
  try {
    const response = await fiasApi.get(path, {
      params,
      cancelToken
    })
    const mutableData = response.data || {}
    mutableData.data = (mutableData[firstOption] || []).map((item) => ({
      ...item,
      value: item.name,
      text: item.fullname
    }))
    delete mutableData[firstOption]
    return mutableData
  } catch (err) {
    if (!axios.isCancel(err)) throw err
  }
}

export const restoreFullName = (params) => fiasApi.get('/fullName', { params })

export const restoreName = (params) => fiasApi.get('/name', { params })

export const getCitySuggestions = (params) => {
  if (process.env.__USE_FAKE_FIAS__ === '1') {
    const fakeData = {
      data: {
        city: [
          { fullname: 'г Москва', aoguid: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5', name: 'Москва' },
          {
            fullname: 'д Москвинка (обл Курская, р-н Черемисиновский)',
            aoguid: '969b5cba-5d45-4fdf-a9a4-a226a1e0e863',
            name: 'Москвинка'
          },
          {
            fullname: 'п Новая Москва (обл Брянская, р-н Красногорский)',
            aoguid: 'dd192381-0f19-4809-93b5-ec13e76c9da5',
            name: 'Новая Москва'
          },
          {
            fullname: 'д Москвята (обл Кировская, р-н Нагорский)',
            aoguid: 'eb9aecea-4b0f-4b46-bb0e-1383b34c6a7f',
            name: 'Москвята'
          },
          {
            fullname: 'д Москвина (обл Челябинская, р-н Каслинский)',
            aoguid: '09897b7f-d740-488a-9f55-63933ae8425e',
            name: 'Москвина'
          },
          {
            fullname: 'д Москвин Починок (обл Вологодская, р-н Великоустюгский)',
            aoguid: 'd36d91dd-68be-49b4-8d4e-840e96ea007a',
            name: 'Москвин Починок'
          },
          {
            fullname: 'д Москвино (обл Нижегородская, р-н Шарангский)',
            aoguid: '5102d10b-f5e0-4d2d-b6c9-be5212227e37',
            name: 'Москвино'
          },
          {
            fullname: 'п Москвинка (облНовосибирская, р-н Каргатский)',
            aoguid: 'c3e00b02-e810-4efb-832b-8fb144dee331',
            name: 'Москвинка'
          },
          {
            fullname: 'д Москвино (обл Вологодская, р-н Вашкинский)',
            aoguid: '0fdd917e-ef74-4361-bfe7-f6b8f639825d',
            name: 'Москвино'
          },
          {
            fullname: 'п Новая Москва (обл Нижегородская, р-н Лукояновский)',
            aoguid: '3890e46f-90d9-4f7c-88ac-944d3f656dac',
            name: 'Новая Москва'
          }
        ],
        code: 0,
        message: 'Success'
      }
    }
    return new Promise((resolve) => {
      resolve(fakeData)
    })
  }

  return fiasApi.get('/cityWithState', { params })
}
