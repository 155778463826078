import { TModelItem } from '#components/Form/Form'
import intl from '#intl'

export const subscribeForm: TModelItem[] = [
  {
    line: 1,
    name: 'email',
    type: 'email',
    placeholder: intl.yourEmail,
    required: true,
    emptyMessage: intl.fieldEmpty,
    validationRule: '^[A-Za-z0-9]+([-.-_][A-Za-z0-9]+)*@\\w+([.-]?w+)*(.\\w{2,16})+$',
    validationMessageError: intl.fieldInvalid
  },
  {
    name: 'privateAgreement',
    bottomText: '',
    type: 'checkbox',
    emptyMessage: 'Поле обязательно для заполнения',
    label:
      "Хочу получать рассылки о скидках и акциях,<span class='link js-modal-link' data-document='email_person_data_agreement'> и соглашаюсь на получение рекламы</span>",
    line: 2,
    mask: '1',
    required: 1,
    validationMessageError: 'Введите правильное значение',
    validationRule: '^1$'
  }
]
