import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { getClientData } from '#reducers/clientData/selectors'
import { CreatePrefix } from '#reducers/helper'

export interface INotificationsInitialState {
  loading: boolean
  data: any[]
}

const initialState: INotificationsInitialState = {
  loading: false,
  data: []
}

const prefixHelper = new CreatePrefix('notification')

export const fetchClientNotificationsRequest = createAction(
  prefixHelper.create('fetchClientNotificationsRequest')
)

export const fetchClientNotificationsSuccess = createAction<{ data: [] }>(
  prefixHelper.create('fetchClientNotificationsSuccess')
)

export const fetchClientNotificationsFailure = createAction(
  prefixHelper.create('fetchClientNotificationsFailure')
)

const getNotificationsStub = (_token: string): Promise<{ code: number; data: [] }> =>
  new Promise((resolve) => {
    resolve({
      code: 0,
      data: []
    })
  })

export const fetchClientNotifications = createAsyncThunk(
  prefixHelper.create('notification/fetchClientNotifications'),
  async ({ token }: { token: string }, thunkAPI) => {
    thunkAPI.dispatch(fetchClientNotificationsRequest())
    try {
      // Заглушка
      const response = await getNotificationsStub(token)
      if (response.code !== 0) throw response
      thunkAPI.dispatch(fetchClientNotificationsSuccess(response))
    } catch (err) {
      thunkAPI.dispatch(fetchClientNotificationsFailure())
    }
  }
)

const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchClientNotificationsRequest, (state) => {
        state.loading = true
      })
      .addCase(fetchClientNotificationsSuccess, (state, action) => {
        state.data = action.payload.data
        state.loading = false
      })
      .addCase(fetchClientNotificationsFailure, (state) => {
        state.loading = false
      })
})

export const notificationsSelector = createSelector(
  getClientData,
  (clientData) => clientData.notifications.data as unknown
)

export default notificationsSlice.reducer
