import './FlashMessage.scss'

import classnames from 'classnames'
import { isEmpty, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useRef } from 'react'

import { useIsomorphicLayoutEffect } from '#hook/useIsomorphicLayoutEffect'
import { animateCSS } from '#services/helper'
import { nl2br } from '#src/services/helper'

import Icon from '../Icon'

const Content = (props) => {
  const { message, alarmIcon } = props
  if (typeof message === 'string') {
    return (
      <div
        className={classnames({ 'flash__alarm-message pe-2': true, 'ms-3': !alarmIcon })}
        style={{
          maxWidth: '90%',
          wordBreak: 'break-word'
        }}
        dangerouslySetInnerHTML={{ __html: nl2br(message) }}
      />
    )
  }
  if (typeof message === 'function') return <>{message()}</>
  return message
}
const FlashMessage = (props) => {
  const {
    message,
    type,
    onClose,
    className,
    dismissible,
    alarmIcon,
    style,
    animateIn,
    animateOut,
    timeout
  } = props

  const innerRef = useRef(null)
  const timerId = useRef(null)

  useIsomorphicLayoutEffect(() => {
    !isEmpty(animateIn) && animateCSS(innerRef.current, animateIn)
    if (timeout > 0) timerId.current = setTimeout(handleClose, timeout)
  })

  const handleClose = () => {
    clearTimeout(timerId.current)
    if (isEmpty(animateOut)) {
      onClose()
      return
    }
    animateCSS(innerRef.current, animateOut, onClose)
  }

  const cn = classnames(
    {
      flash__container: true,
      'flash__container-success': type === 'success',
      'flash__container-danger': type === 'danger',
      'flash__container-info': type === 'info'
    },
    className
  )

  return (
    <div className={cn} style={style} ref={innerRef}>
      {alarmIcon && <Icon name='alarm' className='flash__alarm-icon' />}
      <Content alarmIcon={alarmIcon} message={message} />
      {dismissible && <Icon name='close' className='flash__close-icon' onClick={handleClose} />}
    </div>
  )
}

FlashMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
  type: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string,
  dismissible: PropTypes.bool,
  style: PropTypes.object,
  alarmIcon: PropTypes.bool,
  animateIn: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  animateOut: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  timeout: PropTypes.number
}

FlashMessage.defaultProps = {
  message: '',
  type: 'success',
  onClose: noop,
  dismissible: true,
  style: {
    textAlign: 'center',
    paddingTop: '10px'
  },
  alarmIcon: true,
  animateIn: [],
  animateOut: [],
  timeout: 0
}

export default FlashMessage
