import { inRange, sumBy } from 'lodash'

/**
 * @constant
 * @description Количество попыток случайного выбора опции
 */
const CALCULATION_ATTEMPTS_LIMIT = 3

/**
 * @constant
 * @description Код опции, если не удалось выбрать из предстваленных
 */
export const NO_OPTION_CODE = 'NO_OPTION'

/**
 * @constant
 * @description Ключ по которому сохранены данные в localStorage
 */
export const STORAGE_KEY = 'abTestingSettings'

/**
 * @type
 * @description Параметры для расчета
 */
type TOption = {
  code: string
  chance: number
}

/**
 * @type
 * @description Настройки с параметрами для расчета
 */
export type TRawSettings = Array<{
  code: string
  options: TOption[]
}>

/**
 * @type
 * @description Рассчитанная конфигурация настроек
 */
export type TCalculatedSettings = Record<string, string>

/**
 * @type
 * @description Функция-рандомайзер, должна возвращать значение от 0 до 1
 */
type TRandomize = () => number

/**
 * @function
 * @description Расчет шансов срабатывания той или иной опции и возврат сработавшей
 * @param {TOption[]} options
 * @param {randomize} attempt
 * @returns {string}
 */
export const calculateOptions = (
  options: TOption[],
  randomize: TRandomize = Math.random
): string => {
  const total = sumBy(options, 'chance')
  if (!total) return NO_OPTION_CODE
  const optionsWithRanges = options.reduce((acc, option, index) => {
    const from = acc[index - 1]?.range?.[1] ?? 0
    const to = from + option.chance / total
    return [...acc, { ...option, range: [from, to] }]
  }, [] as Array<TOption & { range: number[] }>)
  const inner = (attempt = 1): string => {
    if (attempt > CALCULATION_ATTEMPTS_LIMIT) return NO_OPTION_CODE
    const dice = randomize()
    const option = optionsWithRanges.find(({ range: [start, end] }) => inRange(dice, start, end))
    return option ? option.code : inner(attempt + 1)
  }
  return inner()
}

/**
 * @function
 * @description Возвращает ассоциативный массив, где ключ - код настройки, значение - код сработавшей опции
 * @param {TRawSettings} settings
 * @returns {TCalculatedSettings}
 */
export const calculateSettings = (settings: TRawSettings): TCalculatedSettings =>
  settings.reduce((acc, { code, options }) => ({ ...acc, [code]: calculateOptions(options) }), {})
