import intl from '#intl'

export default class BaseHttpClientError extends Error {
  code: number

  constructor(message = intl.serverError) {
    super(message)
    this.name = 'BaseHttpClientError'
    this.code = 500
  }
}
