import { isNull } from 'lodash'

import { TModelItem } from '#components/Form/Form'
import { Universal } from '#components/LoginForm/Notifications/Universal'
import intl from '#intl'
import { LoginType } from '#modules/api/personal/enums'
import notify from '#services/notify'
import Form, { FormItem } from '#src/components/Form'
import SmartControl from '#src/components/SmartControl'
import { normalizePhoneNumber } from '#src/services/helper'
import { TValidationResult } from '#src/services/validator'

interface IState {
  data: {
    [key: string]: string | number | boolean
  }
  errors: {
    [key: string]: string
  }
  valid: boolean
}

interface IResponse {
  code?: number
  data?: {
    [key: string]: unknown
  }
  limit?: number
  message?: string
  error?: { message?: string } & {
    [key: string]: unknown
  }
}

interface IValidateFomrOptions {
  ignore?: string[]
  showErrors?: boolean
}

interface IErrors {
  [key: string]: string | boolean
}

class BaseClientAuthForm extends Form<unknown, IState> {
  model: TModelItem[] = []
  errorCodes = [1, 2, 3, 5, 6, 401, 429]
  constructor(props: any) {
    super(props)
    this.getLoginType = this.getLoginType.bind(this)
    this.trimWhiteSpaces = this.trimWhiteSpaces.bind(this)
    this.getNormalizedLogin = this.getNormalizedLogin.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.state = {
      ...this.state
    }
  }

  checkResponseCode(response: IResponse = {}, isNotify = true) {
    const result = { errors: {} }
    const { code, limit = null } = response
    if (code && [0, 200].includes(code)) return result
    let message = response.message
      ? response.message
      : (response.error && response.error.message) || intl.serverError
    if (!isNull(limit) && limit >= 0)
      message = `${message}. После исчерпания попыток входа, аккаунт будет заблокирован.\r\n Осталось попыток: ${limit}`

    if (isNotify) {
      notify.push({
        message: <Universal type={'info'} iconName={'triangle'} title={message} message={''} />,
        type: 'info',
        permanent: code === 401,
        alarmIcon: false,
        container: 'top-center'
      })
    }
    return result
  }

  validateItem(item: TModelItem, showError = true): TValidationResult {
    const { data } = this.state
    const { name } = item
    if (name === 'login') {
      const value = data[name]
      if (!value && item.required)
        return { isValid: false, validationErrorMessage: intl.fieldEmpty }
      return super.validateItem(item, showError)
    }
    return super.validateItem(item, showError)
  }

  validateForm(options: IValidateFomrOptions = {}): boolean {
    const errors: IErrors = {}
    const { showErrors = true, ignore = [] } = options
    let isFromValid = true
    this.model.forEach((item: TModelItem) => {
      const name = (item.name || item.systemName) as string
      if (ignore.includes(name)) return
      const node = document.getElementById(name)
      const { isValid, validationErrorMessage } = this.validateItem(item, showErrors)
      if (node && !isValid) {
        isFromValid = false
        errors[name] = validationErrorMessage
      }
    })

    this.setState((state) => ({
      ...state,
      valid: isFromValid,
      errors: showErrors ? errors : state.errors
    }))

    return isFromValid
  }

  getLoginType(login = ''): LoginType {
    if (login.includes('@')) return LoginType.EMAIL
    return LoginType.PHONE
  }

  getNormalizedLogin(_login = ''): string {
    const login = _login.trim()
    const loginType = this.getLoginType(login)
    if (loginType === LoginType.PHONE) return normalizePhoneNumber(login)
    return login
  }

  trimWhiteSpaces(): Promise<void> {
    return new Promise((resolve) => {
      const { data } = this.state
      const normalizedData = this.model
        .map(({ name }: TModelItem) => name as string)
        .reduce((acc, name) => {
          if (data && data[name] && typeof data[name] === 'string') {
            return {
              ...acc,
              [name]: (data[name] as string).trim()
            }
          }
          return {
            ...acc,
            [name]: data[name]
          }
        }, {})

      this.setState(
        (state) => ({
          ...state,
          data: { ...state.data, ...normalizedData }
        }),
        resolve
      )
    })
  }

  renderItem(item: TModelItem) {
    const { data, errors } = this.state
    return (
      <FormItem {...item} key={item.name} error={errors[item.name]}>
        <SmartControl
          {...item}
          value={data[item.name]}
          valid={!errors[item.name]}
          onFocus={this.handleControlFocus}
          onBlur={this.handleControlBlur}
          onChange={this.handleControlChange}
        />
      </FormItem>
    )
  }

  runParentValidationItem = (item: TModelItem, showError = true): TValidationResult =>
    super.validateItem(item, showError)
}

export default BaseClientAuthForm
