import PropTypes from 'prop-types'

import { BaseClientLayout } from '#components/BaseLayout'
import { updateLoggedInToken } from '#reducers/app/appSlice'

export class Layout extends BaseClientLayout {
  constructor(props) {
    super(props)
    this.saveLocalParams = this.saveLocalParams.bind(this)
    this.init()
    this.initCounters()
    this.initLocalStorage()
  }

  init() {
    const { dispatch } = this.props
    const domain = this.getDomain()
    this.saveLocalParams(domain)
    this.loggedInToken() && dispatch(updateLoggedInToken({ token: this.loggedInToken() }))
    this.initModalLinks()
    this.handleWindowResizeImmediate()
    this.initAppSettings()
    window.addEventListener('resize', this.handleWindowResize)
  }
}

Layout.propTypes = {
  dispatch: PropTypes.func
}

Layout.childContextTypes = {
  logout: PropTypes.func
}
