import config from '#config'
import { ROUTES } from '#constants/common'
import intl from '#intl'

type TModelItem = {
  host: string | null
  pathname: string | URL | null
  text?: string | null
  type?: string
  url?: string | URL | null
}

type TModel = {
  title: string
  items: TModelItem[]
}

const { host, lkHost, pkHost, phone, pr, freePhone } = config

const buildUrl = (host: string | null, pathname: string | URL | null): string | URL | null => {
  if (host) return new URL(pathname || '', host).href
  return pathname
}

const isLocalResource = (url: string | URL | null): boolean => !/^[\w+]*:|\/\//.test(url as string)

const defineItemTypeByUrl = (url: string | URL | null): string => {
  if (url) {
    if (isLocalResource(url)) return 'internalLink'
    return 'externalLink'
  }
  return 'text'
}

const model: TModel[] = [
  {
    title: intl.aboutService,
    items: [
      { host, pathname: ROUTES.howTakeLoan, text: intl.howTakeLoan },
      { host, pathname: ROUTES.howReturnLoan, text: intl.howReturnLoan },
      { host, pathname: ROUTES.howProlongLoan, text: intl.howProlongLoan },
      { host, pathname: ROUTES.improveCreditHistory, text: intl.improveCreditHistory },
      { host, pathname: ROUTES.privileges, text: intl.forRegularCustoms },
      { host, pathname: ROUTES.actions, text: intl.actions },
      { host, pathname: ROUTES.news, text: intl.news },
      { host, pathname: ROUTES.press, text: intl.smiAboutUs },
      { host, pathname: ROUTES.faq, text: intl.faq }
    ]
  },
  {
    title: intl.aboutUs,
    items: [
      { host, pathname: ROUTES.about, text: intl.aboutCompany },
      { host, pathname: ROUTES.documents, text: intl.documentsAndInfo },
      { host, pathname: ROUTES.vacancies, text: intl.vacancies },
      { host, pathname: ROUTES.conditions, text: intl.clientInfo },
      { host, pathname: ROUTES.reviews, text: intl.reviewPageTitle },
      { host, pathname: ROUTES.partners, text: intl.affiliateProgram },
      { host: null, pathname: null, text: null, type: 'social' }
    ]
  },
  {
    title: intl.aboutLoans,
    items: [
      { host, pathname: ROUTES.loansToCard, text: intl.loansToCard },
      { host, pathname: ROUTES.obtainingMethods, text: intl.obtainingMethods },
      { host, pathname: ROUTES.loansAmounts, text: intl.loansAmounts },
      { host, pathname: ROUTES.loansAdvantages, text: intl.loansAdvantages },
      { host, pathname: ROUTES.ToWhomGive, text: intl.ToWhomGive },
      { host, pathname: ROUTES.loansToBankAccount, text: intl.loansToBankAccount },
      { host, pathname: ROUTES.blog, text: intl.blogTitle }
    ]
  },
  {
    title: intl.contacts,
    items: [
      { host: lkHost, pathname: null, text: intl.loginToLK },
      { host: pkHost, pathname: null, text: intl.loginToPK },
      { host, pathname: ROUTES.contact, text: intl.askQuestion },
      { host: null, pathname: `mailto:${pr}`, text: intl.pressService },
      {
        host: null,
        pathname: `tel:${phone.replace(/[^\d+\\]*/g, '')}`,
        text: phone,
        type: 'phoneLink'
      },
      {
        host: null,
        pathname: `tel:${freePhone.replace(/[^\d+\\]*/g, '')}`,
        text: freePhone,
        type: 'phoneLink'
      },
      { host, pathname: '/#', text: intl.versionForVisuallyImpaired, type: 'specialVersion' },
      { host, pathname: ROUTES.sitemap, text: intl.sitemap },
      { host: null, pathname: null, text: null, type: 'subscribeForm' }
    ]
  }
]

const buildItem = (item: TModelItem): TModelItem => {
  const { host, pathname, type } = item
  const url = buildUrl(host, pathname)
  return {
    ...item,
    url,
    type: type || defineItemTypeByUrl(url)
  }
}

const buildNavigation = (): TModel[] =>
  model.map((block) => ({
    ...block,
    items: block.items.map((item) => buildItem(item))
  }))

export const sro = buildItem({ host, pathname: ROUTES.certificates, text: intl.sroText })
export const finUpol = {
  pathnames: {
    helpService: ROUTES.contact,
    memoInteraction: ROUTES.conditions
  },
  text: {
    textMemo: intl.finUpolText,
    textHelpService: intl.helpService
  }
}
export const navigation = buildNavigation()
