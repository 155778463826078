import axios from 'axios'
import UniversalFormData from 'form-data'
import { get, isEmpty } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import appConfig from '#config'
import store from '#src/store/storeAccessor'

import { Actions, ActionStages } from './enums'
import {
  IUserActionLogData,
  IUserActionLoggerConfig,
  IUserActionLoggerOptions,
  IUserPackageActionsLogData,
  TLogResponse,
  TUserActionLogInfo
} from './types'

/**
 * @class
 * Класс отвечающий за логирование действий пользователя для сбора статистики и фиксации данных в БД
 */
export default class UserActionLogger {
  /**
   * @property
   * @description Контекст выполнения (билд)
   */
  private static readonly _build: string = <string>process.env.__BUILD__

  /**
   * @property
   * @description Роут для выполнения запроса
   */
  private static readonly _apiRouteSingle: string = '/actionStage/create'

  /**
   * @property
   * @description Роут для выполнения запроса
   */
  private static readonly _apiRoutePackage: string = '/actionStage/packageCreate'

  /**
   * @property
   * @description Полный url для выполнения запроса */
  private readonly _apiUrlSingle: string

  /**
   * @property
   * @description Полный url для выполнения запроса */
  private readonly _apiUrlPackage: string

  /**
   * @property
   * @description Объект конфигурации
   */
  private readonly _config?: IUserActionLoggerConfig

  /**
   * @constructor
   * @param {IUserActionLoggerConfig=} config
   */
  public constructor(config: IUserActionLoggerOptions = {}) {
    const { staticGuid: shouldUseStaticGuid, ...rest } = config
    this._config = rest
    if (shouldUseStaticGuid) this._config.guid = uuidv4()
    const prefixMap = { main: '/api' }
    const prefix = prefixMap[<keyof typeof prefixMap>UserActionLogger._build] || prefixMap.main
    this._apiUrlSingle = new URL(
      `${prefix}${UserActionLogger._apiRouteSingle}`,
      appConfig.apiHost
    ).href
    this._apiUrlPackage = new URL(
      `${prefix}${UserActionLogger._apiRoutePackage}`,
      appConfig.apiHost
    ).href
  }

  /**
   * @property
   * @description Значение guid, в случае если объект был инстанцирован с параметром staticGuid = true
   * @returns {string|undefined}
   */
  public get staticGuid(): string | undefined {
    return this._config?.guid
  }

  /**
   * @method
   * @description Статический метод - синтаксическая альтернатива конструктора
   * @param {IUserActionLoggerConfig=} config
   * @returns {UserActionLogger}
   */
  public static init = (config?: IUserActionLoggerConfig): UserActionLogger =>
    new UserActionLogger(config)

  /**
   * @method
   * @description Выполняет логирование пользовательского действия для пaxios
   * @returns {Promise<boolean | null>}
   */
  public log = async (actionData: IUserActionLogData): Promise<boolean | null> => {
    if (!this.isLoggingEnabled() || !this.shouldLogAction(actionData.action)) return null
    try {
      const { data }: TLogResponse = await axios.post(
        this._apiUrlSingle,
        this.buildActionFormData(actionData)
      )
      if (data.code !== 0) throw data
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  /**
   * @method
   * @description Выполняет логирование пользовательского действия для последующего анализа
   * @param {IUserActionLogData} actionsData Данные для логирования
   * @returns {Promise<boolean | null>}
   */
  public logPackage = async (actionsData: IUserPackageActionsLogData): Promise<boolean | null> => {
    if (!this.isLoggingEnabled()) return null
    const filteredActions = actionsData.actions.filter(({ action }) => this.shouldLogAction(action))
    if (isEmpty(filteredActions)) return null
    try {
      const { data }: TLogResponse = await axios.post(
        this._apiUrlPackage,
        this.buildPackageActionsFormData({ ...actionsData, actions: filteredActions })
      )
      if (data.code !== 0) throw data
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  /**
   * @method
   * @description Shorthand с предустановленным типом действия
   * @param {TUserActionLogInfo=} data
   * @returns {Promise<void>}
   */
  public logRequest = async (data?: TUserActionLogInfo): Promise<boolean | null> =>
    this.log({ stage: ActionStages.REQUEST, data })

  /**
   * @method
   * @description Shorthand с предустановленным типом действия
   * @param {TUserActionLogInfo=} data
   * @returns {Promise<void>}
   */
  public logSuccess = async (data?: TUserActionLogInfo): Promise<boolean | null> =>
    this.log({ stage: ActionStages.SUCCESS, data })

  /**
   * @method
   * @description Shorthand с предустановленным типом действия
   * @param {TUserActionLogInfo=} data
   * @returns {Promise<void>}
   */
  public logFailure = async (data?: TUserActionLogInfo): Promise<boolean | null> =>
    this.log({ stage: ActionStages.FAILURE, data })

  /**
   * @method
   * @description Включено ли логирование
   * @returns {boolean}
   */
  private readonly isLoggingEnabled = (): boolean =>
    Boolean(get(store, ['settings', 'personActionStage', 'isEnabled'], false))

  /**
   * @method
   * @description Необходимо ли логировать запрос
   * @param {Actions=} action
   * @returns {boolean}
   */
  private readonly shouldLogAction = (action?: Actions): boolean => {
    const actionsList = get(store, ['settings', 'personActionStage', 'actionsList'], []) as string[]
    const actualAction = this._config?.action ?? action
    return Boolean(actualAction) && actionsList.includes(<string>action)
  }

  /**
   * @method
   * @description Добавляет в данные сервисную информацию
   * @param {TUserActionLogInfo} data
   * @returns {TUserActionLogInfo}
   */
  private readonly withServiceInfo = (data: TUserActionLogInfo = {}): TUserActionLogInfo => ({
    ...data,
    __service_data: { build: UserActionLogger._build }
  })

  /**
   * @method
   * @description Формирование объекта FormData для отправки в запросе
   * @param {IUserActionLogData} data
   * @returns {FormData}
   */
  private readonly buildActionFormData = (data: IUserActionLogData): UniversalFormData => {
    const mergedData = { ...this._config, ...data }
    const formData = new UniversalFormData()
    const { action, guid, stage, data: info, token } = mergedData
    formData.append('actionGuid', guid ?? uuidv4())
    token && formData.append('token', token)
    action && formData.append('action', action)
    stage && formData.append('actionStage', stage)
    info && formData.append('stageData', JSON.stringify(this.withServiceInfo(info)))
    return formData
  }

  /**
   * @method
   * @description Формирование объекта FormData для отправки в запросе
   * @param {IUserActionLogData} data
   * @returns {FormData}
   */
  private readonly buildPackageActionsFormData = (
    data: IUserPackageActionsLogData
  ): UniversalFormData => {
    const token = data.token ?? this._config?.token
    const guid = data.guid ?? this._config?.guid ?? uuidv4()
    const configAction = this._config?.action
    const formData = new UniversalFormData()
    formData.append('actionGuid', guid)
    token && formData.append('token', token)
    data.actions.forEach((item, index) => {
      formData.append(`actions[${index}][action]`, item.action ?? configAction)
      formData.append(`actions[${index}][actionStage]`, item.stage)
      formData.append(
        `actions[${index}][stageData]`,
        JSON.stringify(this.withServiceInfo(item.data))
      )
    })
    return formData
  }
}
