import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios'
import { camelCase } from 'lodash'
import { Cookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'

import appConfig from '#config'
import { HTTP_STATUS_CODES, UNAUTHORIZED } from '#constants/common'
import intl from '#intl'
import { dispatchCriticalError as criticalError } from '#reducers/clientData/criticalErrorSlice'
import { getLocalClientTime } from '#services/datetime'
import { fileTransferApi } from '#src/modules/api'
import { HttpClient, TAxiosConfig } from '#src/modules/api/httpClient'
import * as ClientApiTypes from '#src/modules/api/personal/types'
import store from '#src/store/storeAccessor'

import {
  ITransformResponseResponseData,
  IUnauthorized,
  ResponseCode,
  TClientUnauthorizedErrors,
  TClientUnauthorizedErrorsTypes
} from '../types/common'

const personalAgreementField = 'personDataAgreement[]'

class PersonalApi extends HttpClient {
  observeNode: TClientUnauthorizedErrorsTypes = 'client'
  protected readonly unauthorizedErrorMessage = ['invalidToken', 'tokenExpired', 'tokenError']
  protected readonly unauthorizedErrors: TClientUnauthorizedErrors = {
    client: (err) => criticalError(err)
  }
  private readonly cookies: Cookies

  public constructor(config: AxiosRequestConfig) {
    super(config)
    this.observeNode = 'client'
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
    this.cookies = new Cookies()
  }

  public async getMarketNotifications(
    token: string
  ): Promise<ClientApiTypes.TGetMarketNotifications> {
    return this.instance.get('/marketNotifications', { params: { token } })
  }

  public markAsReadMarketNotifications(
    data: FormData
  ): Promise<ClientApiTypes.TMarkAsReadMarketNotification> {
    return this.instance.post('/marketNotifications', data)
  }

  public getClientRefinanceInfo({
    token,
    creditId
  }: {
    token: string
    creditId: number
  }): Promise<ClientApiTypes.TGetClientRefinanceInfo> {
    return this.instance.get('/refinance', { params: { token, creditId } })
  }

  public confirmSmsRefinanceCode({
    token,
    creditIdFrom,
    creditIdTo,
    code,
    totalIncome
  }: {
    token: string
    creditIdFrom?: number
    creditIdTo?: number
    code: string
    totalIncome: number
  }): Promise<ClientApiTypes.TSendEarlyRefinanceCode> {
    const data = new FormData()
    data.append('token', token)
    data.append('code', code)
    data.append('totalIncome', String(totalIncome))
    creditIdFrom && data.append('creditIdFrom', String(creditIdFrom))
    creditIdTo && data.append('creditIdTo', String(creditIdTo))
    return this.instance.post('/refinance/confirm_code', data)
  }

  public createRefinanceCredit({
    token,
    creditId,
    personAccountId
  }: {
    token: string
    creditId: number
    personAccountId: string
  }): Promise<ClientApiTypes.TSendEarlyRefinanceCode> {
    const data = new FormData()
    data.append('token', token)
    data.append('creditId', String(creditId))
    data.append('personAccountId', personAccountId)
    data.append('c4s', this.getFingerPrint())
    return this.instance.post('/refinance/create', data)
  }

  public repeatRefinanceEarlyCode({
    token,
    creditIdFrom,
    creditIdTo
  }: {
    token: string
    creditIdFrom?: number
    creditIdTo?: number
  }): Promise<ClientApiTypes.TSendEarlyRefinanceCode> {
    const data = new FormData()
    data.append('token', token)
    creditIdFrom && data.append('creditIdFrom', String(creditIdFrom))
    creditIdTo && data.append('creditIdTo', String(creditIdTo))
    return this.instance.post('/refinance/re_send_code', data)
  }

  public sendEarlyCode({
    token,
    cid,
    resend
  }: {
    token: string
    cid: number
    resend: number
  }): Promise<ClientApiTypes.TSendEarlyCode> {
    const data = new FormData()
    data.append('token', token)
    data.append('cid', String(cid))
    data.append('resend', String(resend))
    return this.instance.post('/sendEarlyCode', data)
  }

  public checkConfirmEarly({
    token,
    cid,
    code
  }: {
    token: string
    cid: number
    code: string
  }): Promise<ClientApiTypes.TCheckConfirmEarly> {
    const data = new FormData()
    data.append('token', token)
    data.append('cid', String(cid))
    data.append('code', code)
    return this.instance.post('/checkConfirmEarly', data)
  }

  public getAccounts(token: string): Promise<ClientApiTypes.TGetClientAccount> {
    return this.instance.get('/getAccounts', { params: { token } })
  }

  public setCardBindFillFormComplete(
    token: string,
    personAccountId: number
  ): Promise<ClientApiTypes.TSetCardBindFillFormComplete> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('personAccountId', String(personAccountId))
    return this.instance.post('/setCardBindFillFormComplete', formData)
  }

  public createCredit(
    params: ClientApiTypes.TCreateCreditParams
  ): Promise<ClientApiTypes.TCreateCredit> {
    const {
      amount,
      term,
      clientAccount,
      promoCode = '',
      token,
      c4s = '',
      ymUid = null,
      creditType
    } = params
    const partnerParameters = decodeURIComponent(this.cookies.get('partnerParameters') || '')
    const formData = new FormData()
    formData.append('token', token)
    formData.append('c4s', c4s)
    formData.append('promoCode', promoCode)
    formData.append('amount', String(amount))
    formData.append('period', String(term))
    formData.append('personAccountId', String(clientAccount))
    formData.append('partnerParameters', partnerParameters)
    formData.append('creditType', creditType)
    ymUid && formData.append('ymUid', ymUid)
    return this.instance.post('/createCredit', formData)
  }

  public getPaymentSystems(token: string): Promise<ClientApiTypes.TGetPaymentSystems> {
    return this.instance.get('/payment/getPaymentSystems', { params: { token } })
  }

  public getUserServices(token: string): Promise<ClientApiTypes.TGetUserServicesResponse> {
    return this.instance.get('/getServices', { params: { token } })
  }

  public cancelService({
    serviceAgreementId,
    token
  }: {
    token: string
    serviceAgreementId: number
  }): Promise<ClientApiTypes.TCustomResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('serviceAgreementId', String(serviceAgreementId))
    return this.instance.post('/cancelService', formData)
  }

  public renewalService({
    serviceAgreementId,
    token
  }: {
    token: string
    serviceAgreementId: number
  }): Promise<ClientApiTypes.TCustomResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('serviceAgreementId', String(serviceAgreementId))
    return this.instance.post('/renewalService', formData)
  }

  public login({
    login = '',
    password = ''
  }: {
    login: string
    password: string
  }): Promise<ClientApiTypes.TLoginResponse> {
    const formData = new FormData()
    formData.append('channel', 'client')
    formData.append('login', login)
    formData.append('pass', password)
    formData.append('type', 'WEB')
    return this.instance.post('/login', formData)
  }

  /*
   * LogIn from CRM to Personal Area
   */
  public loginWithToken(token: string): Promise<ClientApiTypes.TLoginWithTokenResponse> {
    return this.instance.get('/loginWithToken', { params: { token } })
  }

  public logout(token: string): Promise<ClientApiTypes.TLogoutResponse> {
    const formData = new FormData()
    formData.append('channel', 'client')
    formData.append('token', token)
    return this.instance.post('/logout', formData)
  }

  /**
   * Sends request to initiate password restoration
   */
  public sendRecoveryCode({
    login,
    birthDate,
    recaptcha
  }: {
    login: string
    birthDate: string
    recaptcha?: string
  }): Promise<ClientApiTypes.TSendRecoveryCodeResponse> {
    const formData = new FormData()
    formData.append('channel', 'client')
    formData.append('login', login)
    formData.append('birthDate', birthDate)
    recaptcha && formData.append('g-recaptcha-response', recaptcha)
    return this.instance.post('/sendRecoveryCode', formData)
  }

  /**
   * Sends repeat request to initiate password restoration
   */
  public sendRepeatRecoveryCode({
    login,
    birthDate
  }: {
    login: string
    birthDate: string
  }): Promise<ClientApiTypes.TSendRecoveryCodeResponse> {
    const formData = new FormData()
    formData.append('channel', 'client')
    formData.append('login', login)
    formData.append('birthDate', birthDate)
    return this.instance.post('/sendRepeatRecoveryCode', formData)
  }

  /**
   * Sends confirmation code to receive new password
   */
  public sendNewPassword({
    login,
    code
  }: {
    login: string
    code: string
  }): Promise<ClientApiTypes.TSendRecoveryCodeResponse> {
    const formData = new FormData()
    formData.append('channel', 'client')
    formData.append('login', login)
    formData.append('code', code)
    return this.instance.post('/sendNewPassword', formData)
  }

  /**
   * Get some of personal's personal data
   */
  public getPersonalData({
    token,
    dataSet = PersonalData.DataSetType.full
  }: ClientApiTypes.TGetPersonalDataParams): Promise<ClientApiTypes.TGetPersonalDataResponse> {
    return this.instance.get('/getPersonalData', { params: { dataSet, token } })
  }

  /**
   * Get personal's payment schedule
   */
  public getPaymentSchedule({
    token,
    creditId
  }: {
    token: string
    creditId: number
  }): Promise<ClientApiTypes.TGetPaymentScheduleResponse> {
    return this.instance.get('/getPaymentSchedule', { params: { token, creditId } })
  }

  /**
   * Get personal's documents available for downloading for specific credit
   * If creditId is not specified, current active credit documents list will be fetched
   */
  public getDocumentList({
    creditId,
    token,
    shouldIncludeCreditParts = false
  }: {
    creditId: number
    token: string
    shouldIncludeCreditParts: boolean
  }): Promise<ClientApiTypes.TGetDocumentListResponse> {
    return this.instance.get('/getDocumentList', {
      params: { creditId, token, shouldIncludeCreditParts }
    })
  }

  /**
   * Get personal's loan history, including payments history
   */
  public getLoanHistory({
    token,
    itemsPerPage,
    pageNumber
  }: {
    token: string
    itemsPerPage: number
    pageNumber: number
  }): Promise<ClientApiTypes.TGetLoanHistoryResponse> {
    return this.instance.get('/getLoanHistory', { params: { token, itemsPerPage, pageNumber } })
  }

  /**
   * Adding bank account for personal
   */
  public addBillAccount({
    billNumber,
    billBic,
    token
  }: {
    token: string
    billNumber: number
    billBic: number
  }): Promise<ClientApiTypes.TAddBillAccountResponse> {
    const formData = new FormData()
    formData.append('billBik', String(billBic))
    formData.append('billNumber', String(billNumber))
    formData.append('token', token)
    return this.instance.post('/addBillAccount', formData)
  }

  /**
   * Adding card account for personal
   */
  public addCardAccount({
    cardDate,
    cardName,
    cardNumber,
    cardPhotoId,
    token
  }: {
    cardNumber: string
    cardName: string
    cardDate: string
    cardPhotoId: number
    token: string
  }): Promise<ClientApiTypes.TAddCardAccountResponse> {
    const formData = new FormData()
    formData.append('cardNumber', cardNumber)
    formData.append('cardName', cardName)
    formData.append('cardDate', cardDate)
    formData.append('cardPhotoId', String(cardPhotoId))
    formData.append('token', token)
    return this.instance.post('/addCardAccount', formData)
  }

  /**
   * Повторный запрос смс кода для подтверждения займа
   * @param token
   */
  public repeatCreditConfirmCode(
    token: string
  ): Promise<ClientApiTypes.TRepeatCreditConfirmCodeResponse> {
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post('/repeatCreditConfirmCode', formData)
  }

  /**
   * Confirms credit
   */
  public confirmCredit(
    params: ClientApiTypes.TConfirmCreditParams
  ): Promise<ClientApiTypes.TConfirmCreditResponse> {
    const {
      token,
      creditId,
      smsCode,
      inn,
      totalIncome,
      mobileNumber,
      insuranceLifeChecked,
      insuranceCardChecked,
      serviceDoctorNearbyChecked,
      assignmentOfRightsChecked,
      serviceRiskChecked,
      d2InsuranceCardsChecked,
      d2InsuranceChecked,
      tkbProcessingPersonalDataChecked,
      megafonProcessingPersonalDataChecked,
      fincardProcessingPersonalDataChecked,
      mtsProcessingPersonalDataChecked,
      t2ProcessingPersonalDataChecked,
      veonProcessingPersonalDataChecked,
      d2ProcessingPersonalDataChecked,
      absolutProcessingPersonalDataChecked,
      doctorNearbyProcessingPersonalDataChecked,
      scoristaProcessingPersonalDataChecked,
      tkbLifeChecked
    } = params
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    formData.append('smsCode', smsCode)
    inn && formData.append('inn', String(inn).trim())
    formData.append('totalIncome', totalIncome)
    formData.append('mobileNumber', mobileNumber)
    insuranceLifeChecked && formData.append('insuranceChecked', String(insuranceLifeChecked))
    insuranceCardChecked && formData.append('insuranceCardChecked', String(insuranceCardChecked))
    serviceDoctorNearbyChecked &&
      formData.append('doctorNearbyChecked', String(serviceDoctorNearbyChecked))
    assignmentOfRightsChecked &&
      formData.append('assignmentOfRightsChecked', String(assignmentOfRightsChecked))
    serviceRiskChecked && formData.append('serviceRiskChecked', String(serviceRiskChecked))
    d2InsuranceCardsChecked &&
      formData.append('d2InsuranceCardsChecked', String(d2InsuranceCardsChecked))
    d2InsuranceChecked && formData.append('d2InsuranceChecked', String(d2InsuranceChecked))
    veonProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'veonProcessingPersonalDataChecked')
    megafonProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'megafonProcessingPersonalDataChecked')
    mtsProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'mtsProcessingPersonalDataChecked')
    t2ProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 't2ProcessingPersonalDataChecked')
    tkbProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'tkbProcessingPersonalDataChecked')
    fincardProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'fincardProcessingPersonalDataChecked')
    d2ProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'd2ProcessingPersonalDataChecked')
    absolutProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'absolutProcessingPersonalDataChecked')
    doctorNearbyProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'doctorNearbyProcessingPersonalDataChecked')
    scoristaProcessingPersonalDataChecked &&
      formData.append(personalAgreementField, 'scoristaProcessingPersonalDataChecked')
    tkbLifeChecked &&
      Number(tkbLifeChecked) &&
      formData.append('tkbLifeChecked', String(tkbLifeChecked))

    return this.instance.post('/confirmCredit', formData)
  }

  /**
   * Uploading card image in personal area
   */
  public addCardPhoto(
    token: string,
    cardImage: File,
    config: TAxiosConfig = {}
  ): Promise<ClientApiTypes.TAddCardPhotoResponse> {
    const fileName = cardImage.name || [uuidv4(), 'jpeg'].join('.')
    const formData = new FormData()
    const requestConfig = {
      retry: 3,
      retryTime: 2000,
      ...config
    }
    formData.append('token', token)
    formData.append('cardImage', cardImage, fileName)
    return this.instance.post('/addCardPhoto', formData, requestConfig)
  }

  /**
   * Crop card image in personal area
   */
  public cropCardPhoto(
    formData: FormData,
    config: TAxiosConfig = {}
  ): Promise<ClientApiTypes.TCropCardPhotoResponse> {
    return this.instance.post('/cropCardPhoto', formData, config)
  }

  /**
   * Получить обрезанный файл
   */
  public getCardPhoto(token: string): Promise<Blob> {
    return this.instance.get('/card/get', { params: { token }, responseType: 'blob' })
  }

  /**
   * Get information about user credits with state and status
   */
  public getLoans(token: string): Promise<ClientApiTypes.TGetLoansResponse> {
    return this.instance.get('/loans', { params: { token } })
  }

  /**
   * Get default promo-code for personal
   */
  public getDefaultPromo({
    token
  }: {
    token: string
  }): Promise<ClientApiTypes.TGetDefaultPromoResponse> {
    return this.instance.get('/getDefaultPromo', { params: { token } })
  }

  /**
   * Get amounts-terms related values for new credit form slider
   */
  public getSliderInfo({
    promoCode = '',
    token,
    groupId = null
  }: {
    promoCode?: string
    token: string
    groupId?: TNullable<number>
  }): Promise<ClientApiTypes.TGetSliderInfoResponse> {
    return this.instance.get('/getSliderInfo', { params: { token, promoCode, groupId } })
  }

  /**
   * Get url to payment gateway for card confirmation
   */
  public confirmCard({
    token,
    accountId
  }: {
    token: string
    accountId: number
  }): Promise<ClientApiTypes.TConfirmCardResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('accountId', String(accountId))
    return this.instance.post('/confirmCard', formData)
  }

  /**
   * Updates user's email through personal area page
   */
  public editEmail({
    token,
    email,
    requestBonus
  }: {
    token: string
    email: string
    requestBonus?: boolean
  }): Promise<ClientApiTypes.TEditEmailResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('email', email)
    requestBonus && formData.append('requestBonus', String(requestBonus))
    return this.instance.post('/editEmail', formData)
  }

  /**
   * Repeat user's email confirmation link
   */
  public repeatEmailConfirmationLink({
    token
  }: {
    token: string
  }): Promise<ClientApiTypes.TRepeatEmailLinkResponse> {
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post('/repeatEmailConfirmationLink', formData)
  }

  /**
   * Gets amount of bonuses for subscription for news
   */
  public getBonusForEmailData(token: string): Promise<ClientApiTypes.TGetBonusForEmailResponse> {
    return this.instance.get('/getBonusForEmailData', { params: { token } })
  }

  /**
   * Удаляет ранее добавленный счет или карту
   */
  public removeAccount({
    token,
    accountId
  }: {
    token: string
    accountId: number
  }): Promise<ClientApiTypes.TRemoveAccountSuccess> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('accountId', String(accountId))
    return this.instance.post('/removeAccount', formData)
  }

  /**
   * Confirms or declines the gray zone counter offer
   */
  public confirmGray({
    token,
    action
  }: {
    token: string
    action: string
  }): Promise<ClientApiTypes.TConfirmGrayResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('action', action)
    return this.instance.post('/confirmGray', formData)
  }

  /**
   * Sends request for phone number change
   */
  public changePhoneNumberRequest({
    token,
    phoneNumber
  }: {
    token: string
    phoneNumber: string
  }): Promise<ClientApiTypes.TChangePhoneNumberResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('mobileNumber', phoneNumber)
    return this.instance.post('/changePhoneNumberRequest', formData)
  }

  /**
   * Sends request for phone number change
   */
  public changePhoneNumberConfirm({
    token,
    phoneNumber,
    smsCode
  }: {
    token: string
    phoneNumber: string
    smsCode: string
  }): Promise<ClientApiTypes.TChangePhoneNumberConfirmResp> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('mobileNumber', phoneNumber)
    formData.append('smsCode', smsCode)
    return this.instance.post('/changePhoneNumberConfirm', formData)
  }

  /**
   * Gets personal list of files for notify
   */
  public getPersonalFilesList(
    token: string
  ): Promise<ClientApiTypes.TGetPersonalFilesListResponse> {
    return this.instance.get('/getPersonalFilesList', { params: { token } })
  }

  /**
   * Check person's passport validity
   */
  public checkPassport(token: string): Promise<ClientApiTypes.TCheckPassportResponse> {
    return this.instance.get('/checkPassport', { params: { token } })
  }

  /**
   * Get one of personal's credit documents, if response content-type is not
   * application/pdf - throws an error
   */
  public async downloadDocument(params: {
    token: string
    creditId?: number
    document: string
    type?: string
    freezeId?: number
  }): Promise<TNullable<string | Blob>> {
    const url = new URL('/client/downloadDocument', appConfig.apiHost).href
    const response = await fileTransferApi.get<TNullable<string | Blob>>(url, {
      params,
      responseType: 'blob'
    })
    const { data } = response
    const contentType = response?.headers['content-type'] as string
    if (contentType === 'application/pdf') return data as string
    if (!data) throw response
    if (data instanceof global.Blob) {
      const convertBlobToString = (): Promise<TNullable<string>> =>
        new Promise((resolve) => {
          const reader = new global.FileReader()
          reader.addEventListener('loadend', ({ target }: ProgressEvent<FileReader>) =>
            resolve(target?.result as TNullable<string>)
          )
          reader.readAsText(data)
        })
      return convertBlobToString()
    }
    return data
  }

  /**
   * Uploads documents required for credit
   */
  public uploadPhotoData({
    token,
    type,
    file,
    onUploadProgress,
    signal
  }: {
    token: string
    type: 'passport' | 'selfie'
    file: File
    onUploadProgress: () => void
    signal: AbortSignal
  }): Promise<unknown> {
    const formData = new FormData()
    const fileType = { passport: 36, selfie: 37 }[type] || null
    fileType && formData.append('type', String(fileType))
    formData.append('file', file)
    try {
      return this.instance.post('/uploadPhotoData', formData, {
        params: { token },
        onUploadProgress,
        signal
      })
    } catch (err) {
      const catchResponse = err as { response?: { status?: number } }
      throw catchResponse?.response?.status === HTTP_STATUS_CODES.PayloadTooLarge
        ? new Error(intl.filesSizesLimitExceeded)
        : err
    }
  }

  /**
   * Отправка на сервер обрезанного фото
   */
  public async sendCardPhoto({
    token,
    file,
    matrix,
    sse = null,
    cancelToken = null
  }: {
    token: string
    file: File
    matrix: Array<number[]>
    sse: TNullable<string>
    cancelToken: TNullable<CancelToken>
  }): Promise<ClientApiTypes.TCropCardPhotoResponse> {
    const formData = new FormData()
    sse && formData.append('sse', sse)
    matrix
      .slice(0, 2)
      .forEach((row, rInd) =>
        row.forEach((cell, cInd) => formData.append(`matrix[${rInd}][${cInd}]`, cell.toFixed(7)))
      )
    const config = { channel: 'cameraCropper' } as { channel: string; cancelToken?: CancelToken }
    if (cancelToken) config.cancelToken = cancelToken
    const response = await this.addCardPhoto(token, file, config)
    if (response.code !== ResponseCode.success) throw response
    const { cardPhotoId } = response
    cardPhotoId && formData.append('cardPhotoId', String(cardPhotoId))
    formData.append('token', token)
    return this.cropCardPhoto(formData, config)
  }

  /**
   * Gets data regarding restructuring of credit
   */
  public getFreezeState({
    token,
    creditId
  }: {
    token: string
    creditId: number
  }): Promise<ClientApiTypes.TGetFreezeStateResponse> {
    return this.instance.get('/freeze/getFreeze', { params: { token, creditId } })
  }

  /**
   * Confirm credit restructuring with sms-code
   */
  public confirmFreeze({
    token,
    creditId,
    freezeId,
    smsCode
  }: {
    token: string
    creditId: number
    freezeId: number
    smsCode: string
  }): Promise<ClientApiTypes.TConfirmFreezeResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    formData.append('freezeId', String(freezeId))
    formData.append('smsCode', smsCode)
    return this.instance.post('/freeze/confirm', formData)
  }

  /**
   * Requests new sms-code for credit restructuring confirmation
   */
  public repeatSmsForFreezeConfirm({
    token,
    creditId,
    freezeId
  }: {
    token: string
    creditId: number
    freezeId: number
  }): Promise<ClientApiTypes.TFreezeRepeatSmsResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    formData.append('freezeId', String(freezeId))
    return this.instance.post('/freeze/repeatSms', formData)
  }

  /**
   * Get payment form or / and redirect link to payment gateway
   */
  public addPayment({
    token,
    creditId,
    mobile,
    early,
    system,
    prolongation,
    amount
  }: {
    token: string
    creditId: number
    system: number
    amount: string
    early: number
    prolongation: number
    mobile: boolean
  }): Promise<ClientApiTypes.TAddPaymentResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    formData.append('system', String(system))
    formData.append('amount', amount)
    formData.append('early', String(early))
    formData.append('prolongation', String(prolongation))
    formData.append('mobile', String(mobile))
    return this.instance.post('/payment/addPayment', formData)
  }

  /**
   * Order partial early repayment for consumer credit
   */
  public earlyRepayment({
    token,
    creditId
  }: {
    token: string
    creditId: number
  }): Promise<ClientApiTypes.TEarlyRepaymentResponse> {
    const formData = new FormData()
    formData.append('creditId', String(creditId))
    formData.append('token', token)
    return this.instance.post('/payment/earlyRepayment', formData)
  }

  /**
   * Cancel partial early repayment for consumer credit
   */
  public cancelEarlyRepayment({
    token,
    creditId
  }: {
    token: string
    creditId: number
  }): Promise<ClientApiTypes.TCancelEarlyRepaymentResponse> {
    const formData = new FormData()
    formData.append('creditId', String(creditId))
    formData.append('token', token)
    return this.instance.post('/payment/cancelEarlyRepayment', formData)
  }

  /*
                   Используется если необходимо разрешить пользователю оформить повторную заявку на займ в течение дня
                    */
  public dataCorrected({
    token
  }: {
    token: string
  }): Promise<ClientApiTypes.TDataCorrectedResponse> {
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post('/dataCorrected', formData)
  }

  /**
   * удаление пользователя из ЛК
   */
  public deletePersonalAccount({
    token
  }: {
    token: string
  }): Promise<ClientApiTypes.TDeletePersonalAccountResponse> {
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post('/delete', formData)
  }

  /**
   * проверка статуса начисления процентов
   */
  public checkPercentStatus({
    creditId,
    token,
    paymentType
  }: {
    creditId: number
    token: string
    paymentType: string
  }): Promise<ClientApiTypes.TCheckPercentStatusResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    formData.append('paymentType', paymentType)
    formData.append('date', getLocalClientTime())
    return this.instance.post('/payment/checkPercentStatus', formData)
  }

  /**
   * Получение данных по статусу отказа клиента от взаимодействия
   */
  public getRefusalToInteractData({
    creditId,
    token
  }: {
    creditId: number
    token: string
  }): Promise<ClientApiTypes.TRefusalToInteractDataResponse> {
    return this.instance.get('/getRefusalToInteractData', { params: { creditId, token } })
  }

  /**
   * Отправка данных представителя клиента при отказе от взаимодействия
   */
  public setRepresentativeData({
    creditId,
    token,
    representativeName,
    representativeLawyerNumber,
    representativeAddress,
    representativePhone,
    representativeEmail
  }: {
    creditId: number
    token: string
    representativeName: string
    representativeLawyerNumber: string
    representativeAddress: string
    representativePhone: string
    representativeEmail: string
  }): Promise<ClientApiTypes.TRefusalToInteractDataResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    formData.append('representativeName', String(representativeName))
    formData.append('representativeLawyerNumber', String(representativeLawyerNumber))
    formData.append('representativeAddress', String(representativeAddress))
    formData.append('representativePhone', String(representativePhone))
    formData.append('representativeEmail', String(representativeEmail || ''))

    return this.instance.post('/setRepresentativeData', formData)
  }

  /**
   * Подтверждение отказа клиентом от взаимодействия
   */
  public confirmRefusalInteractStatement({
    creditId,
    token
  }: {
    creditId: number
    token: string
  }): Promise<ClientApiTypes.TRefusalToInteractDataResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    return this.instance.post('/confirmRefusalToInteractStatement', formData)
  }

  /**
   * Подтверждение отказа клиентом от взаимодействия (через представителя)
   */
  public confirmRefusalInteractThroughStatement({
    creditId,
    token
  }: {
    creditId: number
    token: string
  }): Promise<ClientApiTypes.TRefusalToInteractDataResponse> {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('creditId', String(creditId))
    return this.instance.post('/confirmInteractThroughStatement', formData)
  }

  protected transformResponse<T extends ITransformResponseResponseData>(
    response: AxiosResponse<T>
  ): T {
    const { data = {} as T } = response
    const message = response.data?.message ?? ''
    const normalizedMessage = camelCase(message)
    if (this.unauthorizedErrorMessage.includes(normalizedMessage)) {
      const err: IUnauthorized = { type: UNAUTHORIZED, message }
      store.dispatchAction(this.unauthorizedErrors[this.observeNode](err))
      return { ...data, ...err }
    }
    return data
  }

  private getFingerPrint(): string {
    return (this.cookies.get('c4s') as string) || uuidv4()
  }
}

const clientConfig: AxiosRequestConfig = {
  baseURL: new URL('/client', appConfig.apiHost).href
}

export const personalApi = new PersonalApi(clientConfig)
