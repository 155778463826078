import { createSelector, createSlice } from '@reduxjs/toolkit'

import { TProductConfig } from '#modules/BannerWithTextConfig'
import { fetchBackgroundWithText } from '#reducers/backgroundWithText/effects'
import { TMainDataStateType } from '#src/reducers'

export type TBackgroundState = {
  data: TNullable<TProductConfig>
  loading: boolean
}

const initialState: TBackgroundState = {
  data: null,
  loading: false
}

const backgroundWithTextSlice = createSlice({
  name: 'backgroundWithText',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchBackgroundWithText.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchBackgroundWithText.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
      })
      .addCase(fetchBackgroundWithText.rejected, (state) => {
        state.loading = false
      })
})
export default backgroundWithTextSlice.reducer
const backgroundWithText = (state: TMainDataStateType): TBackgroundState => state.backgroundWithText
export const backgroundDataSelector = createSelector(backgroundWithText, (state) => state.data)
export const backgroundLoadingSelector = createSelector(
  backgroundWithText,
  (state) => state.loading
)
