import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'

import { partnerDataDefaultState } from '#components/PartnerPagesNew/models/partnerDataHelper'
import { getErrorMessage } from '#reducers/helper'
import { fetchManualPartnerData, fetchPartnerData } from '#reducers/partnerData/effects'
import {
  TPartnerData,
  TPartnerDataDefaultState,
  TUpdatePersonRowByIdPayload
} from '#reducers/partnerData/types'
import notify from '#services/notify'

const partnerDataSlice = createSlice({
  name: 'partnerData',
  initialState: (partnerDataDefaultState as unknown) as TPartnerDataDefaultState,
  reducers: {
    update: (state, action) => {
      merge(state, action.payload)
    },
    updateProp: (state, action: PayloadAction<{ value: () => void }>) => {
      state.onLogout = action.payload.value
    },
    updatePersonRowById: (state, { payload }: PayloadAction<TUpdatePersonRowByIdPayload>) => {
      if (state.data.length > 0) {
        // @ts-ignore
        state.data = state.data.map((person: TPartnerData) => {
          if (person.personId !== payload.personId) return state.data
          return {
            ...state,
            ...payload.data
          }
        })
      }
    },
    togglePartnerMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.menuOpened = payload
    },
    fetchPartnerWrongToken: (state) => {
      state.loading = false
      state.onLogout?.()
    },
    fetchPartnerDataNoAccess: (state) => {
      state.loading = false
      document.location.href = '/'
    },
    fetchPartnerErrorFormat: (state, { payload }: PayloadAction<string>) => {
      state.loading = false
      state.errorMessage = payload
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPartnerData.rejected, (state, action) => {
        const message = getErrorMessage(action)
        notify.push({ message, type: 'danger' })
        state.loading = false
      })
      .addCase(fetchManualPartnerData.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchManualPartnerData.fulfilled, (state, action) => {
        // @ts-ignore
        state.data = action.payload
        state.loading = false
      })
      .addCase(fetchPartnerData.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        if (action.payload) state.data = action.payload
        state.loading = false
      })
      .addMatcher(isAnyOf(fetchPartnerData.pending, fetchManualPartnerData.pending), (state) => {
        state.loading = true
        state.errorMessage = ''
      })
})

export const {
  update,
  updateProp,
  updatePersonRowById,
  togglePartnerMenu,
  fetchPartnerWrongToken,
  fetchPartnerDataNoAccess,
  fetchPartnerErrorFormat
} = partnerDataSlice.actions
export default partnerDataSlice.reducer
