import classnames from 'classnames'
import { ChangeEvent, Component, CSSProperties, FocusEvent } from 'react'
import { FileWithPreview } from 'react-dropzone'

import { TModelItem } from '#components/Form/Form'
import { TSmartControlColorPickerProps } from '#components/SmartControl/SmartControlColorPicker'
import { TSmartControlRichTextProps } from '#components/SmartControl/SmartControlRichText'

import { TSMartControlFocusEvent, TSmartControlReactSelectProps, TTypes } from './SmartControl'
import { TCheckboxProps, TCheckboxSelectEvent } from './SmartControlCheckbox'

export interface ISmartControlBaseState {
  value?: string | number
  isOpen?: boolean
  focusedElement?: null | EventTarget | { name: string }
  isMouseEnter?: boolean
  blurHandlerDisabled?: boolean
  preview?: string
  childBox?: {
    [key: string]: string | number
  }
}

/* Перенести в SmartControlInput после его типизации */
export type TSmartControlInputProps = TModelItem & {
  mask?: string | boolean
}

export type TAddFileEvent = {
  target: {
    name: string
    preview: FileWithPreview
    value: FileWithPreview[]
  }
}
/* Перенести в SmartControlFile после его типизации */
export type TSmartControlFileProps = TModelItem & {
  onRemove?: () => void
  onFilesAdd: (event: TAddFileEvent) => void | Promise<void>
  previewStyle?: CSSProperties
  previewClasses?: string
  noPreview?: boolean
  preview?: TNullable<string>
}

export type TSmartControlBaseProps =
  | TSmartControlColorPickerProps
  | TSmartControlRichTextProps
  | TSmartControlInputProps
  | TSmartControlFileProps
  | TSmartControlReactSelectProps
  | TCheckboxProps

type TCreatedCustomEvent = { target: { name: string } }
type TSizes = 's' | 'm' | 'l' | 'xl'

export interface ISmartControlBaseProps {
  value?: string | number
  disabled?: boolean
  size?: TSizes
  valid?: boolean
  loading?: boolean
  hidden?: boolean
  additionalPromoStyle?: boolean
  inAction?: boolean
  prevent?: boolean
  customClass?: string
  type?: TTypes
  className?: string
  multiple?: boolean
  mask?: string | boolean
  onFocus?: (event: TSMartControlFocusEvent) => void
  onBlur?: (event: FocusEvent) => void
  onKeyUp?: (event: KeyboardEvent) => void
  onChange?: (event: ChangeEvent) => void
  onBeforeChange?: () => void
  onAfterChange?: () => void
}

export class SmartControlBase<
  P extends TSmartControlBaseProps,
  S extends ISmartControlBaseState
> extends Component<P & ISmartControlBaseProps, S> {
  private timerId: null | number
  constructor(props: P & ISmartControlBaseProps) {
    super(props)
    this.state = {
      ...this.state,
      value: this.normalizeValue(props.value),
      isOpen: false
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBefore = this.handleBefore.bind(this)
    this.handleAfter = this.handleAfter.bind(this)
    this.handleFocusSelect = this.handleFocusSelect.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.timerId = null
  }

  componentDidUpdate(prevProps: ISmartControlBaseProps): void {
    const nextValue = this.normalizeValue(this.props.value)
    if (this.props !== prevProps) {
      if (typeof this.props.value !== 'undefined' && nextValue !== this.state.value)
        this.setState({ value: nextValue })
      if (this.props.disabled) this.setState({ focusedElement: null })
    }
  }

  componentWillUnmount(): void {
    this.timerId && window.clearTimeout(this.timerId)
  }

  normalizeValue(value: string | number | undefined): string | number {
    if (typeof value !== 'undefined')
      return ['string', 'number'].includes(typeof value) || Array.isArray(value) ? value : ''
    return ''
  }

  getClassName(): string {
    const {
      size = 'm',
      type,
      disabled,
      valid,
      loading,
      hidden,
      additionalPromoStyle,
      inAction,
      prevent,
      customClass = null
    } = this.props
    const { focusedElement, value } = this.state

    let classes = {
      'smart-control': true,
      [`smart-control_size_${size}`]: true,
      'smart-control_disabled': disabled,
      'smart-control_focus': focusedElement && !disabled,
      'smart-control_filled': Boolean(value),
      'smart-control_valid': valid,
      'smart-control_invalid': !valid,
      'smart-control_loading': loading,
      'smart-control_hidden': hidden,
      'smart-control_additional': additionalPromoStyle,
      'smart-control_inAction': inAction,
      'smart-control_prevent': prevent
    }

    if (type) {
      classes = {
        ...classes,
        [`smart-control_type_${type.toString()}`]: true,
        'smart-control_checked':
          ['checkbox', 'radio'].indexOf(type.toString()) !== -1 && Boolean(Number(value))
      }
    }

    return classnames(classes, customClass, this.props.className)
  }

  handleFocus(event?: FocusEvent): void {
    const { name, onFocus } = this.props

    const cloneEvent = Object.assign({}, event)
    let target: HTMLInputElement
    if (event) target = event.target as HTMLInputElement
    else target = (cloneEvent?.target ?? {}) as HTMLInputElement

    target.name = name as string
    this.setState({ focusedElement: target }, () => {
      onFocus && onFocus({ ...cloneEvent, ...target })
    })
  }

  handleFocusSelect(event: FocusEvent): void {
    const { name, onFocus } = this.props
    const { isOpen, isMouseEnter } = this.state
    const target = event.target as HTMLInputElement
    target.name = name as string
    const cloneEvent = Object.assign({}, event)

    if (!isOpen && isMouseEnter) {
      this.setState(
        (state) => ({
          ...state,
          focusedElement: target,
          isOpen: !state.isOpen
        }),
        () => {
          onFocus && onFocus({ ...cloneEvent, target })
        }
      )
    } else if (isMouseEnter && isOpen) {
      this.setState((state) => ({
        ...state,
        focusedElement: null,
        isOpen: !state.isOpen
      }))
    } else if (!isMouseEnter && !isOpen) {
      this.setState(() => ({ focusedElement: null, isOpen: false }))
    }
  }

  handleBlur(event?: FocusEvent): void {
    const { type, name, onBlur } = this.props
    const { value, blurHandlerDisabled } = this.state

    if (blurHandlerDisabled) {
      this.setState({ blurHandlerDisabled: false })
      return
    }
    const cloneEvent = Object.assign({}, event)

    let target: HTMLInputElement
    if (event) target = event.target as HTMLInputElement
    else target = (cloneEvent?.target ?? { target: { type } }) as HTMLInputElement

    const elArray = Array.from(target)
    try {
      target = [...elArray]
    } catch (e) {
      return
    }
    target.name = name as string
    target.value = value as string
    target.type = type

    this.timerId = window.setTimeout(() => {
      this.setState({ focusedElement: null, isOpen: false }, () => {
        onBlur && onBlur({ ...cloneEvent, target })
      })
    }, 0)
  }

  handleKeyUp(event: KeyboardEvent): void {
    const { onKeyUp } = this.props

    onKeyUp && onKeyUp(event)
  }

  handleSelect(value: string | number, event: MouseEvent): void {
    const selectValue = String(value)
    const selectTarget = {
      value: selectValue
    } as HTMLInputElement
    const selectEvent = { target: selectTarget, type: 'select' }
    this.handleChange(selectEvent)
    event.type === 'click' && this.handleBlur()
  }

  handleChange(event: ChangeEvent | TCheckboxSelectEvent): void {
    const { type, name, multiple, onChange } = this.props
    let { value } = event.target as HTMLInputElement
    if (type === 'checkbox') {
      value = Number((event as TCheckboxSelectEvent).target.checked)
    } else if (type !== 'file' && (type !== 'select' || !multiple)) {
      value = !value ? '' : String(value)
    } else if (type === 'file') {
      const preview = (value?.[0]?.preview ?? '') as string
      this.setState({ preview })
    }

    if (
      type === 'tel' &&
      event?.nativeEvent?.inputType === 'insertFromPaste' // событие вставки
    ) {
      // убираем фокус после вставки, чтобы состояние маски обновилось
      if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
      return // пропускаем событие вставки для input с телефоном
    }

    event.target = { ...event.target }
    event.target.name = name
    event.target.value = value
    event.target.type = type
    event.type = event.type || 'change'
    this.setState({ value })
    onChange && onChange(event)
  }

  handleBefore(): void {
    const { onBeforeChange } = this.props
    onBeforeChange && onBeforeChange()
  }

  handleAfter(): void {
    const { onAfterChange } = this.props
    onAfterChange && onAfterChange()
  }

  onMouseEnter(): void {
    this.setState({ isMouseEnter: true })
  }

  onMouseLeave(): void {
    this.setState({ isMouseEnter: false })
  }
}
