import { CSSProperties, FocusEventHandler, MouseEventHandler } from 'react'

import { Icon } from '../Icon/Icon'
import { ISmartControlBaseState, SmartControlBase } from './SmartControlBase'

export type TCheckboxProps = {
  id: string
  name: string
  type: 'checkbox'
  placeholder?: string
  required?: boolean
  disabled?: boolean
  style?: CSSProperties
  iconName?: string
}

export type TCheckboxSelectEvent = {
  type: 'change'
  target: {
    checked: boolean
  }
}

export class SmartControlCheckbox<T, S> extends SmartControlBase<
  TCheckboxProps & T,
  ISmartControlBaseState & S
> {
  handleCheckboxSelect = (checked: boolean): MouseEventHandler => (_event) => {
    const event: TCheckboxSelectEvent = { target: { checked }, type: 'change' }
    this.handleChange(event)
  }
  handleFocus: FocusEventHandler = (event): void => super.handleFocus(event)
  handleBlur: FocusEventHandler = (event): void => super.handleBlur(event)

  render(): JSX.Element {
    const { id, name, type, placeholder, required, disabled, style, iconName } = this.props
    const { value } = this.state
    const attrs = {
      id,
      name,
      type,
      placeholder,
      required,
      disabled,
      value: String(value),
      checked: Boolean(value)
    }

    return (
      <div className={this.getClassName()} style={style}>
        <div className='smart-control__container'>
          <label
            className='smart-control__element'
            onClick={this.handleCheckboxSelect(!attrs.checked)}
            htmlFor={name}
          >
            <Icon name={iconName ? iconName : 'tick'} />
            <input {...attrs} onFocus={this.handleFocus} onBlur={this.handleBlur} />
          </label>
        </div>
      </div>
    )
  }
}
