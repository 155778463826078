import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelCase } from 'lodash'

import { UNAUTHORIZED } from '#constants/common'
import { Observer } from '#modules/api/admin/Observer'
import { Subject } from '#modules/api/admin/Subject'
import * as AdminApiTypes from '#modules/api/admin/types'
import { authApi } from '#modules/api/auth'
import * as AuthTypes from '#modules/api/auth/types'
import { HttpClient } from '#modules/api/httpClient'
import { isFormData } from '#modules/api/interceptorHelper'
import {
  ITransformResponseResponseData,
  IUnauthorized,
  TAdminUnauthorizedErrors,
  TAdminUnauthorizedErrorsTypes
} from '#modules/api/types/common'
import { renewAccessToken } from '#reducers/adminPage/auth/authSlice'
import { unauthorizedToken } from '#reducers/adminPage/auth/effects'
import storeAccessor from '#src/store/storeAccessor'

class WithRefreshTokenClient extends HttpClient {
  _accessToken = ''
  observeNode: TAdminUnauthorizedErrorsTypes = 'admin'
  protected readonly unauthorizedErrorMessage = ['invalidToken', 'tokenExpired', 'tokenError']
  protected readonly unauthorizedErrors: TAdminUnauthorizedErrors = {
    admin: () => unauthorizedToken()
  }
  protected readonly subject: Subject = new Subject()

  get accessToken(): string {
    return this._accessToken
  }

  set accessToken(token: string) {
    this._accessToken = token
  }

  protected static async refreshAccessToken(): Promise<string | null> {
    try {
      const response = await authApi.refreshAccessToken()
      if (response.code === AuthTypes.BaseResponseCodes.Success) {
        // получили от api новый токен, сохраняем его
        const { token } = response
        return token
      }
      return null
    } catch (_err) {
      return null
    }
  }

  protected onResponseSuccess = async <T>(response: AxiosResponse): Promise<T> => {
    const config = response?.config || {}
    const data = this.transformResponse<T>(response) as T & IUnauthorized
    // если у пользователя просрочен токен, то необходимо попробовать его обновить (если не просрочен сам рефреш токен)
    if (data?.type !== UNAUTHORIZED) return data
    // механизм остановки перезапроса, через наблюдателя, путем ожидания резолва промиса
    // пока не прошел рефреш токена, инициализарованного первым упавшим запросом
    if (this.subject.getState() === 'pending') {
      let observer = null
      await new Promise<void>((resolve) => {
        observer = new Observer(resolve)
        this.subject.attach(observer)
      })
      this.subject.detach(observer)
      const configWithToken = this.updateConfigToken(config)
      return this.instance(configWithToken)
    }
    try {
      this.subject.setState('pending')
      const token = await WithRefreshTokenClient.refreshAccessToken()
      if (token) {
        this.accessToken = token
        storeAccessor.dispatchAction(renewAccessToken({ token: this.accessToken }))
        this.subject.setState('ready')
        const configWithToken = this.updateConfigToken(config)
        return this.instance(configWithToken)
      }
      this.subject.setState('ready')
      storeAccessor.dispatchAction(this.unauthorizedErrors[this.observeNode]())
      return data
    } catch (err) {
      this.subject.setState('ready')
      // логаут из системы, что-то пошло не так
      storeAccessor.dispatchAction(this.unauthorizedErrors[this.observeNode]())
      return data
    }
  }

  protected transformResponse<T extends ITransformResponseResponseData>(
    response: AxiosResponse<T>
  ): T | (T & IUnauthorized) {
    const { data } = response
    const message = response.data?.message ?? ''
    const normalizedMessage = camelCase(message)
    if (this.unauthorizedErrorMessage.includes(normalizedMessage)) {
      const err: IUnauthorized = { type: UNAUTHORIZED, message }
      return { ...data, ...err }
    }
    return data
  }

  private updateConfigToken(config: AxiosRequestConfig): AxiosRequestConfig {
    // замена токена в исходном запросе FormData
    const requestData = config.data as FormData
    if (isFormData(requestData)) {
      requestData.set('token', this.accessToken)
      config.data = requestData
    }
    if (typeof config.params === 'object') {
      // замена токена в исходном запросе при переходе по ссылке требующей токен
      config.params = { ...config.params, token: this.accessToken } as AdminApiTypes.TRequestParams
    }
    return config
  }
}

export default WithRefreshTokenClient
