import { AxiosRequestConfig } from 'axios'
import UniversalFormData from 'form-data'

import appConfig from '#config'
import * as AdminApiTypes from '#modules/api/admin/types'
import { ICodeError, ICodeWithMessage } from '#modules/api/types/common'
import { HttpClient } from '#src/modules/api/httpClient'

export interface IGetPageSuccess extends ICodeWithMessage {
  data: AdminApiTypes.TArticle
}
type TGetPageResponse = IGetPageSuccess | ICodeError

export class BaseApi extends HttpClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
  }

  public getPage(path: string[]): Promise<TGetPageResponse> {
    return this.instance.get(path.join('/'))
  }

  public logChain(data: UniversalFormData): Promise<unknown> {
    return this.instance.post('/log/chain', data)
  }
}

const baseConfig: AxiosRequestConfig = {
  baseURL: appConfig.apiHost
}

export const baseApi = new BaseApi(baseConfig)
