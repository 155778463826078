import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction
} from '@reduxjs/toolkit'
import { has, omit } from 'lodash'

import { ILoanData, ILoanDetailSection } from '#components/PersonalAreaPage/types'
import intl from '#intl'
import {
  CheckingResults,
  HowPaymentEnum,
  LoanProductTypes,
  PersonalAccountState
} from '#modules/api/personal/enums'
import {
  IGetClientRefinanceInfo,
  TLoanData,
  TPaymentType,
  TRefusalResponseParams,
  TRefusalToInteractData,
  TRefusalToInteractDataPayload,
  TRefusalToInteractDataValidateErrors,
  TSetRepresentativeResponseParams
} from '#modules/api/personal/types'
import { ResponseCode } from '#modules/api/types/common'
import { getClientData } from '#reducers/clientData/selectors'
import { TPersonalRootState } from '#reducers/entries/personal'
import noty from '#services/notify'
import { personalApi } from '#src/modules/api'
import passportChecker from '#src/modules/passportChecker'

import { CreatePrefix, getErrorMessage } from '../helper'

export type TPercentStatus = {
  status: TNullable<number>
  message: TNullable<string>
  isFetched: boolean
}

export interface ICheckPercentStatusParams {
  creditId: number
  token: string
  paymentType: HowPaymentEnum
}

interface ILoanInitialState {
  loading: boolean
  items: ILoanData[]
  refinanceInfo: TRefinanceInfo & {
    loading: boolean
  }
  paymentTypes: TPaymentType[]
  percentStatus: TPercentStatus
  refusalToInteractData: TRefusalToInteractData
}

type TActionGetClientLoanStateSuccess = {
  items: TLoanData[]
}

type TGetPaymentTypes = {
  paymentTypes: TPaymentType[]
}

type TRefinanceInfo = {
  data: TNullable<{ id: number; creditId: number; creditIdTo: TNullable<number> }>
  metadata: { isEnabled: boolean; errorMessage: string }
}

type TUpdatePersonalAccountState = {
  state: Loan.PersonalAccountState
}

const initialState: ILoanInitialState = {
  loading: false,
  items: [],
  refinanceInfo: { data: null, metadata: { isEnabled: false, errorMessage: '' }, loading: false },
  paymentTypes: [],
  percentStatus: { status: null, message: null, isFetched: false },
  refusalToInteractData: {
    loading: false,
    isSendRepresentativeData: false,
    isFetched: false,
    isSendStatement: false,
    isRefusalToInteractConfirmationButtonActive: false,
    representativeAddress: null,
    representativeEmail: null,
    representativeLawyerNumber: null,
    representativeName: null,
    representativePhone: null,
    statementInteractThroughConfirmationDate: null,
    statementRefusalToInteractConfirmationDate: null
  }
}

const prefixHelper = new CreatePrefix('creditHistory')

const getClientLoanStateRequest = createAction(prefixHelper.create('getClientLoanStateRequest'))
const getClientLoanStateSuccess = createAction<TActionGetClientLoanStateSuccess>(
  prefixHelper.create('getClientLoanStateSuccess')
)
const getClientLoanStateFailure = createAction(prefixHelper.create('getClientLoanStateFailure'))
const getPaymentTypes = createAction<TGetPaymentTypes>(prefixHelper.create('getPaymentTypes'))
const fetchClientRefinanceInfoRequest = createAction(
  prefixHelper.create('fetchClientRefinanceInfoRequest')
)
const fetchClientRefinanceInfoSuccess = createAction<IGetClientRefinanceInfo>(
  prefixHelper.create('fetchClientRefinanceInfoSuccess')
)
const fetchClientRefinanceInfoFailure = createAction<string>(
  prefixHelper.create('fetchClientRefinanceInfoFailure')
)

export const updatePersonalAccountState = createAction<TUpdatePersonalAccountState>(
  prefixHelper.create('updatePersonalAccountState')
)

export const getClientLoanState = createAsyncThunk(
  prefixHelper.create('payment/getPaymentSystems'),
  async (token: string, thunkAPI) => {
    thunkAPI.dispatch(getClientLoanStateRequest())
    try {
      const paymentsResponse = await personalApi.getPaymentSystems(token)
      if (paymentsResponse.code !== 0) throw paymentsResponse
      else thunkAPI.dispatch(getPaymentTypes(paymentsResponse.data))

      const response = await personalApi.getLoans(token) // array
      if (response.code !== 0) throw response
      const { loans }: { loans: TLoanData[] } = response.data

      for (const loan of loans) {
        if (loan.state === 'productConfirm' || loan.state === 'productOrder') {
          const { isExpired } = await passportChecker.check(token, {
            timeout: 5
          })
          if (isExpired === CheckingResults.expired) loan['statePassport'] = 'invalidPassport'
        }
      }
      /**
       * Первый в списке кредит всегда основной
       */
      loans.forEach((loan, inx) => {
        loan['isRefinanceCredit'] = inx !== 0
      })

      const clientLoans = loans.sort((a, b) => b.creditId - a.creditId)
      const callback = (): void =>
        void thunkAPI.dispatch(getClientLoanStateSuccess({ items: clientLoans }))
      const [mainLoanState] = loans
      if (mainLoanState?.creditId) {
        const creditId = mainLoanState?.creditId
        void thunkAPI.dispatch(fetchClientRefinanceInfo({ token, creditId, callback }))
      }

      callback()
    } catch (err) {
      thunkAPI.dispatch(getClientLoanStateFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const fetchClientRefinanceInfo = createAsyncThunk(
  prefixHelper.create('refinance'),
  async (
    { token, creditId, callback }: { token: string; creditId: number; callback: () => void },
    thunkAPI
  ) => {
    thunkAPI.dispatch(fetchClientRefinanceInfoRequest())
    try {
      const response = await personalApi.getClientRefinanceInfo({ token, creditId })
      if (response.code === 0) {
        thunkAPI.dispatch(fetchClientRefinanceInfoSuccess(response))
        callback()
      } else {
        thunkAPI.dispatch(fetchClientRefinanceInfoFailure(response.message))
      }
    } catch (error) {
      if (error instanceof Error) thunkAPI.dispatch(fetchClientRefinanceInfoFailure(error.message))
    }
  }
)

export const checkPercentStatus = createAsyncThunk(
  prefixHelper.create('checkPercentStatus'),
  async ({ creditId, token, paymentType }: ICheckPercentStatusParams, thunkAPI) => {
    try {
      const response = await personalApi.checkPercentStatus({ creditId, token, paymentType })
      if (response.code !== ResponseCode.success) throw response
      return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
      thunkAPI.dispatch(flushPercentStatus())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const getRefusalToInteractData = createAsyncThunk(
  prefixHelper.create('getRefusalToInteractData'),
  async (params: TRefusalResponseParams, thunkAPI) => {
    const response = await personalApi.getRefusalToInteractData(params)
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)
    return thunkAPI.fulfillWithValue(response.data)
  }
)

export const sendRepresentativeData = createAsyncThunk(
  prefixHelper.create('sendRepresentativeData'),
  async (params: TSetRepresentativeResponseParams, thunkAPI) => {
    // @ts-ignore
    const response = await personalApi.setRepresentativeData(params)
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)
    thunkAPI.dispatch(setRepresentativeData(params))
    return thunkAPI.fulfillWithValue(response.data)
  }
)

export const confirmRefusalInteractClientStatement = createAsyncThunk(
  prefixHelper.create('confirmRefusalInteractStatement'),
  async (params: TRefusalResponseParams, thunkAPI) => {
    const response = await personalApi.confirmRefusalInteractStatement(params)
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)
    return thunkAPI.fulfillWithValue(response.data)
  }
)

export const confirmRefusalInteractRepresentStatement = createAsyncThunk(
  prefixHelper.create('confirmRefusalInteractThroughStatement'),
  async (params: TRefusalResponseParams, thunkAPI) => {
    const response = await personalApi.confirmRefusalInteractThroughStatement(params)
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)
    return thunkAPI.fulfillWithValue(response.data)
  }
)

const loanSlice = createSlice({
  name: 'loanSlice',
  initialState,
  reducers: {
    flushPercentStatus: (state) => {
      state.percentStatus = {
        status: null,
        message: null,
        isFetched: false
      }
    },
    setRepresentativeData: (state, action: PayloadAction<TRefusalToInteractDataPayload>) => {
      state.refusalToInteractData = {
        ...state.refusalToInteractData,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getClientLoanStateRequest, (state) => {
        state.items = []
        state.loading = true
      })
      .addCase(getClientLoanStateSuccess, (state, action) => {
        state.items = action.payload.items
        state.loading = false
      })
      .addCase(getClientLoanStateFailure, (state) => {
        state.loading = false
      })
      .addCase(fetchClientRefinanceInfoRequest, (state) => {
        state.refinanceInfo = {
          ...state.refinanceInfo,
          loading: true
        }
      })
      .addCase(fetchClientRefinanceInfoSuccess, (state, action) => {
        state.refinanceInfo = {
          data: action.payload.data,
          metadata: action.payload.metadata,
          loading: false
        }
      })
      .addCase(fetchClientRefinanceInfoFailure, (state, action) => {
        state.refinanceInfo = {
          ...state.refinanceInfo,
          loading: false,
          metadata: {
            ...state.refinanceInfo.metadata,
            errorMessage: action.payload
          }
        }
      })
      .addCase(updatePersonalAccountState, (state, action) => {
        state.items = state.items.map((item) => ({ ...item, state: action.payload.state }))
      })
      .addCase(getPaymentTypes, (state, action) => {
        state.paymentTypes = action.payload.paymentTypes
      })
      .addCase(getRefusalToInteractData.pending, (state) => {
        state.refusalToInteractData = {
          ...state.refusalToInteractData,
          loading: true,
          isFetched: false
        }
      })
      .addCase(sendRepresentativeData.pending, (state) => {
        state.refusalToInteractData = {
          ...state.refusalToInteractData,
          isSendRepresentativeData: true
        }
      })
      .addCase(getRefusalToInteractData.fulfilled, (state, action) => {
        state.refusalToInteractData = {
          loading: false,
          isFetched: true,
          isSendRepresentativeData: false,
          isSendStatement: false,
          ...(action.payload as TRefusalToInteractDataPayload)
        }
      })
      .addCase(sendRepresentativeData.fulfilled, (state) => {
        state.refusalToInteractData = {
          ...state.refusalToInteractData,
          isSendRepresentativeData: false
        }

        noty.push({
          message: 'Заявление успешно сформировано',
          type: 'success',
          container: 'top-center'
        })
      })
      .addCase(sendRepresentativeData.rejected, (state, action) => {
        state.refusalToInteractData = {
          ...state.refusalToInteractData,
          isSendRepresentativeData: false
        }

        const error = getErrorMessage(action) as TRefusalToInteractDataValidateErrors | string

        if (has((error as TRefusalToInteractDataValidateErrors).data, 'errors')) {
          const errorObject = (error as TRefusalToInteractDataValidateErrors).data.errors
          const [currentError] = Object.keys(errorObject)
          noty.push({
            message: errorObject[currentError as keyof typeof errorObject],
            type: 'danger',
            container: 'bottom-center'
          })
          return
        }

        noty.push({
          message: error || intl.serverError,
          type: 'danger',
          container: 'bottom-center'
        })
      })
      .addCase(getRefusalToInteractData.rejected, (state, action) => {
        state.refusalToInteractData = {
          ...state.refusalToInteractData,
          loading: false,
          isFetched: false
        }
        const message = getErrorMessage(action)
        noty.push({
          message: message || intl.serverError,
          type: 'danger',
          container: 'bottom-center'
        })
      })
      .addCase(checkPercentStatus.fulfilled, (state, action) => {
        state.percentStatus = {
          status: action.payload.status,
          message: action.payload.message,
          isFetched: true
        }
      })
      .addCase(confirmRefusalInteractClientStatement.fulfilled, (state) => {
        state.refusalToInteractData = {
          ...state.refusalToInteractData,
          statementRefusalToInteractConfirmationDate: true,
          isSendStatement: false
        }
      })
      .addCase(confirmRefusalInteractRepresentStatement.fulfilled, (state) => {
        state.refusalToInteractData = {
          ...state.refusalToInteractData,
          statementInteractThroughConfirmationDate: true,
          isSendStatement: false
        }
      })
      .addMatcher(
        isAnyOf(
          confirmRefusalInteractClientStatement.pending,
          confirmRefusalInteractRepresentStatement.pending
        ),
        (state) => {
          state.refusalToInteractData = {
            ...state.refusalToInteractData,
            isSendStatement: true
          }
        }
      )
      .addMatcher(
        isAnyOf(
          confirmRefusalInteractClientStatement.rejected,
          confirmRefusalInteractRepresentStatement.rejected
        ),
        (state, action) => {
          state.refusalToInteractData = {
            ...state.refusalToInteractData,
            isSendStatement: false
          }
          const message = getErrorMessage(action)
          noty.push({
            message: message || intl.serverError,
            type: 'danger',
            container: 'bottom-center'
          })
        }
      )
})

interface ILoanDetails {
  loanInfo: ILoanDetailSection
}

const getClientCreditItems = (state: TPersonalRootState): ILoanData[] => state.clientData.loan.items

export const getLoanDetailsByIdSelector = createSelector(
  getClientCreditItems,
  (state: TPersonalRootState, creditId: number) => creditId,
  (items, creditId): ILoanDetails | Partial<ILoanDetails> =>
    items.find((item) => item.creditId === creditId)?.loanDetails || ({} as Partial<ILoanDetails>)
)
export const getLoanDataByIdSelector = createSelector(
  getClientCreditItems,
  (state: TPersonalRootState, creditId: number) => creditId,
  (items, creditId): ILoanData | Partial<ILoanData> =>
    items.find((item) => item.creditId === creditId) || {}
)

export const isTkbForPersonalSelector = createSelector(getLoanDataByIdSelector, (clientData) => {
  const { creditType } = clientData as ILoanData
  return [
    LoanProductTypes.CONSUMER_CREDIT_MONTH,
    LoanProductTypes.CONSUMER_CREDIT_MONTH_SHORT
  ].includes(creditType)
})

export const tkbInsuranceLifeForPersonalSelector = createSelector(
  getLoanDataByIdSelector,
  (clientData) => {
    const { state } = clientData
    if (state === PersonalAccountState.PRODUCT_CONFIRM) {
      const { tkbInsuranceLife } = clientData as Loan.IProductConfirm
      return tkbInsuranceLife
    }
    return null
  }
)

export const loanDetailsSelector = createSelector(
  getLoanDetailsByIdSelector,
  getLoanDataByIdSelector,
  (loanDetails, loanData) =>
    Object.entries(loanDetails)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .sort(([, a], [, b]) => Number(a.order) - Number(b.order))
      .map(([sectionKey, sectionValue]) => ({
        sectionKey,
        sectionValue:
          (loanData.isLastPaymentDay &&
            omit(sectionValue, ['earlyPayment', 'totalPaymentsMoreOrEqualDebt', 'isCsh'])) ||
          omit(sectionValue, ['order', 'totalPaymentsMoreOrEqualDebt', 'isCsh'])
      }))
)
export const loanStateSelector = createSelector(
  getLoanDataByIdSelector,
  (clientData) => clientData.state
)
export const LoanLoadingSelector = createSelector(
  getClientData,
  (clientData) => clientData.loan.loading
)
export const loanDataSelector = createSelector(
  getLoanDataByIdSelector,
  (clientData) => clientData as ILoanData
)
// @ts-ignore
export const loanWaySelector = createSelector(getLoanDataByIdSelector, (clientData: TLoanData) => {
  if (clientData.state === PersonalAccountState.PRODUCT_CONFIRM) return clientData.loanWay
  return null
})
export const loanRefinanceStatusSelector = createSelector(
  getClientData,
  (clientData) => clientData.loan.refinanceInfo.metadata.isEnabled
)

export const loanPercentStatusSelector = createSelector(
  getClientData,
  (clientData) => clientData.loan.percentStatus
)

export const refusalToInteractData = createSelector(
  getClientData,
  (clientData) => clientData.loan.refusalToInteractData
)

export const refusalToInteractDataLoadingSelector = createSelector(
  getClientData,
  (clientData) => clientData.loan.refusalToInteractData.loading
)
export const refusalToInteractFetchedData = createSelector(
  getClientData,
  (clientData) => clientData.loan.refusalToInteractData.isFetched
)

export const representativeDataLoadingSelector = createSelector(
  getClientData,
  (clientData) => clientData.loan.refusalToInteractData.isSendRepresentativeData
)

export const { flushPercentStatus, setRepresentativeData } = loanSlice.actions
export default loanSlice.reducer
