import { Cookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ROUTES } from '#constants/common'
import { useIsomorphicLayoutEffect } from '#hook/useIsomorphicLayoutEffect'
import { brokerApi, personalApi } from '#modules/api'
import { updateLoggedInToken } from '#reducers/app/appSlice'

const cookies = new Cookies()

const mapApi = {
  client: (token) => personalApi.loginWithToken(token),
  broker: (token) => brokerApi.brokerLoginWithToken(token)
}
export const LogInWithToken = ({ logInFrom }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  useIsomorphicLayoutEffect(() => {
    async function logInWithToken() {
      const searchParams = new URLSearchParams(location.search)
      const token = searchParams.get('token')
      const role = searchParams.get('role')
      try {
        const apiRequest = mapApi[logInFrom]
        const response = await apiRequest(token)
        if (response.code !== 0) throw response
        cookies.set(`${logInFrom}Token`, token, { path: ROUTES.main })
        logInFrom === 'client' &&
          role !== 'client' &&
          cookies.set('crmUser', 1, { path: ROUTES.main })
        dispatch(updateLoggedInToken({ token }))
        navigate(ROUTES.main, { replace: true })
      } catch (error) {
        cookies.remove(`${logInFrom}Token`, { path: ROUTES.main })
        logInFrom === 'client' && cookies.remove('crmUser', { path: ROUTES.main })
        navigate(ROUTES.main)
      }
    }

    void logInWithToken()
  }, [dispatch, navigate, logInFrom])

  return null
}
