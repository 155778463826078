import { combineReducers } from '@reduxjs/toolkit'

import button from '#reducers/adminPage/personNotification/buttonSlice'
import buttons from '#reducers/adminPage/personNotification/buttonsSlice'
import notification from '#reducers/adminPage/personNotification/notificationSlice'
import notifications from '#reducers/adminPage/personNotification/notificationsSlice'

const personNotification = combineReducers({
  button,
  buttons,
  notifications,
  notification
})

export type TNotificationReducerType = typeof personNotification
export type TNotificationState = ReturnType<TNotificationReducerType>

export default personNotification
