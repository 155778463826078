import { combineReducers } from '@reduxjs/toolkit'

import articles from '#reducers/adminPage/articles/articlesSlice'
import auth from '#reducers/adminPage/auth/authSlice'
import authors from '#reducers/adminPage/authorsSlice'
import content from '#reducers/adminPage/content/contentSlice'
import notification from '#reducers/adminPage/personNotification'
import productInfo from '#reducers/adminPage/productsInfo/productInfoSlice'
import profile from '#reducers/adminPage/profileSlice'
import serverFiles from '#reducers/adminPage/serverFiles/serverFilesSlice'
import sitemap from '#reducers/adminPage/sitemapSlice'
import testimonials from '#reducers/adminPage/testimonials/testimonialsSlice'

const adminPage = combineReducers({
  auth,
  authors,
  profile,
  articles,
  testimonials,
  notification,
  content,
  serverFiles,
  sitemap,
  productInfo
})

export type TAdminPageReducerType = typeof adminPage
export type TAdminPageState = ReturnType<TAdminPageReducerType>

export default adminPage
