import { createAsyncThunk } from '@reduxjs/toolkit'

import { getModel } from '#components/AdminPage/Articles/EditForm/model'
import { adminApi } from '#modules/api'
import * as AdminApiTypes from '#modules/api/admin/types'
import { ArticleResponseCode } from '#modules/api/types/common'
import { setRemoveArticleId, updateModel } from '#reducers/adminPage/articles/articlesSlice'
import { CreatePrefix } from '#reducers/helper'

const prefixHelper = new CreatePrefix('articles')
export const fetchArticles = createAsyncThunk(
  prefixHelper.create('fetchArticles'),
  async (params: AdminApiTypes.IGetArticlesRequest, thunkAPI) => {
    const response = await adminApi.getArticlesList(params)
    if (response.code !== AdminApiTypes.ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
    return response.data
  }
)

export const fetchFullArticleData = createAsyncThunk(
  prefixHelper.create('fetchFullArticleData'),
  async (params: AdminApiTypes.TFetchFullArticlesRequest, thunkAPI) => {
    const { token, articleId } = params
    const response = await adminApi.getArticle(token, articleId)
    if (response.code === AdminApiTypes.ResponseCode.success)
      return thunkAPI.fulfillWithValue(response.data)
    if (response.code === ArticleResponseCode.notFound)
      return thunkAPI.rejectWithValue({ message: 'Статья не найдена' })
    return thunkAPI.rejectWithValue({ message: response.message })
  }
)

export const createNewArticle = createAsyncThunk(
  prefixHelper.create('createNewArticle'),
  async (params: AdminApiTypes.ICreateArticlesRequest, thunkAPI) => {
    const response = await adminApi.createArticle(params)
    if (response.code !== AdminApiTypes.ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
    const { articleId } = response.data
    return articleId
  }
)

export const updateArticle = createAsyncThunk(
  prefixHelper.create('updateArticle'),
  async (params: AdminApiTypes.IUpdateArticlesRequest, thunkAPI) => {
    const response = await adminApi.updateArticle(params)
    if (response.code !== AdminApiTypes.ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
  }
)

export const removeArticle = createAsyncThunk(
  prefixHelper.create('removeArticle'),
  async (params: { token: string; id: number }, thunkAPI) => {
    const { token, id } = params
    thunkAPI.dispatch(setRemoveArticleId(id))
    const response = await adminApi.removeArticle({ token, articleId: id })
    if (response.code !== AdminApiTypes.ResponseCode.success)
      return thunkAPI.rejectWithValue({ message: response.message })
    return thunkAPI.fulfillWithValue({ payload: id })
  }
)

export const fetchModel = createAsyncThunk(
  prefixHelper.create('fetchModel'),
  async (token: string, thunkAPI) => {
    const model = await getModel(token)
    thunkAPI.dispatch(updateModel(model))
  }
)
