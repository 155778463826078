import './form-subscribe.scss'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import Button from '#components/Button'
import { Form, FormItem } from '#components/Form'
import Icon from '#components/Icon'
import Modal from '#components/Modal'
import SmartControl from '#components/SmartControl'
import WrapSubscribeInputs from '#components/SubscribeForm/WrapSubscribeInputs'
import intl from '#intl'
import { subscribeForm } from '#src/models/subscribeForm'

const initialData = {
  email: '',
  privateAgreement: 1
}
export class SubscribeForm extends Form {
  constructor(props) {
    super(props)
    this.model = subscribeForm
    this.state = {
      ...this.state,
      data: {
        ...this.state.data,
        ...initialData
      },
      form: this.computeForm()
    }
  }

  validateForm = (showErrors = false) => {
    let formErrors = {}
    let isFormValid = true

    this.model?.forEach((_item) => {
      const item = { ..._item }
      item.name = this.getItemName(item)
      const { isValid, validationErrorMessage } = this.validateItem(item, showErrors)
      if (!isValid) {
        isFormValid = false
        formErrors = { ...formErrors, [item.name]: validationErrorMessage }
      }
    })

    if (showErrors) this.setState({ valid: isFormValid, errors: { ...formErrors } })
    else this.setState({ valid: isFormValid })

    return isFormValid
  }

  handleSubmit(event) {
    const { loading } = this.state
    if (!loading && this.validateForm(true))
      setTimeout(() => this.setState({ data: { ...initialData } }), 0)
    else event.preventDefault()
  }

  renderEmailForm = (item) => {
    const { data, errors, loading } = this.state
    const { withWrapper, formItemClass = '', subscribeEmailId = 'subscribeEmail' } = this.props
    const classes = classnames(formItemClass)
    return (
      <FormItem key={item.name} {...item} error={errors[item.name]} className={classes}>
        <SmartControl
          {...item}
          id={subscribeEmailId}
          value={data[item.name]}
          valid={!errors[item.name]}
          error={errors[item.name]}
          onFocus={this.handleControlFocus}
          onChange={this.handleControlChange}
          className={classnames({ 'me-4': withWrapper })}
        />
        <Button
          className={classnames({ 'me-2': withWrapper })}
          type='submit'
          loading={loading}
          ariaLabel={'subscribe'}
        >
          {withWrapper ? intl.subscribe : <Icon name='envelope' />}
        </Button>
      </FormItem>
    )
  }

  renderPrivateAgreement = (item) => {
    const { name } = item
    const { errors, data } = this.state
    const smartControlProps = {
      ...item,
      value: data[name],
      valid: !errors[name],
      error: errors[name],
      onChange: this.handleControlChange,
      onFocus: this.handleControlFocus
    }

    return this.props.renderAgreements({
      key: name,
      handleModalLinkClick: this.handleModalLinkClick,
      ...smartControlProps
    })
  }

  handleModalClose = () => this.setState({ modalMessage: null, modalDocument: null })

  handleModalLinkClick = (evt) => {
    const documentName = evt.target.getAttribute('data-document')

    evt.stopPropagation()
    const { data } = this.state

    this.setState({
      modalDocument: {
        url: '',
        data: {
          document: documentName,
          fio: `${data.lastname || ''} ${data.firstname || ''} ${data.fathername || ''}`
        }
      }
    })
  }

  renderItem = (item) => {
    const { name } = item
    const { hasAgreement = false } = this.props
    if (name === 'privateAgreement') {
      if (hasAgreement) return this.renderPrivateAgreement(item)
      return null
    }
    return this.renderEmailForm(item)
  }

  render() {
    const { form, modalDocument } = this.state
    const {
      className,
      noCaption = false,
      withWrapper,
      hasAgreement = false,
      unisenderListId
    } = this.props
    const shouldShowCaption = !noCaption
    const classes = classnames(
      {
        form: true,
        'form-subscribe': !withWrapper,
        'block-subscribe': withWrapper,
        'block-subscribe__wrapper': withWrapper
      },
      className
    )

    return (
      <>
        <form
          className={classes}
          method='POST'
          target='_blank'
          noValidate
          onSubmit={this.handleSubmit}
          action='https://cp.unisender.com/ru/subscribe?hash=5qfgz4fcoc3pheqqdoq96n71xqwpeqzsgfrt91jy'
        >
          <input type='hidden' name='charset' value='UTF-8' />
          <input type='hidden' name='default_list_id' value={unisenderListId} />
          <input type='hidden' name='overwrite' value='2' />
          {shouldShowCaption && <div>{intl.subscribeToNews}</div>}
          {withWrapper && (
            <div className='content'>
              <h3 className='title'>{intl.subscribeBlockTitle}</h3>
              <div className='text'>{intl.subscribeBlockText}</div>
            </div>
          )}
          <WrapSubscribeInputs hasAgreement={hasAgreement}>
            {form.lines.map((line, key) => (
              <div className='form__line' key={key}>
                {line.items.map((item) => !this.checkDepends(item) && this.renderItem(item))}
              </div>
            ))}
          </WrapSubscribeInputs>
        </form>
        {modalDocument && <Modal {...modalDocument} onClose={this.handleModalClose} />}
      </>
    )
  }
}

SubscribeForm.propTypes = {
  unisenderListId: PropTypes.string,
  hasAgreement: PropTypes.bool,
  subscribeEmailId: PropTypes.string
}

export default SubscribeForm
