import { brokerApi } from '#modules/api'
import { BrokerApiTypes as Types } from '#modules/api/broker/types'

export type TPartnerData = TUpdatePersonRowByIdPayload['data']

export type TPartnerDataDefaultState = {
  loading: boolean
  className: string
  title: string
  filters: string[]
  requestRules: {
    bids: {
      periodStart: string
      periodEnd: string
      treatment: string
    }
    accounts: {
      periodStart: string
      periodEnd: string
    }
    statistics: {
      dateFrom: string
      dateTo: string
      onlyNew: number
      groupingType: string
    }
    loans: {
      createDateFrom: string
      createDateTo: string
      status: string
      limit: number
      offset: number
    }
  }
  data: []
  chart: []
  info: unknown
  menuOpened: boolean
  errorMessage: string
  onLogout: TNullable<() => void>
}

export type TPartnerApiTypes = {
  loans: (params: Types.IGetPartnerLoansRequest) => Promise<Types.TGetPartnerLoansResponse>
  statistics: (
    params: Types.IGetPartnerStatisticsRequest
  ) => Promise<Types.TGetPartnerStatisticsResponse>
  info: (params: Types.IGetPartnerInfoRequest) => Promise<Types.TGetPartnerInfoResponse>
  bids: (params: Types.IGetPersonsRequest) => Promise<Types.TGetPersonsResponse>
  accounts: (params: Types.IGetAccountsRequest) => Promise<Types.TGetAccountsResponse>
}

export type TPartnerInfoParams = {
  contact: string
  email: string
  gidOffline: number
  groupId: number
  interestRate: TNullable<number>
  interestRateReuse: TNullable<number>
  isBroker: number
  name: string
  param: string
  partnerId: number
}

export const {
  getPartnerInfo,
  getPartnerBids,
  getPartnerAccounts,
  getPartnerStatistics,
  getPartnerLoans
} = brokerApi

export const _brokerApi: TPartnerApiTypes = {
  loans: getPartnerLoans,
  statistics: getPartnerStatistics,
  info: getPartnerInfo,
  bids: getPartnerBids,
  accounts: getPartnerAccounts
}

export type TRequestRules = {
  brokerToken: string
  brokerInfo: string
  part: keyof TPartnerApiTypes
  createDateFrom: string
  createDateTo: string
  limit: number
  offset: number
  status: string | number
  periodStart: string
  periodEnd: string
  treatment: number
  dateFrom: string
  dateTo: string
  groupingType: string
}

export type TUpdatePersonRowByIdPayload = {
  data: {
    amount: string
    approvedDate: string
    brokerComment: string
    createDate: string
    id: string
    percent: string
    period: string
    personFullName: string
    personId: string
    request_id: string
    returnDate: string
    status: string
    treatment?: number
  }
  personId: string
}
