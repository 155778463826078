import cn from 'classnames'
import { CSSProperties, FC } from 'react'

import intl from '#intl'

import Icon from '../Icon'
import { DEFAULT_CLASSES, DEFAULT_STYLE } from './constants'

interface IUnknownErrorProps {
  errorMessage: string
  className?: string
  style?: CSSProperties
}

const UnknownError: FC<IUnknownErrorProps> = ({ errorMessage, className, style }) => (
  <div
    className={cn({ [DEFAULT_CLASSES]: true }, className)}
    style={{ ...DEFAULT_STYLE, ...style }}
  >
    <div className='d-flex justify-content-between align-items-center text-secondary'>
      <Icon name='error-outline' className='me-3 mb-1 icon-lg' />
      <p className='font-size-large text-uppercase fw-bold'>{intl.errorOccured}</p>
    </div>
    {errorMessage && <small>{errorMessage}</small>}
    <p className='text-secondary fw-bold'>{intl.tryAgainLater}</p>
  </div>
)

export default UnknownError
