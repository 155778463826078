import { AxiosRequestConfig } from 'axios'
import { FileWithPreview } from 'react-dropzone'

import appConfig from '#config'
import { NodeApiTypes } from '#modules/api/node/types'
import WithRefreshTokenClient from '#modules/api/withRefreshTokenClient'

import ValidationError from '../exceptions/ValidationError'

class NodeApi extends WithRefreshTokenClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this.observeNode = 'admin'
    this.initializeResponseInterceptor()
  }

  private static checkFile(file: File | null): boolean {
    return file instanceof File
  }

  public saveBannerFile(data: FormData, token: string): Promise<NodeApiTypes.TAddFileSuccess> {
    return this.instance.post('/landing/saveBannerFile', data, { params: { token } })
  }

  public uploadArticleImage({
    file,
    fileName,
    token,
    config = {}
  }: {
    file: Blob
    fileName: string
    token: string
    config: AxiosRequestConfig
  }): Promise<NodeApiTypes.TUploadArticleImageSuccess> {
    const formData = new FormData()
    formData.append('articleImage', file, fileName)
    return this.instance.post('/landing/uploadArticleImage', formData, {
      ...config,
      params: { token }
    })
  }

  public uploadArticleFile({
    file,
    fileName,
    token
  }: {
    file: File
    fileName: string
    token: string
  }): Promise<NodeApiTypes.TUploadArticleImageSuccess> {
    const formData = new FormData()
    formData.append('articleFile', file, fileName)
    return this.instance.post('/landing/uploadArticleFile', formData, { params: { token } })
  }

  public addFileBanner(file: File | null, token: string): Promise<NodeApiTypes.TAddFileSuccess> {
    return this.saveFile(file, token, '/personal/postBannerData')
  }

  public notificationBanner(
    file: FileWithPreview,
    token: string
  ): Promise<NodeApiTypes.TAddFileSuccess> {
    return this.saveFile(file, token, '/personal/notificationBanner')
  }

  public showServerFiles(token: string): Promise<NodeApiTypes.TShowServerFilesResponse> {
    return this.instance.get('/files/search', { params: { token } })
  }

  public refreshSitemap(
    token: string
  ): Promise<
    | { code: NodeApiTypes.ServerNodeResponseCode.success }
    | { code: NodeApiTypes.ServerNodeResponseCode.error }
  > {
    const formData = new FormData()
    formData.append('token', token)
    return this.instance.post('/sitemap/refreshSitemap', formData, { params: { token } })
  }

  public saveAuthorImage(photo: File | null, token: string): Promise<NodeApiTypes.TAddFileSuccess> {
    return this.saveFile(photo, token, '/authors/uploadAuthorImage')
  }

  private saveFile(
    file: File | null,
    token: string,
    url: string
  ): Promise<NodeApiTypes.TAddFileSuccess> {
    if (!NodeApi.checkFile(file))
      return Promise.reject(new ValidationError('Некорректный тип файла изображения'))
    const formData = new FormData()
    formData.append('file', file as File)
    return this.instance.post(url, formData, { params: { token } })
  }

  private initializeResponseInterceptor(): number {
    return this.instance.interceptors.response.use(this.onResponseSuccess)
  }
}

const nodeConfig: AxiosRequestConfig = {
  baseURL: new URL('/admin/api/v1', appConfig.host).href
}

export const nodeApi = new NodeApi(nodeConfig)
