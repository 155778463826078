import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { NavigateFunction } from 'react-router-dom'

import { adminApi } from '#modules/api'
import * as Types from '#modules/api/admin/types'
import { ResponseCode } from '#modules/api/types/common'
import { getErrorMessage } from '#reducers/helper'
import { closeModal } from '#reducers/ui/modalSlice'
import { TMainDataStateType } from '#src/reducers'

export type TButtonsInitialState = {
  isLoading: boolean
  error: string
}

type TDeleteParams = {
  button: Types.TButtonListItem
  token: TNullable<string>
}

const initialState: TButtonsInitialState = {
  isLoading: false,
  error: ''
}

export const deleteButton = createAsyncThunk(
  'personNotificationButton/delete',
  async ({ button, token }: TDeleteParams, { rejectWithValue, dispatch }) => {
    const formData = new FormData()
    formData.append('id', String(button.id))
    token && formData.append('token', token)
    const response = await adminApi.deleteNotificationButton(formData)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return rejectWithValue({
        message
      })
    }
    dispatch(closeModal())
    return button
  }
)

export const editButton = createAsyncThunk(
  'personNotificationButton/edit',
  async (
    { data, navigate }: { data: FormData; navigate: NavigateFunction },
    { rejectWithValue }
  ) => {
    const response = await adminApi.updateNotificationButton(data)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return rejectWithValue({
        message
      })
    }
    navigate('/admin/personal-notifications')
    return response.data
  }
)

const buttonSlice = createSlice({
  name: 'personNotificationButton',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(closeModal, (state) => {
        state.isLoading = false
        state.error = ''
      })
      .addMatcher(isAnyOf(deleteButton.pending, editButton.pending), (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isAnyOf(deleteButton.fulfilled, editButton.fulfilled), (state) => {
        state.isLoading = false
      })
      .addMatcher(isAnyOf(deleteButton.rejected, editButton.rejected), (state, action) => {
        state.isLoading = false
        state.error = getErrorMessage(action)
      })
  }
})

export const buttonSelector = (state: TMainDataStateType): TButtonsInitialState =>
  state.adminPage.notification.button

export default buttonSlice.reducer
