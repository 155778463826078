import { TFaqItem } from '#components/FAQ/types'

export const items: TFaqItem[] = [
  {
    title: 'Как можно вернуть деньги?',
    text:
      '1) Переводом с карты. <br />' +
      '2) Переводом на счёт. Он может занять до 3 рабочих дней. Учитывайте это, погашая заём в выходные и праздники. <br />' +
      '3) Переводом с мобильного телефона. Для подтверждения придёт СМС. <i>Внимание! Переводы со счёта «Билайн» недоступны</i>. <br />' +
      '4) Через «Элекснет». <br />' +
      '5) Через терминалы в любом банке <br />' +
      'Часть процентов можно погасить баллами (опция недоступна для займов по акции). Подробнее о способах оплаты <a href="https://4slovo.ru/kak_vernut_zajm" target="_blank">здесь</a>.'
  },
  {
    title: 'Как погасить заём без комиссии?',
    text: `Оплатите заём без комиссии в <a href="https://4slovo.ru/elecsnet-terminal" target="_blank">терминалах оплаты «Элекснет»</a>.<br /><br />
      В прочих случаях комиссия зависит от правил перевода денег у оператора.
`
  },
  {
    title: 'Как отказаться от страховки?',
    text:
      'Отправьте заявление: бланк можно получить у службы поддержки по почте <a href="mailto:info@4slovo.ru" target="_blank">info@4slovo.ru</a> или через <a href="https://4slovo.ru/contact" target="_blank">форму обратной связи</a>. Оформить возврат денег можно только в течение 30 календарных дней с даты заключения договора.'
  },
  {
    title: 'Как я могу пожаловаться?',
    text:
      'Отправьте обращение через <a href="https://4slovo.ru/complain" target="_blank">Приёмную по защите прав заёмщиков</a>, выбрав категорию — «жалоба на обслуживание» или «жалоба на взыскание»'
  },
  {
    title: 'Что будет, если я не успею вернуть деньги вовремя?',
    text:
      'Мы рекомендуем продлить заём. Продление не считается просрочкой, не портит кредитную историю и не влияет на статус клиента в <a href="https://4slovo.ru/privileges" target="_blank">Программе лояльности</a>.' +
      ' Нажмите кнопку «Продлить» в личном кабинете, выберите нужный срок пролонгации и оплатите проценты, начисленные по договору микрозайма.' +
      ' Срок возврата микрозайма переносится на указанный вами срок пролонгации. <br /> <br />' +
      ' Когда заём уходит в просрочку, на него продолжают начисляться проценты.' +
      ' Запись о долге попадает в кредитную историю и портит репутацию заёмщика.' +
      ' При следующей попытке получить кредит (заём) могут возникнуть проблемы.' +
      ' Поэтому старайтесь возвращать деньги вовремя! <br /><br />' +
      ' Если вариант с продлением вам не подходит, вы можете получить консультацию у сотрудника отдела взыскания.' +
      ' Вопрос по задолженности можно задать по телефону 8 800 511 28 19 (звонок бесплатный) с 08:00 до 17:00 по московскому времени в будний день.' +
      ' Для связи с оператором выберите в голосовом меню кнопку «3», далее нажмите кнопку «2» и дождитесь ответа.'
  },
  {
    title: 'Я могу продлить срок займа?',
    text:
      'В личном кабинете есть кнопка «Продлить» для тех займов, которые можно продлевать.' +
      ' Вы платите только проценты, начисленные по текущему договору микрозайма.' +
      ' МФК «Честное слово» не взимает каких-либо комиссий за пролонгацию.<br /><br />' +
      ' Продление недоступно для <a href="https://www.youtube.com/shorts/fINskYzjoqI" target="_blank">займов по акции</a>' +
      ' и займов с аннуитетными платежами!'
  },
  {
    title: 'Правила пролонгации?',
    text:
      'Продлить заём можно на срок до 30 дней.' +
      ' Доступное количество дней, на которое в данный момент вы можете произвести продление' +
      ' с учетом законодательных ограничений и выбранного способа оплаты, вы увидите в личном кабинете.<br /><br />' +
      ' Просроченный заём можно продлить только на 1-й, 2-й и 3-й день просрочки.'
  },
  {
    title: 'Как продлить заём в личном кабинете?',
    text:
      '1. Выбрать вкладку «Текущий заём» и нажать кнопку «Продлить». <br /><br />' +
      ' Если продление доступно, кнопка будет зелёного цвета. Если недоступно — серого.' +
      ' Для <a href="https://www.youtube.com/shorts/fINskYzjoqI" target=_blank">займов по акции</a> продление недоступно,' +
      ' кнопки «Продлить» в личном кабинете не будет! <br /><br />' +
      '2. Выбрать способ оплаты: <ul><li>банковской картой.</li>' +
      '<li>бонусными баллами. Недоступно для займов по акции.</li>' +
      '<li>со счёта мобильного телефона для операторов: МегаФон, Теле2, МТС. Переводы со счёта «Билайн» недоступны.</li></ul>' +
      '3. Выбрать количество дней и нажать кнопку «Оплатить».<br />' +
      '4. После оплаты зайти в личный кабинет и обновить страницу. Информация появится во вкладке «История платежей».'
  },
  {
    title: 'Как погасить заём досрочно?',
    text:
      'По кнопке «Полное досрочное погашение» в личном кабинете.<br /><br /> Как устроено полное досрочное погашение, можно узнать из <a href="https://youtu.be/7esVI912_ls?t=44" target="_blank">видео</a>. При частичном досрочном погашении зачислим платёж в дату по графику или в соответствии с договором. <br/ ><br /> В сумме платежа, пересчитанной к досрочному погашению, не отражаются бонусные баллы.<br /><br /> Если заём по акции погасить досрочно, скидка <a href="https://www.youtube.com/shorts/GTZigN71Y7Y" target="_blank">не сработает</a>. '
  },
  {
    title: 'Почему моя сумма по договору больше 1,3 x размера займа?',
    text:
      'Обратите внимание, что для договоров, заключённых до 1 июля 2023 года, действуют старые условия. То есть ставка 1% в день, предельная величина полной стоимости займа — 365% годовых и предельный размер задолженности по договору — до 150% от суммы займа.<br /><br /> Если у вас остались вопросы, <a href="https://4slovo.ru/contact" target="_blank">напишите</a> службе поддержки, выбрав категорию «Погашение займа».'
  },
  {
    title: 'Я погасил заём, но в БКИ остались старые данные.',
    text:
      '<a href="https://4slovo.ru/complain" target="_blank">Напишите</a> в Приёмную по защите прав заёмщиков, выбрав категорию «неверные данные в БКИ». Укажите номер займа или логин и прикрепите копию выписки из БКИ с информацией о займе, датой выписки и названием БКИ. Мы повторно направим данные в бюро.'
  },
  {
    title: 'Я уже погасил долг, почему звонят коллекторы?',
    text:
      'Коллекторы получают данные на следующий рабочий день после погашения долга. <br /> Если задолженность оплачена в пятницу, информация о её погашении придёт в понедельник.'
  },
  {
    title: 'Как получить справку о задолженности?',
    text:
      'В личном кабинете можно посмотреть, скачать и распечатать справку о текущей задолженности или погашенном займе. Перейдите на вкладку «История» и откройте раздел «Документы». Показываем, как это устроено, в <a href="https://www.youtube.com/shorts/mRGDutLdXt8" target="_blank">коротком видео</a>.'
  },
  {
    title: 'Как отозвать согласие на взаимодействие с третьими лицами?',
    text:
      'Заполните заявление (<a href="https://4slovo.ru/images/upload/files/20d65df88d10f761e6224b7d038e882c.pdf" target="_blank">скачать</a> форму) и отправьте письмом с уведомлением о вручении (заказным письмом), вручением под расписку или через нотариуса на адрес ООО МФК «ЧЕСТНОЕ СЛОВО»: 127051, г. Москва, 1-й Колобовский пер, д.6, стр.3, пом. Б, комн. 7–21.'
  },
  {
    title: 'Мошенники взяли заём на моё имя. Что делать?',
    text:
      'Изучите <a href="https://4slovo.ru/esli_vy_stali_zhertvoj_moshennikov/" target="_blank">инструкцию</a>, как действовать, если вы стали жертвой мошенников. Далее отправьте обращение через <a href="https://4slovo.ru/complain" target="_blank">Приёмную по защите прав заёмщиков</a>, выбрав категорию «мошенничество». '
  }
]
