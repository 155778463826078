import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { CreatePrefix } from '#reducers/helper'
import { personalApi } from '#src/modules/api'
import { TUserDocument } from '#src/modules/api/personal/types'

type TGetDocumentListParam = {
  creditId: number
  token: string
  shouldIncludeCreditParts: boolean
}

export interface IDocumentsInitialState {
  loading: boolean
  data: TUserDocument[]
}

const initialState: IDocumentsInitialState = {
  loading: false,
  data: []
}

const prefixHelper = new CreatePrefix('creditHistory')

export const fetchUserDocumentsRequest = createAction(
  prefixHelper.create('fetchUserDocumentsRequest')
)
export const fetchUserDocumentsSuccess = createAction<TUserDocument[]>(
  prefixHelper.create('fetchUserDocumentsSuccess')
)
export const fetchUserDocumentsFailure = createAction(
  prefixHelper.create('fetchUserDocumentsFailure')
)

export const fetchUserDocuments = createAsyncThunk(
  prefixHelper.create('fetchProfileSucceed'),
  async (
    { creditId, token, shouldIncludeCreditParts = false }: TGetDocumentListParam,
    thunkAPI
  ) => {
    thunkAPI.dispatch(fetchUserDocumentsRequest())
    try {
      const response = await personalApi.getDocumentList({
        creditId,
        token,
        shouldIncludeCreditParts
      })
      if (response.code !== 0) throw response
      thunkAPI.dispatch(fetchUserDocumentsSuccess(response.data))
    } catch (err) {
      thunkAPI.dispatch(fetchUserDocumentsFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserDocumentsRequest, (state) => {
        state.loading = true
      })
      .addCase(fetchUserDocumentsSuccess, (state, action) => {
        state.data = action.payload
        state.loading = false
      })
      .addCase(fetchUserDocumentsFailure, (state) => {
        state.loading = false
      })
})

export default documentsSlice.reducer
