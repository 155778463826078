import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TButtonType } from '#components/AdminPage/NotificationSettings/ButtonCard/ButtonForm/types'
import { adminApi } from '#modules/api'
import { deleteButton, editButton } from '#reducers/adminPage/personNotification/buttonSlice'
import { getErrorMessage } from '#reducers/helper'
import { closeModal } from '#reducers/ui/modalSlice'
import noty from '#services/notify'
import * as AdminApiTypes from '#src/modules/api/admin/types'
import { TMainDataStateType } from '#src/reducers'

export type TButtonsInitialState = {
  isLoading: {
    items: boolean
    item: boolean
  }
  isFetched: boolean
  items: AdminApiTypes.TButtonListItem[]
  pie: TComputedButtonPie[]
  error: {
    items: string
    item: string
  }
  count: number
}

export type TComputedButtonPie = {
  name: TButtonType
  value: number
}

type TCountButtonTypes = {
  [key in TButtonType]: number | never
}

type TPaginationProps = {
  page: number
  rowsPerPage: number
}

export type TEntries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export type TButtonsObjectStructure = {
  [key: number]: AdminApiTypes.TButtonListItem
}

export function computeButtonPie(buttons: AdminApiTypes.TButtonListItem[]): TComputedButtonPie[] {
  const countButtonTypes = {} as TCountButtonTypes
  for (const { type } of buttons)
    countButtonTypes[type] = countButtonTypes[type] ? countButtonTypes[type] + 1 : 1
  // принудительно говорим, что ключ это тип данных TButtonType а не string
  // @ts-ignore
  const entries: TEntries<TCountButtonTypes> = Object.entries(countButtonTypes)
  return entries.map(([key, value]) => ({
    name: key,
    value
  }))
}

const initialState: TButtonsInitialState = {
  isLoading: {
    item: false,
    items: false
  },
  isFetched: false,
  items: [],
  pie: [],
  error: {
    item: '',
    items: ''
  },
  count: 0
}

const calcPie = (
  items: AdminApiTypes.TButtonListItem[]
): {
  pie: TComputedButtonPie[]
  count: number
} => {
  const pie = computeButtonPie(items)
  const count = items.length
  return {
    pie,
    count
  }
}

const calcPieOptionsAfterRemove = (
  state: TButtonsInitialState,
  item: AdminApiTypes.TButtonListItem
): {
  items: AdminApiTypes.TButtonListItem[]
  pie: TComputedButtonPie[]
  count: number
} => {
  const items = state.items.filter((btn) => btn.id !== item.id)
  const { pie, count } = calcPie(items)
  return {
    items,
    pie,
    count
  }
}

const calcPieOptionsAfterAdd = (
  state: TButtonsInitialState,
  item: AdminApiTypes.TButtonListItem
): {
  items: AdminApiTypes.TButtonListItem[]
  pie: TComputedButtonPie[]
  count: number
} => {
  const items = state.items.concat(item)
  const { pie, count } = calcPie(items)
  return {
    items,
    pie,
    count
  }
}

export const fetchButtonList = createAsyncThunk(
  'personNotificationButtons/fetchList',
  async (token: string, { rejectWithValue }) => {
    const response = await adminApi.getNotificationButtonList(token)
    if (response.code !== AdminApiTypes.ResponseCode.success) {
      const { message } = response
      return rejectWithValue({
        message
      })
    }
    return response.data
  }
)

export const addButton = createAsyncThunk(
  'personNotificationButtons/addNewButton',
  async (buttonData: FormData, { rejectWithValue, dispatch }) => {
    const response = await adminApi.addNotificationButton(buttonData)
    if (response.code !== AdminApiTypes.ResponseCode.success) {
      const { message } = response
      return rejectWithValue({
        message
      })
    }
    const { data } = response
    dispatch(closeModal())
    noty.push({
      message: 'Успешно добавлено',
      type: 'success',
      alarmIcon: false,
      container: 'top-right'
    })
    return data
  }
)

const buttonsSlice = createSlice({
  name: 'personNotificationButtons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchButtonList.pending, (state) => {
        state.isLoading.items = true
        state.isFetched = false
        state.error.items = ''
      })
      .addCase(
        fetchButtonList.fulfilled,
        (state, action: PayloadAction<AdminApiTypes.TButtonListItem[]>) => {
          state.isLoading.items = false
          state.isFetched = true
          state.items = action.payload
          state.pie = computeButtonPie(action.payload)
          state.count = action.payload.length
        }
      )
      .addCase(fetchButtonList.rejected, (state, action) => {
        state.isLoading.items = false
        state.error.items = getErrorMessage(action)
      })
      .addCase(addButton.pending, (state) => {
        state.isLoading.item = true
        state.error.item = ''
      })
      .addCase(
        addButton.fulfilled,
        (state, action: PayloadAction<AdminApiTypes.TButtonListItem>) => {
          const { pie, items, count } = calcPieOptionsAfterAdd(state, action.payload)
          state.pie = pie
          state.items = items
          state.count = count
        }
      )
      .addCase(addButton.rejected, (state, action) => {
        state.isLoading.item = false
        state.error.item = getErrorMessage(action)
      })
      .addCase(
        deleteButton.fulfilled,
        (state, action: PayloadAction<AdminApiTypes.TButtonListItem>) => {
          const { pie, count, items } = calcPieOptionsAfterRemove(state, action.payload)
          state.pie = pie
          state.items = items
          state.count = count
        }
      )
      .addCase(
        editButton.fulfilled,
        (state, action: PayloadAction<AdminApiTypes.TButtonListItem>) => {
          state.items = state.items.map((btn) =>
            btn.id === action.payload.id ? { ...btn, ...action.payload } : btn
          )
        }
      )
  }
})

export const adminNotificationButtons = (state: TMainDataStateType): TButtonsInitialState =>
  state.adminPage.notification.buttons
export const adminNotificationButtonFetched = createSelector(
  adminNotificationButtons,
  (button) => button.isFetched
)
export const adminNotificationButtonLoadingItems = createSelector(
  adminNotificationButtons,
  (button) => button.isLoading.items
)
export const adminNotificationButtonLoadingItem = createSelector(
  adminNotificationButtons,
  (button) => button.isLoading.item
)
export const adminNotificationButtonCount = createSelector(
  adminNotificationButtons,
  (button) => button.count
)
export const adminNotificationButtonPie = createSelector(
  adminNotificationButtons,
  (button) => button.pie
)
export const adminNotificationButtonErrorItems = createSelector(
  adminNotificationButtons,
  (button) => button.error.items
)
export const adminNotificationButtonErrorItem = createSelector(
  adminNotificationButtons,
  (button) => button.error.item
)
export const adminNotificationButtonItems = createSelector(
  adminNotificationButtons,
  (button) => button.items
)

export const adminNotificationButtonPagination = (
  state: TMainDataStateType,
  props: TPaginationProps
): AdminApiTypes.TButtonListItem[] => {
  const { page, rowsPerPage } = props
  return state.adminPage.notification.buttons.items.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )
}

export const adminNotificationButtonItemsObjectStructure = createSelector(
  adminNotificationButtonItems,
  (buttons) =>
    buttons.reduce((acc, curr) => {
      const index = curr.id
      return {
        ...acc,
        [index]: curr
      }
    }, {})
)

export default buttonsSlice.reducer
