import classnames from 'classnames'
import { addDays, addMonths } from 'date-fns'
import FormData from 'form-data'
import { flattenDeep, has, isNull, isUndefined, keys, omitBy, pickBy } from 'lodash'
import { Route } from 'react-router-dom'

import config from '#config'
import { SCREEN_RESOLUTION } from '#constants/common'
import intl from '#intl'
import { restoreName } from '#services/fias'

export const preventWindowScrollWithOpenMenu = (prevent) => {
  const noScrollClass = 'no-scroll'
  const app = document.getElementById('app')
  const classNames = (app.className || '').split(' ')
  if (prevent) classNames.push(noScrollClass)
  else classNames.splice(classNames.indexOf(noScrollClass), 1)
  app.className = classNames.length ? classNames.join(' ') : ''
}

export const preventWindowScroll = (prevent) => {
  const noScrollClass = 'no-scroll'
  const scrollingElement = document.scrollingElement || document.documentElement
  const app = document.getElementById('app')
  const { scrollTop } = scrollingElement
  const classNames = (app.className || '').split(' ')
  let noScrollLevel = parseInt(app.getAttribute('data-noscroll-level') || 0, 10)

  if (prevent) {
    noScrollLevel += 1

    if (noScrollLevel <= 1) {
      classNames.push(noScrollClass)
      app.style.top = -scrollTop + 'px'
    }
  } else {
    const _scrollTop = parseInt(app.style.top, 10)
    noScrollLevel -= 1

    if (noScrollLevel === 0) {
      classNames.splice(classNames.indexOf(noScrollClass), 1)
      app.style.top = null
      setTimeout(() => {
        scrollingElement.scrollTop = _scrollTop * -1
      }, 0)
    }
  }

  app.className = classNames.length ? classNames.join(' ') : ''
  app.setAttribute('data-noscroll-level', noScrollLevel)
}

export const formatValueByCount = (count, values) => {
  const num10 = count % 10
  const num100 = count % 100
  if (num10 === 1 && num100 !== 11) return `${count} ${values[0]}`
  if (num10 > 4 || (num100 < 20 && num100 > 10) || num10 === 0) return `${count} ${values[2]}`
  return `${count} ${values[1]}`
}

export const isScreenMobile = () => window.innerWidth < SCREEN_RESOLUTION.tablet

export const isScreenTablet = () =>
  window.innerWidth >= SCREEN_RESOLUTION.tablet && window.innerWidth < SCREEN_RESOLUTION.desktop

export const isScreenDesktop = () => window.innerWidth >= SCREEN_RESOLUTION.desktop

export const scrollTo = (target, offset = 0, callback = null) => {
  const scrollingElement = document.scrollingElement || document.documentElement
  let element = null
  let scrollY

  switch (typeof target) {
    case 'number':
      scrollY = target
      break
    case 'string':
      element = document.querySelector(target)
      scrollY = element
        ? scrollingElement.scrollTop + element.getBoundingClientRect().top - offset
        : 0
      break
    default:
      scrollY = target
        ? scrollingElement.scrollTop + target.getBoundingClientRect().top - offset
        : 0
  }

  if (typeof callback === 'function') {
    const onScroll = () => {
      if (Math.floor(window.pageYOffset) === Math.floor(scrollY)) {
        window.removeEventListener('scroll', onScroll)
        callback()
      }
    }

    window.addEventListener('scroll', onScroll)
    onScroll()
  }

  window.scrollTo({
    top: scrollY,
    behavior: 'smooth'
  })
}

export const getTabsByRow = ({ row }) => {
  const { finished } = row
  const tabs = new Set()

  tabs.add('handleMoveToShowForm')
  tabs.add('handlePaymentsMethod')

  if (!finished) {
    tabs.add('handleMoveToCreditForm')
    tabs.add('handleCardPhotosForBroker')
  }

  return Array.from(tabs)
}

export const restoreForm = (data, token) => {
  const recoverData = { ...data }
  const { passportCityGuid, passportCity, cityGuid, city, city1Guid, city1, birthPlace } = data
  const promises = []

  // восстанавливаем данные если была регистрация со старого фронта по guid данным
  if (passportCityGuid && !passportCity) {
    const params = {
      aoguid: passportCityGuid,
      token
    }
    promises.push(restoreName(params))
  } else {
    promises.push(Promise.resolve({ data: { name: passportCity } }))
  }

  if (cityGuid && !city) {
    const params = {
      aoguid: cityGuid,
      token
    }
    promises.push(restoreName(params))
  } else {
    promises.push(Promise.resolve({ data: { name: city } }))
  }

  if (city1Guid && !city1) {
    const params = {
      aoguid: city1Guid,
      token
    }
    promises.push(restoreName(params))
  } else {
    promises.push(Promise.resolve({ data: { name: city1 } }))
  }

  // так как место рождения может быть выбрана либо из списка
  // либо введена вручную то проверяем на guid
  // если guid, то пользователь на старом фронте выбрал адресс из списка
  // и мы его перезаписываем восстановленным из guid
  // иначе ничего не делаем
  const regex = new RegExp(/^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/)
  if (regex.test(birthPlace)) {
    const params = {
      aoguid: birthPlace,
      token
    }
    promises.push(restoreName(params))
  } else {
    promises.push(Promise.resolve({ data: { name: birthPlace } }))
  }

  return Promise.all(promises)
    .then((response) => {
      const [passport, city, city1, birthPlace] = response

      recoverData.passportCity = passport.data.name
      recoverData.city = city.data.name
      recoverData.city1 = city1.data.name
      recoverData.birthPlace = birthPlace.data.name
      return omitBy(recoverData, isUndefined)
    })
    .catch(() => recoverData)
}

export const nl2br = (str, isXhtml = false) => {
  const breakTag = isXhtml ? '<br />' : '<br>'
  return String(str).replace(/([^\n\r>]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export const pl2span = (str) => str.replace(/<p[^>]*>/g, '<span>').replace(/<\/p>/g, '</span>')

export const responseError = (response) => {
  if (!response || !has(response, 'code')) return intl.serverError
  if (response.code === 1) {
    const { message = '' } = response
    if (message) return message
    return intl.serverError
  }
  if ([3, 5, 6].includes(response.code) && !Object.keys(response.data || {}).length)
    return response.message || intl.serverError
}

export const downloadBlob = (data, fileName) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = window.URL.createObjectURL(data)
  a.setAttribute('download', fileName)
  a.click()
}

export const download = (fileUrl, fileName) => {
  const a = document.createElement('a')
  a.href = fileUrl
  a.setAttribute('download', fileName)
  a.setAttribute('target', '__blank')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const downloadUsingFetch = async (fileUrl, fileName) => {
  const file = await fetch(fileUrl)
  const fileBlob = await file.blob()
  const fileBlobUrl = window.URL.createObjectURL(fileBlob)
  const a = document.createElement('a')
  a.href = fileBlobUrl
  a.setAttribute('download', fileName)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(fileBlobUrl)
}

export const returnNull = () => null

export const formatAsMoney = (value, floatDigits = 0) =>
  `${Number(value).toFixed(floatDigits)} ${intl.currency}`

export const formatAsPercent = (value) => `${value} %`

export const getHexColorFromString = (str) => {
  const colors = [
    '#e51c23',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#5677fc',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#259b24',
    '#8bc34a',
    '#afb42b',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b'
  ]
  const hash = str.split('').reduce((acc, char) => char.charCodeAt() + ((acc << 5) - acc), 0)
  const normalizedHash = ((hash % colors.length) + colors.length) % colors.length
  return colors[normalizedHash]
}

export const animateCSS = (node, animationNames, callback) => {
  if (!node) return
  const animationClasses = flattenDeep(['animated', animationNames])
  node.classList.add(...animationClasses)

  const handleAnimationEnd = () => {
    node.classList.remove(...animationClasses)
    node.removeEventListener('animationend', handleAnimationEnd)
    if (typeof callback === 'function') callback()
    if (callback === 'loop') setTimeout(() => animateCSS(node, animationNames, callback), 0)
  }

  node.addEventListener('animationend', handleAnimationEnd)
}

export const animateCSSAsync = (node, animationName) =>
  new Promise((resolve) => {
    animateCSS(node, animationName, resolve)
  })

export const handleCheckRegistartionDone = (response = {}) => {
  const { code } = response
  if (code === 303) {
    return {
      registerError: new Error('registartion is done'),
      redirectUrl: response.message
    }
  }
  return response
}

/**
 * Check if argument is Blob
 * @param {Blob|string} file
 */
export const isBlobFile = (file) => file instanceof global.Blob || file.startsWith('blob:')

/**
 * Same as lodash's compact, but for objects
 * Returns new object with omitted falsy fields
 * @param {object} obj
 */
export const compactKeys = (obj) => keys(pickBy(obj))
/**
 * Trying to return new URL(url, base).href, otherwise, if parsing failed, returns "url" parameter
 * @param {string} url
 * @param {string} base
 */
export const buildUrl = (url, base) => {
  let result = url
  try {
    result = new URL(url, base).href
  } catch (e) {
    return url
  }
  return result
}

export const buildDataQa = (base, ...parts) => {
  if (base) return [base, ...parts].join('_')
}

const stateType = (filed, errors, apiErrors) => {
  if (errors[filed] && !apiErrors[filed]) return 'validationError' // ошибку отловил фронт
  if (errors[filed] && apiErrors[filed]) return 'formFieldsError' // ошибку отловил api
  return 'ok'
}

export const logFields = ({ token, step, data, action, errors, validationApiErrors }, model) => {
  const logData = new FormData()
  logData.append('token', token)
  logData.append('stage', step)
  logData.append('action', action)
  model.forEach((item) => {
    const { systemName: field } = item
    const value = isUndefined(data[field]) ? null : data[field]
    const slicedValue = typeof value === 'string' ? value.slice(0, 254) : value
    const state = stateType(field, errors, validationApiErrors)
    logData.append(
      'fields[]',
      JSON.stringify({
        field,
        value: slicedValue,
        state
      })
    )
  })
  return logData
}

/**
 * Interpolates string by replacing placeholders (map.key) with values (map.values)
 * Placeholders should be defined as following '...{{placeholderKey}}...'
 * @param {string} source
 * @param {object} map
 */
export const interpolate = (source = '', map = {}) => {
  const pairs = Object.entries(map)
  const inner = (str, replacers) => {
    if (replacers.length === 0) return str
    const [first, ...rest] = replacers
    const [key, value] = first
    const replaced = str.replace(`{{${key}}}`, value)
    return inner(replaced, rest)
  }
  return inner(source, pairs)
}

export const hasProfit = ({ percents, percentsWithPromo }) =>
  !isNull(percentsWithPromo) && percentsWithPromo < percents

export const objectToFormData = (params, extraKey) => {
  const formData = new FormData()
  Object.entries(params).forEach(([key, value]) => {
    if (extraKey) formData.append(`${extraKey}[${key}]`, String(value ?? ''))
    else formData.append(`${key}`, String(value ?? ''))
  })
  return formData
}

export const buildSchemaOrg = (prod, pathname) => {
  const { authorData } = prod
  const data = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': config.host + pathname // разобраться с url//
    },
    headline: prod.title,
    image: prod.imageSource,
    datePublished: prod.publishDate,
    dateModified: prod.modifyDate,
    publisher: {
      '@type': 'Organization',
      name: 'Редакция онлайн-сервиса "Честное слово"',
      logo: { '@type': 'ImageObject', url: 'http: //www.w3.org/2000/svg' }
    },
    description: prod.seoDescription
  }

  if (authorData) {
    const isRedactionAuthor = authorData.id === 1
    let author
    if (isRedactionAuthor) {
      author = {
        '@type': 'Organization',
        name: authorData.name,
        url: 'https://4slovo.ru/'
      }
    } else {
      author = {
        '@type': 'Person',
        name: authorData.name,
        jobTitle: authorData.uniqPosition
      }
    }

    data.author = author
  }

  return JSON.stringify(data)
}

export const getUrlPath = (req) => {
  if (typeof window === 'undefined') return req.path
  if (!req && window) return window.location.pathname + window.location.search
  return req.path
}
export const getModificators = (dimension) => {
  if (dimension === 'day') {
    return {
      dateModificator: addDays,
      dateList: intl.days
    }
  }
  return {
    dateModificator: addMonths,
    dateList: intl.monthsList
  }
}

export const normalizePhoneNumber = (phoneNumber = '') => {
  const phoneWithNumbersOnly = String(phoneNumber).replace(/\D/g, '')
  const numbersCount = phoneWithNumbersOnly.length
  const [countryCode] = phoneWithNumbersOnly
  if (numbersCount === 11) {
    if (Number(countryCode) === 7) return phoneWithNumbersOnly
    if (Number(countryCode) === 8) return phoneWithNumbersOnly.replace(/^8/, '7')
  }
  if (numbersCount === 10 && Number(countryCode) === 9) return `7${phoneWithNumbersOnly}`

  return phoneWithNumbersOnly
}

export const setClassNameToTabList = ({ isActive, device }) => {
  const { isMobile } = device
  return classnames({
    'mobile-tab-list-item': isMobile,
    'mobile-tab-list-active': isActive && isMobile,
    'tab-list-item': !isMobile,
    'tab-list-active': isActive && !isMobile
  })
}

export const getRoutesList = (routes, element) =>
  routes.map((url, index) => <Route path={url} key={index} element={element} />)
