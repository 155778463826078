import { TFaqItem } from '#components/FAQ/types'

export const items: TFaqItem[] = [
  {
    title: 'У меня проблема с заполнением заявки. Что делать?',
    text:
      'Обращайте внимание на подсказки красным цветом под полями заявки.<br /><br /> Если вы не нашли ответа на ваш вопрос на этой странице, <a href="https://4slovo.ru/contact" target="_blank">напишите</a> службе поддержки. Часы работы — 10:00–22:00 по Москве.'
  },
  {
    title: 'Как сделать и загрузить фотографию карты?',
    text:
      'Посмотрите короткую <a href="https://4slovo.ru/photo" target="_blank">видеоинструкцию</a>. <br /> Если остались вопросы, <a href="https://4slovo.ru/contact" target="_blank">напишите</a> службе поддержки, она работает с 10:00 до 22:00 по Москве.'
  },
  {
    title: 'Моего населённого пункта нет в списке. Что делать?',
    text: 'Выбирайте ближайшую к вам точку.'
  },
  {
    title: 'Мне не пришло СМС с кодом. Что делать?',
    text:
      'СМС не всегда приходят сразу. Иногда нужно подождать некоторое время. <br /><br /> Убедитесь, что верно указали номер. Проверьте, не включена ли блокировка приёма сообщений с коротких номеров. Узнать это можно у вашего оператора.<br /> <br /> Нажмите кнопку «повторить СМС». Запросить повтор можно 2 раза. Если код не пришёл, <a href="https://4slovo.ru/contact" target="_blank">напишите</a> службе поддержки. '
  }
]
