import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { CreatePrefix } from '#reducers/helper'
import { personalApi } from '#src/modules/api'
import { IGetLoanHistorySuccess, TUserDocument } from '#src/modules/api/personal/types'

export type TActionClientCreditHistorySuccess = {
  payload: {
    totalItems: number
    itemsPerPage: number
    currentPage: number
    items: {
      [key: string]: any
    }
  }
}

type TCreditItems = {
  id: 1
  amount: string
  createTs: string
  status: string
  payments: {
    paymentSum: {
      value: number
      format: string
      title: string
    }
  }
  documents: TUserDocument
}

type TGetLoanHistoryParam = {
  token: string
  itemsPerPage: number
  pageNumber: number
}

interface ICreditHistoryState {
  loading: boolean
  data: {
    totalItems: number
    itemsPerPage: number
    items: Partial<TCreditItems>
  }
}

const initialState: ICreditHistoryState = {
  loading: false,
  data: {
    totalItems: 0,
    itemsPerPage: 0,
    items: {}
  }
}

const prefixHelper = new CreatePrefix('creditHistory')

export const fetchClientCreditHistoryRequest = createAction(
  prefixHelper.create('fetchClientCreditHistoryRequest')
)
export const fetchClientCreditHistorySuccess = createAction<IGetLoanHistorySuccess>(
  prefixHelper.create('fetchClientCreditHistorySuccess')
)
export const fetchClientCreditHistoryFailure = createAction(
  prefixHelper.create('fetchClientCreditHistoryFailure')
)

export const fetchClientCreditHistory = createAsyncThunk(
  prefixHelper.create('getLoanHistory'),
  async ({ token, pageNumber, itemsPerPage }: TGetLoanHistoryParam, thunkAPI) => {
    thunkAPI.dispatch(fetchClientCreditHistoryRequest())
    try {
      const response = await personalApi.getLoanHistory({ token, pageNumber, itemsPerPage })
      if (response.code !== 0) throw response
      thunkAPI.dispatch(fetchClientCreditHistorySuccess(response))
    } catch (err) {
      thunkAPI.dispatch(fetchClientCreditHistoryFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const creditHistorySlice = createSlice({
  name: 'creditHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchClientCreditHistoryRequest, (state) => {
        state.loading = true
      })
      .addCase(fetchClientCreditHistorySuccess, (state, action) => {
        state.data.totalItems = action.payload.data.totalItems
        state.data.itemsPerPage = action.payload.data.itemsPerPage
        state.data.items = {
          ...state.data.items,
          [String(action.payload.data.currentPage)]: action.payload.data.items
        }
        state.loading = false
      })
      .addCase(fetchClientCreditHistoryFailure, (state) => {
        state.loading = false
      })
})

export default creditHistorySlice.reducer
