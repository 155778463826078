import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css'

import { noop, uniqueId } from 'lodash'
import { store } from 'react-notifications-component'

import FlashMessage from '../components/FlashMessage/FlashMessage'

const DISMISS_DURATION = 7000
export const buildNotification = (options = {}) => {
  const {
    id = uniqueId('minor_'),
    message = '',
    type = 'info',
    permanent = Boolean(options.id),
    onRemoval = noop,
    container = 'top-right',
    onClose = null,
    style = {},
    className = '',
    dismissible,
    alarmIcon,
    width,
    dismissDuration = DISMISS_DURATION
  } = options

  const onCloseAction = (id) => () => (onClose ? onClose(id) : store.removeNotification(id))

  return {
    id,
    title: '',
    message,
    type,
    insert: 'top',
    container,
    content: (
      <FlashMessage
        message={message}
        dismissible={dismissible}
        alarmIcon={alarmIcon}
        style={style}
        className={className}
        type={type}
        onClose={onCloseAction(id)}
      />
    ),
    animationIn: ['animated', 'faster', 'fadeInLeft'],
    animationOut: ['animated', 'faster', 'fadeOutRight'],
    slidingEnter: {
      duration: 300,
      timingFunction: 'linear',
      delay: 0
    },
    slidingExit: {
      duration: 300,
      timingFunction: 'linear',
      delay: 0
    },
    touchRevert: {
      duration: 600,
      timingFunction: 'linear',
      delay: 0
    },
    touchSlidingExit: {
      swipe: {
        duration: 600,
        timingFunction: 'linear',
        delay: 0
      },
      fade: {
        duration: 300,
        timingFunction: 'linear',
        delay: 0
      }
    },
    dismiss: {
      duration: permanent ? 0 : dismissDuration,
      pauseOnHover: true,
      waitForAnimation: true,
      onScreen: false,
      showIcon: false,
      click: false,
      touch: false
    },
    onRemoval,
    width
  }
}

const clearNotifications = () => {
  const notifications = document.querySelectorAll('.notification-item')
  Array.from(notifications).forEach((node) => {
    node.innerHTML = ''
  })
}

export default {
  push: (options) => store.addNotification(buildNotification(options)),
  removeById: (id) => store.removeNotification(id),
  clear: clearNotifications
}
