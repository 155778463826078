import { createAsyncThunk } from '@reduxjs/toolkit'

import intl from '#intl'
import { adminApi } from '#modules/api'
import { TGetProductDescriptionByIdParams } from '#modules/api/admin/types'
import { ResponseCode } from '#modules/api/types/common'
import {
  removeCurrentDescriptionFailure,
  removeCurrentDescriptionRequest
} from '#reducers/adminPage/productsInfo/productInfoSlice'
import { TRemoveProductDescriptionPayload } from '#reducers/adminPage/productsInfo/types'
import { CreatePrefix } from '#reducers/helper'

const prefixHelper = new CreatePrefix('productDescription')

export const getProductDescription = createAsyncThunk(
  prefixHelper.create('getProductDescription'),
  async (_, thunkAPI) => {
    const response = await adminApi.getProductDescription()
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)

    return thunkAPI.fulfillWithValue(response)
  }
)

export const getProductDescriptionById = createAsyncThunk(
  prefixHelper.create('getProductDescriptionById'),
  async ({ id, token, cancelToken }: TGetProductDescriptionByIdParams, thunkAPI) => {
    const response = await adminApi.getProductDescriptionById({ id, token, cancelToken })
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)
    return thunkAPI.fulfillWithValue(response.data)
  }
)

export const getPersonGroups = createAsyncThunk(
  prefixHelper.create('getPersonGroups'),
  async (_, thunkAPI) => {
    const response = await adminApi.getPersonGroups()
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)

    return thunkAPI.fulfillWithValue(response.data)
  }
)

export const saveCurrentDescription = createAsyncThunk(
  prefixHelper.create('saveCurrentDescription'),
  async (formData: FormData, thunkAPI) => {
    try {
      let response
      // если есть id - то обновление, если нет, то добавляем запись
      if (formData.has('id')) response = await adminApi.saveProductDescription(formData)
      else response = await adminApi.addProductDescription(formData)

      if (response.code === ResponseCode.success) {
        void thunkAPI.dispatch(getProductDescription())
        void thunkAPI.dispatch(getPersonGroups())
        return thunkAPI.fulfillWithValue(response)
      }
      return thunkAPI.rejectWithValue(response)
    } catch (err: unknown) {
      // @ts-ignore
      const message = (err?.message || intl.serverError) as string
      return thunkAPI.rejectWithValue({ message })
    }
  }
)

export const removeCurrentDescription = createAsyncThunk(
  prefixHelper.create('removeCurrentDescription'),
  async (params: TRemoveProductDescriptionPayload, thunkAPI) => {
    const { id, token } = params

    const formData = new FormData()
    formData.append('id', String(id))
    formData.append('token', token)
    thunkAPI.dispatch(removeCurrentDescriptionRequest(id))

    const response = await adminApi.deleteProductDescription(formData)
    void thunkAPI.dispatch(getProductDescription())
    void thunkAPI.dispatch(getPersonGroups())
    if (response.code !== ResponseCode.success) {
      thunkAPI.dispatch(removeCurrentDescriptionFailure(id))
      return thunkAPI.rejectWithValue(response)
    }
    return thunkAPI.fulfillWithValue(response)
  }
)

export const getActiveLoanConditionsWithActivePersonGroup = createAsyncThunk(
  prefixHelper.create('getActiveLoanConditionsWithActivePersonGroup'),
  async (token: string, thunkAPI) => {
    const response = await adminApi.getActiveLoanConditionsWithActivePersonGroup(token)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
    return thunkAPI.fulfillWithValue(response.data)
  }
)
