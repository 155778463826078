import classnames from 'classnames'
import { CSSProperties, FC, useCallback, useEffect, useState } from 'react'

import Button from '#components/Button'
import intl from '#intl'

import Icon from '../Icon'
import { DEFAULT_CLASSES, DEFAULT_STYLE, RECONNECT_INTERVAL_S } from './constants'

interface IChunkLoadErrorProps {
  attempt: number
  attempts: number
  onManualReset: () => void
  isAllAttemptsFailed: boolean
  className?: string
  style?: CSSProperties
}

const ChunkLoadError: FC<IChunkLoadErrorProps> = (props) => {
  const [reconnectCount, setReconnectCount] = useState(RECONNECT_INTERVAL_S)

  useEffect(() => {
    const UPDATE_INTERVAL_S = 1
    const UPDATE_INTERVAL_MS = UPDATE_INTERVAL_S * 1000
    const timerId = setInterval(
      () => setReconnectCount((counter) => counter - UPDATE_INTERVAL_S),
      UPDATE_INTERVAL_MS
    )
    return () => clearInterval(timerId)
  }, [])

  const { attempt, attempts, onManualReset, isAllAttemptsFailed, className, style } = props

  const handleReset = useCallback(() => onManualReset && onManualReset(), [onManualReset])

  return (
    <div
      className={classnames({ [DEFAULT_CLASSES]: true }, className)}
      style={{ ...DEFAULT_STYLE, ...style }}
    >
      <Icon
        name='no-internet'
        style={{
          position: 'absolute',
          zIndex: 0,
          maxWidth: 150,
          height: '75%',
          left: '5%',
          color: 'rgba(0, 0, 0, 0.075)'
        }}
      />
      <p className='text-uppercase text-secondary fw-bold'>{'Неполадки с соединением'}</p>
      {isAllAttemptsFailed ? (
        <>
          <p className='text-center'>{'Не удалось восстановить подключение, попробуйте позже'}</p>
          <Button inverted size='s' color='gray' className='mx-auto my-2' onClick={handleReset}>
            {intl.repeat}
          </Button>
        </>
      ) : (
        <>
          <p>{`Повторное соединение через ${reconnectCount} секунд`}</p>
          {Boolean(attempt && attempts) && <p>{`Попытка ${attempt} из ${attempts}`}</p>}
        </>
      )}
    </div>
  )
}

export default ChunkLoadError
