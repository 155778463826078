import intl from '#intl'

import BaseHttpClientError from './BaseHttpClientError'

export default class NotFoundError extends BaseHttpClientError {
  constructor(message = intl.pageNotFound) {
    super(message)
    this.name = 'NotFoundError'
    this.code = 404
  }
}
