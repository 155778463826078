import { debounce, identity, isUndefined, noop, omit, pick } from 'lodash'

type TData = Record<string, unknown>

type TNullObject = Record<string, undefined>

enum Modes {
  include = 'include',
  exclude = 'exclude'
}

type TRecord =
  | {
      identityToken: string
      data: TData
    }
  | TNullObject

type TFilter = {
  mode: keyof typeof Modes
  keys?: Array<string | undefined>
}

interface IOptions {
  debounceTime?: number
  filter?: TFilter
  identityToken?: string | null
}

/**
 * @class
 * Класс для сохранения / восстановления данных из storage клиента
 */
export default class DataSaver {
  /**
   * @property
   * @description Ключ в хранилище
   */
  private readonly _key: string

  /**
   * @property
   * @description Массив ключей которые нужно сохранять,
   * если не передан, будут созранены все ключи
   */
  private readonly _filter?: TFilter

  /**
   * @property
   * @description Уникальный идентификатор,
   * если не будет совпадать с тем что записан в хранилище - запись из хранилища будет удалена
   */
  private readonly _identityToken: string

  /**
   * @property
   * @description Уникальный идентификатор,
   * если не будет совпадать с тем что записан в хранилище - запись из хранилища будет удалена
   */
  get identityToken(): string | null {
    return this._identityToken || null
  }

  /**
   * @property
   * @description Ключ в хранилище
   */
  get key(): string {
    return this._key
  }

  /**
   * @constructor
   * @param {string} key
   * @param {IOptions=} options
   */
  constructor(key: string, options: IOptions = {}) {
    if (!key) throw new Error('Key is mandatory parameter')
    const { debounceTime, filter, identityToken } = options
    this._key = key
    this._filter = filter
    this._identityToken = identityToken || ''
    this.saveData = debounceTime
      ? debounce(this.saveData.bind(this), debounceTime)
      : this.saveData.bind(this)
    this.checkIdentity()
  }

  /**
   * @method
   * @description Сохраняет переданные данные в хранилище
   * Если хранилище недоступно - ничего не происходит
   * @param {TData=} data
   */
  public saveData(data: TData = {}): void {
    try {
      window.localStorage.setItem(
        this._key,
        JSON.stringify({
          identityToken: this._identityToken,
          data: this.filterDataBeforeSave(data)
        })
      )
    } catch (e) {
      noop(e)
    }
  }

  /**
   * @method
   * @description Извлекает сохраненные данные из хранилища
   * Если хранилище недоступно - возвращает null
   * @returns {TData|null}
   */
  public getData(specificKeys?: Array<string>): TData | TNullObject {
    const data = this.getRecord().data ?? {}
    return specificKeys ? pick(data, specificKeys) : data
  }

  /**
   * @method
   * @description Очищает данные в хранилище
   * Если хранилище недоступно - ничего не происходит
   */
  public clear(): void {
    try {
      window.localStorage.removeItem(this._key)
    } catch (e) {
      noop(e)
    }
  }

  /**
   * @method
   * @description Проверяет совпадение identityToken с тем что записан в хранилище,
   * Если не совпадает - очищает хранилище
   */
  private checkIdentity(): void {
    const savedIdentityToken = this.getRecord().identityToken ?? null
    if (savedIdentityToken !== this._identityToken) this.clear()
  }

  /**
   * @method
   * @description Извлекает запись из хранилища
   * Если хранилище недоступно - возвращает пустой объект
   * @returns {TRecord}
   */
  private getRecord(): TRecord {
    try {
      const data = window.localStorage.getItem(this._key)
      return data ? (JSON.parse(data) as TRecord) : {}
    } catch (_e) {
      return {}
    }
  }

  /**
   * @method
   * @description Фильтрует данные
   * @param {TData=} data
   * @returns {TData}
   */
  private filterDataBeforeSave(data: TData = {}): TData {
    if (isUndefined(this._filter)) return data
    const { mode, keys } = this._filter
    const selector =
      {
        [Modes.include]: pick,
        [Modes.exclude]: omit
      }[mode] ?? identity
    // @ts-ignore
    return selector(data, keys) as TData
  }
}
