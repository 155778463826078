import axios from 'axios'
import { identity } from 'lodash'

import { buildLogRecord } from './buildLogRecord'
import { LOGGING_SERVICE_URL } from './constants'
import { IBuildLogRecordOptions, IProcessedLogRecord, IReportOptions } from './types'
import { isBrowserEnvironment, isNodeEnvironment, isReportEnabled } from './utils'

const defaultFallback = (e: any): void => console.error(e)
export async function reportError(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  options: IReportOptions & IBuildLogRecordOptions = {},
  context: CallableFunction = reportError
): Promise<void> {
  const { processStack = identity, guid = '', fallback = defaultFallback, append = {} } = options
  if (!isReportEnabled() && isBrowserEnvironment()) return fallback(data)
  try {
    const record = await buildLogRecord(data, { guid }, context)
    if (!record) return
    const { stack } = record
    const processedStack = await processStack(stack)
    const stringifiedStack = processedStack?.map((sf) => sf.toString()) || []
    const processedRecord: IProcessedLogRecord = { ...append, ...record, stack: stringifiedStack }
    if (!isReportEnabled() && isNodeEnvironment()) return fallback(data)
    await axios.post(LOGGING_SERVICE_URL, processedRecord).catch(() => fallback(processedRecord))
  } catch (e) {
    fallback(e)
  }
}

export default reportError
