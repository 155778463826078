import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction
} from '@reduxjs/toolkit'
import { findKey, flattenDeep, head, last, remove } from 'lodash'

import {
  ChangeSitemapValueError,
  UpdateGroupError,
  UpdateRelationShipError
} from '#components/AdminPage/Sitemap/errors'
import { adminApi } from '#modules/api'
import { ResponseCode } from '#modules/api/types/common'
import { Queue } from '#modules/Queue'
import { TListKeys } from '#reducers/commonTypes'
import {
  createPageListStructure,
  createPageListStructureDeep,
  CreatePrefix,
  getErrorMessage
} from '#reducers/helper'
import noty from '#services/notify'
import * as AdminApiTypes from '#src/modules/api/admin/types'
import { TMainDataStateType } from '#src/reducers'

type TSitemapListState = {
  items: AdminApiTypes.TSitemapItem[]
}
type TInitialState = {
  inactive: TSitemapListState
  activeItemSelected: TNullable<AdminApiTypes.TSitemapItem>
  inactiveItemSelected: TNullable<AdminApiTypes.TSitemapItem>
  isLoading: boolean
  currentSitemap: TNullable<TExtendedSitemapItem>
  pageGroup: {
    currentSitemapGroup: TNullable<AdminApiTypes.TPageGroup>
    list: TListKeys<AdminApiTypes.TPageGroup>
    isLoading: boolean
    errorMessage: string
    isOpen: boolean
  }
  activePages: {
    grouped: TListKeys<AdminApiTypes.IPageGroupWithPages>
    float: TListKeys<AdminApiTypes.TSitemapItem>
    all: TListKeys<AdminApiTypes.TSitemapItem>
  }
  lastPage: {
    maxOrder: number
    page: TNullable<AdminApiTypes.TSitemapItem>
  }
  canUndo: boolean
}

type TSnapShotActivePages = Pick<TInitialState, 'activePages'>

type TSiteMapWithParam = {
  token: string
  inSitemap: 0 | 1
}

type TChangeSitemapValueArgs = {
  id: number
  siteMap: number
  token: string
}

export type TAddAfterPage = {
  after: number
  children: number
  queueData?: TDropSitemapOn
}

export type TAddToChildList = {
  parent: number
  children: number
  queueData?: TDropSitemapOn
}

export enum SitemapItemType {
  active = 'active',
  inactive = 'inactive'
}

export type TExtendedSitemapItem = AdminApiTypes.TSitemapItem & { type: SitemapItemType }

export enum DropSitemapAction {
  dropSitemapItemOnSitemapItem = 'dropSitemapItemOnSitemapItem',
  dropSitemapItemOnDivider = 'dropSitemapItemOnDivider',
  dropSitemapItemOnPageGroup = 'dropSitemapItemOnPageGroup',
  toggleSitemapValueInPage = 'toggleSitemapValueInPage'
}
type TDropSitemapOn = {
  action: DropSitemapAction
  sitemapData: AdminApiTypes.TSitemapItem
  type: SitemapItemType
  currentSitemap: TExtendedSitemapItem
  isShowChildNodes?: boolean
} & TSnapShotActivePages

export const queue = new Queue<TDropSitemapOn>({ maxLength: 1 })
function getElementBy<T>(id: number, list: TListKeys<T>): T {
  return list.byId[id]
}

const sitemapListState: TSitemapListState = {
  items: []
}

const initialState: TInitialState = {
  inactive: sitemapListState,
  activeItemSelected: null,
  inactiveItemSelected: null,
  isLoading: false,
  currentSitemap: null,
  pageGroup: {
    currentSitemapGroup: null,
    list: {
      byId: {},
      allIds: []
    },
    isLoading: false,
    errorMessage: '',
    isOpen: false
  },
  activePages: {
    grouped: {
      byId: {},
      allIds: []
    },
    float: {
      byId: {},
      allIds: []
    },
    all: {
      byId: {},
      allIds: []
    }
  },
  lastPage: {
    maxOrder: 0,
    page: null
  },
  canUndo: false
}

const fetchPagesByParams = ({
  token,
  inSitemap
}: TSiteMapWithParam): Promise<AdminApiTypes.TGetSitemapList> =>
  adminApi.getPagesByParams({ token, inSitemap })

const prefixHelper = new CreatePrefix('adminPageSitemap')

export const fetchSitemapCard = createAsyncThunk<
  { grouped: AdminApiTypes.IPageGroupWithPages[]; float: AdminApiTypes.TSitemapItem[] },
  { token: string }
>(prefixHelper.create('fetchSitemapCard'), async ({ token }, thunkAPI) => {
  const response = await adminApi.getSitemapCard(token)
  if (response.code !== ResponseCode.success) {
    const { message } = response
    return thunkAPI.rejectWithValue({ message })
  }
  return response.data
})

export const fetchInactivePages = createAsyncThunk<AdminApiTypes.TSitemapItem[], { token: string }>(
  prefixHelper.create('fetchInActivePages'),
  async ({ token }, { rejectWithValue }) => {
    const response = await fetchPagesByParams({ token, inSitemap: 0 })
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return rejectWithValue({ message })
    }
    return response.data
  }
)

export const fetchPageGroupList = createAsyncThunk<AdminApiTypes.TPageGroup[], { token: string }>(
  prefixHelper.create('fetchPageGroupList'),
  async ({ token }, { rejectWithValue }) => {
    const response = await adminApi.getPageGroupList({
      token
    })
    if (response.code !== ResponseCode.success)
      return rejectWithValue({ message: 'Не удалось получить список групп' })

    return response.data
  }
)

export const updateRelationship = createAsyncThunk<void, AdminApiTypes.TUpdateRelationshipRequest>(
  prefixHelper.create('updateRelationship'),
  async (data, { rejectWithValue, dispatch }) => {
    const response = await adminApi.updateRelationship(data)
    if (response.code !== ResponseCode.success)
      return rejectWithValue(new UpdateRelationShipError())

    await Promise.all([
      dispatch(fetchSitemapCard({ token: data.token })),
      dispatch(fetchInactivePages({ token: data.token }))
    ])
  }
)

export const toggleSitemapValueInPage = createAsyncThunk<void, TChangeSitemapValueArgs>(
  prefixHelper.create('toggleSitemapValueInPage'),
  async ({ id, siteMap, token }, { dispatch, rejectWithValue, getState }) => {
    const response = await adminApi.toggleSitemapValueInPage({
      token,
      pageId: id,
      siteMap
    })
    if (response.code !== ResponseCode.success)
      return rejectWithValue(new ChangeSitemapValueError())

    // save snapshot
    const {
      adminPage: {
        sitemap: { activePages, inactive }
      }
    } = getState() as TMainDataStateType
    const getCurrentSitemap = (siteMap: number, id: number): AdminApiTypes.TSitemapItem => {
      if (siteMap)
        return inactive.items.find((item) => item.id === id) as AdminApiTypes.TSitemapItem
      return activePages.all.byId[id]
    }
    queue.enqueue({
      action: DropSitemapAction.toggleSitemapValueInPage,
      type: siteMap ? SitemapItemType.inactive : SitemapItemType.active,
      currentSitemap: {
        ...getCurrentSitemap(siteMap, id),
        type: siteMap ? SitemapItemType.inactive : SitemapItemType.active
      },
      activePages,
      sitemapData: getCurrentSitemap(siteMap, id)
    })

    dispatch(setCurrentSitemap(null))
    dispatch(setActiveItemSelected(null))
    dispatch(setInactiveItemSelected(null))
    await Promise.all([
      dispatch(fetchSitemapCard({ token })),
      dispatch(fetchInactivePages({ token }))
    ])
  }
)

export const addToChildList = createAsyncThunk<void, TAddToChildList & { token: string }>(
  prefixHelper.create('addToChildList'),
  async ({ parent, children, token, queueData }, { dispatch, rejectWithValue }) => {
    const response = await adminApi.toggleSitemapValueInPage({
      token,
      pageId: children,
      siteMap: 1
    })
    if (response.code !== ResponseCode.success)
      return rejectWithValue(new ChangeSitemapValueError())

    const data: AdminApiTypes.TUpdateRelationshipRequest = {
      token,
      children,
      parent
    }
    await dispatch(updateRelationship(data))
    if (queueData) queue.enqueue(queueData)
  }
)

export const addAfterPage = createAsyncThunk<
  void,
  TAddAfterPage & { token: string },
  { state: TMainDataStateType }
>(
  prefixHelper.create('addAfterPage'),
  async ({ after, children, token, queueData }, { dispatch, rejectWithValue, getState }) => {
    const response = await adminApi.toggleSitemapValueInPage({
      token,
      pageId: children,
      siteMap: 1
    })
    if (response.code !== ResponseCode.success)
      return rejectWithValue(new ChangeSitemapValueError())

    let data: AdminApiTypes.TUpdateRelationshipRequest = {
      token,
      children
    }

    const allActiveList = getState().adminPage.sitemap.activePages.all
    const sitemap = getElementBy(after, allActiveList)

    if (sitemap.id !== sitemap.parent) data = { ...data, parent: sitemap.parent, after: sitemap.id }
    else data = { ...data, after: sitemap.id }

    // если статья сгруппирована в группу, то необходимо так же добавить признак группы
    if (sitemap.pageGroup) {
      const {
        pageGroup: { id: groupId }
      } = sitemap
      await dispatch(dropSitemapItemOnPageGroup({ token, pageId: children, groupId }))
    }

    await dispatch(updateRelationship(data))
    if (queueData) queue.enqueue(queueData)
  }
)

export const dropSitemapItemOnSitemapItem = createAsyncThunk<
  void,
  {
    sitemapData: AdminApiTypes.TSitemapItem
    type: SitemapItemType
    currentSitemap: TExtendedSitemapItem
    token: string
  },
  { state: TMainDataStateType }
>(
  prefixHelper.create('dropSitemapItemOnSitemapItem'),
  async ({ sitemapData, type, currentSitemap, token }, { dispatch, getState, rejectWithValue }) => {
    const { id } = sitemapData
    const { id: children, type: currentSitemapType } = currentSitemap
    const { activePages } = getState().adminPage.sitemap

    const queueData = {
      action: DropSitemapAction.dropSitemapItemOnSitemapItem,
      sitemapData,
      type,
      currentSitemap,
      activePages
    }

    if (currentSitemap.pageGroup)
      await adminApi.removeGroupFromPage({ pageId: currentSitemap.id, token })

    const data = {
      token,
      parent: id,
      children
    }
    // элемент из карты сайта привязывается к элементу в карте сайте
    if (currentSitemapType === SitemapItemType.active && type === SitemapItemType.active) {
      const {
        meta: { requestStatus }
      } = await dispatch(updateRelationship(data))
      dispatch(setCurrentSitemap(null))
      if (requestStatus === 'rejected') return rejectWithValue(new UpdateRelationShipError())
      return queue.enqueue(queueData)
    }
    // элемент из карты сайта привязывается к элементу вне карты сайта
    // либо элемент вне карты сайта привязывается к элементу вне карты сайта
    if (
      (currentSitemapType === SitemapItemType.active && type === SitemapItemType.inactive) ||
      (currentSitemapType === SitemapItemType.inactive && type === SitemapItemType.inactive)
    )
      return

    // элемент вне карты сайта привязывается к элементу в карте сайте через обновление флага site_map
    const {
      meta: { requestStatus }
    } = await dispatch(addToChildList(data))
    dispatch(setCurrentSitemap(null))
    if (requestStatus === 'rejected') return rejectWithValue(new ChangeSitemapValueError())
    return queue.enqueue(queueData)
  }
)

export const undoDropSitemapItem = createAsyncThunk(
  prefixHelper.create('undoDropSitemapItem'),
  async (data: TDropSitemapOn & { token: string }, thunkAPI) => {
    const { action } = data
    if (action === DropSitemapAction.dropSitemapItemOnSitemapItem) {
      const { currentSitemap, token, type } = data

      // возвращаем привязку к группе, если ранее она была удалена
      if (currentSitemap.pageGroup) {
        await adminApi.addGroupToPage({
          groupId: currentSitemap.pageGroup.id,
          pageId: currentSitemap.id,
          token
        })
      }

      if (currentSitemap.type === SitemapItemType.active && type === SitemapItemType.active) {
        let recoverData = {
          token
        } as { token: string; parent: number; children: number }
        // был один родитель, стал другой, можем вернуть к предыдущему родителю
        if (currentSitemap.id !== currentSitemap.parent) {
          recoverData = {
            ...recoverData,
            parent: currentSitemap.parent,
            children: currentSitemap.id
          }
          await thunkAPI.dispatch(updateRelationship(recoverData))
        }
      }
    }

    if (action === DropSitemapAction.dropSitemapItemOnDivider) {
      const { currentSitemap, type, token, sitemapData, isShowChildNodes } = data
      // элемент из карты сайта привязывается к элементу в карте сайте
      if (currentSitemap.type === SitemapItemType.active && type === SitemapItemType.active) {
        // изменения по привязке к группе
        if (sitemapData.pageGroup) {
          if (isShowChildNodes) {
            if (currentSitemap.pageGroup) {
              // возвращаем группу, которая была
              const response = await adminApi.addGroupToPage({
                groupId: currentSitemap.pageGroup.id,
                pageId: currentSitemap.id,
                token
              })
              if (response.code !== ResponseCode.success)
                return thunkAPI.rejectWithValue(new UpdateRelationShipError())
            }
          } else {
            const { id: pageId } = currentSitemap
            const response = await adminApi.removeGroupFromPage({ pageId, token })
            if (response.code !== ResponseCode.success)
              return thunkAPI.rejectWithValue(new UpdateRelationShipError())
          }
        } else if (currentSitemap.pageGroup) {
          const response = await adminApi.addGroupToPage({
            groupId: currentSitemap.pageGroup.id,
            pageId: currentSitemap.id,
            token
          })
          if (response.code !== ResponseCode.success)
            return thunkAPI.rejectWithValue(new UpdateRelationShipError())
        }

        let recoverData = { token } as {
          token: string
          parent: number
          children: number
          after?: number
        }
        // был один родитель, стал другой, можем вернуть к предыдущему родителю
        if (currentSitemap.id !== currentSitemap.parent) {
          recoverData = {
            ...recoverData,
            parent: currentSitemap.parent,
            children: currentSitemap.id
          }
          if (currentSitemap.order_number > 0) {
            const {
              activePages: {
                all: { byId }
              }
            } = data
            const key = findKey(byId, {
              parent: currentSitemap.parent,
              level: currentSitemap.level,
              order_number: currentSitemap.order_number - 1
            }) as string
            const { id } = byId[Number(key)]
            recoverData = { ...recoverData, after: id }
          }
          await thunkAPI.dispatch(updateRelationship(recoverData))
        }
      }
    }

    if (action === DropSitemapAction.dropSitemapItemOnPageGroup) {
      const { currentSitemap, token } = data
      // если была ранее группа, то ее возвращаем
      if (currentSitemap.pageGroup) {
        const response = await adminApi.addGroupToPage({
          groupId: currentSitemap.pageGroup.id,
          pageId: currentSitemap.id,
          token
        })
        if (response.code !== ResponseCode.success)
          return thunkAPI.rejectWithValue(new UpdateRelationShipError())
      } else {
        const { id: pageId } = currentSitemap
        const response = await adminApi.removeGroupFromPage({ pageId, token })
        if (response.code !== ResponseCode.success)
          return thunkAPI.rejectWithValue(new UpdateRelationShipError())
      }
      // далее восстанавливаем исходное положение
    }

    if (action === DropSitemapAction.toggleSitemapValueInPage) {
      const { currentSitemap, token } = data
      const response = await adminApi.toggleSitemapValueInPage({
        token,
        pageId: currentSitemap.id,
        siteMap: Number(currentSitemap.type === SitemapItemType.active)
      })
      if (response.code !== ResponseCode.success)
        return thunkAPI.rejectWithValue(new ChangeSitemapValueError())

      await Promise.all([
        thunkAPI.dispatch(fetchSitemapCard({ token })),
        thunkAPI.dispatch(fetchInactivePages({ token }))
      ])
    }

    if (
      [
        DropSitemapAction.dropSitemapItemOnSitemapItem,
        DropSitemapAction.dropSitemapItemOnDivider,
        DropSitemapAction.dropSitemapItemOnPageGroup,
        DropSitemapAction.toggleSitemapValueInPage
      ].includes(action)
    ) {
      const { currentSitemap, type, token } = data
      // элемент вне карты сайта привязывается к элементу в карте сайте через обновление флага site_map
      // элемент вне карты сайта был брошен на группу через обновление флага site_map
      if (
        (currentSitemap.type === SitemapItemType.inactive && type === SitemapItemType.active) ||
        (currentSitemap.type === SitemapItemType.inactive &&
          type === SitemapItemType.inactive &&
          DropSitemapAction.dropSitemapItemOnPageGroup)
      ) {
        // достаточно отката флага, что статья добавлена в карту сайта
        const response = await adminApi.toggleSitemapValueInPage({
          token,
          pageId: currentSitemap.id,
          siteMap: 0
        })
        if (response.code !== ResponseCode.success)
          return thunkAPI.rejectWithValue(new ChangeSitemapValueError())

        await Promise.all([
          thunkAPI.dispatch(fetchSitemapCard({ token })),
          thunkAPI.dispatch(fetchInactivePages({ token }))
        ])
      }

      if (
        currentSitemap.type === SitemapItemType.active &&
        type === SitemapItemType.active &&
        currentSitemap.id === currentSitemap.parent
      ) {
        // 1) был самостоятельным элементом, его закинули на другой divider
        // 2) был самостоятельным элементом и его закинули к другому элементу в качестве children
        // 3) был активным элементом, удалил с карты сайта, необходимо восстановить
        const {
          activePages: {
            all: { byId }
          }
        } = data
        if (currentSitemap.order_number > 0) {
          const key = findKey(byId, {
            level: currentSitemap.level,
            order_number: currentSitemap.order_number - 1
          }) as string
          const { id } = byId[Number(key)]
          await thunkAPI.dispatch(
            updateRelationship({
              token,
              children: currentSitemap.id,
              after: id
            })
          )
        } else {
          // самостоятельный элемент оказался первым в списке, поэтому необходимо
          // понять какой элемент сейчас находится первым в списке
          const {
            adminPage: {
              sitemap: {
                activePages: {
                  all: { byId }
                }
              }
            }
          } = thunkAPI.getState() as TMainDataStateType
          const key = findKey(byId, {
            level: currentSitemap.level,
            order_number: 0
          }) as string
          const { id } = byId[Number(key)]

          await thunkAPI.dispatch(
            updateRelationship({
              token,
              children: currentSitemap.id,
              after: id
            })
          )
          await thunkAPI.dispatch(
            updateRelationship({
              token,
              children: id,
              after: currentSitemap.id
            })
          )
        }
      }

      if (
        currentSitemap.type === SitemapItemType.active &&
        type === SitemapItemType.active &&
        currentSitemap.id !== currentSitemap.parent
      ) {
        let recoverData = { token, parent: currentSitemap.parent, children: currentSitemap.id } as {
          token: string
          parent: number
          children: number
          after?: number
        }
        // был один родитель, стал другой, можем вернуть к предыдущему родителю
        if (currentSitemap.order_number > 0) {
          const {
            activePages: {
              all: { byId }
            }
          } = data
          const key = findKey(byId, {
            parent: currentSitemap.parent,
            level: currentSitemap.level,
            order_number: currentSitemap.order_number - 1
          }) as string
          const { id } = byId[Number(key)]
          recoverData = { ...recoverData, after: id }
        }
        await thunkAPI.dispatch(updateRelationship(recoverData))
      }
    }
  }
)

export const dropSitemapItemOnDivider = createAsyncThunk<
  void,
  {
    sitemapData: AdminApiTypes.TSitemapItem
    type: SitemapItemType
    isShowChildNodes: boolean
    currentSitemap: TExtendedSitemapItem
    token: string
  },
  { state: TMainDataStateType }
>(
  prefixHelper.create('dropSitemapItemOnDivider'),
  async (
    { token, sitemapData, type, isShowChildNodes, currentSitemap },
    { dispatch, rejectWithValue, getState }
  ) => {
    const { activePages } = getState().adminPage.sitemap
    const { id: children, type: currentSitemapType } = currentSitemap
    const hasChildNodes = sitemapData.children.length > 0

    const recoverData = {
      action: DropSitemapAction.dropSitemapItemOnDivider,
      sitemapData,
      type,
      currentSitemap,
      activePages,
      isShowChildNodes
    }

    let data: AdminApiTypes.TUpdateRelationshipRequest = {
      token,
      children
    }
    // элемент вне карты сайта привязывается к элементу в карте сайте через обновление флага site_map
    if (currentSitemapType === SitemapItemType.inactive && type === SitemapItemType.active) {
      await dispatch(addAfterPage({ children, token, after: sitemapData.id }))
      dispatch(setCurrentSitemap(null))
      return queue.enqueue(recoverData)
    }

    if (sitemapData.id !== sitemapData.parent) {
      data = { ...data, parent: sitemapData.parent, after: sitemapData.id }
    } else if (isShowChildNodes && hasChildNodes) {
      data = { ...data, parent: sitemapData.parent }
      if (sitemapData.id !== sitemapData.parent) data = { ...data, after: sitemapData.id }
    } else {
      data = { ...data, after: sitemapData.id }
    }
    // элемент из карты сайта привязывается к элементу в карте сайте
    if (currentSitemapType === SitemapItemType.active && type === SitemapItemType.active) {
      if (sitemapData.pageGroup) {
        if (isShowChildNodes) {
          if (currentSitemap.pageGroup) {
            const response = await adminApi.removeGroupFromPage({
              pageId: currentSitemap.id,
              token
            })
            if (response.code !== ResponseCode.success)
              return rejectWithValue(new UpdateRelationShipError())
          }
        } else {
          const {
            pageGroup: { id: groupId }
          } = sitemapData
          const { id: pageId } = currentSitemap
          const response = await adminApi.addGroupToPage({ groupId, pageId, token })
          if (response.code !== ResponseCode.success)
            return rejectWithValue(new UpdateRelationShipError())
        }
      } else if (currentSitemap.pageGroup) {
        const response = await adminApi.removeGroupFromPage({
          pageId: currentSitemap.id,
          token
        })
        if (response.code !== ResponseCode.success)
          return rejectWithValue(new UpdateRelationShipError())
      }
      await dispatch(updateRelationship(data))
      dispatch(setCurrentSitemap(null))
      return queue.enqueue(recoverData)
    }
    // элемент из карты сайта привязывается к элементу вне карты сайта
    // либо элемент вне карты сайта привязывается к элементу вне карты сайта
  }
)

export const dropSitemapItemOnPageGroup = createAsyncThunk<
  number,
  { token: string; pageId: number; groupId: number; type?: SitemapItemType },
  { state: TMainDataStateType }
>(
  prefixHelper.create('dropSitemapItemOnPageGroup'),
  async ({ token, pageId, groupId, type }, thunkAPI) => {
    const response = await adminApi.addGroupToPage({ groupId, pageId, token })
    if (response.code !== ResponseCode.success)
      return thunkAPI.rejectWithValue(new UpdateRelationShipError())
    if (type && type === SitemapItemType.inactive) {
      const response = await adminApi.toggleSitemapValueInPage({
        token,
        pageId,
        siteMap: 1,
        groupId
      })
      if (response.code !== ResponseCode.success)
        return thunkAPI.rejectWithValue(new ChangeSitemapValueError())
    }
    const {
      lastPage: { page }
    } = thunkAPI.getState().adminPage.sitemap
    const { id: after } = page as AdminApiTypes.TSitemapItem
    const data = {
      children: pageId,
      after,
      token
    }
    await thunkAPI.dispatch(updateRelationship(data))

    thunkAPI.dispatch(setCurrentSitemap(null))
    thunkAPI.dispatch(setActiveItemSelected(null))
    thunkAPI.dispatch(setInactiveItemSelected(null))
    return pageId
  }
)

export const removePageGroupFromSitemapCard = createAsyncThunk<
  void,
  {
    id: number
    token: string
  }
>(
  prefixHelper.create('removePageGroupFromSitemapCard'),
  async ({ id, token }, { rejectWithValue, dispatch }) => {
    const response = await adminApi.removePageGroupFromSitemapCard({
      id,
      token
    })
    if (response.code !== ResponseCode.success) return rejectWithValue(new UpdateGroupError())
    await Promise.all([
      dispatch(fetchSitemapCard({ token })),
      dispatch(fetchInactivePages({ token })),
      dispatch(fetchPageGroupList({ token }))
    ])
  }
)

export const createPageGroup = createAsyncThunk<
  AdminApiTypes.TPageGroup,
  {
    name: string
    token: string
  }
>(prefixHelper.create('addPageGroup'), async ({ name, token }, thunkAPI) => {
  const response = await adminApi.createPageGroup({ name, token })
  if (response.code !== ResponseCode.success)
    return thunkAPI.rejectWithValue({ message: 'Не удалось создать новую группу' })
  thunkAPI.dispatch(setIsOpenGroupForm(false))
  thunkAPI.dispatch(setCurrentSitemapGroup(null))
  return response.data
})

export const editPageGroup = createAsyncThunk<
  AdminApiTypes.TPageGroup,
  {
    id: number
    token: string
    isUse?: number
    orderId?: number
    name?: string
  }
>(prefixHelper.create('editPageGroup'), async ({ id, token, isUse, orderId, name }, thunkAPI) => {
  const response = await adminApi.editPageGroup({
    id,
    token,
    isUse,
    orderId,
    name
  })
  if (response.code !== ResponseCode.success)
    return thunkAPI.rejectWithValue({ message: 'Не удалось отредактировать группу' })
  thunkAPI.dispatch(setIsOpenGroupForm(false))
  thunkAPI.dispatch(setCurrentSitemapGroup(null))
  return response.data
})

export const appendPageGroupToSitemap = createAsyncThunk<
  AdminApiTypes.TPageGroup,
  { id: number; token: string }
>(prefixHelper.create('appendPageGroupToSitemap'), async ({ id, token }, thunkAPI) => {
  const response = await adminApi.addGroupToSitemapCard({
    id,
    token
  })
  if (response.code !== ResponseCode.success)
    return thunkAPI.rejectWithValue({ message: 'Не удалось добавить группу к карте сайта' })
  return response.data
})

export const deletePageGroup = createAsyncThunk<void, { id: number; token: string }>(
  prefixHelper.create('deletePageGroup'),
  async ({ token, id }, thunkAPI) => {
    const response = await adminApi.deletePageGroup({
      groupId: id,
      token
    })
    if (response.code !== ResponseCode.success)
      return thunkAPI.rejectWithValue({ message: 'Не удалось удалить группу' })
    await thunkAPI.dispatch(fetchPageGroupList({ token }))
  }
)

export const orderPage = createAsyncThunk<
  void,
  { token: string; id: number; actionType: 'orderUp' | 'orderDown' }
>(prefixHelper.create('orderPage'), async ({ token, id, actionType }, thunkAPI) => {
  const response = await adminApi.changeOrder({
    token,
    id,
    actionType
  })
  if (response.code !== ResponseCode.success)
    return thunkAPI.rejectWithValue({ message: 'Не удалось добавить группу к карте сайта' })
  await thunkAPI.dispatch(fetchSitemapCard({ token }))
})

const sitemapSlice = createSlice({
  name: 'adminPageSitemap',
  initialState,
  reducers: {
    setActiveItemSelected(state, action: PayloadAction<TNullable<AdminApiTypes.TSitemapItem>>) {
      state.activeItemSelected = action.payload
    },
    setInactiveItemSelected(state, action: PayloadAction<TNullable<AdminApiTypes.TSitemapItem>>) {
      state.inactiveItemSelected = action.payload
    },
    setCurrentSitemap(state, action: PayloadAction<TNullable<TExtendedSitemapItem>>) {
      state.currentSitemap = action.payload
    },
    setCurrentSitemapGroup(state, action: PayloadAction<TNullable<AdminApiTypes.TPageGroup>>) {
      state.pageGroup.currentSitemapGroup = action.payload
    },
    setIsOpenGroupForm(state, action: PayloadAction<boolean>) {
      state.pageGroup.isOpen = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateRelationship.fulfilled, (state) => {
        state.activeItemSelected = null
        state.inactiveItemSelected = null
      })
      .addCase(fetchPageGroupList.pending, (state) => {
        state.pageGroup.errorMessage = ''
      })
      .addCase(fetchPageGroupList.rejected, (state, action) => {
        state.pageGroup.errorMessage = getErrorMessage(action)
      })
      .addCase(fetchPageGroupList.fulfilled, (state, action) => {
        state.pageGroup.list = createPageListStructure(action.payload)
      })
      .addCase(fetchSitemapCard.fulfilled, (state, action) => {
        state.activePages.grouped = createPageListStructure(action.payload.grouped)
        state.activePages.float = createPageListStructure(action.payload.float)
        const allItems = flattenDeep([
          ...Object.values(action.payload.grouped).map((group) => group.pages),
          ...action.payload.float
        ])
        state.activePages.all = createPageListStructureDeep(allItems)
        const maxOrder = Math.max(
          ...allItems.filter((item) => item.level === 0).map((item) => item.order_number)
        )
        state.lastPage.page = allItems.find(
          (item) => item.order_number === maxOrder && item.level === 0
        ) as AdminApiTypes.TSitemapItem
        state.lastPage.maxOrder = maxOrder
      })
      .addCase(fetchInactivePages.fulfilled, (state, action) => {
        state.inactive.items = action.payload
        state.inactiveItemSelected = null
      })
      .addCase(createPageGroup.fulfilled, (state, action) => {
        state.pageGroup.list.allIds.push(action.payload.id)
        state.pageGroup.list.byId[action.payload.id] = action.payload
      })
      .addCase(editPageGroup.fulfilled, (state, action) => {
        state.pageGroup.list.byId[action.payload.id] = action.payload
      })
      .addCase(appendPageGroupToSitemap.fulfilled, (state, action) => {
        // удаляем из списка групп
        remove(state.pageGroup.list.allIds, (id) => id === action.payload.id)
        delete state.pageGroup.list.byId[action.payload.id]
        // добавляем на карте сайта
        state.activePages.grouped.allIds.push(action.payload.id)
        state.activePages.grouped.byId[action.payload.id] = { ...action.payload, pages: [] }
      })
      .addCase(dropSitemapItemOnPageGroup.fulfilled, (state, action) => {
        remove(state.inactive.items, (item) => item.id === action.payload)
      })
      .addMatcher(
        isAnyOf(fetchPageGroupList.pending, createPageGroup.pending, editPageGroup.pending),
        (state) => {
          state.pageGroup.isLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          editPageGroup.fulfilled,
          editPageGroup.rejected,
          createPageGroup.rejected,
          createPageGroup.fulfilled,
          fetchPageGroupList.rejected,
          fetchPageGroupList.fulfilled
        ),
        (state) => {
          state.pageGroup.isLoading = false
        }
      )
      .addMatcher(
        isAnyOf(
          appendPageGroupToSitemap.pending,
          fetchSitemapCard.pending,
          fetchInactivePages.pending,
          addToChildList.pending,
          toggleSitemapValueInPage.pending,
          updateRelationship.pending,
          addAfterPage.pending,
          removePageGroupFromSitemapCard.pending,
          dropSitemapItemOnPageGroup.pending
        ),
        (state) => {
          state.isLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          appendPageGroupToSitemap.fulfilled,
          fetchSitemapCard.fulfilled,
          fetchInactivePages.fulfilled,
          addToChildList.fulfilled,
          toggleSitemapValueInPage.fulfilled,
          updateRelationship.fulfilled,
          addAfterPage.fulfilled,
          removePageGroupFromSitemapCard.fulfilled,
          dropSitemapItemOnPageGroup.fulfilled
        ),
        (state) => {
          state.isLoading = false
        }
      )
      .addMatcher(
        isAnyOf(
          appendPageGroupToSitemap.rejected,
          fetchSitemapCard.rejected,
          fetchInactivePages.rejected,
          addToChildList.rejected,
          toggleSitemapValueInPage.rejected,
          updateRelationship.rejected,
          addAfterPage.rejected,
          removePageGroupFromSitemapCard.rejected,
          editPageGroup.rejected,
          dropSitemapItemOnPageGroup.rejected
        ),
        (state, action) => {
          state.isLoading = false
          let message = getErrorMessage(action)
          if (
            action.payload instanceof ChangeSitemapValueError ||
            action.payload instanceof UpdateRelationShipError
          ) {
            // eslint-disable-next-line prefer-destructuring
            message = action.payload.message
          }
          noty.push({
            message,
            type: 'danger',
            container: 'top-right'
          })
        }
      )
      .addMatcher(
        isAnyOf(
          dropSitemapItemOnSitemapItem.fulfilled,
          dropSitemapItemOnDivider.fulfilled,
          dropSitemapItemOnPageGroup.fulfilled,
          toggleSitemapValueInPage.fulfilled,
          addToChildList.fulfilled,
          addAfterPage.fulfilled,
          undoDropSitemapItem.fulfilled
        ),
        (state) => {
          state.canUndo = !queue.isEmpty()
        }
      )
  }
})

export default sitemapSlice.reducer
export const {
  setActiveItemSelected,
  setInactiveItemSelected,
  setCurrentSitemap,
  setCurrentSitemapGroup,
  setIsOpenGroupForm
} = sitemapSlice.actions

const inactive = (state: TMainDataStateType): TSitemapListState => state.adminPage.sitemap.inactive
export const isLoading = (state: TMainDataStateType): boolean => state.adminPage.sitemap.isLoading
export const inactiveMapList = createSelector(inactive, (state) => state.items)
export const activeItemSelectedSelector = (
  state: TMainDataStateType
): TNullable<AdminApiTypes.TSitemapItem> => state.adminPage.sitemap.activeItemSelected
export const inactiveItemSelectedSelector = (
  state: TMainDataStateType
): TNullable<AdminApiTypes.TSitemapItem> => state.adminPage.sitemap.inactiveItemSelected
export const currentSitemapSelector = (
  state: TMainDataStateType
): TNullable<TExtendedSitemapItem> => state.adminPage.sitemap.currentSitemap
export const currentSitemapGroupSelector = (
  state: TMainDataStateType
): TNullable<AdminApiTypes.TPageGroup> => state.adminPage.sitemap.pageGroup.currentSitemapGroup
export const pageGroupErrorMessageSelector = (state: TMainDataStateType): string =>
  state.adminPage.sitemap.pageGroup.errorMessage
export const pageGroupListSelector = (
  state: TMainDataStateType
): TListKeys<AdminApiTypes.TPageGroup> => state.adminPage.sitemap.pageGroup.list
export const activePageGroupListSelector = createSelector(pageGroupListSelector, (list) =>
  list.allIds.filter((id) => !list.byId[id].isUse)
)
export const pageGroupFetchingSelector = (state: TMainDataStateType): boolean =>
  state.adminPage.sitemap.pageGroup.isLoading
export const isOpenGroupFormSelector = (state: TMainDataStateType): boolean =>
  state.adminPage.sitemap.pageGroup.isOpen
export const activeFloatSelector = (
  state: TMainDataStateType
): TListKeys<AdminApiTypes.TSitemapItem> => state.adminPage.sitemap.activePages.float
export const activeGroupedSelector = (
  state: TMainDataStateType
): TListKeys<AdminApiTypes.IPageGroupWithPages> => state.adminPage.sitemap.activePages.grouped
export const firstGroupSelector = (
  state: TMainDataStateType,
  pageGroup: AdminApiTypes.TPageGroup
): boolean => {
  const id = head<number>(state.adminPage.sitemap.activePages.grouped.allIds)
  return pageGroup.id !== id
}
export const lastGroupSelector = (
  state: TMainDataStateType,
  pageGroup: AdminApiTypes.TPageGroup
): boolean => {
  const id = last<number>(state.adminPage.sitemap.activePages.grouped.allIds)
  return pageGroup.id !== id
}
export const canUnoSelector = (state: TMainDataStateType): boolean =>
  state.adminPage.sitemap.canUndo
export const activePagesSelector = (
  state: TMainDataStateType
): Pick<TInitialState, 'activePages'> => ({ activePages: state.adminPage.sitemap.activePages })
