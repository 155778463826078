import { combineReducers } from '@reduxjs/toolkit'

import abTestSlice from '#reducers/abTest/abTestSlice'
import appSlice from '#reducers/app/appSlice'
import cameraSlice from '#reducers/cameraSlice'
import feedbackFormSlice from '#reducers/feedbackForm/feedbackFormSlice'
import loanConditionsSlice from '#reducers/loanConditions/loanConditionsSlice'
import loanFormStateSlice from '#reducers/loanFormState/loanFormStateSlice'

import clientData from '../../clientData'
import deviceReducer from '../../deviceTypeSlice'
import settingsSlice from '../../settingsSlice'
import uiReducer from '../../ui'

export const personalReducer = combineReducers({
  app: appSlice,
  feedbackForm: feedbackFormSlice,
  loanFormState: loanFormStateSlice,
  loanConditions: loanConditionsSlice,
  ui: uiReducer,
  clientData,
  settings: settingsSlice,
  abTest: abTestSlice,
  device: deviceReducer,
  camera: cameraSlice
})

export type TPersonalRootState = ReturnType<typeof personalReducer>
