import { camelCase, has } from 'lodash'

import theme from '#src/assets/scss/Exports.module.scss'

const DEFAULT_STATUS = 'new'
const BASE_COLOR = theme.primaryColor

const BENEFITS =
  'Понизьте процентную ставку, увеличьте сумму и срок займа перейдя на новый "{{statusName}}" уровень'

export const STATUSES = {
  [DEFAULT_STATUS]: {
    translation: 'Не присвоен',
    color: null,
    progress: null
  },
  firstPaid: {
    translation: 'Базовый',
    color: 'transparent',
    progress: 0
  },
  bronze: {
    translation: 'Бронзовый',
    color: 'goldenrod',
    progress: 16.66
  },
  silver: {
    translation: 'Серебряный',
    color: 'lightblue',
    progress: 33.33
  },
  gold: {
    translation: 'Золотой',
    color: 'gold',
    progress: 50
  },
  platinum: {
    translation: 'Платиновый',
    color: 'lightsteelblue',
    progress: 66.66
  },
  platinumXx: {
    translation: 'Платиновый +',
    color: 'lightsteelblue',
    progress: 88.33
  },
  diamond: {
    translation: 'Бриллиантовый',
    color: 'palevioletred',
    progress: 100
  }
}

export const normalizeStatusName = (statusName = DEFAULT_STATUS) => camelCase(statusName)

const buildBackgoundColor = (baseColor, color) => {
  if (color === 'transparent') return color
  return `linear-gradient(45deg, ${baseColor}, ${color})`
}

const getNextStatusTranslation = (personGroups, name) => {
  const sorted = Object.entries(personGroups).sort(([, a], [, b]) => a.progress - b.progress)
  const currentIndex = sorted.findIndex(([key]) => name === key)
  if (currentIndex >= 0) {
    const [, nextStatus = {}] = sorted[currentIndex + 1] || []
    return nextStatus.text || ''
  }
  return ''
}

const buildPersonStatusData = (personGroups, statusName) => {
  const normalizedRawStatusName = normalizeStatusName(statusName)
  const actualStatusName = has(personGroups, normalizedRawStatusName)
    ? normalizedRawStatusName
    : DEFAULT_STATUS
  const currentStatus = personGroups[actualStatusName]
  const nextStatusTranslation = getNextStatusTranslation(personGroups, actualStatusName)
  return {
    ...currentStatus,
    background: buildBackgoundColor(BASE_COLOR, currentStatus.color),
    nextStatusTranslation,
    benefits: nextStatusTranslation ? BENEFITS.replace('{{statusName}}', nextStatusTranslation) : ''
  }
}

export default buildPersonStatusData
