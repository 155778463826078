import { CancelToken } from 'axios'
import { NavigateFunction } from 'react-router-dom'

import * as AdminApiTypes from '#modules/api/admin/types'

export type TFetchPageListParamsRequest = {
  token: string
  cancelToken: CancelToken
  currentPage: number
  itemsPerPage: number
  filterByActive: string
  filterByName: string
  filterByUrl: string
}

export type TFetchPageItemParamsRequest = {
  id: string
  token: string
  cancelToken: CancelToken
}

export type TUpdateItemParamsRequest = {
  data: AdminApiTypes.TPageItemContent
  token: string
  contentId: string
  navigate: NavigateFunction
}

export type TCreateItemParamsRequest = Omit<TUpdateItemParamsRequest, 'contentId'>

export enum PublishedStateText {
  published = 'Да',
  unPublished = 'Нет'
}
