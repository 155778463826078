import { createAsyncThunk } from '@reduxjs/toolkit'
import { findKey } from 'lodash'
import get from 'lodash/get'
import has from 'lodash/has'
import indexOf from 'lodash/indexOf'
import keys from 'lodash/keys'
import last from 'lodash/last'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'

import { FREE_DAYS_WITH_RESTRICTIONS, MICRO_CREDIT } from '#constants/common'
import { mainSiteApi } from '#modules/api'
import { LoanProductTypes } from '#modules/api/personal/enums'
import { ResponseCode } from '#modules/api/types/common'
import { CreatePrefix } from '#reducers/helper'
import { setLoanConditions } from '#reducers/loanConditions/loanConditionsSlice'
import { TLoanConditionData } from '#reducers/loanConditions/types'
import {
  restoreLoanFormStateSuccess,
  setFetchingRequest,
  updateLoanConditionsSliderData
} from '#reducers/loanFormState/loanFormStateSlice'
import { TLoanFormInitialState, TRestoreLoanFormStateParams } from '#reducers/loanFormState/types'
import { TMainDataStateType } from '#src/reducers'

const prefixHelper = new CreatePrefix('loanFormState')
export const USE_DEFAULT_VALUES = 'useDefaultValues'
export const USE_EXACT_VALUES = 'useExactValues'
const BUILD = process.env.__BUILD__ || 'main'
const SLIDER_INFO_TERM_MAX = 'sliderInfo.term.max'
const SLIDER_INFO_AMOUNT_MAX = 'sliderInfo.amount.max'

type TBuildSlidersData = {
  loanConditionsSliceState: TLoanConditionData
  loanFormSliceState: TLoanFormInitialState
  mode: string
}

export const buildSlidersData = ({
  loanConditionsSliceState,
  loanFormSliceState,
  mode
}: TBuildSlidersData): TLoanFormInitialState => {
  const {
    data: { creditType, amount, term, promoCode },
    appliedPromoCode
  } = loanFormSliceState

  const maxPossibleAmount = loanConditionsSliceState?.promo?.conditions?.MAX_POSSIBLE_AMOUNT

  const {
    fetchProductType = null,
    promoType = null,
    products,
    // @ts-ignore
    promoStyle: { maxPossiblePeriod = null } = {},
    defaultProduct: defaultProductsByBuild = {} as typeof LoanProductTypes
  } = loanConditionsSliceState

  const defaultProduct = has(
    products,
    defaultProductsByBuild[BUILD as keyof typeof defaultProductsByBuild]
  )
    ? defaultProductsByBuild[BUILD as keyof typeof defaultProductsByBuild]
    : last(keys(products))

  const productKey = fetchProductType ? fetchProductType : creditType || (defaultProduct as string)

  const conditions = loanConditionsSliceState.products[productKey as keyof typeof products]

  const absAmountMinItem = minBy(conditions, 'sliderInfo.amount.min')
  const absAmountMaxItem = maxBy(conditions, SLIDER_INFO_AMOUNT_MAX)
  const absTermMinItem = minBy(conditions, 'sliderInfo.term.min')
  const absTermMaxItem = maxBy(conditions, SLIDER_INFO_TERM_MAX)

  const absAmountMin = get(absAmountMinItem, 'sliderInfo.amount.min') as number
  const absAmountMax = get(absAmountMaxItem, SLIDER_INFO_AMOUNT_MAX) as number
  const absTermMin = get(absTermMinItem, 'sliderInfo.term.min') as number
  const absTermMax = get(absTermMaxItem, SLIDER_INFO_TERM_MAX) as number

  // Мэппинг исходных индексов loanConditions на индексы сортированных loanConditions
  // для того чтобы определять в какой новый loanCondition переходить при выходе
  // ползунка слайдера за диапазон min | max текущего loanCondition'а
  const sortedAscByMaxAmount = sortBy(conditions, SLIDER_INFO_AMOUNT_MAX)
  const amountIndexes = sortedAscByMaxAmount.map((item) => indexOf(conditions, item))

  const sortedAscByMaxTerm = sortBy(conditions, SLIDER_INFO_TERM_MAX)
  const termIndexes = sortedAscByMaxTerm.map((item) => indexOf(conditions, item))
  // ---

  let index
  let termValue!: number
  let amountValue!: number

  if (mode === USE_EXACT_VALUES) {
    termValue = term
    amountValue = amount
    index = conditions.findIndex((condition) =>
      has(condition, ['calculatedInfo', `amount_${amountValue}`, `period_${termValue}`])
    )
  }

  if (mode === USE_DEFAULT_VALUES) {
    if (promoType === FREE_DAYS_WITH_RESTRICTIONS && productKey === MICRO_CREDIT) {
      termValue = maxPossiblePeriod as number
      index = conditions.findIndex(({ calculatedInfo = {} } = {} as IProductItem) =>
        values(calculatedInfo).some((periods) => has(periods, `period_${termValue}`))
      )

      amountValue = maxPossibleAmount
        ? maxPossibleAmount
        : get(conditions, [index, 'sliderInfo', 'amount', 'max'])
    } else {
      index = indexOf(conditions, absTermMaxItem)
      termValue = absTermMax
      amountValue = get(conditions, [index, 'sliderInfo', 'amount', 'max'])
    }
  }

  if (index && index < 0) {
    index = indexOf(conditions, absTermMaxItem)
    termValue = absTermMax
    amountValue = get(conditions, [index, 'sliderInfo', 'amount', 'max'])
  }

  const termStep = get(conditions, [index as number, 'sliderInfo', 'term', 'step'])
  const amountStep = get(conditions, [index as number, 'sliderInfo', 'amount', 'step'])

  // размерность
  const dimension = get(conditions, [index as number, 'dimension']) || 'day'

  // @ts-ignore
  return {
    amount: {
      min: absAmountMin,
      max: absAmountMax,
      step: amountStep,
      indexList: amountIndexes
    },
    term: {
      min: absTermMin,
      max: absTermMax,
      step: termStep,
      indexList: termIndexes
    },
    index,
    data: {
      creditType: productKey,
      term: termValue,
      amount: amountValue,
      promoCode,
      promoType
    },
    appliedPromoCode,
    dimension
  }
}

export const resetSliders = createAsyncThunk<void, void, { state: TMainDataStateType }>(
  prefixHelper.create('resetSliders'),
  (_, thunkAPI) => {
    const loanConditions = thunkAPI.getState().loanConditions.data as TLoanConditionData
    thunkAPI.dispatch(updateLoanConditionsSliderData(loanConditions))
  }
)

// --- Используется при восставновлении формы регистрации пользователей на ленгдинге
export const restoreLoanFormState = createAsyncThunk<void, TRestoreLoanFormStateParams>(
  prefixHelper.create('restoreLoanFormState'),
  async ({ loanData = {}, requestParams = {} }, thunkAPI) => {
    thunkAPI.dispatch(setFetchingRequest())
    const promoCode = get(loanData, ['data', 'promoCode'], null) as string
    const response = await mainSiteApi.getSliderInfo({ promoCode, ...requestParams })
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue({})

    const loanConditions = response.data
    const appliedPromoCode = findKey(loanConditions.promo) || null
    const loanFormState = { ...loanData, appliedPromoCode } as TLoanFormInitialState

    thunkAPI.dispatch(restoreLoanFormStateSuccess({ loanConditions, loanFormState }))
    thunkAPI.dispatch(setLoanConditions(loanConditions))
  }
)
