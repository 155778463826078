import { debounce, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Cookies } from 'react-cookie'

import appConfig from '#config'
import { COOKIE_4P, UTM_LIST } from '#constants/common'
import intl from '#intl'
import { UserStatistics } from '#modules/UserStatistics'
import {
  updateParsedParameters,
  updatePartnerParameters,
  updateReferer
} from '#reducers/app/appSlice'
import { updateDeviceType } from '#reducers/deviceTypeSlice'
import { setLocale, setServerTime } from '#services/datetime'
import { isScreenDesktop, isScreenMobile, isScreenTablet, serialize } from '#services/helper'
import { fetchAppSettings } from '#src/reducers/settingsSlice'

const cookies = new Cookies()

class Base extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.init = this.init.bind(this)
    this.loggedInToken = this.loggedInToken.bind(this)
    this.handleWindowResize = debounce(this.handleWindowResizeImmediate.bind(this), 1000)
    this.userStatistics = new UserStatistics()
    setLocale('ru')
    void this.initTime()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
    document.removeEventListener('c4s_generated', this.wrapSendIncomingStat, false)
  }

  async initTime() {
    await setServerTime()
  }

  sendIncomingStatOnC4sReceived = () => {
    document.addEventListener('c4s_generated', this.wrapSendIncomingStat, false)
  }

  handleWindowResizeImmediate() {
    const { dispatch } = this.props
    dispatch(
      updateDeviceType({
        isMobile: isScreenMobile(),
        isTablet: isScreenTablet(),
        isDesktop: isScreenDesktop()
      })
    )
  }

  initCounters() {
    window.ga && window.ga('create', appConfig.ga, 'auto')
  }

  initAppSettings() {
    this.props.dispatch(fetchAppSettings())
  }

  getChildContext() {
    return {
      logout: this.logout
    }
  }

  getDomain() {
    const { hostname } = window.location
    const domain = hostname.split('.').slice(-2).filter(Boolean).join('.')
    return `.${domain}`
  }

  addCopyrightLink() {
    const selection = window.getSelection()
    const pagelink = `<br /><br /> ${intl.source}: <a href='${document.location.href}'>${intl.copyright2}</a>`
    const el = document.createElement('div')
    el.style.position = 'absolute'
    el.style.left = '-99999px'
    el.innerHTML = selection + pagelink
    document.body.appendChild(el)
    selection.selectAllChildren(el)
    setTimeout(() => {
      document.body.removeChild(el)
    }, 1)
  }

  initModalLinks() {
    document.addEventListener('click', (event) => {
      let modalLink =
        String(event.target.className || '').search('js-modal-link') !== -1 && event.target
      const path = event.path || (event.composedPath && event.composedPath())
      if (!modalLink && path) {
        // eslint-disable-next-line prefer-destructuring
        modalLink = path.filter(
          (item) => String(item.className || '').search('js-modal-link') !== -1
        )[0]
      }

      if (modalLink && modalLink.href) {
        event.preventDefault()
        this.setState({
          modalDocument: {
            url: modalLink.href
          }
        })
      }
    })
  }

  encodeParameters(params = {}) {
    const keys = Object.keys(params)
    const reduced = keys.reduce((acc, key) => {
      if (!UTM_LIST.has(key)) return acc
      const value = decodeURI(params[key])
      return { ...acc, [UTM_LIST.get(key)]: { value } }
    }, {})
    return isEmpty(reduced) ? '' : serialize(reduced)
  }

  hasSystemPageReferrer = (ref) => {
    const url = new URL('/system', appConfig.host).href
    return ref.includes(url)
  }

  saveLocalParams(domain) {
    const {
      parameters = {},
      partnerParameters = '',
      referer = ''
    } = this.userStatistics.getVisitParameters()
    const cookieOptions = { path: '/', domain, maxAge: 60 * 60 * 24 }
    const { dispatch } = this.props

    const aprt = parameters[COOKIE_4P] || null
    if (aprt) {
      cookies.set('partnerParameters', partnerParameters, cookieOptions)
      cookies.set('4p', aprt, { path: '/', domain })
    }

    const encodedParams = this.encodeParameters(parameters)
    if (encodedParams) cookies.set('uf', encodedParams, cookieOptions)

    const savedReferrer = cookies.get('referer')
    if (referer && !savedReferrer && !this.hasSystemPageReferrer(referer))
      cookies.set('referer', referer, cookieOptions)

    dispatch(updateParsedParameters({ parsedParameters: parameters }))

    if (partnerParameters) {
      dispatch(updatePartnerParameters({ partnerParameters }))
      dispatch(updateReferer({ referer }))
    }
  }

  getLocationParametersString() {
    const searchString = window.location.search
    const [firstChar] = searchString
    return (firstChar === '?' ? searchString.substr(1) : searchString) || ''
  }

  wrapSendIncomingStat = () => {
    void this.userStatistics.sendIncomingStat()
  }

  loggedInToken(key) {
    return cookies.get(key)
  }

  initLocalStorage() {
    try {
      if (this.userStatistics.supportsLocalStorage())
        window.localStorage.setItem('buildAt', appConfig.buildAt)
    } catch (e) {
      return false
    }
  }
}

Base.propTypes = {
  dispatch: PropTypes.func
}

Base.childContextTypes = {
  logout: PropTypes.func
}

export default Base
