import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

const SUGGESTED_SCREEN_WIDTH = 0
const SUGGESTED_SCREEN_HEIGHT = 0

const getWidth = () => (typeof window === 'undefined' ? SUGGESTED_SCREEN_WIDTH : window.innerWidth)
const getHeight = () =>
  typeof window === 'undefined' ? SUGGESTED_SCREEN_HEIGHT : window.innerHeight

const getUserDevice = () => {
  const width = getWidth()
  return {
    isMobile: width > 0 && width <= 480,
    isPhablet: width > 0 && width <= 576,
    isUpToTablet: width > 0 && width <= 768,
    isTablet: width >= 768 && width < 1024,
    isDesktop: width >= 1024 && width < 1440,
    isFromDesktop: width >= 1024,
    isDesktopWide: width >= 1440
  }
}

const withWindowSize = (WrappedComponent) => (props) => {
  const [windowSize, setWindowSize] = useState({ width: getWidth(), height: getHeight() })
  const [userDevice, setUserDevice] = useState(getUserDevice())

  const debouncedhandleWindowResize = useMemo(
    () =>
      debounce(() => {
        setWindowSize({ width: getWidth(), height: getHeight() })
        setUserDevice(getUserDevice())
      }, 200),
    []
  )

  useEffect(() => {
    window.addEventListener('resize', debouncedhandleWindowResize)
    return () => {
      window.removeEventListener('resize', debouncedhandleWindowResize)
      debouncedhandleWindowResize.cancel()
    }
  }, [debouncedhandleWindowResize])

  useEffect(() => {
    debouncedhandleWindowResize()
    debouncedhandleWindowResize.flush()
  }, [debouncedhandleWindowResize])

  return <WrappedComponent windowSize={windowSize} userDevice={userDevice} {...props} />
}

export default withWindowSize
