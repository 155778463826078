import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { Cookies } from 'react-cookie'

import {
  checkPhoneCode,
  checkToken,
  getPhoneCode,
  login,
  logout,
  removeCookie,
  setCookie
} from '#reducers/adminPage/auth/effects'
import {
  TActionLoggedIn,
  TActionPhoneCodeSuccess,
  TActionRenewAccessToken,
  TActionSaveRefreshToken,
  TActionSaveVerificationToken
} from '#reducers/adminPage/auth/types'
import { getErrorMessage } from '#reducers/helper'
import { TMainDataStateType } from '#src/reducers'

const cookies = new Cookies()

export interface IAuthInitialState {
  isLoading: boolean
  token: TNullable<string>
  verificationToken: TNullable<string>
  refreshToken: TNullable<string>
  isCodeRequested: boolean
  error: string
  codeTs: TNullable<number>
  isTokenVerified: boolean
}

const initialState: IAuthInitialState = {
  isLoading: false,
  token: (cookies.get('adminToken') as string) ?? null,
  verificationToken: (cookies.get('verificationToken') as string) ?? null,
  refreshToken: null,
  isCodeRequested: false,
  error: '',
  codeTs: null,
  isTokenVerified: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedInToken: (state, { payload }: PayloadAction<TActionLoggedIn>) => {
      state.token = payload.token
      state.isTokenVerified = true
    },
    saveVerificationToken: (state, { payload }: PayloadAction<TActionSaveVerificationToken>) => {
      state.verificationToken = payload.verificationToken
    },
    saveRefreshToken: (state, { payload }: PayloadAction<TActionSaveRefreshToken>) => {
      window.localStorage.setItem('refreshToken', payload.refreshToken)
      state.refreshToken = payload.refreshToken
    },
    renewAccessToken: (state, { payload }: PayloadAction<TActionRenewAccessToken>) => {
      removeCookie('adminToken')
      setCookie('adminToken', payload.token)
      state.token = payload.token
    },
    phoneCodeSuccess: (state, { payload }: PayloadAction<TActionPhoneCodeSuccess>) => {
      state.isLoading = false
      state.isCodeRequested = true
      state.codeTs = payload.codeTs
    },
    logoutSuccess: (state) => {
      state.isLoading = false
      state.token = null
      state.isTokenVerified = false
    },
    smsCodeVerifySuccess: (state, { payload }: PayloadAction<TActionSaveVerificationToken>) => {
      state.isLoading = false
      state.isCodeRequested = false
      state.token = payload.verificationToken
      state.isTokenVerified = true
      state.verificationToken = null
      state.codeTs = null
    },
    verifyTokenSuccess: (state) => {
      state.isLoading = false
      state.isTokenVerified = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkToken.pending, (state) => {
        state.isLoading = true
        state.isTokenVerified = false
      })
      .addMatcher(
        isAnyOf(login.pending, logout.pending, getPhoneCode.pending, checkPhoneCode.pending),
        (state) => {
          state.isLoading = true
          state.error = ''
        }
      )
      .addMatcher(
        isAnyOf(
          login.fulfilled,
          logout.fulfilled,
          getPhoneCode.fulfilled,
          checkPhoneCode.fulfilled
        ),
        (state) => {
          state.isLoading = false
        }
      )
      .addMatcher(
        isAnyOf(login.rejected, logout.rejected, getPhoneCode.rejected, checkPhoneCode.rejected),
        (state, action) => {
          const message = getErrorMessage(action)
          state.isLoading = false
          state.error = message
        }
      )
  }
})
export default authSlice.reducer

export const {
  loggedInToken,
  saveRefreshToken,
  renewAccessToken,
  saveVerificationToken,
  phoneCodeSuccess,
  logoutSuccess,
  smsCodeVerifySuccess,
  verifyTokenSuccess
} = authSlice.actions

const auth = (state: TMainDataStateType): IAuthInitialState => state.adminPage.auth
export const adminAuthTokenSelector = createSelector(auth, (auth) => auth.token)
