import intl from '#intl'

import BaseHttpClientError from './BaseHttpClientError'

export default class CancelRequestError extends BaseHttpClientError {
  constructor(message = intl.requestAborted) {
    super(message)
    this.name = 'CancelRequestError'
    this.code = 499
  }
}
