import NodeCache from 'node-cache'

import appConfig from '#config'

const cache = new NodeCache({
  stdTTL: 5 * 60,
  useClones: false
})

export class CacheResponse {
  private static instance: CacheResponse
  private constructor() {}

  public static getInstance(): CacheResponse {
    if (!CacheResponse.instance) CacheResponse.instance = new CacheResponse()

    return CacheResponse.instance
  }

  public hasData(key: string): boolean {
    return cache.has(key)
  }

  public getData<T>(key: string): T | undefined {
    return cache.get(key)
  }

  public setData<T>(key: string, value: T): void {
    if (Number(appConfig.isCachingDisabled)) return
    cache.set(key, value)
  }
}

const cacheResponse = CacheResponse.getInstance()

export default cacheResponse
