/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import StackTrace from 'stacktrace-js'

import { ErrorWithData } from '#src/errors'

import { DEFAULT_MESSAGE, GUID_HEADER_NAME } from './constants'
import { IBuildLogRecordOptions, ILogRecord } from './types'
import { buildServiceName, generateGuid, isBrowserEnvironment } from './utils'

/** Функция для корректного именования в логах */
async function buildCustomStackTrace(
  context: CallableFunction = buildCustomStackTrace
): Promise<StackTrace.StackFrame[]> {
  if (typeof Error.stackTraceLimit !== 'undefined') Error.stackTraceLimit = Infinity
  const errorObj = new Error()
  if (typeof Error.captureStackTrace === 'function') Error.captureStackTrace(errorObj, context)
  return StackTrace.fromError(errorObj)
}

const getCookie = (cookieName: string): string | null => {
  const normalizedName = cookieName.replace(/([$()*+./?[\\\]^{|}])/g, '\\$1')
  const regExp = new RegExp(`(?:^|; )${normalizedName}=([^;]*)`)
  const [, match] = regExp.exec(document.cookie) ?? []
  return match ? decodeURIComponent(match) : null
}

const getToken = (): string => ['clientToken', 'token'].map(getCookie).find(Boolean) || ''

const getUserAgent = (): string => window?.navigator?.userAgent || ''

const stringifyData = (data: unknown): string => {
  try {
    let stringified = JSON.stringify(data)
    if (stringified === '{}') stringified = String(data)
    return stringified
  } catch (_e) {
    return String(data)
  }
}

export async function buildLogRecord(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  options: IBuildLogRecordOptions = {},
  context: CallableFunction = buildLogRecord
): Promise<ILogRecord | null> {
  let stackFrames: StackTrace.StackFrame[] = []
  let msg = ''
  const guid =
    (options?.guid as string) ||
    (data?.config?.headers?.[GUID_HEADER_NAME] as string) || // Если это ошибка HTTP выполненного axios
    generateGuid()
  try {
    switch (true) {
      case typeof DOMException !== 'undefined' && data instanceof DOMException:
      case typeof DOMError !== 'undefined' && data instanceof DOMError:
        stackFrames = await buildCustomStackTrace(context)
        msg = `${String(data.name)}: ${String(data.message)}`
        break
      case data instanceof ErrorWithData:
        stackFrames = await StackTrace.fromError(data)
        msg = `${String(data.name)}: ${String(data.message)}, DATA: ${stringifyData(data.data)}`
        break
      case data instanceof Error:
        stackFrames = await StackTrace.fromError(data)
        msg = String(data.message)
        break
      default:
        stackFrames = await buildCustomStackTrace(context)
        msg = stringifyData(data)
        break
    }

    const [firstStackFrame = {} as StackTrace.StackFrame] = stackFrames
    const { fileName = '', lineNumber = 0 } = firstStackFrame

    return {
      fileName,
      lineNumber,
      stack: stackFrames,
      message: msg || DEFAULT_MESSAGE,
      guid,
      serviceName: buildServiceName(),
      userAgent: isBrowserEnvironment() ? getUserAgent() : '',
      token: isBrowserEnvironment() ? getToken() : ''
    }
  } catch (e) {
    console.error(e)
    return null
  }
}
