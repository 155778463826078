import { AxiosRequestConfig, AxiosResponse } from 'axios'

import appConfig from '#config'
import { HttpClient } from '#src/modules/api/httpClient'

class FiasApi extends HttpClient {
  public constructor(config: AxiosRequestConfig) {
    super(config)
    this._initializeRequestInterceptor()
    this.initializeResponseInterceptor()
  }

  private readonly initializeResponseInterceptor = (): void => {
    this.instance.interceptors.response.use(this.fiasResponseSuccess, this.onResponseError)
  }

  private readonly fiasResponseSuccess = (response: AxiosResponse): AxiosResponse => response
}

const fias = new FiasApi({
  baseURL: new URL('/fias/address/', appConfig.fiasHost).href
})

export default fias.instance
