import './footer.scss'

import loadable from '@loadable/component'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Microdata from '#components/Microdata/Microdata'
import { PrivateAgreementLinks } from '#components/RegistrationForm/PrivateAgreementLinks'
import SubscribeForm from '#components/SubscribeForm'
import config from '#config'
import { ROUTES } from '#constants/common'
import intl from '#intl'
import bankLogo from '#src/assets/img/br-logo.png'
import finLogo from '#src/assets/img/fin-logo.png'
import sroLogo from '#src/assets/img/mir-logo.png'

import { finUpol, navigation, sro } from './navigation'

const Icon = loadable(() => import('#components/Icon'), {
  ssr: false
})

const renderAgreements = (props) => <PrivateAgreementLinks {...props} />

const getFileName = (link) => link.split('/').slice(-1)

const renderWeInWorld = (key) => (
  <div className='footer__social' key={key}>
    <div className='d-flex justify-content-end w-100' key={key}>
      <span className='me-3'>{intl.weInWorld}</span>
      <a href='https://almal.ge/' target='blank'>
        <Icon name='georgia' />
      </a>
      <a href='https://4slovo.kz/' target='blank'>
        <Icon name='kazakhstan' />
      </a>
    </div>
  </div>
)

const renderWeInSocial = (key) => (
  <div className='footer__social' key={key}>
    <div>{intl.weInSocial}</div>
    <a href='https://vk.com/4slovo' target='blank' aria-label='vkontakte'>
      <Icon name='vkontakte' />
    </a>
    <a href='https://ok.ru/mfk4slovo/' target='blank' aria-label='odnoklassniki'>
      <Icon name='odnoklassniki' />
    </a>
    <a href='https://www.youtube.com/@mfk_4slovo' target='blank' aria-label='youtube'>
      <Icon name='youtube' />
    </a>
  </div>
)

const renderSocial = (key) => (
  <div className='footer__social' key={key}>
    <div className='d-flex justify-content-end w-100' key={key}>
      <span className='me-3'>{intl.weInWorld}</span>
      <a href='https://almal.ge/' target='blank' aria-label='georgia'>
        <Icon name='georgia' />
      </a>
      <a href='https://4slovo.kz/' target='blank' aria-label='kazakhstan'>
        <Icon name='kazakhstan' />
      </a>
    </div>
    <div>{intl.weInSocial}</div>
    <a href='https://vk.com/4slovo' target='blank' aria-label='vkontakte'>
      <Icon name='vkontakte' />
    </a>
    <a href='https://ok.ru/mfk4slovo/' target='blank' aria-label='odnoklassniki'>
      <Icon name='odnoklassniki' />
    </a>
    <a href='https://www.youtube.com/@mfk_4slovo' target='blank' aria-label='youtube'>
      <Icon name='youtube' />
    </a>
  </div>
)

const renderSpecialVersionLink = (url, text, key) => (
  <div className='d-flex align-items-center' key={key}>
    <Icon className='icon-md me-2' name='eyeglasses' />
    <a href={url} id='specialversion'>
      {text}
    </a>
  </div>
)

const typeMapping = {
  internalLink: function InternalLink(url, text, key) {
    return (
      <Link key={key} to={url}>
        {text}
      </Link>
    )
  },
  phoneLink: function PhoneLink(url, text, key) {
    return (
      <a key={key} className='phone-link' href={url}>
        {text}
      </a>
    )
  },
  externalLink: function ExternalLink(url, text, key) {
    return (
      <a key={key} href={url}>
        {text}
      </a>
    )
  },
  text: function Text(url, text, key) {
    return (
      <span key={key} className='w-100'>
        {text}
      </span>
    )
  },
  social: (url, text, key) => renderSocial(key),
  subscribeForm: function SubscribeFormType(url, text, key) {
    return (
      <SubscribeForm
        subscribeEmailId={'subscribeEmail'}
        key={key}
        className='footer__subscribe mx-0'
        formItemClass={'ps-0 ps-md-2'}
        hasAgreement={true}
        unisenderListId={'5088938'}
        renderAgreements={renderAgreements}
      />
    )
  },
  specialVersion: (url, text, key) => renderSpecialVersionLink(url, text, key),
  weInWorld: (url, text, key) => renderWeInWorld(key),
  weInSocial: (url, text, key) => renderWeInSocial(key)
}

const getItemRenderer = (type) => typeMapping[type] || (() => null)

const renderBlock = (block, key) => {
  const { title, items } = block
  return (
    <div className='footer__navigation__block' key={key}>
      {title === intl.contacts ? (
        <a className='footer__link-title' href={`${ROUTES.contacts}`}>
          <div className='footer__block-title text-uppercase'>{title}</div>
        </a>
      ) : (
        <div className='footer__block-title text-uppercase'>{title}</div>
      )}
      <div className='footer__navigation__items'>
        {items.map(({ type, url, text }, key) => getItemRenderer(type)(url, text, key))}
      </div>
    </div>
  )
}

const RenderSro = () => {
  const { pathname, text } = sro
  const sroFileName = getFileName(sroLogo)
  return (
    <div className='footer__sro-block'>
      <div className='img-sro'>
        <img
          className='lazyload'
          src={`${config.host}/${sroFileName}`}
          width='120px'
          height='auto'
          alt='Mir'
        />
      </div>
      <div className='sro-text'>
        <a href='https://npmir.ru/' rel='noreferrer' target='_blank'>
          {'https://npmir.ru/'}
        </a>
        <br />
        <br />
        <p>
          {'107078, г. Москва, Орликов'}
          <br />
          {'переулок, д.5, стр.1, этаж 2, пом.11'}
        </p>
        <br />
        <p>
          {'МФК «Честное слово» является '}

          <Link to={pathname}>{text}</Link>
          {' c 12.10.2015'}
        </p>
      </div>
    </div>
  )
}

const RenderFinUpol = () => {
  const { pathnames, text } = finUpol
  const finLogoFileName = getFileName(finLogo)
  return (
    <div className='footer__sro-block'>
      <div className='img-fin'>
        <img
          className='lazyload'
          src={`${config.host}/${finLogoFileName}`}
          width='55px'
          height='auto'
          alt='FinUpol'
        />
      </div>
      <div className='fin-text'>
        <a href='https://finombudsman.ru/' rel='noreferrer' target='_blank'>
          {'https://finombudsman.ru/'}
        </a>
        <br />
        <br />
        <p style={{ marginBottom: '3px' }}>
          {'Вы можете обратиться с жалобой'} <br /> {'в Службу финансового уполномоченного:'} <br />
        </p>
        <Link to={pathnames.memoInteraction}>
          <p className='memo-text'>
            {text.textMemo}
            <br />
            {'по взаимодействию'}
            <br />
            {' с финансовым уполномоченным'}
          </p>
        </Link>
        <br />
        <p style={{ marginTop: '2px' }}>
          {'19017, г. Москва,'} <br />
          {'Старомонетный пер., дом 3.'}
          <br />
          {'Тел.: 8 (800) 200-00-10'}
        </p>
        <br />
      </div>
    </div>
  )
}

const RenderBankRf = () => {
  const bankLogoFileName = getFileName(bankLogo)
  return (
    <div className='footer__sro-block'>
      <div className='img-bank'>
        <img
          className='lazyload'
          src={`${config.host}/${bankLogoFileName}`}
          width='175px'
          height='auto'
          alt='BankRF'
        />
      </div>
      <div className='bank-text'>
        <p>
          <a href='https://cbr.ru/' rel='noreferrer' target='_blank'>
            {'https://cbr.ru/'}
          </a>
        </p>
        <br />
        <p style={{ marginTop: '1px' }}>
          {'Интернет-приёмная Банка России:'}
          <br />
          <a href='https://cbr.ru/Reception/' rel='noreferrer' target='_blank'>
            {'https://cbr.ru/Reception/'}
          </a>
        </p>
        <br />
        <p>
          {'Реестры субъектов рынка'}
          <br />
          {'микрофинансирования:'} <br />
          <a href='https://cbr.ru/microfinance/registry/' rel='noreferrer' target='_blank'>
            {'https://cbr.ru/microfinance/registry/'}
          </a>
        </p>
        <br />
        <p>
          {'Регистрационный номер записи'}
          <br /> {'651303045002916.'} <br />
          {'Дата внесения 26.03.2013'}
        </p>
        <br />
        <p>
          {'Реестр Финансовых организаций:'}
          <br />
          <a
            href='https://www.cbr.ru/finorg/foinfo/?ogrn=1137746046943'
            rel='noreferrer'
            target='_blank'
          >
            {'https://www.cbr.ru/finorg/foinfo'}
          </a>
        </p>
      </div>
    </div>
  )
}

const RenderServiceText = () => {
  const device = useSelector((store) => store.device)
  const { isMobile } = device
  const mobileStyle = {
    paddingTop: '10px',
    textAlign: 'justify'
  }
  return (
    <div className='footer__sro-block'>
      <div className='footer__helpservice-text' style={isMobile ? mobileStyle : null}>
        {isMobile ? (
          <>
            <p>
              {
                'ВНИМАНИЕ! Операторы контактного центра Финансового уполномоченного не дают справок и разъяснений по вопросам деятельности ООО МФК «ЧЕСТНОЕ СЛОВО»'
              }
            </p>

            <p style={{ marginTop: '3px' }}>
              {'За консультацией обращайтесь в '}
              <Link to={finUpol.pathnames.helpService}>{finUpol.text.textHelpService}</Link>
              {' ООО МФК «ЧЕСТНОЕ СЛОВО».'}
            </p>
          </>
        ) : (
          <>
            <p>
              {'ВНИМАНИЕ! Операторы'}
              <br /> {'контактного центра Финансового'}
              <br /> {'уполномоченного не дают справок'}
              <br /> {'и разъяснений по вопросам'} <br />
              {'деятельности ООО МФК «ЧЕСТНОЕ'}
              <br />
              {'СЛОВО»'}
            </p>
            <br />
            <p style={{ marginTop: '3px' }}>
              {'За консультацией обращайтесь'} <br />
              {'в '}
              <Link to={finUpol.pathnames.helpService}>{finUpol.text.textHelpService}</Link>
              {' ООО МФК'} <br />
              {'«ЧЕСТНОЕ СЛОВО».'}
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export const Footer = (props) => {
  useEffect(() => {
    const eventAwesome = new CustomEvent('renderSpecialVersion', {
      bubbles: true,
      detail: { status: 'ok' }
    })
    document.dispatchEvent(eventAwesome)
  }, [])
  const className = classnames({ footer: true }, props.className)
  return (
    <footer className={className}>
      <div className='footer__wrapper'>
        <div className='footer__navigation my-2'>
          {navigation.map((block, key) => renderBlock(block, key))}
        </div>
        <div className='footer__ombudsman border-top border-secondary w-100'>
          <RenderSro />
          <RenderBankRf />
          <RenderFinUpol />
          <RenderServiceText />
        </div>
        <Icon name='logo' className='logo mt-4 pt-4 border-top border-secondary w-100' />
        <div className='text-secondary text-center mt-4'>
          {'© 2013–'}
          {new Date().getFullYear()} {intl.copyright}
        </div>
        <div
          className='footer__font-normal text-center text-secondary mt-4'
          dangerouslySetInnerHTML={{ __html: intl.percentInfoText }}
        />
        <div className='footer__font-normal text-center text-secondary mt-4'>
          {intl.licenseText}
        </div>
        <div className='footer__font-normal text-center text-secondary mb-3'>
          {intl.organizationAddress}
        </div>
      </div>
      <Microdata />
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string
}

export default Footer
