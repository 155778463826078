import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TConfirmDeleteActionProps } from '#components/AdminPage/NotificationSettings/ButtonCard/ConfirmDeleteButton'
import { TConfirmDeleteNotification } from '#components/AdminPage/NotificationSettings/NotificationCard/ConfirmDeleteNotification'
import { TConfirmDeleteBanner } from '#components/AdminPage/PersonalAreaPromoSettings/PromoBannerForm/ConfirmDeleteBanner'
import { IAdminNotificationAddButtonProps } from '#components/Dialogs/AdminNotification/AddButton'
import { DIALOG_COMPONENTS } from '#src/components/Dialogs'

export type TPersonalMainProps = {
  size: string
  title: string
  content: JSX.Element
}

export type TModalPayload = {
  modalType: TModalType
  modalProps: TModalProps
}

export type TModalType = keyof typeof DIALOG_COMPONENTS
export type TModalProps =
  | TPersonalMainProps
  | IAdminNotificationAddButtonProps
  | TConfirmDeleteActionProps
  | TConfirmDeleteNotification // определить по мере поступления
  | TConfirmDeleteBanner

type TInitialState = {
  modalType: TNullable<TModalType>
  modalProps: TModalProps | TEmptyObject
}

export const initialState: TInitialState = {
  modalType: null,
  modalProps: {}
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: {
      reducer(state, action: PayloadAction<TInitialState>) {
        state.modalType = action.payload.modalType
        state.modalProps = action.payload.modalProps
      },
      prepare(modalType: TNullable<TModalType>, modalProps: TModalProps | TEmptyObject) {
        return {
          payload: { modalType, modalProps }
        }
      }
    },
    closeModal() {
      return initialState
    }
  }
})

export const { openModal, closeModal } = modalSlice.actions
export default modalSlice.reducer
