import { isEmpty, isPlainObject, noop } from 'lodash'
import { ComponentType, ReactElement } from 'react'
import ym, { YMInitializer } from 'react-yandex-metrika'
import {useIsomorphicLayoutEffect} from "#hook/useIsomorphicLayoutEffect";

type TOptions = Partial<typeof ymDefaultOptions>
type TAccounts = Array<number>
type TSender = typeof ym

/** @enum Список методов которые возможно отправить в метрику */
enum YmMethods {
  addFileExtension = 'addFileExtension',
  extLink = 'extLink',
  file = 'file',
  getClientID = 'getClientID',
  hit = 'hit',
  notBounce = 'notBounce',
  params = 'params',
  reachGoal = 'reachGoal',
  setUserID = 'setUserID',
  userParams = 'userParams'
}

/** @constant Список дефолтных опций для инициализации счетчика */
const ymDefaultOptions = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  webvisor: true,
  trackHash: true
}

/**
 * @class
 * Абстракция для работы с Yandex.Metrika
 * Реализует Singleton
 * Служит для инициализации счетчика и последующего сбора и отправки данных
 * Для применения необходимо отрендерить Metrika.Component, затем можно вызывать Metrika.send()
 * При SSR создается объект-заглушка
 */
class Metrika {
  /** @property Название тега в который будет помещен скрипт метрики */
  private static readonly _containerElement = 'metrika-container'

  /** @property Инстанс класса для реализации Singleton */
  private static _instance: Metrika

  /** @property Компонент отвечающий за рендеринг кода метрики */
  private _component!: ComponentType

  /** @property Является ли экземпляр объектом-заглушкой */
  private _isPlugImplementation!: boolean

  /** @property Был ли установлен на странице скрипт метрики */
  private _isInitialized!: boolean

  /** @property Отправщик данных в метрику */
  private _sender!: TSender

  /** Компонент отвечающий за рендеринг кода метрики */
  public get Component(): ComponentType {
    return this._component
  }

  /** Был ли установлен на странице скрипт метрики */
  public get isInitialized(): boolean {
    return this._isInitialized
  }

  /**
   * @constructor
   * @description Создает Singleton объект класса Metrika (приватный метод)
   * @throws Выбросит исключение если Singleton уже создан в системе
   */
  private constructor() {
    if (Metrika._instance instanceof Metrika)
      throw new Error('Cannot initialize singleton Metrika more than once')

    Metrika._instance = this
    this._isInitialized = false
  }

  /**
   * @method
   * @description Создает Singleton объект класса Metrika
   * @param accounts
   * @param options
   * @returns {Metrika}
   * @throws Выбросит исключение если передан некорректный массив счетчиков
   */
  public static init(accounts: TAccounts, options: TOptions = {}): Metrika {
    if (isEmpty(accounts) || !accounts.every(Boolean))
      throw new Error('Provided counters are incorrect')
    const instance = new Metrika()
    instance._isPlugImplementation = false
    instance._component = instance.buildComponent(accounts, { ...ymDefaultOptions, ...options })
    instance._sender = ym
    return instance
  }

  /**
   * @method
   * @description Создает Singleton объект-заглушку класса Metrika
   * @returns {Metrika}
   */
  public static initPlug(): Metrika {
    const instance = new Metrika()
    instance._isPlugImplementation = true
    instance._component = instance.buildComponentPlug()
    instance._sender = noop
    return instance
  }

  /**
   * @method
   * @description Отправляет данные о посещении страницы пользователем в метрику
   * @param {string} path
   * @param {object=} options
   * @returns {void}
   */
  public sendHit(path: string, options: Record<string, unknown> = {}): void {
    if (!isEmpty(path)) this.send(YmMethods.hit, path, options)
  }

  /**
   * @method
   * @description Отправляет сессионные параметры в метрику
   * @param {object} params
   * @returns {void}
   */
  public sendSessionParams(params: Record<string, unknown>): void {
    if (!isEmpty(params)) this.send(YmMethods.params, this.omitUid(params))
  }

  /**
   * @method
   * @description Отправляет параметры пользователя в метрику
   * @param {object} params
   * @returns {void}
   */
  public sendUserParams(params: Record<string, unknown>): void {
    if (!isEmpty(params)) this.send(YmMethods.userParams, this.omitUid(params))
  }

  /**
   * @method
   * @description Отправляет идентификатор пользователя в метрику
   * @param {string} uid
   * @returns {void}
   */
  public sendUserId(uid: string): void {
    if (isEmpty(uid)) return
    this.send(YmMethods.setUserID, uid)
    this.send(YmMethods.params, { userId: uid })
    this.send(YmMethods.userParams, { UserID: uid })
  }

  /**
   * @method
   * @description Вычисляет и отправляет ip пользователя в метрику
   * @returns {void}
   */
  public sendUserIp(): void {
    if (this._isPlugImplementation) return
    // eslint-disable-next-line promise/prefer-await-to-then
    void this.fetchUserIp().then((ip) => (ip ? this.sendSessionParams({ userIp: ip }) : null))
  }

  /**
   * @method
   * @description Получает внутренний идентификатор метрики
   * @returns {Promise<string|null>}
   */
  public async getClientId(): Promise<string | null> {
    return new Promise((resolve) => {
      if (this._isPlugImplementation) resolve(null)
      this.send(YmMethods.getClientID, resolve)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      setTimeout(() => resolve(null), 5000)
    })
  }

  /**
   * @method
   * @description Отправка достижения цели метрики
   * @param {String} goal
   * @returns {void}
   */
  public sendGoal(goal: string): void {
    this.send(YmMethods.reachGoal, goal)
  }

  /**
   * @method
   * @description Построение React-компонента отвечающего за рендеринг кода метрики
   * @param {TAccounts} accounts
   * @param {TOptions} options
   * @returns {ComponentType}
   */
  private buildComponent(accounts: TAccounts, options: TOptions): ComponentType {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return function Component(): ReactElement {
      useIsomorphicLayoutEffect(() => {
        that._isInitialized = true
      }, [])
      return (
        <YMInitializer
          accounts={accounts}
          options={options}
          containerElement={Metrika._containerElement}
          version='2'
        />
      )
    }
  }

  /**
   * @method
   * @description Построение заглушки React-компонента отвечающего за рендеринг кода метрики
   * @returns {ComponentType}
   */
  private buildComponentPlug(): ComponentType {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return function Nothing() {
      useIsomorphicLayoutEffect(() => {
        that._isInitialized = true
      }, [])
      return null
    }
  }

  /**
   * @method
   * @description Определяет ip пользователя
   * @returns {Promise<string|null>}
   */
  private async fetchUserIp(): Promise<string | null> {
    if (typeof window === 'undefined' || typeof window.fetch !== 'function') return null
    try {
      const response = await window.fetch('https://api.ipify.org?format=json')
      const { ip } = (await response.json()) as { ip?: string }
      return ip ?? null
    } catch (_e) {
      return null
    }
  }

  /**
   * @method
   * @description Не позволяет произвольно отправлять идентификатор пользователя
   * в обход специального предназначенного для этого метода
   * @param {object} params
   * @returns {object}
   */
  private omitUid(params: Record<string, unknown>): Record<string, unknown> {
    if (!isPlainObject(params)) return params
    const { UserID, userId, ...otherParams } = params
    if ([UserID, userId].some(Boolean)) {
      console.warn(
        'Parameters "UserID", "userId" are reserved and will be omitted. To transfer user identificator use "sendUserId" method'
      )
    }
    return otherParams
  }

  /**
   * @method
   * @description Метод для отправки данных в метрику
   * @param {string} methodName имя метода согласно документации YM
   * @param {any[]} args аргументы метода согласно документации YM
   * @returns {void}
   */
  private send(methodName: YmMethods, ...args: unknown[]): void {
    if (!this._isInitialized) console.error('Can not send data before script was initialized')
    this._sender(methodName, ...args)
  }
}

export default Metrika
