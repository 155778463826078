import intl from '#intl'

export const changePasswordForm = [
  {
    line: 1,
    name: 'login',
    type: 'text',
    label: intl.phoneOrEmail,
    placeholder: intl.loginPlaceholder,
    required: true,
    emptyMessage: intl.fieldEmpty
  }
]

export const changePasswordFormClientRequest = [
  {
    line: 1,
    name: 'login',
    type: 'tel',
    label: intl.mobilePhone,
    placeholder: '',
    required: true,
    emptyMessage: intl.fieldEmpty,
    inputMode: 'numeric',
    mask: '+7 (999) 999-99-99',
    validationRule: '^\\+7 \\(9[0-9]{2}\\) [0-9]{3}\\-[0-9]{2}\\-[0-9]{2}$|^79[0-9]{9}$',
    validationMessageError: 'Введите корректный номер телефона'
  },
  {
    line: 2,
    name: 'birthDate',
    type: 'date',
    label: intl.birthDate,
    placeholder: '',
    required: true,
    emptyMessage: 'Укажите дату Вашего рождения',
    inputMode: 'numeric',
    mask: 'YYYY-MM-DD',
    validationRule: '^[0-9]{4}-[0-9]{2}-[0-9]{2}$',
    validationMessageError: 'Неверная дата рождения',
    bottomText: 'Проверка для защиты персональных данных'
  },
  {
    line: 3,
    name: 'recaptcha',
    type: 'recaptcha',
    required: true,
    emptyMessage: intl.fieldEmpty
  }
]

export const changePasswordFormClientSubmit = [
  {
    line: 1,
    name: 'login',
    type: 'tel',
    label: intl.mobilePhone,
    placeholder: '',
    required: true,
    emptyMessage: intl.fieldEmpty,
    inputMode: 'numeric',
    mask: '+7 (999) 999-99-99',
    validationRule: '^\\+7 \\(9[0-9]{2}\\) [0-9]{3}\\-[0-9]{2}\\-[0-9]{2}$|^79[0-9]{9}$',
    validationMessageError: 'Введите корректный номер мобильного телефона'
  },
  {
    line: 2,
    name: 'birthDate',
    type: 'date',
    label: intl.birthDate,
    placeholder: '',
    required: true,
    emptyMessage: 'Укажите дату Вашего рождения',
    inputMode: 'numeric',
    mask: 'YYYY-MM-DD',
    validationRule: '^[0-9]{4}-[0-9]{2}-[0-9]{2}$',
    validationMessageError: 'Неверная дата рождения',
    bottomText: 'Проверка для защиты персональных данных'
  },
  {
    line: 3,
    name: 'code',
    type: 'number',
    label: intl.confirmationCode,
    maxLength: 4,
    placeholder: '',
    required: true,
    emptyMessage: intl.fieldEmpty
  }
]
