import { createAsyncThunk } from '@reduxjs/toolkit'

import { ResponseCode } from '#modules/api/types/common'
import { CreatePrefix } from '#reducers/helper'
import {
  getTicketDetails,
  getTicketForm,
  getTicketListForEmail
} from '#services/api/osTicketClient/osTicketClientApi'
import { TGetTicketDataProps } from '#services/api/osTicketClient/types'

const prefixHelper = new CreatePrefix('feedbackForm')

export const fetchFeedbackForm = createAsyncThunk(
  prefixHelper.create('fetchFeedbackForm'),
  async (_, thunkAPI) => {
    const response = await getTicketForm()
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue({})
    const { topic, forms } = response
    return thunkAPI.fulfillWithValue({ topic, forms })
  }
)

export const fetchTicketList = createAsyncThunk(
  prefixHelper.create('fetchTicketList'),
  async ({ email, ticketId }: TGetTicketDataProps, thunkAPI) => {
    const response = await getTicketListForEmail(email, ticketId)
    if (response.code !== 0) return thunkAPI.rejectWithValue(response.error)

    const processed = response.data.result
      .map((item) => ({
        id: item.number,
        createdAt: item.createDate,
        updateDate: item.updateDate,
        subject: item.subject,
        status: item.status.name,
        email
      }))
      .filter(({ id }) => (ticketId ? id === ticketId : true))
      .sort(({ updateDate: a }, { updateDate: b }) => Number(new Date(b)) - Number(new Date(a)))
    return thunkAPI.fulfillWithValue({ data: processed })
  }
)

export const fetchTicketDetails = createAsyncThunk(
  prefixHelper.create('fetchTicketDetails'),
  async ({ email, ticketId }: TGetTicketDataProps, thunkAPI) => {
    const response = await getTicketDetails(email, ticketId)
    if (response.code !== ResponseCode.success)
      return thunkAPI.rejectWithValue({ error: response.error, id: ticketId })

    const processed = response.data.message
      .map((item) => ({
        author: item.user.name,
        createdAt: item.created,
        textContent: item.message.body,
        attachment: item.attachment
      }))
      .sort(({ createdAt: a }, { createdAt: b }) => Number(new Date(b)) - Number(new Date(a)))
    return thunkAPI.fulfillWithValue({ id: ticketId, data: processed })
  }
)
