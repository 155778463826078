import './change-password-form.scss'

import cn from 'classnames'
import { isNull } from 'lodash'
import { createRef, Fragment } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'

import { Universal } from '#components/LoginForm/Notifications/Universal'
import appConfig from '#config'
import { ROUTES } from '#constants/common'
import { withRouter } from '#hoc/withRouter'
import withWindowSize from '#hoc/withWindowSize'
import intl from '#intl'
import { personalApi } from '#modules/api'
import metrika from '#modules/metrica'
import { currentClientNotificationType, saveClientNotificationType } from '#reducers/app/appSlice'
import notify from '#services/notify'
import withErrorLogger from '#src/hoc/withErrorLogger'
import {
  changePasswordFormClientRequest,
  changePasswordFormClientSubmit
} from '#src/models/changePasswordForm'

import { Button } from '../Button/Button'
import { FormItem } from '../Form/FormItem'
import BaseClientAuthForm from '../PersonalAreaPage/components/BaseClientAuthForm'

const TOP_CENTER = 'top-center'

export class ChangePasswordFormClient extends BaseClientAuthForm {
  model = changePasswordFormClientRequest
  recaptchaRef = createRef()

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      requestStatus: '',
      isLoading: false,
      codeRepeatCooldown: 0,
      form: this.computeForm()
    }
    this.handlePasswordChangeSubmit = this.handlePasswordChangeSubmit.bind(this)
    this.handleConfirmationCodeRequest = this.handleConfirmationCodeRequest.bind(this)
    this.handleConfirmationCodeRerepeat = this.handleConfirmationCodeRerepeat.bind(this)
    this.coolDownTimer = null
  }

  componentDidMount() {
    metrika.sendGoal('forget-password-goal')
  }

  componentWillUnmount() {
    if (this.coolDownTimer) clearInterval(this.coolDownTimer)
  }

  sendMetrikaDataForCodeRequest = () => {
    const { requestStatus } = this.state
    if (requestStatus === 'sent') metrika.sendGoal('repeat-code')
    else metrika.sendGoal('recover-password')
  }

  sendMetrikaDataForPasswordChangeSubmit = () => {
    const { currentClientNotificationType } = this.props
    if (currentClientNotificationType === 'call') metrika.sendGoal('flash-code-enter')
    if (currentClientNotificationType === 'sms') metrika.sendGoal('sms-code-enter')
  }

  requestConfirmationCode = async () => {
    this.setState({ isLoading: true })
    const { data, requestStatus } = this.state
    try {
      const response =
        requestStatus === 'sent'
          ? await personalApi.sendRepeatRecoveryCode(data)
          : await personalApi.sendRecoveryCode(data)
      // отправка в метрику данных по отправке/запросе кода восстановления
      this.sendMetrikaDataForCodeRequest()

      if (response.code !== 0) {
        metrika.sendGoal('request-code-error')
        throw response
      }

      this.setState(
        (state) => ({
          ...state,
          data: {
            ...state.data,
            code: ''
          },
          requestStatus: 'sent',
          disabledItems: {
            ...state.disabledItems,
            login: true,
            birthDate: true
          },
          errors: {
            ...state.errors,
            login: null
          }
        }),
        () => {
          this.model = changePasswordFormClientSubmit
          this.setState({
            form: this.computeForm()
          })
          const { data: { clientNotificationType } = {} } = response
          const { saveClientNotificationType } = this.props
          saveClientNotificationType({ clientNotificationType })
          const message =
            {
              call:
                '<span>Вам поступит звонок. Введите <strong>4 последние цифры</strong> входящего номера</span>',
              email: intl.codeConfirmationViaEmailMessage,
              sms: intl.codeConfirmationViaSms
            }[clientNotificationType] || intl.codeConfirmationFallbackMessage
          notify.push({
            message: <Universal type={'success'} iconName={'info'} title={message} message={''} />,
            type: 'success',
            alarmIcon: false,
            container: TOP_CENTER
          })
          this.setCoolDownTimer()
        }
      )
    } catch (err) {
      this.props.logError(err)
      this.handleResponse(err)
    } finally {
      this.fallbackAfterSubmit()
    }
  }

  // обработчик запроса получения смс кода
  async handleConfirmationCodeRequest(event) {
    event.preventDefault()
    await this.trimWhiteSpaces()
    const {
      data: { login },
      isLoading
    } = this.state
    if (isLoading || !this.validateForm()) return
    this.setState(
      (state) => ({
        ...state,
        data: {
          ...state.data,
          login: this.getNormalizedLogin(login)
        }
      }),
      () => void this.requestConfirmationCode()
    )
  }

  setCoolDownTimer = () => {
    const startTimer = () => {
      this.coolDownTimer = setInterval(() => {
        if (this.state.codeRepeatCooldown < 1) {
          clearInterval(this.coolDownTimer)
          return this.setState({ codeRepeatCooldown: 0 })
        }
        this.setState((state) => ({ ...state, codeRepeatCooldown: state.codeRepeatCooldown - 1 }))
      }, 1000)
    }

    this.setState({ codeRepeatCooldown: 60 }, startTimer)
  }

  // обработчик запроса повторного получения смс кода
  async handleConfirmationCodeRerepeat(event) {
    event.preventDefault()
    const { isLoading, codeRepeatCooldown } = this.state
    if (isLoading || codeRepeatCooldown || !this.validateForm({ ignore: ['code'] })) return
    return this.requestConfirmationCode()
  }

  fallbackAfterSubmit = () => {
    this.setState(
      (state) => ({
        ...state,
        isLoading: false,
        data: { ...state.data, recaptcha: null }
      }),
      this.recaptchaRef.current?.reset?.()
    )
  }

  // обработчик после успешной отправки смс кода на телефон
  async handlePasswordChangeSubmit(event) {
    event.preventDefault()
    const { isLoading, data } = this.state
    if (isLoading || !this.validateForm()) return
    this.setState({ isLoading: true })
    try {
      const response = await personalApi.sendNewPassword(data)
      const { code, data: { clientNotificationType } = {} } = response
      if (code !== 0) throw response
      // отправка в метрику данных при подтверждении формы восстановления пароля отправленным кодом
      this.sendMetrikaDataForPasswordChangeSubmit()
      const message =
        {
          sms: intl.newPasswordWasSentBySms,
          email: intl.newPasswordWasSentByEmail
        }[clientNotificationType] || intl.newPasswordWasSent
      try {
        notify.push({
          message: <Universal type={'success'} iconName={'info'} title={message} message={''} />,
          type: 'success',
          alarmIcon: false,
          container: 'top-center'
        })
      } catch (_) {
        this.props.logError(new Error(`Ошибка при отправке уведомления: ${message}`))
      }
      this.props.navigate(ROUTES.login)
    } catch (err) {
      this.props.logError(err)
      this.handleResponse(err)
      this.fallbackAfterSubmit()
    }
  }

  handleRecaptchaChange = (name) => (value) =>
    this.setState((state) => ({
      data: { ...state.data, [name]: isNull(value) ? '' : value },
      errors: { ...state.errors, [name]: '' }
    }))

  renderConfirmationCode = (item) => {
    const { isLoading, codeRepeatCooldown } = this.state
    const hintClasses = cn({
      link: true,
      'link--disabled': isLoading || codeRepeatCooldown
    })

    return (
      <>
        {super.renderItem({
          ...item,
          disabled: item.isLoading
        })}
        {codeRepeatCooldown ? (
          <span
            className='text-left text-secondary font-size-small change-password-form__bottomHint mt-1 d-block'
            style={{ maxWidth: 270 }}
          >
            {intl.codeRepeatWillBecomeAvailableInSec.replace('{{seconds}}', codeRepeatCooldown)}
          </span>
        ) : (
          <span
            className={cn(
              hintClasses,
              'text-left font-size-small change-password-form__bottomHint mt-1 d-block'
            )}
            onClick={this.handleConfirmationCodeRerepeat}
          >
            {intl.getNewSmsCode}
          </span>
        )}
      </>
    )
  }

  renderRecaptcha = (item) => {
    const { errors } = this.state
    const {
      userDevice: { isMobile }
    } = this.props
    return (
      <FormItem
        key={item.name}
        {...item}
        error={errors[item.name]}
        className='d-flex-centered flex-column'
      >
        <ReCAPTCHA
          id={item.name}
          ref={this.recaptchaRef}
          hl='ru'
          className={cn({ 'mt-2 mx-auto': true })}
          sitekey={appConfig.recaptchaKey}
          size={isMobile ? 'compact' : 'normal'}
          onChange={this.handleRecaptchaChange(item.name)}
        />
      </FormItem>
    )
  }

  renderItem(item) {
    const { isCaptchaEnabled } = this.props
    item.disabled = Boolean(this.state.disabledItems[item.name])
    const { name } = item
    if (name === 'recaptcha') return isCaptchaEnabled ? this.renderRecaptcha(item) : null
    if (name === 'code') return this.renderConfirmationCode(item)
    return super.renderItem({ ...item, dropDownClass: 'smart-control__dropdown_additionalClass' })
  }

  render() {
    const { form, isLoading, requestStatus } = this.state
    const className = cn(
      {
        form: true,
        'change-password-form': true
      },
      this.props.className
    )

    return (
      <form
        className={className}
        noValidate
        onSubmit={
          requestStatus === 'sent'
            ? this.handlePasswordChangeSubmit
            : this.handleConfirmationCodeRequest
        }
        data-qa='changePasswordFormClient'
      >
        {form.lines.map((line, key) => (
          <div className='form__line' key={key}>
            {line.items.map(
              (item) =>
                !this.checkDepends(item) && (
                  <Fragment key={item.name}>{this.renderItem(item)}</Fragment>
                )
            )}
          </div>
        ))}
        <div className='form__bottom'>
          <Button
            type={'button'}
            size='l'
            fluid
            isLoading={isLoading}
            disabled={isLoading}
            data-qa='submitButton'
          >
            {requestStatus === 'sent' ? intl.recoverPassword : intl.continue}
          </Button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state = {}) => ({
  isCaptchaEnabled: state.settings.data.googleCaptcha?.isEnabled,
  currentClientNotificationType: currentClientNotificationType(state)
})

const mapDispatchToProps = (dispatch) => ({
  saveClientNotificationType: ({ clientNotificationType }) =>
    dispatch(saveClientNotificationType({ clientNotificationType }))
})

export default withRouter(
  withWindowSize(
    withErrorLogger(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordFormClient))
  )
)
