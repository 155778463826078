import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import config from '#config'
import { adminApi, nodeApi } from '#modules/api'
import { ROUTES } from '#src/constants/common'
import { IAuthor } from '#src/modules/api/admin/types'
import { ResponseCode } from '#src/modules/api/types/common'
import notify from '#src/services/notify'

import { TMainDataStateType } from '..'
import { CreatePrefix } from '../helper'

export type TFormData = {
  name: string
  position: string
  education: string
  uniqPosition: string
  photo: File[]
}

export const fetchGetAuthors = createAsyncThunk(
  'authors/getAuthors',
  async (token: string, thunkAPI) => {
    const response = await adminApi.getAuthors(token)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
    return response.data
  }
)

export const fetchRemoveAuthor = createAsyncThunk(
  'authors/removeAuthor',
  async ({ id, token }: { id: number; token: string }, { dispatch }) => {
    const response = await adminApi.removeAuthor({ id, token })

    if (response.code === ResponseCode.success) {
      notify.push({
        message: 'Автор удален',
        type: 'success'
      })
      void dispatch(fetchRemoveAuthorSuccess(response.id))
    }
  }
)

export const fetchEditAuthor = createAsyncThunk(
  'authors/editAuthor',
  async ({ data, id, token }: { data: TFormData; id: number; token: string }) => {
    const fileName = await saveAuthorImage(data.photo, token)

    if (fileName) {
      const authorData = {
        id,
        name: data.name,
        position: data.position,
        education: data.education,
        uniqPosition: data.uniqPosition,
        fileName
      }
      await adminApi.editAuthor({ ...authorData, token })
      notify.push({
        message: 'Данные изменены',
        type: 'success'
      })
    }
  }
)

export const fetchGetAuthorData = createAsyncThunk(
  'authors/getAuthor',
  async ({ id, token }: { id: number; token: string }, { dispatch }) => {
    void dispatch(setCurrentEditAuthor(null))
    const response = await adminApi.getAuthor({ id, token })

    if (response.code === ResponseCode.success) {
      const authorData = response.data
      const authorPhoto = await fetch(`${config.host}/img/${authorData.fileName}`).then((r) =>
        // eslint-disable-next-line promise/no-nesting
        r
          .blob()
          .then((blobFile) => new File([blobFile], authorData.fileName, { type: 'image/png' }))
      )
      const authorWithBlobFile = {
        ...authorData,
        photo: [authorPhoto]
      }
      void dispatch(setCurrentEditAuthor(authorWithBlobFile))
    }
  }
)

export const fetchAddAuthor = createAsyncThunk(
  'authors/addAuthor',
  async ({ data, token }: { data: TFormData; token: string }) => {
    const fileName = await saveAuthorImage(data.photo, token)

    if (fileName) {
      const authorData = {
        name: data.name,
        position: data.position,
        education: data.education,
        uniqPosition: data.uniqPosition,
        fileName
      }
      await adminApi.addAuthor({ ...authorData, token })
      window.location.href = ROUTES.adminAuthors
    }
  }
)

const prefixHelper = new CreatePrefix('authors')

const fetchRemoveAuthorSuccess = createAction<number>(
  prefixHelper.create('fetchRemoveAuthorSuccess')
)
const setCurrentEditAuthor = createAction<IAuthor | null>(
  prefixHelper.create('setCurrentEditAuthor')
)

export const saveAuthorImage = async (photo: File[], token: string): Promise<string | null> => {
  const { fileName } = await nodeApi.saveAuthorImage(photo[0], token)
  return fileName
}

type TAuthorsInitialState = {
  items: IAuthor[]
  currentEditAuthor: IAuthor | null
}

const initialState: TAuthorsInitialState = {
  items: [],
  currentEditAuthor: null
}

const authorsSlice = createSlice({
  name: 'authors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetAuthors.fulfilled, (state, action) => {
        state.items = action.payload
      })
      .addCase(fetchRemoveAuthorSuccess, (state, action) => {
        state.items = state.items.filter((author) => author.id !== action.payload)
      })
      .addCase(setCurrentEditAuthor, (state, action) => {
        state.currentEditAuthor = action.payload
      })
  }
})

export default authorsSlice.reducer

const authors = (state: TMainDataStateType): TAuthorsInitialState => state.adminPage.authors
export const authorsItemsSelector = createSelector(authors, (authors) => authors.items)

export const authorsEditAuthorSelector = createSelector(
  authors,
  (authors) => authors.currentEditAuthor
)
