import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from 'lodash'

import { updateSitemap } from '#components/AdminPage/services/updateSitemap'
import intl from '#intl'
import { adminApi } from '#modules/api'
import * as AdminApiTypes from '#modules/api/admin/types'
import { ResponseCode } from '#modules/api/types/common'
import {
  TCreateItemParamsRequest,
  TFetchPageItemParamsRequest,
  TFetchPageListParamsRequest,
  TUpdateItemParamsRequest
} from '#reducers/adminPage/content/types'
import { CreatePrefix, errorPath } from '#reducers/helper'

const prefixHelper = new CreatePrefix('content')
const CONTENT_MANIPULATE_ERROR_MESSAGE = 'Произошла ошибка при сохранении данных'

export const fetchPageItemHistory = createAsyncThunk(
  prefixHelper.create('fetchPageItemHistory'),
  async (params: AdminApiTypes.IGetPageItemHistory, thunkAPI) => {
    const { token, url, cancelToken } = params
    const response = await adminApi.getPageItemHistory({
      token,
      url,
      cancelToken
    })
    if (response.code === ResponseCode.success) return thunkAPI.fulfillWithValue(response.data)
    const message = get(response, errorPath) || intl.serverError
    return thunkAPI.rejectWithValue({ message })
  }
)

export const fetchPageList = createAsyncThunk(
  prefixHelper.create('fetchPageList'),
  async (params: TFetchPageListParamsRequest, thunkAPI) => {
    const response = await adminApi.getPageList(params)
    if (response.code === ResponseCode.success) return thunkAPI.fulfillWithValue(response.data)
    const { message } = response
    return thunkAPI.rejectWithValue({ message })
  }
)

export const fetchPageItemContent = createAsyncThunk(
  prefixHelper.create('fetchPageItemContent'),
  async (params: TFetchPageItemParamsRequest, thunkAPI) => {
    const { id, token, cancelToken } = params
    const response = await adminApi.getPageItemContent(Number(id), token, cancelToken)
    if (response.code === ResponseCode.success) {
      const { data } = response
      return thunkAPI.fulfillWithValue(data)
    }
    const { message } = response
    return thunkAPI.rejectWithValue({ message })
  }
)

export const updatePageItemContent = createAsyncThunk(
  prefixHelper.create('updatePageItemContent'),
  async (params: TUpdateItemParamsRequest, thunkAPI) => {
    const { data, token, contentId, navigate } = params
    const response = await adminApi.updatePageItemContent({ ...data, token, contentId })
    if (response.code === ResponseCode.success) {
      await updateSitemap(token)
      navigate('/admin/content')
      return
    }
    return thunkAPI.rejectWithValue({ message: CONTENT_MANIPULATE_ERROR_MESSAGE })
  }
)

export const createPageItemContent = createAsyncThunk(
  prefixHelper.create('createPageItemContent'),
  async (params: TCreateItemParamsRequest, thunkAPI) => {
    const { data, token, navigate } = params
    const response = await adminApi.createPageItemContent({ ...data, token })
    if (response.code === ResponseCode.success) {
      await updateSitemap(token)
      navigate('/admin/content')
      return thunkAPI.fulfillWithValue({})
    }
    return thunkAPI.rejectWithValue({ message: CONTENT_MANIPULATE_ERROR_MESSAGE })
  }
)
