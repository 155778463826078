import config from '#config'

import Metrika from './Metrika'

const DEV_ENV = 'dev'

const {
  metricaEnabled = '',
  ymCounterDev = null,
  ymCounterProd = null,
  env = DEV_ENV
} = config as Record<string, string>

const accounts = <Array<number>>[]
const isMetricaEnabled = Boolean(Number(metricaEnabled))
const isBrowserContext = typeof process !== 'undefined' && Boolean(process.env?.__CLIENT__)

if (isMetricaEnabled) {
  if (env === DEV_ENV) accounts.push(Number(ymCounterDev))
  else accounts.push(Number(ymCounterProd))
}

/** @constant
 * Инициализировать заглушку если:
 *   функционал сбора данных НЕ включен в конфиге
 *   ИЛИ НЕ переданы номера счетчиков
 *   ИЛИ контекст исполнения НЕ браузер
 */
const isPlug = !isMetricaEnabled || !accounts.length || !isBrowserContext

const metrika = isPlug ? Metrika.initPlug() : Metrika.init(accounts)

export const MetrikaComponent = metrika.Component
export default metrika
