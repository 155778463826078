import '../../assets/scss/common.scss'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'

import ErrorBoundary from '#components/ErrorBoundary'
import configureStore from '#src/store/configureStore'
import storeAccessor from '#src/store/storeAccessor'

if (process.env.__BUILD__ === 'main') {
  require('lazysizes')
  require('lazysizes/plugins/attrchange/ls.attrchange')
  require('lazysizes/plugins/unveilhooks/ls.unveilhooks')
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
smoothscroll.polyfill()
export const store = configureStore()
storeAccessor.link(store)

export type TMainAppDispatch = typeof store.dispatch

type TAppStateProps = {
  location: string
}
export default class App extends Component<TAppStateProps, TAppStateProps> {
  state = {
    location: ''
  }

  static getDerivedStateFromProps = (
    props: TAppStateProps,
    state: TAppStateProps
  ): TNullable<TAppStateProps> =>
    state && props.location !== state.location ? { ...state, location: props.location } : null

  render(): JSX.Element {
    return (
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router>
            <ErrorBoundary style={{ margin: '10% 0' }}>{this.props.children}</ErrorBoundary>
          </Router>
        </MuiPickersUtilsProvider>
      </Provider>
    )
  }
}
