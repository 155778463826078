import './page-404.scss'

import { Helmet } from 'react-helmet'

import appConfig from '#config'
import { getRouteValue } from '#constants/common'
import intl from '#intl'
import notFoundImg from '#src/assets/img/404.png'

import { Button } from '../Button/Button'

export const Page404 = () => (
  <>
    <Helmet>
      <link rel='preload' href={notFoundImg} as='image' />
    </Helmet>
    <div className='page page-404'>
      <div className='page-404__content'>
        <div className='container'>
          <img src={notFoundImg} width={506} height={235} alt={'Page not found'} />
          <h2 className='page-404__title'>{intl.pageNotFound}</h2>
          <div className='page-404__text'>{intl.pageNotFoundText}</div>
          <Button
            type='anchor'
            url={new URL(getRouteValue('main'), appConfig.host).href}
            className='page-404__button'
            size='l'
            fluid
          >
            {intl.mainPage}
          </Button>
          <Button
            type='anchor'
            url={new URL(getRouteValue('register'), appConfig.host).href}
            className='page-404__button'
            size='l'
            inverted
            fluid
          >
            {intl.loanApplicationNotFoundPage}
          </Button>
        </div>
      </div>
    </div>
  </>
)
