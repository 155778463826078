import { AxiosRequestConfig } from 'axios'
import { Cookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'

import { axiosRequestConfig } from '#modules/api/main/mainSiteApi'
import { HttpClient } from '#src/modules/api/httpClient'

import * as AuthApiTypes from './types'

class Auth extends HttpClient {
  private readonly cookies: Cookies

  public constructor(config: AxiosRequestConfig) {
    super(config)
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
    this.cookies = new Cookies()
  }

  public auth(formData: FormData): Promise<AuthApiTypes.TAuthResponse> {
    // добавление fingerprint c4s
    formData.append('fingerprint', this.getFingerPrint())
    formData.append('userAgent', this.getUserAgent())
    return this.instance.post('/auth/authUser', formData)
  }

  public requestPhoneCode(formData: FormData): Promise<AuthApiTypes.TOTPResponse> {
    return this.instance.post('/auth/phoneCode/get', formData)
  }

  public checkPhoneCode(formData: FormData): Promise<AuthApiTypes.TOTPCheckResponse> {
    return this.instance.post('/auth/phoneCode/check', formData)
  }

  public logout(formData: FormData): Promise<AuthApiTypes.TLogoutResponse> {
    return this.instance.post('/auth/authLogOut', formData)
  }

  public refreshAccessToken(): Promise<AuthApiTypes.TAccessToken> {
    const formData = new FormData()
    formData.append('channel', 'admin')
    formData.append('fingerprint', this.getFingerPrint())
    formData.append('refreshToken', this.getRefreshToken())
    return this.instance.post('/auth/refreshToken', formData)
  }

  private getFingerPrint(): string {
    return (this.cookies.get('c4s') as string) || uuidv4()
  }

  private getUserAgent(): string {
    return window?.navigator?.userAgent ?? 'User agent is not defined'
  }

  private getRefreshToken(): string {
    return window.localStorage.getItem('refreshToken') ?? 'token is not defined'
  }
}

export const authApi = new Auth(axiosRequestConfig)
