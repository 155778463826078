import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mapValues, merge } from 'lodash'

import DataSaver from '#modules/DataSaver'
import {
  calculateSettings,
  filterObject,
  STORAGE_KEY,
  TCalculatedSettings,
  TRawSettings
} from '#services/helper'

export interface IAbTestState {
  isCalculated: boolean
  dataToLog: TCalculatedSettings
  data: TCalculatedSettings
}

export type TInitAbSettingsSuccess = Omit<IAbTestState, 'isCalculated'>
export const initialState: IAbTestState = {
  isCalculated: false,
  dataToLog: {},
  data: {}
}

const abTestSlice = createSlice({
  name: 'abTest',
  initialState,
  reducers: {
    initAbSettings: (state, { payload }: PayloadAction<TRawSettings>) => {
      const calculatedSettings = mapValues(calculateSettings(payload), (value) => ({
        value,
        isLogged: false
      }))
      const dataSaver = new DataSaver(STORAGE_KEY)
      const savedSettings = dataSaver.getData()
      const mergedSettings = merge(calculatedSettings, savedSettings)
      const notLoggedSettings = filterObject(mergedSettings, ({ isLogged }) => !isLogged)

      const dataToLog = mapValues(notLoggedSettings, ({ value }) => value)
      const data = mapValues(mergedSettings, ({ value }) => value)
      dataSaver.saveData(mergedSettings)

      state.isCalculated = true
      state.data = data
      state.dataToLog = dataToLog
    }
  }
})

export const { initAbSettings } = abTestSlice.actions

export default abTestSlice.reducer
