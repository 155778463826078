import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { TModelItem } from '#components/Form/Form'
import * as AdminApiTypes from '#modules/api/admin/types'
import {
  createNewArticle,
  fetchArticles,
  fetchFullArticleData,
  removeArticle,
  updateArticle
} from '#reducers/adminPage/articles/effects'
import { getErrorMessage } from '#reducers/helper'
import notify from '#services/notify'

export type TArticlesReducerState = {
  articles: AdminApiTypes.TArticle[]
  currentArticle: AdminApiTypes.TArticle | TEmptyObject
  totalItems: number
  currentPage: number
  totalPages: number
  itemsPerPage: number
  removingArticlesIds: number[]
  loading: boolean
  model: TModelItem[]
}

const initialState: TArticlesReducerState = {
  articles: [],
  currentArticle: {},
  totalItems: 0,
  currentPage: 1,
  totalPages: 1,
  itemsPerPage: 6,
  removingArticlesIds: [],
  loading: false,
  model: []
}

const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setRemoveArticleId: (state, { payload }: PayloadAction<number>) => {
      state.removingArticlesIds = [...new Set(state.removingArticlesIds.concat(payload))]
    },
    setCurrentPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload
    },
    setItemsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.itemsPerPage = payload
    },
    flushCurrentArticleData: (state) => {
      state.currentArticle = {}
    },
    updateModel: (state, action: PayloadAction<TModelItem[]>) => {
      // @ts-ignore
      state.model = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.loading = false
        state.totalItems = action.payload.totalItems
        state.articles = action.payload.items
      })
      .addCase(fetchArticles.rejected, (state) => {
        state.loading = false
        state.articles = []
      })
      .addCase(fetchFullArticleData.pending, (state) => {
        state.loading = true
        state.currentArticle = {}
      })
      .addCase(fetchFullArticleData.fulfilled, (state, action) => {
        state.currentArticle = action.payload.article
        state.loading = false
      })
      .addCase(updateArticle.fulfilled, (state) => {
        state.loading = false
        notify.push({ message: 'Cтатья успешно изменена', type: 'success' })
      })
      .addCase(createNewArticle.fulfilled, (state) => {
        state.totalItems = state.totalItems + 1
        state.loading = false
        notify.push({ message: 'Cтатья успешно добавлена', type: 'success' })
      })
      .addCase(
        removeArticle.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id }
            }
          }
        ) => {
          state.totalItems = state.totalItems - 1
          state.articles = state.articles.filter((item) => item.id !== id)
          notify.push({ message: 'Статья успешно удалена', type: 'success' })
        }
      )
      .addCase(removeArticle.rejected, (state, action) => {
        state.removingArticlesIds = state.removingArticlesIds.filter((id) => id !== action.payload)
      })
      .addMatcher(
        isAnyOf(fetchArticles.pending, createNewArticle.pending, updateArticle.pending),
        (state) => {
          state.loading = true
        }
      )
      .addMatcher(
        isAnyOf(fetchFullArticleData.rejected, updateArticle.rejected, createNewArticle.rejected),
        (state, action) => {
          const message = getErrorMessage(action)
          notify.push({ message, type: 'danger' })
          state.loading = false
        }
      )
  }
})

export const {
  setRemoveArticleId,
  setCurrentPageNumber,
  setItemsPerPage,
  flushCurrentArticleData,
  updateModel
} = articlesSlice.actions

export default articlesSlice.reducer
