import { createSlice } from '@reduxjs/toolkit'

import * as AdminApiTypes from '#modules/api/admin/types'
import { fetchBlog } from '#reducers/blog/effects'

type TInitialState = {
  loading: boolean
  items: AdminApiTypes.TArticle[]
}
export const initialState: TInitialState = {
  loading: false,
  items: []
}

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchBlog.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchBlog.fulfilled, (state, action) => {
        state.items = action.payload.data
        state.loading = false
      })
      .addCase(fetchBlog.rejected, (state) => {
        state.loading = false
      })
})
export default blogSlice.reducer
