import { createAsyncThunk } from '@reduxjs/toolkit'

import { adminApi } from '#modules/api'
import {
  TTestimonial,
  TTestimonialsRequestById,
  TTestimonialWithoutId
} from '#modules/api/admin/testimonials'
import { ResponseCode } from '#modules/api/admin/types'
import { TestimonialResponseCode } from '#modules/api/types/common'
import { CreatePrefix } from '#reducers/helper'

const prefixHelper = new CreatePrefix('testimonials')

export const getTestimonialsList = createAsyncThunk(
  prefixHelper.create('getTestimonialsList'),
  async (data: { token: string; limit: number; offset: number }, thunkAPI) => {
    const { token, limit, offset } = data
    const response = await adminApi.getTestimonialsList({ token, limit, offset })
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
    return response.data
  }
)

export const createTestimonial = createAsyncThunk(
  prefixHelper.create('createTestimonial'),
  async ({ token, data }: { data: TTestimonialWithoutId; token: string }, thunkAPI) => {
    const response = await adminApi.createTestimonial({ ...data, token })
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({ message })
    }
    return response.id
  }
)

export const removeTestimonial = createAsyncThunk(
  prefixHelper.create('removeTestimonial'),
  async (params: { token: string; id: number }, thunkAPI) => {
    const { token, id } = params
    const response = await adminApi.removeTestimonial({ token, id })
    if (response.code !== ResponseCode.success)
      return thunkAPI.rejectWithValue({ message: response.message })
    return thunkAPI.fulfillWithValue({ payload: id })
  }
)

export const getTestimonialById = createAsyncThunk(
  prefixHelper.create('getTestimonialById'),
  async (params: { token: string; id: number }, thunkAPI) => {
    const { token, id } = params
    const response = await adminApi.getTestimonial({ token, id })
    if (response.code === ResponseCode.success) return response.data
    if (response.code === TestimonialResponseCode.notFound)
      return thunkAPI.rejectWithValue({ message: 'Отзыв не найден' })
    return thunkAPI.rejectWithValue({ message: response.message })
  }
)

export const updateTestimonial = createAsyncThunk(
  prefixHelper.create('updateTestimonial'),
  async (params: { data: TTestimonial; id: number; token: string }, thunkAPI) => {
    const { data, token, id } = params
    const response = await adminApi.updateTestimonial({ ...data, id, token })
    if (response.code !== ResponseCode.success)
      return thunkAPI.rejectWithValue({ message: response.message })
  }
)

export const sendTestimonialToSupport = createAsyncThunk(
  prefixHelper.create('sendTestimonialToSupport'),
  async (params: TTestimonialsRequestById, thunkAPI) => {
    const { token, id } = params
    const response = await adminApi.sendTestimonialToSupport({ id, token })
    if (response.code !== ResponseCode.success)
      return thunkAPI.rejectWithValue({ message: response.message })
  }
)
