import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import { isAfter, isBefore, parseISO } from 'date-fns'

import { TAccounts } from '#modules/api/personal/types'
import { TPersonalRootState } from '#reducers/entries/personal'
import { CreatePrefix } from '#reducers/helper'
import { personalApi } from '#src/modules/api'

export type TActionRemoveAccount = {
  id: number
}

export interface IAccountInitialState {
  loading: boolean
  data: TAccounts
}

const initialState: IAccountInitialState = {
  loading: false,
  data: []
}

export type TFetchProfileSucceed = typeof fetchUserAccountsRequest

export type TActionType =
  | ActionCreatorWithoutPayload
  | ActionCreatorWithoutPayload
  | ActionCreatorWithPayload<TAccounts>
  | ActionCreatorWithPayload<TActionRemoveAccount>

const prefixHelper = new CreatePrefix('profile')

export const fetchUserAccountsRequest = createAction(
  prefixHelper.create('fetchUserAccountsRequest')
)
export const fetchUserAccountsFailure = createAction(
  prefixHelper.create('fetchUserAccountsFailure')
)
export const fetchUserAccountsSuccess = createAction<TAccounts>(
  prefixHelper.create('fetchProfileSucceed')
)
export const removeAccount = createAction<TActionRemoveAccount>(
  prefixHelper.create('removeAccount')
)

export const fetchUserAccounts = createAsyncThunk(
  prefixHelper.create('getAccounts'),
  async (token: string, thunkAPI) => {
    thunkAPI.dispatch(fetchUserAccountsRequest())
    try {
      const response = await personalApi.getAccounts(token)
      if (response.code !== 0) throw response
      const { data } = response
      thunkAPI.dispatch(fetchUserAccountsSuccess(data))
    } catch (err) {
      thunkAPI.dispatch(fetchUserAccountsFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const fetchUserAccountsWithoutLoading = createAsyncThunk(
  prefixHelper.create('getAccountsWithoutLoading'),
  async (token: string, thunkAPI) => {
    try {
      const response = await personalApi.getAccounts(token)
      if (response.code !== 0) throw response
      const { data } = response
      thunkAPI.dispatch(fetchUserAccountsSuccess(data))
    } catch (err) {
      thunkAPI.dispatch(fetchUserAccountsFailure())
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserAccountsRequest, (state) => {
        state.loading = true
      })
      .addCase(fetchUserAccountsSuccess, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchUserAccountsFailure, (state) => {
        state.loading = false
      })
      .addCase(removeAccount, (state, action) => {
        state.data = state.data.filter((id) => id !== action.payload)
      })
})

export const isLoadingAccounts = (state: TPersonalRootState): boolean =>
  state.clientData.accounts.loading

const getAccounts = (state: TPersonalRootState): TAccounts => state.clientData.accounts.data

const MINUS_ONE = -1
const ONE = 1
const ZERO = 0

export const accountsSelector = createSelector(getAccounts, (accounts) =>
  accounts
    .slice()
    .sort((a, b) => {
      const dateA = parseISO(a.createTs)
      const dateB = parseISO(b.createTs)
      if (isAfter(dateA, dateB)) return MINUS_ONE
      if (isBefore(dateA, dateB)) return ONE
      return ZERO
    })
    .sort((a, b) => b.type - a.type)
    .sort((a, b) => {
      if (a.isValid === b.isValid) return ZERO
      return a.isValid ? MINUS_ONE : ONE
    })
)

export const validAccountsSelector = createSelector(accountsSelector, (accounts) =>
  accounts.filter(({ isValid }) => isValid)
)

export default accountsSlice.reducer
