import { ReactNode } from 'react'
import { useParams } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'

export function withRouter(Component: any): ReactNode {
  return function ComponentWithRouterProp(props: any): ReactNode {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    return <Component {...props} location={location} navigate={navigate} params={params} />
  }
}
