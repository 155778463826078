export const FETCH_PERSON_GROUPS_REQUEST = 'FETCH_PERSON_GROUPS_REQUEST'
export const FETCH_PERSON_GROUPS_SUCCESS = 'FETCH_PERSON_GROUPS_SUCCESS'
export const FETCH_PERSON_GROUPS_FAILURE = 'FETCH_PERSON_GROUPS_FAILURE'

export interface IGroup {
  id: number
  name: string
  color: string | null
  progress: number | null
  text: string
}

export interface IPersonGroups {
  [key: string]: IGroup
}

export interface IPersonGroupState {
  readonly data: IPersonGroups | null
  readonly isLoading: boolean
  readonly isFetched: boolean
}

interface IFetchGroupsActionRequest {
  type: typeof FETCH_PERSON_GROUPS_REQUEST
}

interface IFetchGroupsActionSuccess {
  type: typeof FETCH_PERSON_GROUPS_SUCCESS
  payload: IPersonGroups
}

interface IFetchGroupsActionFailure {
  type: typeof FETCH_PERSON_GROUPS_FAILURE
}

export type TPersonGroupsActionTypes =
  | IFetchGroupsActionRequest
  | IFetchGroupsActionSuccess
  | IFetchGroupsActionFailure
