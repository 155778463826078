import { createAsyncThunk } from '@reduxjs/toolkit'
import { findKey } from 'lodash'
import UniversalCookie from 'universal-cookie'

import intl from '#intl'
import * as AdminApiTypes from '#modules/api/admin/types'
import { SliderDataError } from '#modules/api/exceptions'
import { TGetPartnerGidResponse } from '#modules/api/main/types'
import * as PersonalApiTypes from '#modules/api/personal/types'
import { ResponseCode } from '#modules/api/types/common'
import { CreatePrefix } from '#reducers/helper'
import { setLoanConditions } from '#reducers/loanConditions/loanConditionsSlice'
import {
  ISliderInfoParamsProps,
  TFetchDefaultPromoCode,
  TGetDefaultPromo,
  TMapApiToBuild
} from '#reducers/loanConditions/types'
import {
  fetchLoanConditionsSuccess,
  setFetchingRequest,
  setLoanFormState,
  updateLoanConditionsSliderData,
  updateLoanConditionsSuccess
} from '#reducers/loanFormState/loanFormStateSlice'
import { adminApi, mainSiteApi, personalApi } from '#src/modules/api'
import CacheResponse from '#src/modules/CacheResponse'
import { TMainDataStateType } from '#src/reducers'

const prefixHelper = new CreatePrefix('loanConditions')

/**
 * Мэппинг методов api в соответствии с конкретным билдом приложения
 */
const mapApiToBuild: TMapApiToBuild = {
  personal: {
    getDefaultPromo: personalApi.getDefaultPromo.bind(personalApi),
    getSliderInfo: personalApi.getSliderInfo.bind(personalApi),
    getProductDescription: adminApi.getProductDescription.bind(adminApi)
  },
  main: {
    getDefaultPromo: mainSiteApi.getDefaultPromo.bind(mainSiteApi),
    getSliderInfo: mainSiteApi.getSliderInfo.bind(mainSiteApi),
    getProductDescription: adminApi.getProductDescription.bind(adminApi)
  }
}

/**
 * Набор методов api, соответствующий конкретному билду приложения
 * Если билд не определен явно, то берется набор соответствующий билду main
 */
const _api =
  mapApiToBuild[process.env.__BUILD__ as keyof typeof mapApiToBuild] || mapApiToBuild.main
type TUpdateLoanConditionsPersonal = {
  apiType: 'personal'
  token: string
  promoCode?: string
}

type TUpdateLoanConditionsMain = {
  apiType: 'main'
  promoCode?: string
  groupId?: number
}

type TUpdateLoanConditionsArgs = TUpdateLoanConditionsPersonal | TUpdateLoanConditionsMain

export const updateLoanConditions = createAsyncThunk(
  prefixHelper.create('updateLoanConditions'),
  async (params: TUpdateLoanConditionsArgs, thunkAPI) => {
    try {
      const { apiType } = params
      const response =
        apiType === 'main'
          ? await mainSiteApi.getSliderInfo(params)
          : await personalApi.getSliderInfo(params)

      if (response.code !== ResponseCode.success) throw response
      // установка состояния в redux state
      thunkAPI.dispatch(updateLoanConditionsSliderData(response.data))
      thunkAPI.dispatch(setLoanConditions(response.data))

      const { promoCode } = params
      if (promoCode) {
        const currentState = thunkAPI.getState() as TMainDataStateType
        const { promo } = currentState.loanConditions.data
        const appliedPromoCode = findKey(promo)
        if (!appliedPromoCode) throw new Error(intl.invalidPromoCode)
        else thunkAPI.dispatch(setLoanFormState({ appliedPromoCode }))
        thunkAPI.dispatch(updateLoanConditionsSuccess())
      } else {
        thunkAPI.dispatch(
          setLoanFormState({
            data: { promoCode: '' },
            appliedPromoCode: null
          })
        )
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const fetchLoanConditionsWithDefaultPromoCode = createAsyncThunk(
  prefixHelper.create('fetchLoanConditionsWithDefaultPromoCode'),
  async (params: TFetchDefaultPromoCode, thunkAPI) => {
    thunkAPI.dispatch(setFetchingRequest())
    let isUseDefaultPromo = true
    let serverCookies = null
    let groupId = params?.groupId
    const { aprt159 = null } = params

    if (!groupId && aprt159) {
      const response: TGetPartnerGidResponse = await mainSiteApi.getPartnerGidFromQueryString(
        aprt159
      )
      if (response.code !== ResponseCode.success) throw new SliderDataError()
      groupId = response.partnerGid
    }

    /**
     * personal or server
     */
    if (typeof params !== 'undefined') {
      const { serverCookie = null } = params
      /**
       * if serverCookie -> request from server
       */
      serverCookies = serverCookie ? new UniversalCookie(serverCookie) : new UniversalCookie()
    } else {
      /**
       * request from personal
       */
      serverCookies = new UniversalCookie()
    }

    if (serverCookies) {
      isUseDefaultPromo = serverCookies.get('isUseDefaultPromo')
        ? Boolean(
            serverCookies.get('isUseDefaultPromo') === 'true' &&
              typeof serverCookies.get('isUseDefaultPromo') === 'string'
          )
        : true
    }

    // =======================================================
    /* Кеширование ответов от api*/
    const defaultPromoParams = params
      ? { ...params, groupId }
      : { defaultPromoParams: 'defaultPromoParams' }
    const defaultPromoKey = `defaultPromo${JSON.stringify(defaultPromoParams)}`
    let promoResponse: PersonalApiTypes.TGetDefaultPromoResponse | undefined
    if (CacheResponse.hasData(defaultPromoKey)) {
      promoResponse = CacheResponse.getData(defaultPromoKey)
    } else {
      promoResponse = await _api.getDefaultPromo(defaultPromoParams as TGetDefaultPromo)
      CacheResponse.setData(defaultPromoKey, promoResponse)
    }
    const { code, data } = promoResponse as PersonalApiTypes.IGetDefaultPromoSuccess
    const promoCode = code === 0 && isUseDefaultPromo ? data : ''

    /* Кеширование ответов от api*/
    const sliderInfoParams = params
      ? { ...params, promoCode, groupId }
      : { sliderInfoParams: 'sliderInfoParams' }
    const sliderInfoKey = `sliderInfo_${JSON.stringify(sliderInfoParams)}`
    let response
    if (CacheResponse.getData(sliderInfoKey)) {
      response = CacheResponse.getData(sliderInfoKey) as PersonalApiTypes.IGetSliderInfoSuccess
    } else {
      response = await _api.getSliderInfo(sliderInfoParams as ISliderInfoParamsProps)
      if (response.code === ResponseCode.success) CacheResponse.setData(sliderInfoKey, response)
    }
    if (response.code !== ResponseCode.success) throw new SliderDataError()

    thunkAPI.dispatch(fetchLoanConditionsSuccess(response.data))

    const appliedPromoCode = findKey(response.data.promo) as string

    if (appliedPromoCode)
      thunkAPI.dispatch(setLoanFormState({ appliedPromoCode, data: { promoCode } }))

    return thunkAPI.fulfillWithValue(response.data)
  }
)

export const fetchProductDescription = createAsyncThunk(
  prefixHelper.create('fetchProductDescription'),
  async (status: AdminApiTypes.TPersonGroups, thunkAPI) => {
    const response = await _api.getProductDescription(status)
    if (response.code !== ResponseCode.success) return thunkAPI.rejectWithValue(response)
    return thunkAPI.fulfillWithValue(response.data)
  }
)

export const fetchConsumerCreditCondition = createAsyncThunk(
  prefixHelper.create('fetchConsumerCreditCondition'),
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setFetchingRequest())
    /* Кеширование ответов от api*/
    let response: PersonalApiTypes.TGetSliderInfoResponse | PersonalApiTypes.IGetSliderInfoSuccess

    if (CacheResponse.getData('fetchConsumerCreditCondition')) {
      response = CacheResponse.getData(
        'fetchConsumerCreditCondition'
      ) as PersonalApiTypes.IGetSliderInfoSuccess
    } else {
      response = await _api.getSliderInfo({ promoCode: '' })
      if (response.code === ResponseCode.success)
        CacheResponse.setData('fetchConsumerCreditCondition', response)
    }

    if (response.code === ResponseCode.success) {
      thunkAPI.dispatch(
        fetchLoanConditionsSuccess({
          ...response.data,
          fetchProductType: 'ConsumerCredit'
        })
      )
      return thunkAPI.fulfillWithValue(response.data)
    }
    thunkAPI.dispatch(updateLoanConditionsSuccess())
    return thunkAPI.rejectWithValue({})
  }
)
