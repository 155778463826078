import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'

import { INVALID_TOKEN, UNAUTHORIZED } from '#constants/common'

type TError = {
  [key: string]: string | number
}

type TTimer = number | undefined

interface IProps {
  error?: TError
  children: ReactElement
}

const COUNTDOWN = 7
const TIME_INTERVAL = 1
const AUTH_TOKEN_NAME = 'clientToken'

const AuthErrorComponent: FC<IProps> = (props) => {
  const { error = {}, children } = props
  const type = error?.type || ''
  const errorMessage = error?.message || ''

  const [isAuthErrorOccurred, setIsAuthErrorOccurred] = useState<boolean>(false)
  const [countDown, setCountDown] = useState(COUNTDOWN)

  const timerRef = useRef<TTimer>()

  const [, , removeCookies] = useCookies([AUTH_TOKEN_NAME])

  useEffect(() => {
    if (type === UNAUTHORIZED || errorMessage === INVALID_TOKEN) {
      removeCookies(AUTH_TOKEN_NAME)
      setIsAuthErrorOccurred(true)
    }
  }, [removeCookies, type, errorMessage])

  useEffect(() => {
    if (isAuthErrorOccurred && countDown < 2) {
      window.clearInterval(timerRef.current)
      window.location.reload()
    }
  }, [countDown, isAuthErrorOccurred])

  useEffect(() => {
    if (!isAuthErrorOccurred) return
    timerRef.current = window.setInterval(
      () => setCountDown((prevState) => prevState - TIME_INTERVAL),
      1000
    )
    return () => window.clearInterval(timerRef.current)
  }, [isAuthErrorOccurred])

  if (!isAuthErrorOccurred) return children as ReactElement

  return (
    <div
      className='d-flex-centered flex-column w-100 min-height-50vh mx-auto text-center'
      style={{ maxWidth: '90vw' }}
    >
      <h2 className='text-muted'>{'Ваша сессия истекла'}</h2>
      <p>{'Необходимо повторно авторизоваться в личном кабинете'}</p>
      <p>
        {'Вы будете автоматически перенаправлены на страницу входа через:'}{' '}
        <span className='fw-bold text-primary'>{countDown}</span>
        {' c.'}
      </p>
      <p>
        {'Если этого не произошло, перейдите по '}
        <a href='' className='fw-bold'>
          {'ссылке'}
        </a>
        {'.'}
      </p>
    </div>
  )
}

export default AuthErrorComponent
