import intl from '#intl'

import BaseHttpClientError from './BaseHttpClientError'

export default class NetworkError extends BaseHttpClientError {
  constructor(message = intl.netWorkErr) {
    super(message)
    this.name = 'NetworkError'
    this.code = 503
  }
}
