import './navbar.scss'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined'
import classnames from 'classnames'
import { camelCase } from 'lodash'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import config from '#config'
import { buildDataQa } from '#services/helper'

import intl from '../../intl/ru.json'
import noty from '../../services/notify'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import Overlay from '../Overlay/Overlay'
import * as routes from '../PersonalAreaPage/routes'
import { Spinner } from '../Spinner/Spinner'

export class NavBar extends Component {
  modalRef = createRef()
  targetModal = null

  constructor(props) {
    super(props)
    this.state = { isMenuActive: false, isLoggingOut: false }
    this.isIOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    this.dataQa = 'navBar'
  }

  handleToggleMenu = () => this.setState((prevState) => ({ isMenuActive: !prevState.isMenuActive }))

  handleLogout = async () => {
    this.setState({ isLoggingOut: true })
    try {
      await this.context.logout()
    } catch (err) {
      console.error(err)
      const { error = {} } = err
      const message = err.message || error.message || intl.netWorkErr
      noty.push({ message, type: 'danger' })
    } finally {
      this.setState({ isLoggingOut: false })
    }
  }

  renderBurger = () => {
    const { isMenuActive } = this.state
    const classes = classnames({ burger: true, 'is-active': isMenuActive })
    return (
      <span
        className={classes}
        onClick={this.handleToggleMenu}
        data-qa={buildDataQa(this.dataQa, 'burgerIcon')}
      >
        <i className='burger__icon' />
      </span>
    )
  }

  renderLogOutButton = () => (
    <span className='navbar__login' onClick={this.handleLogout}>
      <Icon
        className='navbar__login-icon'
        name='logout'
        data-qa={buildDataQa(this.dataQa, 'logoutButton')}
      />
      <Button
        className='navbar__login-button'
        size='s'
        inverted
        data-qa={buildDataQa(this.dataQa, 'logoutButton')}
      >
        {intl.logoutAction}
      </Button>
    </span>
  )

  renderLogInButton = () => (
    <Link className='navbar__login' to='/login'>
      <Icon
        className='navbar__login-icon'
        name='user'
        data-qa={buildDataQa(this.dataQa, 'loginButton')}
      />
      <Button
        className='navbar__login-button'
        size='s'
        inverted
        data-qa={buildDataQa(this.dataQa, 'loginButton')}
      >
        {intl.loginAction}
      </Button>
    </Link>
  )

  buildNavLinkDataQa = ({ type, pathname, url }) => {
    const path = type === 'internal' ? url : pathname
    return buildDataQa(this.dataQa, 'linkTo', camelCase(path.replace(/\//g, '')) || 'main')
  }

  renderNavLinks = (routes, options = {}) => {
    const { dontKeepInternalHistory } = this.props
    const { type = 'internal', style = 'topNav', showIcons = false } = options
    const isTopNav = style === 'topNav'
    const isSideNav = style === 'sideNav'
    const styleObj = isSideNav ? { width: '250px', fontSize: '10px' } : null

    const listClasses = classnames({
      'p-2': true,
      navbar__container_top: isTopNav
    })
    const linkClasses = classnames({
      'd-flex justify-content-between': isTopNav,
      'text-decoration-none text-secondary': isSideNav
    })
    const listItemClasses = classnames({
      'p-0': isTopNav
    })

    const NavLink = (props) =>
      type === 'internal' ? (
        <Link to={props.url} {...props} replace={dontKeepInternalHistory}>
          {props.children}
        </Link>
      ) : (
        <a href={props.url} {...props}>
          {props.children}
        </a>
      )

    const IconComponent = ({ icon }) =>
      icon ? (
        <Icon name={icon} className='icon-sm' />
      ) : (
        <KeyboardArrowRightOutlinedIcon style={{ marginRight: '4px' }} />
      )

    return (
      <List style={styleObj} className={listClasses}>
        {routes.map(({ text, url, icon, pathname }) => (
          <NavLink
            key={text}
            className={linkClasses}
            url={url}
            data-qa={this.buildNavLinkDataQa({ type, url, pathname })}
          >
            <ListItem button={isSideNav} className={listItemClasses}>
              <ListItemText>{text}</ListItemText>
              {showIcons && (
                <ListItemIcon style={{ minWidth: '25px' }}>
                  <IconComponent icon={icon} />
                </ListItemIcon>
              )}
            </ListItem>
          </NavLink>
        ))}
      </List>
    )
  }

  renderSideMenu = () => {
    const { token } = this.props
    const isAuth = Boolean(token)
    return (
      <div
        role='presentation'
        onClick={this.handleToggleMenu}
        data-qa={buildDataQa(this.dataQa, 'sideMenu')}
      >
        {isAuth && (
          <>
            {this.renderNavLinks(routes.internal, {
              type: 'internal',
              style: 'sideNav',
              showIcons: true
            })}
            <Divider />
          </>
        )}
        {this.renderNavLinks(routes.external, {
          type: 'external',
          style: 'sideNav',
          showIcons: true
        })}
      </div>
    )
  }

  render() {
    const { token } = this.props
    const isAuth = Boolean(token)
    const classes = classnames({ navbar: true }, this.props.className)
    const { isMenuActive, isLoggingOut } = this.state
    return (
      <nav className={classes}>
        {this.renderBurger()}
        <a className='navbar__logo' href={config.host} data-qa={buildDataQa(this.dataQa, 'logo')}>
          <Icon name='logo-textless' />
          <Icon name='logo-short' />
        </a>
        {this.renderNavLinks(routes.external, { type: 'external' })}
        {isAuth ? this.renderLogOutButton() : this.renderLogInButton()}
        <Overlay opacity={0.2} className='d-flex-centered' isOpen={isLoggingOut}>
          <Spinner className='spinner-centered text-primary spinner-lg' />
        </Overlay>
        <SwipeableDrawer
          disableDiscovery={this.isIOS}
          open={isMenuActive}
          onClose={this.handleToggleMenu}
          onOpen={this.handleToggleMenu}
        >
          {this.renderSideMenu()}
        </SwipeableDrawer>
      </nav>
    )
  }
}

NavBar.propTypes = {
  className: PropTypes.string,
  token: PropTypes.string,
  dontKeepInternalHistory: PropTypes.bool
}

NavBar.contextTypes = { logout: PropTypes.func }

const mapStateToProps = (state) => ({ token: state.app.loggedInToken })

export default connect(mapStateToProps)(NavBar)
