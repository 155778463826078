import {
  ABSOLUT_PROCESSING_PERSONAL_DATA,
  D2_PROCESSING_PERSONAL_DATA,
  DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA,
  SCORISTA_PROCESSING_PERSONAL_DATA
} from '#components/PersonalAreaPage/components/ConfirmLoanForm/model'
import {
  CheckingResults,
  ClientAccountStatus,
  ClientAccountType,
  LoanProductTypes,
  NotificationType,
  RefinanceErrors,
  UniqueCodesConditionName
} from '#modules/api/personal/enums'

import * as AdminApiTypes from '../admin/types'
import {
  ClientLoginCode,
  ICodeError,
  ICodeLoansError,
  ICodeWithMessage,
  IRefinanceCodeError,
  RefinanceResponseDataExistCode,
  RefinanceResponseErrorCode
} from '../types/common'

// ====== interfaces ======
export interface IGetMarketNotificationsSuccess extends ICodeWithMessage {
  data: TMarketNotification[]
}

export enum DataSetType {
  mobileNumber = 'mobileNumber',
  prevTotalIncome = 'prevTotalIncome',
  currentTotalIncome = 'currentTotalIncome',
  innFromService = 'innFromService',
  full = 'full'
}

export type TLoanData =
  | Loan.IProductBankruptcy
  | Loan.IProductSold
  | Loan.IShowProductInfo
  | Loan.IClientBlocked
  | Loan.IProductConfirm
  | Loan.IProductInProgress
  | Loan.IProductDenied
  | Loan.IProductCreated
  | Loan.IProductTkbStub
  | Loan.IProductOrder

interface IGetLoansSuccess extends ICodeWithMessage {
  data: {
    loans: TLoanData[]
  }
}

export interface IGetClientRefinanceInfo extends ICodeWithMessage {
  data: TNullable<{ id: number; creditId: number; creditIdTo: TNullable<number> }>
  metadata: {
    isEnabled: boolean
    errorMessage: string
    amount: number
  }
}

type TEarlyDataSuccess = {
  id: number
  creditId: number
  creditIdTo: number
}

export interface ISendEarlyCodeSuccess extends ICodeWithMessage {
  data: TEarlyDataSuccess
  metadata: {
    isEnabled: boolean
    errorMessage: string
    amount: number
  }
}

interface ICheckConfirmEarlySuccess extends ICodeWithMessage {
  data: {
    error: 0 | 1
    message: string
  }
}

interface IRefinanceCodeExist {
  code: RefinanceResponseDataExistCode.exist
  message: string
  data: {
    id: number
    creditId: number
    creditIdTo: number
  }
}

interface IRefinanceCodeErrorByCard {
  code: RefinanceResponseErrorCode.error
  data: null
  metadata: {
    isEnabled: boolean
    errorMessage: string
    amount: number
    errorCode: RefinanceErrors.CHECK_ISSUING_MONEY_TO_CARD_CODE
  }
}

interface IBaseClientAccount {
  account: string
  createTs: string
  disableReason: string
  id: number
  isActive: TNullable<number>
  isApproved: TNullable<number>
  isBillStop: number
  isValid: number
  isVerified: TNullable<number>
  status: ClientAccountStatus
  type: ClientAccountType
}

export interface IGetClientCardAccount extends IBaseClientAccount {
  type: ClientAccountType.card
  cardHolder: string
  validTs: string
}

interface IGetClientBankAccount extends IBaseClientAccount {
  type: ClientAccountType.account
  bankName: string
  bik: string
}

interface IGetClientAccountSuccess extends ICodeWithMessage {
  data: TAccounts
}

interface ICreateCreditSuccess extends ICodeWithMessage {
  data: {
    creditId: number
  }
}

interface ILoginSuccess {
  code: ClientLoginCode.success
  message: string
  token: string
}

interface ILoginInvalidCredentials {
  code: ClientLoginCode.invalidCredentials
  error: {
    message: string
    type: 'AuthError'
  }
}

interface ILoginUserIsRemoved {
  code: ClientLoginCode.isRemoved
  error: {
    message: string
    type: 'AuthError'
  }
}

interface ISendRecoveryCodeSuccess extends ICodeWithMessage {
  data: {
    clientNotificationType: NotificationType
  }
}

interface IGetPaymentSchedulerSuccess extends ICodeWithMessage {
  data: {
    count: TPaymentScheduler<number>
    nextPaymentDate: TPaymentScheduler<TTimeZone>
    nextPaymentAmount: TPaymentScheduler<number>
    paymentAmountSum: TPaymentScheduler<number>
    paymentPrincipalSum: TPaymentScheduler<number>
    paymentInterestSum: TPaymentScheduler<number>
    periods: TPaymentPeriods[]
  }
}

interface IGetDocumentListSuccess extends ICodeWithMessage {
  data: TUserDocument[]
}

export interface IGetLoanHistorySuccess extends ICodeWithMessage {
  data: {
    currentPage: number
    itemsPerPage: number
    totalItems: number
    items: TLoanHistoryItem[]
  }
}

interface IAddCardAccountSuccess extends ICodeWithMessage {
  accountId: number
}

export interface IFreezeStructure {
  stepsCount: number
  step: number
  creditId: number
  document: string
  format: string
  params: {
    freezeId: number
  }
}

interface IGetFreezeStateSuccess extends ICodeWithMessage {
  data: TNullable<IFreezeStructure>
}

interface IConfirmFreezeSuccess extends ICodeWithMessage {
  data: {
    isFinished: boolean
  }
}

export interface IPaymentData {
  url: TNullable<string>
  message: TNullable<string>
  button: string
  error: boolean | string
  hiddenFields: Array<{ [key: string]: string }>
  method: TNullable<string>
  action: TNullable<string>
  paymentSystem: number
}

export interface IPercentStatusData {
  status: number
  message: string
}

export type TRefusalToInteractData = {
  loading: boolean
  isFetched: boolean
  isSendRepresentativeData: boolean
  isSendStatement: boolean
  isRefusalToInteractConfirmationButtonActive: boolean
  representativeAddress: TNullable<string>
  representativeEmail: TNullable<string>
  representativeLawyerNumber: TNullable<string>
  representativeName: TNullable<string>
  representativePhone: TNullable<string>
  statementInteractThroughConfirmationDate: TNullable<string | boolean>
  statementRefusalToInteractConfirmationDate: TNullable<string | boolean>
}

export type TRefusalToInteractDataPayload = Omit<
  TRefusalToInteractData,
  'isFetched' | 'isSendRepresentativeData' | 'isSendStatement' | 'loading'
>

export type TRefusalToInteractDataValidateErrors = {
  data: {
    errors: { [key: string]: string }
  }
}

export type TRefusalResponseParams = {
  token: string
  creditId: number
}

export type TSetRepresentativeResponseParams = TRefusalToInteractDataPayload &
  TRefusalResponseParams

export interface IAddPaymentSuccess extends ICodeWithMessage {
  data: IPaymentData
}

export interface ICheckPercentStatusSuccess extends ICodeWithMessage {
  data: IPercentStatusData
}

export interface IRefusalToInteractDataSuccess extends ICodeWithMessage {
  data: TRefusalToInteractData
}

interface IAddCardPhotoSuccess extends ICodeWithMessage {
  cardPhotoId: number
}

export interface IGetDefaultPromoSuccess extends ICodeWithMessage {
  data: string
}

export interface IGetSliderInfoSuccess extends ICodeWithMessage {
  data: {
    defaultProduct: {
      main: LoanProductTypes
      partner: LoanProductTypes
      personal: LoanProductTypes
    }
    promo: {
      freeDays: TNullable<number>
      freeDaysInMaxPeriod: TNullable<number>
      fixSum: TNullable<number>
      fixPercent: TNullable<number>
      periodFree: TNullable<number | { start: TTimeZone; end: TTimeZone }>
      simple: TNullable<number>
      conditions?: TNullable<{
        MAX_POSSIBLE_AMOUNT: number
      }>
    }
    promoDescription: TNullable<string>
    promoType: TNullable<number>
    promoStyle:
      | []
      | {
          [key in UniqueCodesConditionName]: string | number | null
        }
    products: {
      [key in LoanProductTypes]: IProductItem[]
    }
    insuranceList: IInsuranceItem[]
  }
}

interface IConfirmCardSuccess extends ICodeWithMessage {
  url: TNullable<string>
}

interface IEditEmailSuccess extends ICodeWithMessage {
  email: string
}

interface IGetBonusForEmailSuccess extends ICodeWithMessage {
  data: {
    isEnabled: boolean
    title: string
    content: string
  }
}

interface IGetPersonalFilesListSuccess extends ICodeWithMessage {
  data: TFileItem[]
}

interface ICheckPassportSuccess extends ICodeWithMessage {
  isExpired: CheckingResults
}

interface IGetPaymentSystemsSuccess extends ICodeWithMessage {
  data: {
    paymentTypes: TPaymentType[]
  }
}

// ====== types ======
export type TButtonItem = {
  color: string
  id: number
  text: string
  textColor: string
  type: 'ACCEPT' | 'CLOSE' | 'REJECT'
  url: TNullable<string>
}

export type TMarketNotification = {
  id: number
  buttons: TButtonItem[]
  desktopImage: TNullable<string>
  tabletImage: TNullable<string>
  mobileImage: TNullable<string>
  text: string
  type: AdminApiTypes.TNotificationType
}

export type TAccounts = (IGetClientCardAccount | IGetClientBankAccount)[]
export type TAccountData = IGetClientCardAccount | IGetClientBankAccount

type TPaymentScheduler<T> = {
  format: string
  title: string
  value: T
}
type TTimeZone = {
  date: string
  timezone_type: number
  timezone: string
}

type TPaymentPeriods = {
  paymentAmount: TPaymentScheduler<number>
  paymentPrincipal: TPaymentScheduler<number>
  paymentInterest: TPaymentScheduler<number>
  principalRest: TPaymentScheduler<number>
  periodStart: TPaymentScheduler<TTimeZone>
  paymentDate: TPaymentScheduler<TTimeZone>
  status: TPaymentScheduler<string>
  periodNumber: TPaymentScheduler<number>
}

export type TUserDocument = {
  type: string
  name: string
  parameters?: {
    creditId?: number
    status?: string
    credit_collector_report_id?: number
    prolongationId?: number
    freezeId?: number
    insuranceType?: string
    date?: string
  }
}

type TPaymentItem = {
  paymentNumber: TPaymentScheduler<number> & { name: string }
  paymentAmount: TPaymentScheduler<number> & { name: string }
  paymentProlongation: TPaymentScheduler<number> & { name: string }
  paymentEarly: TPaymentScheduler<string> & { name: string }
  paymentDate: TPaymentScheduler<TTimeZone> & { name: string }
  paymentSource: TPaymentScheduler<string> & { name: string }
  paymentExternalNumber: TPaymentScheduler<number> & { name: string }
}
type TLoanHistoryItem = {
  id: number
  amount: string
  createTs: string
  status: string
  payments: {
    paymentSum: {
      value: number
      format: string
      title: string
      name: string
    }
    paymentsList?: TPaymentItem[]
  }
  documents: TUserDocument[]
}

type TFileItem = {
  id: number
  fileName: string
  documentType: string
  downloadLink: string
}

type TCropMessage = {
  code: 5
  message: string
  data: string[]
}

export type TGetPersonalDataParams = {
  token: string
  dataSet: PersonalData.DataSetType
}

export type TCreateCreditParams = {
  token: string
  c4s?: string
  promoCode?: string
  ymUid?: string
  amount: number
  term: number
  clientAccount: number
  creditType: LoanProductTypes
}

export type TPaymentType = {
  type: number
  name: string
  prolongation: boolean
}

interface IGetPaymentSystemsSuccess extends ICodeWithMessage {
  data: {
    paymentTypes: TPaymentType[]
  }
}

export enum TServiceStatus {
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  DECLINE = 'DECLINE',
  NOT_ACTIVE = 'NOT_ACTIVE',
  PAID = 'PAID',
  AUTOPAYMENT_OFF = 'AUTOPAYMENT_OFF',
  EXPIRED = 'EXPIRED',
  RENEWAL_REQUESTED = 'RENEWAL_REQUESTED',
  ENDED = 'ENDED'
}

export enum TServiceStatusIntl {
  NOT_CONFIRMED = 'Ожидает оплаты',
  DECLINE = 'Отменена',
  NOT_ACTIVE = 'На активации',
  PAID = 'Активна',
  AUTOPAYMENT_OFF = 'Автосписание отменено',
  EXPIRED = 'Просрочена',
  RENEWAL_REQUESTED = 'Запрошено восстановление',
  ENDED = 'Завершена'
}

export type TServiceItem = {
  productName: string
  serviceAgreementId: number
  paymentsLogId: TNullable<number>
  dateConfirm: string
  dateStart: TNullable<string>
  dateExpiration: TNullable<string>
  monthlyPayment: TNullable<string>
  status: TServiceStatus
  statusText: string
  serviceType: 'current' | 'archive'
  serviceEnabled: number
  showCancelButton: boolean
}

interface IGetUserServicesSuccess extends ICodeWithMessage {
  data: {
    services: TServiceItem[]
  }
}

export enum RemovePersonalAccountErrorTypes {
  insuranceExists = 'ExistsActiveInsurance',
  creditExists = 'ExistsOpenCredit'
}

export type TActiveInsuranceError = {
  message: string
  type: RemovePersonalAccountErrorTypes.insuranceExists
  detail: null
}

export type TActiveCreditsError = {
  message: string
  type: RemovePersonalAccountErrorTypes.creditExists
  detail: string[]
}

interface IDeletePersonalAccountError {
  code: 100
  message: string
  errors: (TActiveInsuranceError | TActiveCreditsError)[]
  status: 'error'
}

export type TConfirmCreditParams = {
  token: string
  creditId: number
  mobileNumber: string
  inn: string
  totalIncome: string
  smsCode: string
  insuranceLifeChecked?: 0 | 1
  insuranceCardChecked?: 0 | 1
  serviceDoctorNearbyChecked?: 0 | 1
  serviceRiskChecked?: 0 | 1
  assignmentOfRightsChecked?: 0 | 1
  d2InsuranceCardsChecked?: 0 | 1
  d2InsuranceChecked?: 0 | 1
  tkbLifeChecked?: 0 | 1
  veonProcessingPersonalDataChecked: 0 | 1
  megafonProcessingPersonalDataChecked: 0 | 1
  mtsProcessingPersonalDataChecked: 0 | 1
  t2ProcessingPersonalDataChecked: 0 | 1
  tkbProcessingPersonalDataChecked: 0 | 1
  [ABSOLUT_PROCESSING_PERSONAL_DATA]: 0 | 1
  [D2_PROCESSING_PERSONAL_DATA]: 0 | 1
  fincardProcessingPersonalDataChecked: 0 | 1
  [DOCTOR_NEARBY_PROCESSING_PERSONAL_DATA]: 0 | 1
  [SCORISTA_PROCESSING_PERSONAL_DATA]: 0 | 1
}

export type TGetDefaultPromoPersonal = ({
  token
}: {
  token: string
}) => Promise<TGetDefaultPromoResponse>

export type TGetSliderInfoPersonal = ({
  promoCode,
  token
}: {
  promoCode?: string
  token: string
}) => Promise<TGetSliderInfoResponse>

export type TCustomResponse = ICodeWithMessage | ICodeError
export type TGetMarketNotifications = IGetMarketNotificationsSuccess | ICodeError
export type TMarkAsReadMarketNotification = ICodeWithMessage | ICodeError
export type TSendEarlyCode = ICheckConfirmEarlySuccess | ICodeError
export type TSendEarlyRefinanceCode =
  | ISendEarlyCodeSuccess
  | IRefinanceCodeError
  | IRefinanceCodeExist
  | IRefinanceCodeErrorByCard
export type TCheckConfirmEarly = ICheckConfirmEarlySuccess | ICodeError
export type TGetClientRefinanceInfo = IGetClientRefinanceInfo | IRefinanceCodeError
export type TGetClientAccount = IGetClientAccountSuccess | ICodeError
export type TSetCardBindFillFormComplete = {
  data: boolean
}
export type TCreateCredit = ICreateCreditSuccess | ICodeError
export type TGetPaymentSystems = IGetPaymentSystemsSuccess | ICodeError
export type TLoginResponse =
  | ILoginSuccess
  | ILoginInvalidCredentials
  | ILoginUserIsRemoved
  | ICodeError
export type TLoginWithTokenResponse = ICodeWithMessage | ICodeError
export type TLogoutResponse = ICodeWithMessage | ICodeError
export type TSendRecoveryCodeResponse = ISendRecoveryCodeSuccess | ICodeError
export type TPersonalDataFull = PersonalData.Full
export type TInnFromService = PersonalData.InnFromService
export type TGetPersonalDataResponse =
  | PersonalData.Full
  | PersonalData.MobileNumber
  | PersonalData.InnFromService
  | PersonalData.PrevTotalIncome
  | PersonalData.CurrentTotalIncome
  | ICodeError
export type TGetPaymentScheduleResponse = IGetPaymentSchedulerSuccess | ICodeError
export type TGetDocumentListResponse = IGetDocumentListSuccess | ICodeError
export type TGetLoanHistoryResponse = IGetLoanHistorySuccess | ICodeError
export type TAddBillAccountResponse = ICodeWithMessage | ICodeError
export type TAddCardAccountResponse = IAddCardAccountSuccess | ICodeError
export type TGetFreezeStateResponse = IGetFreezeStateSuccess | ICodeError
export type TConfirmFreezeResponse = IConfirmFreezeSuccess | ICodeError
export type TFreezeRepeatSmsResponse = ICodeWithMessage | ICodeError
export type TAddPaymentResponse = IAddPaymentSuccess | ICodeError
export type TEarlyRepaymentResponse = ICodeWithMessage | ICodeError
export type TCancelEarlyRepaymentResponse = ICodeWithMessage | ICodeError
export type TRepeatCreditConfirmCodeResponse = ICodeWithMessage | ICodeError
export type TConfirmCreditResponse = ICodeWithMessage | ICodeError
export type TAddCardPhotoResponse = IAddCardPhotoSuccess | ICodeError
export type TCropCardPhotoResponse = ICodeWithMessage | ICodeError | TCropMessage
export type TGetLoansResponse = IGetLoansSuccess | ICodeLoansError | ICodeError
export type TGetDefaultPromoResponse = IGetDefaultPromoSuccess | ICodeError
export type TGetSliderInfoResponse = IGetSliderInfoSuccess | ICodeError
export type TConfirmCardResponse = IConfirmCardSuccess | ICodeError
export type TEditEmailResponse = IEditEmailSuccess | ICodeError
export type TRepeatEmailLinkResponse = ICodeWithMessage | ICodeError
export type TGetBonusForEmailResponse = IGetBonusForEmailSuccess | ICodeError
export type TRemoveAccountSuccess = ICodeWithMessage | ICodeError
export type TConfirmGrayResponse = ICodeWithMessage | ICodeError
export type TChangePhoneNumberResponse = ICodeWithMessage | ICodeError
export type TChangePhoneNumberConfirmResp = ICodeWithMessage | ICodeError
export type TGetPersonalFilesListResponse = IGetPersonalFilesListSuccess | ICodeError
export type TCheckPassportResponse = ICheckPassportSuccess | ICodeError
export type TGetUserServicesResponse = IGetUserServicesSuccess | ICodeError
export type TDataCorrectedResponse = ICodeWithMessage | ICodeError
export type TDeletePersonalAccountResponse = ICodeWithMessage | IDeletePersonalAccountError
export type TCheckPercentStatusResponse = ICheckPercentStatusSuccess | ICodeError
export type TRefusalToInteractDataResponse = IRefusalToInteractDataSuccess | ICodeError
