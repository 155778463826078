/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-var-requires,
*/

import { render } from 'react-dom'

import PersonalContainer from './rootContainers/personalContainer'

const container = document.createElement('div')
container.id = 'app'

const notificationsContainer = document.createElement('div')
notificationsContainer.id = 'notify'

document.addEventListener('DOMContentLoaded', (_event) => {
  document.body.appendChild(notificationsContainer)
  document.body.appendChild(container)

  render(<PersonalContainer notificationsContainer={notificationsContainer} />, container)
})

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./rootContainers/personalContainer.jsx', () => {
    const NextRootContainer = require('./rootContainers/personalContainer.jsx').default
    render(<NextRootContainer notificationsContainer={notificationsContainer} />, container)
  })
}
