import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { TRemoveTestimonialSuccess } from '#modules/api/admin/testimonials'
import {
  createTestimonial,
  getTestimonialById,
  getTestimonialsList,
  removeTestimonial,
  sendTestimonialToSupport,
  updateTestimonial
} from '#reducers/adminPage/testimonials/effects'
import {
  TSetCurrentPageNumber,
  TSetItemsPerPage,
  TTestimonialsReducerState
} from '#reducers/adminPage/testimonials/types'
import { getErrorMessage } from '#reducers/helper'
import notify from '#services/notify'

const initialState: TTestimonialsReducerState = {
  testimonials: [],
  currentTestimonial: {},
  totalItems: 0,
  currentPage: 1,
  itemsPerPage: 7,
  loading: false
}

const testimonialsSlice = createSlice({
  name: 'testimonials',
  initialState,
  reducers: {
    removeTestimonialAction: (state, { payload }: PayloadAction<TRemoveTestimonialSuccess>) => {
      state.testimonials = state.testimonials.filter((item) => item.id !== payload.id)
    },
    setItemsPerPage: (state, { payload }: PayloadAction<TSetItemsPerPage>) => {
      state.itemsPerPage = payload.limit
    },
    setCurrentPageNumber: (state, { payload }: PayloadAction<TSetCurrentPageNumber>) => {
      state.currentPage = payload.offset
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestimonialsList.fulfilled, (state, action) => {
        state.testimonials = action.payload.testimonials
        state.totalItems = action.payload.totalItems
      })
      .addCase(
        removeTestimonial.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id }
            }
          }
        ) => {
          notify.push({ message: 'Отзыв успешно удален', type: 'success' })
          state.testimonials = state.testimonials.filter((item) => item.id !== id)
        }
      )
      .addCase(getTestimonialById.fulfilled, (state, action) => {
        state.currentTestimonial = action.payload.testimonial
      })
      .addCase(createTestimonial.fulfilled, () => {
        notify.push({ message: 'Отзыв успешно добавлен', type: 'success' })
      })
      .addCase(updateTestimonial.fulfilled, () => {
        notify.push({ message: 'Отзыв успешно изменен', type: 'success' })
      })
      .addCase(sendTestimonialToSupport.fulfilled, () => {
        notify.push({ message: 'Отзыв успешно отправлен', type: 'success' })
      })
      .addMatcher(
        isAnyOf(
          getTestimonialsList.pending,
          getTestimonialById.pending,
          updateTestimonial.pending,
          removeTestimonial.pending,
          createTestimonial.pending,
          sendTestimonialToSupport.pending
        ),
        (state) => {
          state.loading = true
        }
      )
      .addMatcher(
        isAnyOf(
          getTestimonialsList.fulfilled,
          getTestimonialById.fulfilled,
          updateTestimonial.fulfilled,
          removeTestimonial.fulfilled,
          createTestimonial.fulfilled,
          sendTestimonialToSupport.fulfilled
        ),
        (state) => {
          state.loading = false
        }
      )
      .addMatcher(
        isAnyOf(
          getTestimonialsList.rejected,
          getTestimonialById.rejected,
          removeTestimonial.rejected,
          updateTestimonial.rejected,
          sendTestimonialToSupport.rejected,
          createTestimonial.rejected
        ),
        (state, action) => {
          const message = getErrorMessage(action)
          notify.push({ message, type: 'danger' })
          state.loading = false
        }
      )
  }
})
export default testimonialsSlice.reducer

export const { setItemsPerPage, setCurrentPageNumber } = testimonialsSlice.actions
