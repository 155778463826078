import { get, noop } from 'lodash'
import { Cookies } from 'react-cookie'

import { personalApi } from '#modules/api'
import { CheckingResults } from '#modules/api/personal/enums'
import { PASSPORT_CHECK_RESULT, ROUTES, SUCCESS_RESPONSE_CODE } from '#src/constants/common'
import storeAccessor from '#src/store/storeAccessor'

type TPromise = Promise<{ isExpired: CheckingResults }>

/**
 * @class
 * @description Модуль для проверки паспорта на действительность (синглтон)
 */
class PassportChecker {
  /**
   * @property
   * Инстанс класса (синглтон)
   */
  private static _instance: PassportChecker

  /**
   * @property
   * Текущий запрос на валидацию (только один в единицу времени)
   */
  private _promise: TPromise | null = null

  /**
   * @property
   * Доступ к кешу
   */
  private readonly _cookies: Cookies = new Cookies()

  /**
   * @property
   * Id таймера
   */
  private _timerId!: ReturnType<typeof setTimeout>

  /**
   * @constructor
   * @description Создает Singleton объект класса PassportChecker (приватный метод)
   */
  private constructor() {
    if (PassportChecker._instance instanceof PassportChecker) {
      throw new Error(
        'PassportChecker singleton already initialized. Use getInstance instead of new keyword'
      )
    }

    PassportChecker._instance = this
  }

  /**
   * @method
   * @description Возвращает Singleton объект класса PassportChecker
   */
  public static getInstance(): PassportChecker {
    if (!PassportChecker._instance) return new PassportChecker()
    return PassportChecker._instance
  }

  /**
   * @method
   * @description Выполнение проверки срока действия паспорта
   */
  public async check(token: string, options: { timeout?: number } = {}): TPromise {
    clearTimeout(this._timerId)
    const { timeout = null } = options
    const notChecked = { isExpired: CheckingResults.notChecked }
    return new Promise((resolve) => {
      if (Number.isInteger(timeout)) {
        this._timerId = setTimeout(() => {
          resolve(notChecked)
        }, Number(timeout) * 1000)
      }
      const request = async (): Promise<void> => {
        if (!this._promise) this._promise = this._request(token)
        try {
          resolve(await this._promise)
        } catch (_e) {
          resolve(notChecked)
        } finally {
          clearTimeout(this._timerId)
        }
      }
      void request()
    })
  }

  /**
   * @method
   * @description Предикат - закешировано ли значение
   */
  public isCached(): boolean {
    return typeof this._cookies.get(PASSPORT_CHECK_RESULT) !== 'undefined'
  }

  /**
   * @method
   * @description Очищает кеш
   */
  public clearCache(): void {
    this._cookies.remove(PASSPORT_CHECK_RESULT, { path: ROUTES.main })
  }

  /**
   * @method
   * @description Достает кешированное значение
   */
  private _getCachedValue(): CheckingResults {
    return Number(this._cookies.get(PASSPORT_CHECK_RESULT) ?? CheckingResults.notChecked)
  }

  /**
   * @method
   * @description Кеширует значение
   */
  private _setCachedValue(value: CheckingResults): void {
    const passportCheckInterval = get(
      storeAccessor.getStoreState(),
      ['settings', 'data', 'user_interface', 'passportCheckInterval'],
      1
    ) as number
    this._cookies.set(PASSPORT_CHECK_RESULT, value, {
      path: ROUTES.main,
      maxAge: passportCheckInterval * 60 * 60
    })
  }

  /**
   * @method
   * @description Запрос на проверку
   */
  private async _request(token: string): TPromise {
    let result = this._getCachedValue()
    if (result === CheckingResults.notChecked) {
      try {
        const response = await personalApi.checkPassport(token)
        const { code } = response
        if (code === SUCCESS_RESPONSE_CODE) result = response.isExpired
      } catch (e) {
        noop(e)
      } finally {
        this._promise = null
        this._setCachedValue(result)
      }
    }
    return { isExpired: result }
  }
}

export default PassportChecker
