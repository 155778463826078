import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction
} from '@reduxjs/toolkit'

import { personalApi } from '#modules/api'
import { TServiceItem, TServiceStatus } from '#modules/api/personal/types'
import { ResponseCode } from '#modules/api/types/common'
import { getErrorMessage } from '#reducers/helper'
import { TMainDataStateType } from '#src/reducers'

type TInitialState = {
  isLoading: boolean
  items: TServiceItem[]
  currentTabIndex: number
  currentItemStatus: {
    [key in number]: {
      isLoading: boolean
      error: string
    }
  }
}
const initialState: TInitialState = {
  isLoading: false,
  items: [],
  currentTabIndex: 0,
  currentItemStatus: {}
}

export const fetchUserServices = createAsyncThunk(
  'service/fetchUserServices',
  async (token: string, thunkAPI) => {
    const response = await personalApi.getUserServices(token)
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({
        message
      })
    }
    return response.data.services
  }
)

export const cancelService = createAsyncThunk(
  'service/cancelService',
  async (
    {
      serviceAgreementId,
      token,
      paymentsLogId
    }: { serviceAgreementId: number; token: string; paymentsLogId: TNullable<number> },
    thunkAPI
  ) => {
    const response = await personalApi.cancelService({
      token,
      serviceAgreementId
    })
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({
        id: serviceAgreementId,
        message
      })
    }
    return { paymentsLogId, serviceAgreementId }
  }
)

export const renewalService = createAsyncThunk(
  'service/renewalService',
  async (
    { serviceAgreementId, token }: { serviceAgreementId: number; token: string },
    thunkAPI
  ) => {
    const response = await personalApi.renewalService({
      token,
      serviceAgreementId
    })
    if (response.code !== ResponseCode.success) {
      const { message } = response
      return thunkAPI.rejectWithValue({
        id: serviceAgreementId,
        message
      })
    }
    return serviceAgreementId
  }
)

const servicesSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.currentTabIndex = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserServices.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserServices.fulfilled, (state, action) => {
        state.items = action.payload
      })
      .addCase(cancelService.fulfilled, (state, action) => {
        state.items = state.items.map((item) => {
          const { paymentsLogId, serviceAgreementId } = action.payload
          if (paymentsLogId && item.paymentsLogId === paymentsLogId) {
            return {
              ...item,
              status: TServiceStatus.DECLINE
            }
          }
          if (item.serviceAgreementId === serviceAgreementId) {
            return {
              ...item,
              status: TServiceStatus.DECLINE
            }
          }
          return item
        })
      })
      .addCase(renewalService.fulfilled, (state, action) => {
        state.items = state.items.map((item) => {
          if (item.serviceAgreementId === action.payload) {
            return {
              ...item,
              status: TServiceStatus.RENEWAL_REQUESTED
            }
          }
          return item
        })
      })
      .addCase(renewalService.pending, (state, action) => {
        const {
          meta: {
            arg: { serviceAgreementId }
          }
        } = action
        state.currentItemStatus[serviceAgreementId] = {
          isLoading: true,
          error: ''
        }
      })
      .addCase(cancelService.pending, (state, action) => {
        const {
          meta: {
            arg: { serviceAgreementId, paymentsLogId }
          }
        } = action
        // лоадер для элемента с индексом serviceAgreementId
        state.currentItemStatus[serviceAgreementId] = {
          isLoading: true,
          error: ''
        }
        //  лоадер для элемента с индексом, который имеет такой же paymentsLogId
        state.items.forEach((item) => {
          if (paymentsLogId && paymentsLogId === item.paymentsLogId) {
            state.currentItemStatus[item.serviceAgreementId] = {
              isLoading: true,
              error: ''
            }
          }
        })
      })
      .addCase(cancelService.rejected, (state, action) => {
        const {
          meta: {
            arg: { serviceAgreementId, paymentsLogId }
          }
        } = action
        // лоадер для элемента с индексом serviceAgreementId
        state.currentItemStatus[serviceAgreementId] = {
          isLoading: false,
          error: ''
        }
        //  лоадер для элемента с индексом, который имеет такой же paymentsLogId
        state.items.forEach((item) => {
          if (paymentsLogId && paymentsLogId === item.paymentsLogId) {
            state.currentItemStatus[item.serviceAgreementId] = {
              isLoading: false,
              error: ''
            }
          }
        })
      })
      .addCase(renewalService.rejected, (state, action) => {
        const {
          meta: {
            arg: { serviceAgreementId }
          }
        } = action
        state.currentItemStatus[serviceAgreementId] = {
          isLoading: false,
          error: getErrorMessage(action)
        }
      })
      .addMatcher(isAnyOf(fetchUserServices.fulfilled, fetchUserServices.rejected), (state) => {
        state.isLoading = false
      })
  }
})

const serviceItems = (state: TMainDataStateType): TServiceItem[] => state.clientData.services.items
export const loadingSelector = (state: TMainDataStateType): boolean =>
  state.clientData.services.isLoading
export const currentServicesSelector = createSelector(serviceItems, (items) =>
  items.filter((item) => item.serviceType === 'current')
)
export const archiveServicesSelector = createSelector(serviceItems, (items) =>
  items.filter((item) => item.serviceType === 'archive')
)
export const hasServicesSelector = createSelector(serviceItems, (items) => items.length)
export const currentTabIndexSelector = (state: TMainDataStateType): number =>
  state.clientData.services.currentTabIndex
export const selectCurrentItemStatusById = (
  state: TMainDataStateType,
  id: number
): { isLoading: boolean; error: string } =>
  state.clientData.services.currentItemStatus[id] ?? { isLoading: false, error: '' }
export const { setTabIndex } = servicesSlice.actions
export default servicesSlice.reducer
